/* eslint import/prefer-default-export: 0 */
import api from '../common';

function getRiskCategories() {
  return api.get('/odata/RiskCategoryOData?$select=Id,Description,Code&',{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

export {
    getRiskCategories,
};
