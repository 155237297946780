import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from "antd";
import { Pie } from '@ant-design/charts';

class CustomPieChart extends React.Component {
//   constructor(props) {
//     super(props);
//     // const {appendPadding, angleField, colorField, radius, legend, label, interactions} = this.props;
//     this.state = {      
//       pieConfig: {
//         appendPadding: 10,
//         data: [],
//         angleField: '',
//         colorField: '',
//         radius: 0.8,
//         legend: false,
//         label: {
//             type: 'inner',
//             content: '{name}',
//             style: {
//             fontSize: 11,
//             },
//         },
//         interactions: [
//             {
//             type: 'element-active',
//             },
//         ],
//         },
//     }
//   }

//   componentDidMount() {
//     this.setProgress();
//   }

//   componentDidUpdate(prevProps) {    
//     if (this.props.data !== prevProps.data || this.props.data > 0) {
//       console.log('looking for update', prevProps.data, this.props.data);
//       this.setProgress()
//     }
//   }

//   setProgress = () =>{
//     const {appendPadding, data, angleField, colorField, radius, legend, label, interactions} = this.props;
//     console.log('data', data);
//     this.state = {      
//       pieConfig: {
//         appendPadding,
//         data,
//         angleField,
//         colorField,
//         radius,
//         legend,
//         label,
//         interactions,
//       },
//     }
//   }
    
render() {  
    return (
      <>
        <Spin spinning={this.props.loading}>
          <Pie {...this.props} />
        </Spin>
      </>
    );
  }
}

CustomPieChart.propTypes = {
  loading: PropTypes.bool,
  autofit: PropTypes.bool,
  appendPadding: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  angleField: PropTypes.string,
  colorField: PropTypes.string,
  radius: PropTypes.number,
  legend: PropTypes.bool,
  interactions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
  })),
  label: PropTypes.shape({
    type: PropTypes.string,
    labelHeight:PropTypes.number,
    content: PropTypes.string,
    style: PropTypes.shape({
      fontSize: PropTypes.number,
    }),
  })
};

CustomPieChart.defaultProps = {
  loading: false,
  autofit: true,
  appendPadding: 10,
  data: [],
  angleField: '',
  colorField: '',
  radius: 0.8,
  legend: true,
  label: {
    type: 'inner',
    labelHeight: 18,
    content: '{name}\n{percentage}',
    style: {
      fontSize: 11,
    },
  },
  interactions: [
    {
      type: 'element-selected',
    },
    {
      type: 'element-active',
    },
  ],
};

export { CustomPieChart as default };