import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spin } from "antd";

class CountDisplay extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col xs={18} md={18} lg={18} xl={24}>
            <div className="p5-form-container" style={{ padding: '75px' }}>
              <h1 className="p5-dash-h1-header p5-page-h1-header text-center">
                {
                    this.props.loading ? (
                      <Spin size="medium" />
                    ) : (
                      <span className="dash-percent-value">{this.props.count}</span>
                    )
                }
              </h1>
              <span>
                <h1 className="p5-dash-h1-header text-center">
                  {this.props.title}
                </h1>
              </span>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

CountDisplay.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  count: PropTypes.number,
};

CountDisplay.defaultProps = {
  loading: false,
  title: "",
  count: 0,
};

export { CountDisplay as default };
