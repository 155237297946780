import React from "react";
import PropTypes from "prop-types";
import { push } from 'connected-react-router';
import { connect } from "react-redux";
import { Row, Col, Divider, Checkbox } from "antd";
import { ShepherdTour, TourMethods } from 'react-shepherd'
import moment from "moment";
import { DashboardTourSteps} from '../tour-steps';
import DateSelect from "../../../common/components/date-select";
import { DynamicSelect } from "../../../common/components/dynamic-select";
import { hasRights, rights } from "../../../common/utils/role-right-helper";
import { agencyService, AreaService, TeamService } from "../../../services";
import navigationActions from "../../../common/components/navigation/action";
import TourGuideAction from "../actions";
import TourGuideStartModal from "../components/tour-guide-start-modal";
import systemAdminActions from "../../home/actions";
import { OverdueProgressView, NoLocationProgressView, ActivatedDeviceProgressView, WNAppAllocationView, HandsetAllocationView, RiskCategoryBarChartView, GenderBarChartView, NewDeviceView, RegisteredDeviceCountView } from "../../home/components";
import "../../home/page/HomePage.css";

const { tourOptions, DashboardSteps } = DashboardTourSteps;

class DashboardTourPage extends React.Component {
  constructor(props) {
    super(props);
    const minStartDate = moment().subtract(1, 'months') < moment('1/1/2021') ? moment('1/1/2021') : moment().subtract(1, 'months');
    this.state = {
      startDate: minStartDate,
      endDate: moment(),
      Force: {Id: this.props.ForceId, Name: ""},
      Area: {Id: null, Name: ""},
      Team: {Id: null, Name: ""},
      // DeviceType: {Id: 2, Name: ""}
      ViewAll: false,
      TourStartModalshow: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.props.dispatch(TourGuideAction.CheckUserActiveTourGuides(this.props.userId));
    this.setState({ TourStartModalshow: true });
    this.props.dispatch(TourGuideAction.GetCurrentTourGuide(
      9,
      this.props.userId
    ));
    switch(hasRights([rights.CanManageSystemInfo, rights.CanManageForces]) || hasRights([rights.CanManageAreaUsers])) {
      case true:
        this.setState({
          Force: {
            Id: this.props.ForceId
          }                        
        });        
        break;
      case false:
        dispatch(push('/register'));
        break;
      default:
        break;
    }

    this.props.dispatch(navigationActions.changePage("dashboard"));
    // this.getVictimData(this.state.startDate, this.state.endDate);
    this.getDevicesRegistered(this.state.startDate, this.state.endDate, this.state.Force.Id, this.state.Area.Id, this.state.Team.Id, this.state.ViewAll);
    this.getActivatedDevices(this.state.startDate, this.state.endDate, this.state.Force.Id, this.state.Area.Id, this.state.Team.Id, this.state.ViewAll);
    this.getGendersByRegisteredDevices(this.state.startDate, this.state.endDate, this.state.Force.Id, this.state.Area.Id, this.state.Team.Id, this.state.ViewAll);
    this.getRiskCategoryByRegisteredDevices(this.state.startDate, this.state.endDate, this.state.Force.Id, this.state.Area.Id, this.state.Team.Id, this.state.ViewAll);
    this.getOverdueRegistrations(this.state.startDate, this.state.endDate,this.state.Force.Id, this.state.Area.Id, this.state.Team.Id, this.state.ViewAll);
    this.getNoReportedLocations(this.state.startDate, this.state.endDate, this.state.Force.Id, this.state.Area.Id, this.state.Team.Id, this.state.ViewAll);
    this.getAllocationDetails(this.state.startDate, this.state.endDate,this.state.Force.Id, this.state.ViewAll);
    this.getAllocationUsageDetails(this.state.startDate, this.state.endDate, this.state.Force.Id, this.state.Area.Id, this.state.Team.Id, this.state.ViewAll);
  }

  hideTourGuideLandingModal = () => {
    this.setState({ TourStartModalshow: false });
  };

  pivotCompleteData = () => ({
    TourId: this.props.CurrentTourGuide.TourGuideId,
    UserId: this.props.userId
  });

  CompleteTourGuide = () => {
    this.props.dispatch(TourGuideAction.MarkTourGuideAsCompleted(
      this.pivotCompleteData(),
      this.props.dispatch(TourGuideAction.CheckUserActiveTourGuides(this.props.userId))
    ));
  }

  getDevicesRegistered = (startDate, endDate, forceId, areaId, teamId, viewAll) => {
    const startUtcTime = moment(startDate).local();
    const endUtcTime = moment(endDate).local();
    this.props.dispatch(systemAdminActions.GetDevicesRegistered(startUtcTime.format("l"), endUtcTime.format("l"), forceId, areaId, teamId, viewAll));
  }

  getGendersByRegisteredDevices = (startDate, endDate, forceId, areaId, teamId, viewAll) => {
    const startUtcTime = moment(startDate).local();
    const endUtcTime = moment(endDate).local();
    this.props.dispatch(systemAdminActions.GetGendersByDeviceType(startUtcTime.format("l"), endUtcTime.format("l"), forceId, areaId, teamId, viewAll));
  }

  getRiskCategoryByRegisteredDevices = (startDate, endDate, forceId, areaId, teamId, viewAll) => {
    const startUtcTime = moment(startDate).local();
    const endUtcTime = moment(endDate).local();
    this.props.dispatch(systemAdminActions.GetRiskCategoryByDeviceType(startUtcTime.format("l"), endUtcTime.format("l"), forceId, areaId, teamId, viewAll));
  }

  getActivatedDevices = (startDate, endDate, forceId, areaId, teamId, viewAll) => {
    const startUtcTime = moment(startDate).local();
    const endUtcTime = moment(endDate).local();
    this.props.dispatch(systemAdminActions.GetActivatedDevices(startUtcTime.format("l"), endUtcTime.format("l"), forceId, areaId, teamId, viewAll));
  }

  getOverdueRegistrations = (startDate, endDate, forceId, areaId, teamId, viewAll) => {
    const startUtcTime = moment(startDate).local();
    const endUtcTime = moment(endDate).local();
    this.props.dispatch(systemAdminActions.GetOverdueRegistrations(startUtcTime.format("l"), endUtcTime.format("l"), forceId, areaId, teamId, viewAll));
  }

  getNoReportedLocations = (startDate, endDate, forceId, areaId, teamId, viewAll) => {
    const startUtcTime = moment(startDate).local();
    const endUtcTime = moment(endDate).local();
    this.props.dispatch(systemAdminActions.GetNoLocationsReported(startUtcTime.format("l"), endUtcTime.format("l"), forceId, areaId, teamId, viewAll));
  }

  getAllocationDetails = (startDate, endDate, forceId, viewAll) => {
    const startUtcTime = moment(startDate).local();
    const endUtcTime = moment(endDate).local();
    this.props.dispatch(systemAdminActions.GetAllocationDetails(startUtcTime.format("l"), endUtcTime.format("l"), forceId, viewAll));
  }

  getAllocationUsageDetails = (startDate, endDate, forceId, areaId, teamId, viewAll) => {
    const startUtcTime = moment(startDate).local();
    const endUtcTime = moment(endDate).local();
    this.props.dispatch(systemAdminActions.GetAllocationUsageDetails(startUtcTime.format("l"), endUtcTime.format("l"), forceId, areaId, teamId, viewAll));
  }

  render() {
    return (
      <>
        <ShepherdTour steps={DashboardSteps} tourOptions={tourOptions}>
          <TourMethods>
            {(tourContext) => (
              <TourGuideStartModal
                startTour={tourContext}
                hideModal={this.hideTourGuideLandingModal}
                showModal={this.state.TourStartModalshow}
                completeGuide={this.CompleteTourGuide}
                tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                userId={this.props.userId}
                tourName="DashBoard"
                title="Welcome to the DashBoard Tour Guide"
                description="In this tour guide, you will learn about the Admin Dashboard. This section show metrics about your registrations and license across your organization."
              />
            )}
          </TourMethods>
          <div className="p-4">
            {hasRights([
              rights.CanManageSystemInfo,
              rights.CanManageForces,
            ]) && (
              <Row gutter={[16, 8]}>
                <Col xs={24} md={24} lg={8} align="end">
                  <span>
                    <Checkbox
                      checked={this.state.ViewAll}
                      value={this.state.ViewAll}
                      onChange={(val) => {
                        this.setState({
                          ViewAll: val.target.checked,
                          Force: { Id: this.props.ForceId, Name: "" },
                        });
                        this.getDevicesRegistered(
                          this.state.startDate,
                          this.state.endDate,
                          0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          val.target.checked
                        );
                        this.getActivatedDevices(
                          this.state.startDate,
                          this.state.endDate,
                          0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          val.target.checked
                        );
                        this.getGendersByRegisteredDevices(
                          this.state.startDate,
                          this.state.endDate,
                          0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          val.target.checked
                        );
                        this.getRiskCategoryByRegisteredDevices(
                          this.state.startDate,
                          this.state.endDate,
                          0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          val.target.checked
                        );
                        this.getOverdueRegistrations(
                          this.state.startDate,
                          this.state.endDate,
                          0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          val.target.checked
                        );
                        this.getNoReportedLocations(
                          this.state.startDate,
                          this.state.endDate,
                          0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          val.target.checked
                        );
                        this.getAllocationDetails(
                          this.state.startDate,
                          this.state.endDate,
                          0,
                          val.target.checked
                        );
                        this.getAllocationUsageDetails(
                          this.state.startDate,
                          this.state.endDate,
                          0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          val.target.checked
                        );
                      }}
                    >
                      View All Forces
                    </Checkbox>
                  </span>
                </Col>
              </Row>
            )}
            <Row className="sh-tour-dash-lea-filter" gutter={[16, 8]}>
              <Col xs={24} md={24} lg={8}>
                <div className="p5-form-label">
                  <span>Force:</span>
                </div>
                <DynamicSelect
                  isDisabled={
                    !hasRights([
                      rights.CanManageSystemInfo,
                      rights.CanManageForces,
                    ]) || this.state.ViewAll
                  }
                  hasNone={false}
                  className="p5-form-dropdown"
                  key="ForcePicker"
                  getData={agencyService.getForceList}
                  value={this.state.Force.Id}
                  placeholder="Select Force"
                  onChange={(val) => {
                    this.setState({
                      Force: {
                        Id: val,
                      },
                      Area: {
                        Id: null,
                      },
                      Team: {
                        Id: null,
                      },
                    });
                    this.getDevicesRegistered(
                      this.state.startDate,
                      this.state.endDate,
                      val,
                      null,
                      null,
                      this.state.ViewAll
                    );
                    this.getActivatedDevices(
                      this.state.startDate,
                      this.state.endDate,
                      val,
                      null,
                      null,
                      this.state.ViewAll
                    );
                    this.getGendersByRegisteredDevices(
                      this.state.startDate,
                      this.state.endDate,
                      val,
                      null,
                      null,
                      this.state.ViewAll
                    );
                    this.getRiskCategoryByRegisteredDevices(
                      this.state.startDate,
                      this.state.endDate,
                      val,
                      null,
                      null,
                      this.state.ViewAll
                    );
                    this.getOverdueRegistrations(
                      this.state.startDate,
                      this.state.endDate,
                      val,
                      null,
                      null,
                      this.state.ViewAll
                    );
                    this.getNoReportedLocations(
                      this.state.startDate,
                      this.state.endDate,
                      val,
                      null,
                      null,
                      this.state.ViewAll
                    );
                    this.getAllocationDetails(
                      this.state.startDate,
                      this.state.endDate,
                      val
                    );
                    this.getAllocationUsageDetails(
                      this.state.startDate,
                      this.state.endDate,
                      val,
                      null,
                      null,
                      this.state.ViewAll
                    );
                  }}
                />
              </Col>
              <Col xs={24} md={24} lg={8}>
                <div className="p5-form-label">
                  <span>Area:</span>
                </div>
                <DynamicSelect
                  hasNone
                  className="p5-form-dropdown"
                  isDisabled={this.state.ViewAll}
                  key="AreaPicker"
                  getData={AreaService.getAreaList}
                  displayFieldName="Name"
                  valueFieldName="Id"
                  value={this.state.Area.Id}
                  filters={{ force: this.state.Force.Id }}
                  placeholder="Select Area"
                  onChange={(val) => {
                    this.setState({
                      Area: {
                        Id: val === 0 ? null : val,
                      },
                      Team: {
                        Id: null,
                      },
                    });
                    this.getDevicesRegistered(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      val,
                      null,
                      this.state.ViewAll
                    );
                    this.getActivatedDevices(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      val,
                      null,
                      this.state.ViewAll
                    );
                    this.getGendersByRegisteredDevices(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      val,
                      null,
                      this.state.ViewAll
                    );
                    this.getRiskCategoryByRegisteredDevices(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      val,
                      null,
                      this.state.ViewAll
                    );
                    this.getOverdueRegistrations(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      val,
                      null,
                      this.state.ViewAll
                    );
                    this.getNoReportedLocations(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      val,
                      null,
                      this.state.ViewAll
                    );
                    this.getAllocationDetails(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      this.state.ViewAll
                    );
                    this.getAllocationUsageDetails(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      val,
                      null,
                      this.state.ViewAll
                    );
                  }}
                />
              </Col>
              <Col xs={24} md={24} lg={8}>
                <div className="p5-form-label">
                  <span>Team:</span>
                </div>
                <DynamicSelect
                  hasNone
                  className="p5-form-dropdown"
                  isDisabled={this.state.ViewAll}
                  key="TeamPicker"
                  getData={TeamService.getTeamList}
                  displayFieldName="Name"
                  valueFieldName="Id"
                  value={this.state.Team.Id}
                  filters={{
                    force: this.state.Force.Id,
                    area: this.state.Area.Id === 0 ? null : this.state.Area.Id,
                  }}
                  placeholder="Select Team"
                  onChange={(val) => {
                    this.setState({
                      Team: {
                        Id: val === 0 ? null : val,
                      },
                    });
                    this.getDevicesRegistered(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      this.state.Area.Id,
                      val,
                      this.state.ViewAll
                    );
                    this.getActivatedDevices(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      this.state.Area.Id,
                      val,
                      this.state.ViewAll
                    );
                    this.getGendersByRegisteredDevices(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      this.state.Area.Id,
                      val,
                      this.state.ViewAll
                    );
                    this.getRiskCategoryByRegisteredDevices(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      this.state.Area.Id,
                      val,
                      this.state.ViewAll
                    );
                    this.getOverdueRegistrations(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      this.state.Area.Id,
                      val,
                      this.state.ViewAll
                    );
                    this.getNoReportedLocations(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      this.state.Area.Id,
                      val,
                      this.state.ViewAll
                    );
                    this.getAllocationDetails(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      this.state.ViewAll
                    );
                    this.getAllocationUsageDetails(
                      this.state.startDate,
                      this.state.endDate,
                      this.state.Force.Id,
                      this.state.Area.Id,
                      val,
                      this.state.ViewAll
                    );
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col
                id="realtime-sect"
                xs={24}
                md={24}
                lg={8}
                className="sh-tour-dash-realtime-sect"
              >
                <Divider orientation="left" style={{ borderColor: "black" }}>
                  Realtime Data
                </Divider>
                <Row gutter={[16, 16]} className="divider-bar">
                  <Col
                    id="overdue-review"
                    className="sh-tour-dash-overdue"
                    span={24}
                  >
                    <OverdueProgressView
                      title="Current Overdue Reviews"
                      overdueRegistrations={this.props.overdueRegistrations}
                    />
                  </Col>
                  <Col
                    id="noloc-report"
                    span={24}
                    className="sh-tour-dash-noloc-report"
                  >
                    <NoLocationProgressView
                      title="Current No Locations Reported in 24 hrs."
                      noLocationReported={this.props.noLocationReported}
                    />
                  </Col>
                  <Col
                    id="app-allocation"
                    span={24}
                    className="sh-tour-dash-app-alloc-pie"
                  >
                    <WNAppAllocationView
                      title="Current WN App Allocations"
                      data={this.props.allocationDetails.filter(
                        (f) => f.RegistrationType === "WN App"
                      )}
                    />
                  </Col>
                  <Col
                    id="handset-allocation"
                    span={24}
                    className="sh-tour-dash-handset-alloc-pie"
                  >
                    <HandsetAllocationView
                      title="Current Handset Allocations"
                      data={this.props.allocationDetails.filter(
                        (f) => f.RegistrationType === "Handset"
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              {/* <div style={{borderLeft: "1px solid black", marginLeft: "5px", width: "5px", height: "500px"}} /> */}
              <Col
                id="filtered-sect"
                className="sh-tour-dash-filtered-sect"
                xs={24}
                md={24}
                lg={15}
              >
                <Divider orientation="left" style={{ borderColor: "black" }}>
                  Filtered Data
                </Divider>
                <Row
                  id="data-date-filter"
                  className="sh-tour-dash-data-date-filter"
                >
                  <Col span={24}>
                    <DateSelect
                      Id="VictimDetails"
                      onChange={(startDate, endDate) => {
                        this.setState({
                          startDate,
                          endDate,
                        });
                        // this.getVictimData(startDate, endDate);
                        this.getDevicesRegistered(
                          startDate,
                          endDate,
                          this.state.Force.Id ?? 0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          this.state.ViewAll
                        );
                        this.getActivatedDevices(
                          startDate,
                          endDate,
                          this.state.Force.Id ?? 0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          this.state.ViewAll
                        );
                        this.getGendersByRegisteredDevices(
                          startDate,
                          endDate,
                          this.state.Force.Id ?? 0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          this.state.ViewAll
                        );
                        this.getRiskCategoryByRegisteredDevices(
                          startDate,
                          endDate,
                          this.state.Force.Id ?? 0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          this.state.ViewAll
                        );
                        this.getOverdueRegistrations(
                          startDate,
                          endDate,
                          this.state.Force.Id ?? 0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          this.state.ViewAll
                        );
                        this.getNoReportedLocations(
                          startDate,
                          endDate,
                          this.state.Force.Id ?? 0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          this.state.ViewAll
                        );
                        this.getAllocationDetails(
                          startDate,
                          endDate,
                          this.state.Force.Id ?? 0,
                          this.state.ViewAll
                        );
                        this.getAllocationUsageDetails(
                          startDate,
                          endDate,
                          this.state.Force.Id ?? 0,
                          this.state.Area.Id,
                          this.state.Team.Id,
                          this.state.ViewAll
                        );
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]} className="pt-1">
                  <Col
                    id="new-devices"
                    className="sh-tour-dash-new-devices"
                    xs={24}
                    md={24}
                    lg={24}
                    xl={12}
                  >
                    <NewDeviceView
                      title="New Devices Registered"
                      data={this.props.registeredDevices}
                      loading={this.props.loading}
                    />
                  </Col>
                  <Col
                    id="activated-devices"
                    className="sh-tour-dash-activated-devices"
                    xs={24}
                    md={24}
                    lg={24}
                    xl={12}
                  >
                    <ActivatedDeviceProgressView
                      title="New Activated Devices"
                      activatedDevices={this.props.activatedDevices}
                    />
                  </Col>
                  <Col
                    id="app-registered-devices"
                    className="sh-tour-dash-app-registered-devices"
                    xs={24}
                    md={24}
                    lg={24}
                    xl={12}
                  >
                    <RegisteredDeviceCountView
                      title="New WN App Registered"
                      data={
                        this.props.allocationUsageDetails.filter(
                          (f) => f.RegistrationType === "WN App"
                        ) ?? [{ AreaName: "", AllocatedCount: 0 }]
                      }
                    />
                  </Col>
                  <Col
                    id="handset-registered-devices"
                    className="sh-tour-dash-handset-registered-devices"
                    xs={24}
                    md={24}
                    lg={24}
                    xl={12}
                  >
                    <RegisteredDeviceCountView
                      title="Handset Registered"
                      data={
                        this.props.allocationUsageDetails.filter(
                          (f) => f.RegistrationType === "Handset"
                        ) ?? [{ AreaName: "", AllocatedCount: 0 }]
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="pt-1">
                  <Col
                    id="risk-cat-bar"
                    className="sh-tour-dash-risk-cat-bar"
                    span={24}
                  >
                    <RiskCategoryBarChartView
                      title="Risk Categories"
                      data={this.props.riskCategoryByDeviceType}
                    />
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="pt-1">
                  <Col
                    id="gender-bar"
                    className="sh-tour-dash-gender-bar"
                    span={24}
                  >
                    <GenderBarChartView
                      title="Genders"
                      data={this.props.genderByDeviceType}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </ShepherdTour>
      </>
    );
  }
}

DashboardTourPage.propTypes = {
  dispatch: PropTypes.func,
  registeredDevices: PropTypes.arrayOf(PropTypes.shape({})),
  activatedDevices: PropTypes.arrayOf(PropTypes.shape({})),
  overdueRegistrations: PropTypes.arrayOf(PropTypes.shape({})),
  noLocationReported: PropTypes.arrayOf(PropTypes.shape({})),
  allocationDetails: PropTypes.arrayOf(PropTypes.shape({})),
  allocationUsageDetails: PropTypes.arrayOf(PropTypes.shape({})),
  genderByDeviceType: PropTypes.arrayOf(PropTypes.shape({
    DeviceType: PropTypes.string,
    GenderType: PropTypes.string,
    Count: PropTypes.number,
  })),
  riskCategoryByDeviceType: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  ForceId: PropTypes.number,
  userId: PropTypes.number,
  CurrentTourGuide: PropTypes.shape({
    TourGuideId: PropTypes.number
  }),
};

DashboardTourPage.defaultProps = {
  dispatch: () => {},
  registeredDevices: [],
  activatedDevices: [],
  overdueRegistrations: [],
  noLocationReported: [],
  allocationDetails: [],
  allocationUsageDetails: [],
  genderByDeviceType: [{
    DeviceType: '',
    GenderType: '',
    Count: 0,
  }],
  riskCategoryByDeviceType: [],
  loading: false,
  ForceId: 1,
  userId: 0,
  CurrentTourGuide: {
    TourGuideId: 9
  },
};

function mapStateToProps(state) {
  const {
    loading,
    registeredDevices,
    activatedDevices,
    overdueRegistrations,
    genderByDeviceType,
    riskCategoryByDeviceType,
    noLocationReported,
    allocationDetails,
    allocationUsageDetails,
  } = state.SystemDashboard;
  const { ForceId, Id } = state.AccountState.currentUser;
  const { CurrentTourGuide } = state.TourGuide;

  return {
    loading,
    registeredDevices,
    activatedDevices,
    overdueRegistrations,
    genderByDeviceType,
    riskCategoryByDeviceType,
    noLocationReported,
    allocationDetails,
    ForceId,
    allocationUsageDetails,
    userId: Id,
    CurrentTourGuide,
  };
}

const connectedDashboardTourPagee = connect(mapStateToProps)(DashboardTourPage);

export { connectedDashboardTourPagee as default };
