import React from "react";
// import { BrowserRouter as Router, Link } from "react-router-dom";
// import "antd/dist/antd.css";
import { Layout, Row, Col } from "antd";
import "./Header.scss";

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class SampleHeader extends React.PureComponent {
  render() {
    const { Content } = Layout;

    return (
      <div className="SampleHeader">
        <Content>
          <Row>
            <Col lg={24}>
              <div className="pg-sample-title">
                <span>Sample Page Custom Header</span>
              </div>
            </Col>
          </Row>
        </Content>
      </div>
    );
  }
}

export { SampleHeader as default };
