
const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
          enabled: false
        }
      },
      useModalOverlay: true
};

const LiteVictimTourSteps = [ 
  {
    title: "General",
    id: "tour-vic-general",
    text: [
      `
      <p>
      Any field with an asterisk is mandatory and must be completed. If any asterisked field is not completed it will not allow the registration to be saved.         
      </p>
      <p>
      Where any field has a 'None' or Not Known' option, you must either tick the 'None' or 'Not Known' option if it applies, or complete the data in that field.
      </p>
      `,
    ],
    attachTo: { on: "center" },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },   
  {
    title: "Gender",
    id: "tour-vic-gender",
    text: [
      `
      <p>
      The victim's gender should already be populated from the minimum victim information previously input.         
      </p>
      <p>
      This field is a dropdown menu containing a range of gender options.  
      From the dropdown you can select the relevant gender of the victim        
      </p>
      <p>
      <ul>
      <li>Female</li>
      <li>Male</li>
      <li>Non Binary</li>
      <li>Another Gender/Not Listed</li>
      <li>Unknown/Not Disclosed</li>
      </ul>
      </p>    
      `,
    ],
    attachTo: { element: ".sh-tour-vic-gender", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Local Reference Number",
    id: "tour-vic-ref-num",
    text: [
      `
      <p>
      This is the local case reference number.  Enter that local reference number here.       
      </p>
      <p>
      If you do not know the local reference number, type 'None' in the field.   
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-ref-num", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Risk Level",
    id: "tour-vic-risk-lvl",
    text: [
      `
      <p>
      The risk level for the victim should already be populated from the minimum victim information previously input.         
      </p>
      <p>
      <span>
      This field is a dropdown menu containing three risk levels:
      </span>
      <ul>
      <li>High</li>
      <li>Medium</li>
      <li>Standard</li>
      </ul>
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-risk-lvl", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Risk Category",
    id: "tour-vic-risk-cat",
    text: [
      `
      <p>
      There are a number of broad risk categories that can be selected in relation to an individual registration.  
      From the dropdown menu select any risk categories that may relate to the victim:
      </p>
      <p>
      <ul>
      <li>DA - General</li>
      <li>DA - Stalking/Harassment</li>
      <li>DA - Honour Based Abuse</li>
      <li>DA - Force Marriage</li>
      <li>CSE</li>
      <li>MDS (Modern Day Slavery)</li>
      <li>Serious Sexual Assault</li>
      </ul>
      </p>
      <p>
      As each individual risk category is selected it will automatically populate in the field. 
      Multiple categories may be selected.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-risk-cat", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Officer in the Case",
    id: "tour-vic-user-oic",
    text: [
      `
      <p>
      Type in the name of officer or staff member dealing with the case.
      </p>
      <p>
      Usually the officer in the case is the person completing the registration.
      </p>
      <p>
      This can be amended if the officer in the case changes.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-oic ", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Officer Staff ID",
    id: "tour-vic-oic-staffid",
    text: [
      `
      <p>
      Add the staff ID number here for the officer in the case.
      </p>
      <p>
      This is a free text box and alphanumeric characters are allowed.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-oic-staff-id ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Authorising Officer",
    id: "tour-vic-user-auth-oic",
    text: [
      `
      <p>
      Some forces require an authorising officer for the issue of TecSAFE devices (not mandatory). 
      </p>
      <p>
      If this is needed by your force, type in the name of the authorising officer in the text field. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-auth-oic ", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "Cancel/Save",
    id: "tour-vic-footer-btn",
    text: [
      `
      <p>
      Once you have completed and offender information click 'Save' to add the updated details, or 'Cancel' if you do not want to add the details at this time.     
      </p>
      <p>
      If you have missed any of the asterisked mandatory fields when you click 'Save' it will automatically take you back to the incomplete field, which will be highlighted with a red line around it.  
      </p>
      <p>
      Complete the highlighted field(s) and then click 'Save'. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-footer-btn", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",  
        type: "next",   
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "Victim Data Form Complete",
    id: "tour-help-complete",
    text: [
      `
      <p>
      That concludes the Victim Form page. 
      Please select Complete to this tour to mark as complete.
      </p>
      `,
    ],
    attachTo: { element: "", on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Complete",
        type: "complete",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
];

  export default {
    tourOptions,
    LiteVictimTourSteps
  }