import React from "react";
import { Spin, Row, Col } from "antd";
import PropTypes from "prop-types";
import "./layout.css";

class AccountLayout extends React.PureComponent {
  render() {
    return this.props.loading ? (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          background: "#fff",
          textAlign: "center",
        }}
      >
        <Spin style={{ marginTop: "40vh" }} />
      </div>
    ) : (
      <div className="account-layout-container">
        <Row justify="center">
          <Col span={24}>{this.props.form}</Col>
        </Row>
      </div>
    );
  }
}

AccountLayout.propTypes = {
  form: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};
AccountLayout.defaultProps = {
  loading: false,
};

export default AccountLayout;
