import UploadConstants from './constants';
import { FileService } from '../../../services';

function createDocuments(files, callback) {
  function request() {
    return { type: UploadConstants.CREATE_REQUEST };
  }
  function success() {
    return { type: UploadConstants.CREATE_SUCCESS };
  }
  function failure(error) {
    return { type: UploadConstants.CREATE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(files));
    files.forEach(file => {
        FileService.create(file).then(
        id => {
          dispatch(success(id));
          const Document = {
            File: {
              Id: id,
              Name: file.name,
              ContentType: file.type,
            }
          }
          callback(Document);
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
    });
  };
}

function download(document, callback) {
  function request() { return { type: UploadConstants.GET_REQUEST }; }
  function success() { return { type: UploadConstants.GET_SUCCESS }; }
  function failure(error) { return { type: UploadConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    FileService.get(document.File.Id)
      .then(
        (res) => {
          dispatch(success());
          callback(res, document)
        },
        (error) => {
          dispatch(failure(error.toString()));
        },
      );
  };
}

export default {
    createDocuments,
    download,
};
