import c from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  team: {},
  error: {},
};

export default function TeamReducer(state = initialState, action) {
  switch (action.type) {
    case c.NEW_TEAM_REQUEST:
      return {
        ...state,
        loading: false,
        hasError: false,
        team: {}
      };
    case c.GET_TEAM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_TEAM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        list: {},
        team: {},
      };
    case c.GET_TEAM_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        team: action.team.data,
      };
    case c.GET_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.CREATE_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.CREATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.CREATE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.UPDATE_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.UPDATE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.DELETE_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.DELETE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.DELETE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}
