import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const SaveBtn = (props) => {
  return(
    <Button
      block
      type="submit"
      id="saveBtn"
      className="saveBtn"
      onClick={props.onClick}
      loading={props.loading}
    >
      Save
    </Button>
  );
}

SaveBtn.propTypes = {
    onClick: PropTypes.func,
    loading: PropTypes.bool,
}

SaveBtn.defaultProps = {
    onClick: () => {},
    loading: false,
}

export default SaveBtn;
