import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { Modal, Button, Col, Row, Tag, Divider, Tabs, Tooltip, Spin } from "antd";
import {
  AndroidFilled,
  AppleFilled,
  MobileOutlined,
  // CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { RegisterForm, MinimumDataForm, LiteMinimumDataForm } from "../form";
import {
    phoneNumberFormatter,
  } from "../../../common/utils/phone-number-helper";

import registrationActions from "../actions";
import { IsFullVersion, IsLiteVersion } from "../../../common/utils/system-version-helper";

const { TabPane } = Tabs;

class RegistrationEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "RegModalDetail",
    };
  }

  componentDidUpdate = (oldProps) => {
    if (
      oldProps.data.Id === 0 &&
      this.props.data.Id > 0 &&
      Object.keys(oldProps.data).length > 0 &&
      Object.keys(this.props.data).length > 0
    ) {
      if (
          this.props.data.IsRegistered === true &&
          this.props.data.VictimId === null
        )
          this.UpdateTabPage("MinData");
      else if (
        this.props.data.RegistrationTypeId === 1 &&
        this.props.data.IsRegistered === false 
      )
        this.UpdateTabPage("RegisterDevice");
      else this.UpdateTabPage("RegModalDetail");
    }
  };

  UpdateTabPage = (tab) => {
    this.setState({ activeTab: tab });
  };

  canCloseModal = () => {
    if (
      this.props.data.RegistrationTypeId === 1 &&
      this.props.data.IsRegistered === true &&
      this.props.data.TrackaphoneRegistrationStatusId !== 3
    )
    {
      return false;
    }    

    if (
      this.props.data.IsRegistered === true &&
      this.props.data.VictimId === null
    )
    {
      return false;
    }  

    return true;
  }

  CloseRegistrationEditModal = () => {
    const { dispatch } = this.props;
    dispatch(registrationActions.hideRegistrationEditModal(this.props.onRefresh()));

    if(this.props.data.IsTour)
      return;

    if(this.state.activeTab === "RegisterDevice")
    {
      this.props.dispatch(
        registrationActions.GetRegistrationList(
          "$orderby=RegistrationId asc&$filter=IsRegistered eq false"
        )
      );
    }
    else {
      this.props.dispatch(
        registrationActions.GetRegistrationList(
          "$orderby=RegistrationId asc&$filter=IsRegistered eq true"
        )
      );
    }
  };

  GetStatusDetails = (id, Text) => {
    const statusColors = { 1: "#B90505", 2: "#D38900", 3: "#207603" };

    const statusIcons = {
      1: <CloseCircleOutlined />,
      2: <WarningOutlined />,
      3: <CheckCircleFilled />,
    };

    if(id === 3) {
      return (
        <span style={{ marginRight: "40px" }}>
          <Tag
            style={{ width: "60px", marginTop: '3px', marginRight: '2px', border: "none", backgroundColor: "none", color: "#207603" }}
            color={statusColors[id] || "#ff0000"}
            icon={statusIcons[id] || <CloseCircleOutlined />}
          >
            <b>{Text}</b>
          </Tag>
        </span>
      );
    }
    return (
      <Tag
        style={{ width: "60px", marginTop: '3px' }}
        color={statusColors[id] || "#ff0000"}
        icon={statusIcons[id] || <CloseCircleOutlined />}
      >
        {Text}
      </Tag>
    );  
  };

  GetVictimStatus = (id, Text) => {
    const statusColors = { 0: "#B90505", 6: "#D38900", 7: "#207603" };

    const statusIcons = {
      0: <CloseCircleOutlined />,
      6: <WarningOutlined />,
      7: <CheckCircleFilled />,
    };

    if(id === 7) {
      return (
        <span style={{ marginRight: "40px" }}>
          <Tag
            style={{ width: "60px", marginTop: '3px', marginRight: '2px', border: "none", backgroundColor: "none", color: "#207603" }}
            color={statusColors[id] || "#ff0000"}
            icon={statusIcons[id] || <CloseCircleOutlined />}
          >
            <b>{Text}</b>
          </Tag>
        </span>
      );
    }

    return (
      <span style={{ marginRight: "40px" }}>
        <Tag
          style={{ width: "60px", marginTop: '3px', marginRight: '2px' }}
          color={statusColors[id] || "#ff0000"}
          icon={statusIcons[id] || <CloseCircleOutlined />}
        >
          {Text}
        </Tag>
      </span>
    );  
  };

  GetDeviceType = (deviceTypeId) => {
    if (deviceTypeId === 1) {
      return (
        <span>
          <Tooltip title="Apple">
            <AppleFilled />
          </Tooltip>
        </span>
      );
    }
    if (deviceTypeId === 2) {
      return (
        <span>
          <Tooltip title="Android">
            <AndroidFilled />
          </Tooltip>
        </span>
      );
    }
    return (
      <span>
        <Tooltip title="Handset">
          <MobileOutlined />
        </Tooltip>
      </span>
    );
  };

  RegisterCLI = (data) => {
    //   console.log('CLI', data);
    this.props.dispatch(registrationActions.Register(data, this.refreshModal, this.CloseRegistrationEditModal));
  };

  AddMinData = (data) => {
    // console.log('min data', data);
    this.props.dispatch(registrationActions.AddMinData(data, this.refreshModal));
  };

  refreshModal = (id = 0) => {
    const registrationId = id === 0 ? this.props.data.Id : id

    this.props.dispatch(
      registrationActions.openRegistrationEditModal(registrationId, 0)
    );
  };

  handleData = () => {
    

    if (this.props.data.VictimId === null)
    this.props.dispatch(registrationActions.hideRegistrationEditModal(this.props.dispatch(push(`/register/${this.props.data.Id}/create`))));
    else this.props.dispatch(registrationActions.hideRegistrationEditModal(this.props.dispatch(push(`/register/${this.props.data.Id}/edit`))));
  };

  handleExtendRegistration = () => {
    if (this.props.data.VictimId === null || IsLiteVersion() === true)
    this.props.dispatch(
      registrationActions.ExtendRegistration(
        this.props.data.Id,
        this.props.onRefresh()
      )
    );
    else {
      this.props.dispatch(registrationActions.hideRegistrationEditModal(this.props.dispatch(registrationActions.openExtendModal(this.props.data.Id))));
      // this.props.dispatch(push(`/register/${this.props.data.Id}/extend`));   
      
    }
  };

  handleTourExtend = () => {
    if (IsLiteVersion() === false)
      this.props.dispatch(registrationActions.hideRegistrationEditModal(this.props.dispatch(registrationActions.openTourExtendModal())));
  }

  DeregisterRegistration = () => {
    const { confirm } = Modal;
    const { dispatch, data } = this.props;
    const callback = this.CloseRegistrationEditModal;
    const displayName =
      this.props.data.Name !== null
        ? `${this.props.data.Name} - (${this.props.data.PhoneCountryCode} ${phoneNumberFormatter(this.props.data.PhoneNumber)})`
        : `${this.props.data.PhoneCountryCode} ${phoneNumberFormatter(this.props.data.PhoneNumber)}`;
    confirm({
      content: (
        <span style={{ textAlign: "center" }}>
          Are you sure want to Deregister {displayName}.
        </span>
      ),
      onOk() {
        /* eslint react/prop-types: 0 */
        dispatch(registrationActions.DeregisterVictim(data.Id, callback));
      },
    });
  };

  // refreshPage = () => {
  //   this.props.dispatch(
  //     registrationActions.GetRegistrationList(
  //       "$orderby=RegistrationId asc&$filter=IsRegistered eq true"
  //     )
  //   );
  // };

  GetDeregisterDescription = () => {
    if(this.props.data.Active)
      return "Device is active, unable to deregister";
    
    return "Deregister Device";
  }

  render() {
    return (
      <Modal
        visible={this.props.show}
        // title={this.props.data.Name}
        onCancel={this.CloseRegistrationEditModal}
        footer={null}
        width={700}
        // closable={this.canCloseModal()}
        destroyOnClose
      >
        <Tabs
          size="large"
          activeKey={this.state.activeTab}
          onTabClick={(e) => this.UpdateTabPage(e)}
        > 
          {this.props.data.IsRegistered && (
            <TabPane tab="Registration" key="RegModalDetail">
              {this.props.IsTour && (
                <>
                  <Button id="sh-show-reg-extend-btn" style={{display: "None"}} onClick={() => this.handleTourExtend()} />
                  <Button id="sh-hide-reg-detail-btn" style={{display: "None"}} onClick={() => this.CloseRegistrationEditModal()} />
                </>
              )}
              <Button id="sh-show-reg-extend-btn" style={{display: "None"}} onClick={() => this.handleTourExtend()} />
              <Button id="sh-hide-reg-detail-btn" style={{display: "None"}} onClick={() => this.CloseRegistrationEditModal()} />
              <Spin spinning={this.props.loading}>
                <div className="RegistrationEditModal">
                  <Row>
                    <Col xs={24} sm={24} md={18}>
                      <Row>
                        <Col span={24}>
                          <Divider orientation="left">Details</Divider>
                        </Col>
                      </Row>
                      {IsFullVersion() && (
                        <Row>
                          <Col sm={24} md={6}>
                            <span className="p5-modal-form-label">Name: </span>
                          </Col>
                          <Col sm={24} md={18}>
                            <span className="p5-modal-text">
                              {this.props.data.Name}
                            </span>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col sm={24} md={6}>
                          <span className="p5-modal-form-label">Phone: </span>                        
                        </Col>
                        <Col sm={24} md={18}>
                          <span className="p5-modal-text">{`+${this.props.data.PhoneCountryCode} ${phoneNumberFormatter(this.props.data.PhoneNumber)}`}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={24} md={6}>
                          <span className="p5-modal-form-label">Type: </span>
                        </Col>
                        <Col sm={24} md={18}>
                          <span className="p5-modal-text">
                            {`${this.props.data.RegistrationType} `}
                            {this.GetDeviceType(this.props.data.DeviceTypeId)}
                          </span>
                        </Col>
                      </Row>
                      {this.props.data.RegistrationTypeId === 1 && (
                        <>
                          <Row>
                            <Col sm={24} md={6}>
                              <span className="p5-modal-form-label">
                                {`Code: `} 
                              </span>
                            </Col>
                            <Col sm={24} md={18}>
                              <span className="p5-modal-text">
                                {this.props.data.ConfirmationCode}
                              </span>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                    <Col xs={24} sm={24} md={6} className="gutter-row">
                      <Row>
                        <Col span={24}>
                          <Divider orientation="left">Status</Divider>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} style={{marginBottom: "5px"}}>
                          {this.GetVictimStatus(
                            this.props.data.VictimDataStatus !== null ? this.props.data.VictimDataStatus : 0,
                            "Data"
                          )}
                        </Col>
                        <Col span={12} style={{marginBottom: "5px"}}>
                          {this.GetStatusDetails(
                            this.props.data.PhoneNumber != null ? 3 : 1,
                            "CLI"
                          )}
                        </Col>
                        <Col span={12} style={{marginBottom: "5px"}}>
                          {this.GetStatusDetails(
                            this.props.data.BTStatusId,
                            "BT"
                          )}
                        </Col>
                        {this.props.data.RegistrationTypeId === 1 && (
                          <Col span={12} style={{marginBottom: "5px"}}>
                            {this.GetStatusDetails(
                              this.props.data.TrackaphoneRegistrationStatusId,
                              "T@P"
                            )}
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <br />
                  <hr />
                  <Row gutter={{ xs: 5, sm: 5, md: 5 }}>
                    <Col sm={24} md={12}>
                      <Row gutter={{ xs: 5, sm: 5, md: 5 }}>
                        <Col
                          xs={24}
                          sm={24}
                          md={8}
                          lg={8}
                          xl={8}
                          className="gutter-row sh-tour-red-detail-deregister"
                        >
                          <Tooltip title={this.GetDeregisterDescription}>
                            <Button
                              type="primary"
                              danger
                              style={{ width: "100%", marginTop: "5px" }}
                              key="Desregister"
                              disabled={this.props.data.Active}
                              onClick={this.props.data.IsTour ? console.log("This action is disabled during tour") : this.DeregisterRegistration}
                            >
                              Deregister
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>                    
                    </Col>
                    <Col sm={24} md={12}>
                      <Row gutter={{ xs: 5, sm: 5, md: 5 }} justify="end">
                        <Col 
                          xs={24}
                          sm={24}
                          md={8}
                          lg={8}
                          xl={8}
                          className="gutter-row sh-tour-red-detail-data"
                        >
                          <Button
                            className="p5-default-primary-btn"
                            style={{ width: "100%", marginTop: "5px" }}
                            key="Data"
                            onClick={this.props.data.IsTour ? console.log("This action is disabled during tour") : this.handleData}
                          >
                            Data
                          </Button>
                        </Col>
                        {(this.props.data.CanExtend || this.props.data.IsTour) && (
                          <Col
                            xs={24}
                            sm={24}
                            md={8}
                            lg={8}
                            xl={8}
                            className="gutter-row sh-tour-red-detail-extend"
                          >
                            <Button
                              type="primary"
                              style={{ width: "100%", marginTop: "5px" }}
                              key="Extend"
                              onClick={this.props.data.IsTour ? console.log("This action is disabled during tour") : this.handleExtendRegistration}
                            >
                              Extend
                            </Button>
                          </Col>
                        )}
                        <Col
                          xs={24}
                          sm={24}
                          md={8}
                          lg={8}
                          xl={8}
                          className="gutter-row"
                        >
                          <Button
                            className="p5-default-secondary-btn"
                            key="Close"
                            style={{ width: "100%", marginTop: "5px" }}
                            onClick={this.CloseRegistrationEditModal}
                          >
                            Close
                          </Button>
                        </Col>                  
                      </Row>
                    </Col>
                  </Row>                
                </div>
              </Spin>
            </TabPane>
          )}
          {!this.props.data.IsRegistered && (
            <TabPane tab="Register Device" key="RegisterDevice">
              <div>
                <RegisterForm
                  onSubmit={this.RegisterCLI}
                  {...this.props.data}
                />
              </div>
            </TabPane>
          )}
          {IsFullVersion() === true && this.props.data.IsRegistered && this.props.data.VictimId === null && (
            <TabPane tab={<span className="required-field">Victim Data</span>} key="MinData">
              <div>
                <MinimumDataForm onCancel={this.CloseRegistrationEditModal} isLoading={this.props.loading} onSubmit={this.AddMinData} showClose={false} {...this.props.data} />
              </div>
            </TabPane>
          )}
          {IsLiteVersion() === true && this.props.data.IsRegistered && this.props.data.VictimId === null && (
            <TabPane tab={<span className="required-field">Victim Data</span>} key="MinData">
              <div>
                <LiteMinimumDataForm onCancel={this.CloseRegistrationEditModal} isLoading={this.props.loading} onSubmit={this.AddMinData} showClose={false} {...this.props.data} />
              </div>
            </TabPane>
          )}
          
        </Tabs>
      </Modal>
    );
  }
}

RegistrationEditModal.propTypes = {
  dispatch: PropTypes.func,
  onRefresh: PropTypes.func,
  show: PropTypes.bool,
  data: PropTypes.shape({
    Id: PropTypes.number,
    ConsumerId: PropTypes.number,
    Name: PropTypes.string,
    PhoneCountryCode: PropTypes.string,
    PhoneNumber: PropTypes.string,
    RegistrationTypeId: PropTypes.number,
    RegistrationType: PropTypes.string,
    ConfirmationCode: PropTypes.string,
    DeviceTypeId: PropTypes.number,
    DeviceName: PropTypes.string,
    TrackaphoneRegistrationStatusId: PropTypes.number,
    BTStatusId: PropTypes.number,
    VictimId: PropTypes.number,
    VictimDataStatus: PropTypes.number,
    IsRegistered: PropTypes.bool,
    CanExtend: PropTypes.bool,
    Active: PropTypes.bool,
    IsTour: PropTypes.bool,
  }),
};

RegistrationEditModal.defaultProps = {
  dispatch: () => {},
  onRefresh: () => {},
  show: false,
  data: {
    Id: 0,
    ConsumerId: null,
    Name: "",
    PhoneCountryCode: "",
    PhoneNumber: "",
    RegistrationTypeId: 0,
    RegistrationType: "",
    ConfirmationCode: "",
    DeviceTypeId: 0,
    DeviceName: "",
    TrackaphoneRegistrationStatusId: null,
    BTStatusId: null,
    VictimId: null,
    VictimDataStatus: null,
    IsRegistered: false,
    CanExtend: false,
    Active: false,
    IsTour: false,
  },
};

function mapStateToProps(state) {
  const {
    loading,
    show,
    data,
  } = state.RegisterUserFormState.RegistrationEditModalData;
  return {
    loading,
    show,
    data,
  };
}

const connectedRegistrationEditModal = connect(mapStateToProps)(
  RegistrationEditModal
);

export { connectedRegistrationEditModal as default };
