import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

// import "antd/dist/antd.css";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import UserGrid from "../grid";
import "./UserPage.css";

class ListView extends React.Component {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("user"));
  }

  handleAddClick = () => {
    this.props.dispatch(push("/user/create"))
  };

  render() {
    return (
      <div className="p5-grid-page-container">
        <Row justify="center">
          <Col xs={20} md={20} lg={20}>
            <span>
              <h1 className="p5-page-h1-header">
                Users
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  id="userAdd"
                  className="user-add-icon"
                  alt="userAddIcon"
                  onClick={() => this.handleAddClick()}
                  pull="right"
                  size="1x" 
                />
              </h1>
            </span>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} md={20} lg={20}>
            <UserGrid />
          </Col>
        </Row>
      </div>
    );
  }
}

ListView.propTypes = {
  dispatch: PropTypes.func,
};

ListView.defaultProps = {
  dispatch: () => {},
};

function mapStateToProps() {
  return {};
}

const connectedListView = connect(mapStateToProps)(ListView);

export { connectedListView as default };
