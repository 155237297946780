import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Empty } from "antd";

class ActivatedDeviceProgressView extends React.Component {
  formatpercentage = (value, decimalplaces) => {
    if (value % 1 > 0)
    {
      return value.toFixed(decimalplaces);
    }

    return value.toFixed(0);  
  }

  render() {
    return (
      <>
        <div className="p5-form-container">
          <Row className="text-center" justify="space-around" align="middle">
            <Col span={24}>
              <div>
                <h1 className="p5-dash-h1-header text-center">
                  <span>
                    <FontAwesomeIcon
                      style={{color: '#243B53', marginRight: "5px"}}
                      icon={faMobileAlt}
                    />
                  </span>
                  <span>
                    {this.props.title}
                  </span>
                </h1>
              </div>
            </Col>
          </Row> 
          <br />
          <Row key="activated-dvices" justify="space-around" align="middle">
            {
              this.props.activatedDevices.map((device) => (
                <Col span={12}>
                  <br />
                  <div>
                    <h1 className="p5-dash-h1-header text-center">
                      <span className="dash-percent-value">
                        {device.ActivatedCount}
                      </span>
                    </h1>
                  </div>
                  <div>
                    <h1 className="p5-dash-h1-header text-center">
                      <span>
                        {device.RegistrationType}
                      </span>
                    </h1>
                  </div>
                  <br />                  
                </Col>
              ))
            }
          </Row>   
          {
            this.props.activatedDevices.length === 0 && (
              <>
                <Empty />
              </>
              )
          }     
          <Row className="text-center" justify="space-around" align="middle">
            <Col span={24}>
              <div>
                <span><strong>Handset data in this section is accurate from 5 September 2023</strong></span>
              </div>
            </Col>
          </Row>              
        </div>        
      </>
    );
  }
}

ActivatedDeviceProgressView.propTypes = {
//   loading: PropTypes.bool,
  title: PropTypes.string,
  activatedDevices: PropTypes.arrayOf(PropTypes.shape({})),
};

ActivatedDeviceProgressView.defaultProps = {
//   loading: false,
  title: "",
  activatedDevices: [],
};

export { ActivatedDeviceProgressView as default };
