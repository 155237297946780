import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Row, Col, Modal, Spin } from "antd";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import navigationActions from "../../../common/components/navigation/action";
import TeamForm from "../team-form";
import actions from "../actions";
import "./TeamPages.scss";

class TeamEditPage extends React.Component {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("team"));
    this.props.dispatch(actions.GetTeamById(this.props.match.params.id));
  }

  updateTeam = (val) => {
    this.props.dispatch(actions.UpdateTeam(val, this.handleCallback));
  };

  handleCallback = () => {
    this.props.dispatch(push(`/team`));
  };

  deleteTeam = () => {
    const { confirm } = Modal;
    const { team, dispatch } = this.props;

    confirm({
      icon: <FontAwesomeIcon size="2x" pull="left" icon={faTrash} style={{ color: "#EF4E4E" }} />,
      // icon: <DeleteOutlined style={{ color: "#EF4E4E" }} />,
      /* eslint react/prop-types: 0 */
      content: <span style={{textAlign:"center"}}>All Users will be deleted and all registrations associated with this team will be deregistered. Are you sure want to delete team {team.Name}?</span>,
      onOk() {
        /* eslint react/prop-types: 0 */
        dispatch(actions.DeleteTeam(team.Id));
      },
    });
  };

  handleCancel = () => {
    this.props.dispatch(push("/team"));
  };

  handleCallback = () => {
    this.props.dispatch(push("/team"));
  };

  render() {
    return (
      <div className="p5-page-container-small">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <Spin spinning={this.props.loading}>
              <TeamForm
                loading={this.props.loading}
                onSubmit={this.updateTeam}
                onCancel={this.handleCancel}
                onDelete={this.deleteTeam}
                // eslint-disable-next-line react/jsx-boolean-value
                isUpdateMode={true}
                title="Edit Team"
                {...this.props.team}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

TeamEditPage.propTypes = {
    dispatch: () => {},
    match: PropTypes.shape(),
    team: PropTypes.shape({}),
    loading: PropTypes.bool,
  };
  
  TeamEditPage.defaultProps = {
    dispatch: () => {},
    match: {},
    team: {},
    loading: false,
  };

function mapStateToProps(state) {
  const { team, loading } = state.Team;
  team.Force = { Id: team.LawEnforcementAgencyId ?? 0, Name: "" };
  team.Area = { Id: team.LawEnforcementAgencyAreaId ?? 0, Name: "" };
  return {
    team,
    loading,
  };
}

const connectedTeamEditPage = connect(mapStateToProps)(TeamEditPage);


export default connectedTeamEditPage;
