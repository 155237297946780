import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Input, Button, Form } from "antd";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TechSosLogo from "../../../common/assets/img/TecSAFE_logo_light.png";
import AuthService from "../../../services/Auth";

class MFAForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
        VerificationCode: "",
        IsCodeResent: false,
    };
  }

  handleChange = (e) => {
    const { value, id } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  resendMFA = () => {
    AuthService.resendMFA(this.props.username);
    this.setState({
      IsCodeResent: true
    });
    setTimeout(() => this.setState({
      IsCodeResent: false,
    }), 5000);
  }

  render() {
    const codeResent = (
      <>
        <span style={{color: "#1C5906"}}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className=""
            alt="codeResent"
          />
        </span>
        <span style={{color: "#1C5906"}}>Verification code sent.</span>
      </>
    );

    return (
      <Formik
        enableReinitialize
        initialValues={{
          VerificationCode: this.state.VerificationCode,
        }}
        validationSchema={Yup.object().shape({
            VerificationCode: Yup.string()
            .typeError("Verification Code is required")
            .required("Verification Code is required"),
        })}
        onSubmit={(values) => this.props.onSubmit(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <div className="account-layout-form-container">
            <Row justify="center">
              <Col span={20}>
                <div className="login-logo-container">
                  <img style={{"maxWidth": "450px"}} src={TechSosLogo} width="100%" id="LoginLogo" alt="Logo" />
                </div>
              </Col>
            </Row>
            <Form
              name={this.props.name}
              className="login-form"
              id={this.props.name}
              onChange={handleChange}
              onSubmit={handleSubmit}
              autoComplete={0}
            >
              <Row justify="center">
                <Col span={20}>
                  <h1 className="p5-form-h1-header"> Login </h1>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={20}>
                  <Form.Item
                    validateStatus={
                      errors && errors.VerificationCode && touched.VerificationCode
                        ? "error"
                        : null
                    }
                    help={errors && touched.VerificationCode && errors.VerificationCode}
                  >
                    <div className="p5-form-label"> Verification Code </div>
                    <Input.Password
                      id="VerificationCode"
                      className="p5-form-input"
                      name="VerificationCode"
                      value={values.VerificationCode}
                      placeholder="Verification Code"
                      onChange={this.handleChange}
                      onBlur={handleBlur}
                      onPressEnter={handleSubmit}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={20}>
                  <Form.Item
                    validateStatus={this.props.hasError ? "error" : null}
                    help={
                      this.props.hasError
                        ? this.props.error ?? "Invalid verfication code"
                        : null
                    }
                  >
                    <Button
                      block
                      type="submit"
                      id="loginBtn"
                      className="loginBtn"
                      onClick={handleSubmit}
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              {this.state.IsCodeResent && (
                <Row justify="center">
                  <Col span={20} style={{textAlign: 'center'}}>
                    {codeResent}
                  </Col>
                </Row>
              )}              
              <Row justify="center">
                <Col span={20}>
                  <div className="login-forgot-password">
                    <Button
                      onClick={this.resendMFA}
                    >
                      Re-send verification code
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </Formik>
    );
  }
}

MFAForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  error: PropTypes.string,
  username: PropTypes.string,
};

MFAForm.defaultProps = {
  hasError: false,
  error: null,
  username: null,
};

export default MFAForm;
