import { toast } from "react-toastify";
// import { push } from 'connected-react-router';
import constants from "./constants";
import { RegisterService } from "../../../services";

function registerGmail(data, callback) {

  function request() {
    return { type: constants.REGISTER_GMAIL_REQUEST };
  }
  function success(result) {
    return { type: constants.REGISTER_GMAIL_SUCCESS, result };
  }
  function failure(error) {
    return { type: constants.REGISTER_GMAIL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.registerGmail(data).then(
      (response) => {
        if(!response.data)
        {
          dispatch(failure(response.data));
        }
        else
        {
          const { RegisteredGmail, SentSMS } = response.data;
          if(!RegisteredGmail){
            dispatch(failure(response.data));
            toast("Error adding user email to Google Group");
          }else if(!SentSMS){
            dispatch(failure(response.data));
            toast("Error sending user SMS message");
          }else{
            dispatch(success(response));
            callback(response.data);
          }
        }        
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function newRegistration(data, callback, errorCallback) {
  function request() {
    return { type: constants.NEW_REGISTRATION_REQUEST };
  }
  function success(result) {
    return { type: constants.NEW_REGISTRATION_SUCCESS, result };
  }
  function failure(error) {
    return { type: constants.NEW_REGISTRATION_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.newRegistration(data).then(
      (response) => {
        if(response.data.HasError)
        {
          dispatch(failure(response.data.Error));
          errorCallback(response.data.Error);
        }
        else
        {
          dispatch(success(response));
          toast("Successfully added new registration");
          callback(response.data);
        }        
      },
      (error) => {
        dispatch(failure(error.toString()));
        errorCallback(data);
      }
    );
  };
}

export default {
  newRegistration,
  registerGmail,
};
