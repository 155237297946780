import { toast } from "react-toastify";
import { push } from 'connected-react-router';
import areaConstants from "./constants";
import { AreaService } from "../../services";

function GetGridList() {
  function request() {
    return { type: areaConstants.GET_AREA_LIST_SUCCESS };
  }
  function success(list) {
    return { type: areaConstants.GET_AREA_LIST_SUCCESS, list };
  }
  function failure(error) {
    return { type: areaConstants.GET_AREA_LIST_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    AreaService.getGridList().then(
      (List) => {
        dispatch(success(List.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function CreateArea(data, callback) {
  function request() {
    return { type: areaConstants.CREATE_AREA_REQUEST };
  }
  function success(area) {
    return { type: areaConstants.CREATE_AREA_SUCCESS, area };
  }
  function failure(error) {
    return { type: areaConstants.CREATE_AREA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    AreaService.createArea(data).then(
      (area) => {
        dispatch(success(area));
        toast("Successfully created a new Area");               
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again"); 
      }
    );
  };
}

function GetAreaById(data) {
  function request() {
    return { type: areaConstants.GET_AREA_REQUEST };
  }
  function success(area) {
    return { type: areaConstants.GET_AREA_SUCCESS, area };
  }
  function failure(error) {
    return { type: areaConstants.GET_AREA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    AreaService.getAreaById(data).then(
      (area) => {
        dispatch(success(area));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function UpdateArea(data, callback) {
  function request() {
    return { type: areaConstants.UPDATE_AREA_REQUEST };
  }
  function success() {
    return { type: areaConstants.UPDATE_AREA_SUCCESS };
  }
  function failure(error) {
    return { type: areaConstants.UPDATE_AREA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    AreaService.updateArea(data).then(
      () => {        
        dispatch(success());
        toast("Successfully updated Area");       
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again"); 
      }
    );
  };
}

function DeleteArea(id) {
  function request() {
    return { type: areaConstants.DELETE_AREA_REQUEST };
  }
  function success() {
    return { type: areaConstants.DELETE_AREA_SUCCESS };
  }
  function failure(error) {
    return { type: areaConstants.DELETE_AREA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
   
    AreaService.deleteArea(id).then(
      () => {        
        dispatch(success());
        toast("Successfully Delete this Area");
        dispatch(push(`/area`));
        // callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again"); 
      }
    );
  };
}

export default {
  GetGridList,
  CreateArea,
  GetAreaById,
  UpdateArea,
  DeleteArea,
};
