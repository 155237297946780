import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import { Layout, PageHeader } from "../../common/components";
import { SampleHeader } from "./layout";
import { SamplePage } from "./page";

// const SampleHeader = (
//   <PageHeader title="Sample Header" />
// )

const CustomHeader = (
  <PageHeader customHeader={<SampleHeader />} />
)

const Sample = ({ match }) => (
  <Layout PageHeader={CustomHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={SamplePage} />
    </Switch>
  </Layout>
);

Sample.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Sample.defaultProps = {
  match: {
    url: "",
  },
};

export default withRouter(Sample);
