// import { toast } from "react-toastify";
import { push } from 'connected-react-router';
import tourRegConstants from "./constants";
import { TourRegistrationData } from "./tour-data";
import { TourGuidesService } from "../../services";
// import AuthAction from "../account/actions";

function CheckUserActiveTourGuides(userId = null) {
  function request() {
    return { type: tourRegConstants.TOUR_CHECK_ACTIVE_REQUEST };
  }
  function success(isActive) {
    return { type: tourRegConstants.TOUR_CHECK_ACTIVE_SUCCESS, isActive };
  }
  function failure(error) {
    return { type: tourRegConstants.TOUR_CHECK_ACTIVE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    TourGuidesService.UserHasActiveTourGuides(userId).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function GetRegistrationList(querystring, callback) {
  function request() {
    return { type: tourRegConstants.TOUR_REGISTRATION_LIST_REQUEST };
  }
  function success() {
    return { type: tourRegConstants.TOUR_REGISTRATION_LIST_SUCCESS };
  }
  // function failure(error) {
  //   return { type: tourRegConstants.REGISTRATION_LIST_FAILURE, error };
  // }

  return (dispatch) => {
    dispatch(request());
    dispatch(success());
    callback(TourRegistrationData.RegistrationData);
  };
}

function GetTourListByUser(userId = null) {
  function request() {
    return { type: tourRegConstants.TOUR_GET_LIST_REQUEST };
  }
  function success(TourListDetail) {
    return { type: tourRegConstants.TOUR_GET_LIST_SUCCESS, TourListDetail };
  }
  function failure(error) {
    return { type: tourRegConstants.TOUR_GET_LIST_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    TourGuidesService.getTourGuideListById(userId).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function GetCurrentTourGuide(tourId, userId) {
  function request() {
    return { type: tourRegConstants.TOUR_GET_CURRENT_REQUEST };
  }
  function success(TourDetail) {
    return { type: tourRegConstants.TOUR_GET_CURRENT_SUCCESS, TourDetail };
  }
  function failure(error) {
    return { type: tourRegConstants.TOUR_GET_CURRENT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    TourGuidesService.getTourGuideById(tourId, userId).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function GoToTourGuidePage(tourId, userId, callback = null) {
  function request() {
    return { type: tourRegConstants.TOUR_GET_CURRENT_REQUEST };
  }
  function success(TourDetail) {
    return { type: tourRegConstants.TOUR_GET_CURRENT_SUCCESS, TourDetail };
  }
  function failure(error) {
    return { type: tourRegConstants.TOUR_GET_CURRENT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    TourGuidesService.getTourGuideById(tourId, userId).then(
      (response) => {
        dispatch(success(response.data));
        if(callback !== null)
          callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function RemoveTourModalOverride() {
  function success() {
    return { type: tourRegConstants.TOUR_REMOVE_MODAL_OVERRIDE };
  }

  return (dispatch) => {
    alert('remove overrride');
    dispatch(success());
    
  };
}

function MarkTourGuideAsCompleted(data, callback) {
  function request() {
    return { type: tourRegConstants.TOUR_MARK_COMPLETED_REQUEST };
  }
  function success() {
    return { type: tourRegConstants.TOUR_MARK_COMPLETED_SUCCESS };
  }
  function failure(error) {
    return { type: tourRegConstants.TOUR_MARK_COMPLETED_FAILURE, error };
  }
  function listRequest() {
    return { type: tourRegConstants.TOUR_GET_LIST_REQUEST };
  }
  function listSuccess(TourListDetail) {
    return { type: tourRegConstants.TOUR_GET_LIST_SUCCESS, TourListDetail };
  }
  function listFailure(error) {
    return { type: tourRegConstants.TOUR_GET_LIST_FAILURE, error };
  }


  return (dispatch) => {
    dispatch(request());
    TourGuidesService.markTourGuideCompleted(data).then(
      () => {        
        dispatch(success());
        dispatch(listRequest());
      TourGuidesService.getTourGuideListById(data.UserId).then(
      (response) => {
        dispatch(listSuccess(response.data));
        dispatch(push(`/`));
      },
      (error) => {
        dispatch(listFailure(error.toString()));
      }
    );  
    callback();
    // const newLocation = window.location.href.split(`/`)[0];

    // window.location.href = newLocation
    // window.location.reload();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

export default {
  CheckUserActiveTourGuides,
  GetRegistrationList,
  GetTourListByUser,
  GetCurrentTourGuide,
  GoToTourGuidePage,
  RemoveTourModalOverride,
  MarkTourGuideAsCompleted,
};
