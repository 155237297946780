import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import { Layout, PageHeader } from "../../common/components";
import { UnregisteredPage } from "./pages";

const UserListHeader = (
  <PageHeader title="Unregistered" />
)

const Unregistered = ({ match }) => (
  <Layout PageHeader={UserListHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={UnregisteredPage} />
    </Switch>
  </Layout>
);

Unregistered.propTypes = {
  match: PropTypes.shape({
      url: PropTypes.string,
    }),
};

Unregistered.defaultProps = {
  match: {
      url: "",
    },
};

export default withRouter(Unregistered);
