import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import { Layout, PageHeader } from "../../common/components";
// import { SampleHeader } from "./layout";
import { HubPage, HubCreatePage, HubEditPage } from "./page";

const HubHeader = (
  <PageHeader title="Hub" />
)

// const CustomHeader = (
//   <PageHeader customHeader={<SampleHeader />} />
// )

const Hub = ({ match }) => (
  <Layout PageHeader={HubHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={HubPage} />
      <Route exact path={`${match.url}/create`} component={HubCreatePage} />
      <Route exact path={`${match.url}/:id`} component={HubEditPage} /> 
    </Switch>
  </Layout>
);

Hub.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Hub.defaultProps = {
  match: {
    url: "",
  },
};

export default withRouter(Hub);
