import { toast } from "react-toastify";
import { push } from 'connected-react-router';
import c from "./constants";
import { agencyService } from "../../services";

function GetGridList() {
  function request() {
    return { type: c.GET_LEA_LIST_REQUEST };
  }
  function success(list) {
    return { type: c.GET_LEA_LIST_SUCCESS, list };
  }
  function failure(error) {
    return { type: c.GET_LEA_LIST_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    agencyService.getGridList().then(
      (List) => {
        dispatch(success(List.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}
function CreateForce(data, callback) {
  function request() {
    return { type: c.CREATE_LEA_REQUEST };
  }
  function success(force) {
    return { type: c.CREATE_LEA_SUCCESS, force };
  }
  function failure(error) {
    return { type: c.CREATE_LEA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    agencyService.createForce(data).then(
      (force) => {
        dispatch(success(force));
        toast("successfully created a new Force"); 
        callback(force.data);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

function GetForce(data) {
  function request() {
    return { type: c.GET_LEA_REQUEST };
  }
  function success(force) {
    return { type: c.GET_LEA_SUCCESS, force };
  }
  function failure(error) {
    return { type: c.GET_LEA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    agencyService.getForce(data).then(
      (force) => {
        dispatch(success(force));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function GetForceById(data) {
  function request() {
    return { type: c.GET_LEA_ID_REQUEST };
  }
  function success(force) {
    return { type: c.GET_LEA_ID_SUCCESS, force };
  }
  function failure(error) {
    return { type: c.GET_LEA_ID_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    
    agencyService.getForceById(data).then(
      (force) => {
        dispatch(success(force));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function UpdateForce(data, callback) {
  function request() {
    return { type: c.UPDATE_LEA_REQUEST };
  }
  function success() {
    return { type: c.UPDATE_LEA_SUCCESS };
  }
  function failure(error) {
    return { type: c.UPDATE_LEA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    agencyService.updateForce(data).then(
      () => {
        dispatch(success());
        toast('Successfully updated Force');
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

function DeleteForce(id) {
  function request() {
    return { type: c.DELETE_LEA_REQUEST };
  }
  function success() {
    return { type: c.DELETE_LEA_SUCCESS };
  }
  function failure(error) {
    return { type: c.DELETE_LEA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    agencyService.deleteForce(id).then(
      () => {
        dispatch(success());
        toast("Successfully Deleted this Force");
        dispatch(push(`/force`));
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

export default {
  GetGridList,
  CreateForce,
  GetForce,
  GetForceById,
  UpdateForce,
  DeleteForce,
};
