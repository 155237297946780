import hubConstants from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  hubGrid: {},
  allocation: {},
};

export default function hubReducer(state = initialState, action) {
  switch (action.type) {
    case hubConstants.HUB_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        hubGrid: {},
      };
    case hubConstants.HUB_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        hubGrid: action.hub,
      };
    case hubConstants.HUB_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        hubGrid: {},
      };
    case hubConstants.HUB_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case hubConstants.HUB_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case hubConstants.HUB_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case hubConstants.HUB_GET_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case hubConstants.HUB_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        allocation: action.hub.data,
      };
    case hubConstants.HUB_GET_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case hubConstants.HUB_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case hubConstants.HUB_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case hubConstants.HUB_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}
