import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout, PageHeader } from '../../common/components';
import {  CreatePage, ListView, EditPage} from './page';

const LEAHeader = (
  <PageHeader title="Force Settings" />
)

const LEA = ({ match }) => (
  <Layout PageHeader={LEAHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={ListView} />
      <Route exact path={`${match.url}/create`} component={CreatePage} />
      <Route exact path={`${match.url}/:id`} component={EditPage} />     
    </Switch>
  </Layout>
);

LEA.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

LEA.defaultProps = {
  match: {
    url: '',
  },
};

export default withRouter(LEA);
