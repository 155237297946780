import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SystemVersionSelect from "../../../common/components/system-version-select";
import { NameInput, SaveBtn, CancelBtn } from "../../../common/components/form";
import "./LEAForm.scss";

class LEAForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Id: 0,
      Name: "",
    };
  }

  handleChange = (e) => {
    const { value, id } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  selectCounty = (val) => {
    this.setState({ County: val });
  };

  render() {
    const ValidationSchema = Yup.object().shape({
      Id: Yup.number(),
      Name: Yup.string()
        .typeError("Name is required")
        .required("Name is required"),
      SystemVersionId: Yup.number()
        .typeError("System Version is required")
        .required("System Version is required"),
    });

    return (
      <Formik
        enableReinitialize
        initialValues={{
          Id: this.props.Id || this.state.Id,
          Name: this.props.Name || this.state.Name,
          SystemVersionId: this.props.SystemVersionId,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          this.props.onSubmit(values);
        }}
      >
        {({ values, errors, touched, setFieldValue, handleChange, handleSubmit }) => (
          <Form
            autoComplete={0}
            name="LEAForm"
            id="LEAForm"
            onChange={this.handleChange}
            onSubmit={handleSubmit}
          >
            <div className="p5-form-container">
              <Row justify="center">
                {this.props.isUpdateMode ? (
                  <Col span={12}>
                    <h1 className="p5-form-h1-header">{this.props.title}</h1>
                  </Col>
                ) : (
                  <Col span={22}>
                    <h1 className="p5-form-h1-header">{this.props.title}</h1>
                  </Col>
                )}
                {this.props.isUpdateMode && (
                  <Col span={10}>
                    <Tooltip title="Delete Force">
                      <FontAwesomeIcon
                        onClick={this.props.onDelete}
                        className="force-delete-btn"
                        size="2x"
                        icon={faTrash}
                        pull="right"
                      />
                    </Tooltip>
                  </Col>
                )}
              </Row>
              <Row justify="center">
                <Col xs={24} sm={24} md={22}>
                  <Form.Item
                    validateStatus={
                      errors && errors.SystemVersionId && touched.SystemVersionId
                        ? "error"
                        : null
                    }
                    help={errors && touched.SystemVersionId && errors.SystemVersionId}
                  >
                    <h2 className="p5-form-label">
                      P5 Version
                    </h2>
                    <SystemVersionSelect
                      className="p5-form-dropdown"
                      name="SystemVersionId"
                      value={values.SystemVersionId}
                      onSelect={(val) => {
                          setFieldValue("SystemVersionId", val);
                        }}
                      onChange={(val) => {
                        setFieldValue("SystemVersionId", val);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={22}>
                  <NameInput
                    values={values}
                    errors={errors}
                    touched={touched}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row justify="end">
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  style={{ margin: "5px" }}
                >
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  style={{ margin: "5px" }}
                >
                  <SaveBtn
                    onClick={handleSubmit}
                    loading={this.props.loading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

LEAForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  Id: PropTypes.number,
  Name: PropTypes.string,
  SystemVersionId: PropTypes.number,
  isUpdateMode: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
};

LEAForm.defaultProps = {
  // onSubmit: () => {},
  onCancel: () => {},
  onDelete: () => {},
  title: "",
  Id: 0,
  Name: "",
  SystemVersionId: 1,
  isUpdateMode: false,
  loading: false,
};

export default LEAForm;
