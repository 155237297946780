import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Input } from "antd";
import { DocumentDisplay } from "../../../common/components/file-upload";
import { CancelBtn } from "../../../common/components/form";

class ResourceView extends React.Component {
  render() {
    const { TextArea } = Input;

    return (
      <div className="ResourceView">
        <Row>
          <Col span={24}>
            <h1>{this.props.Title}</h1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TextArea
              style={{ margin: "15px auto" }}
              autoSize
              value={this.props.Description}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DocumentDisplay Documents={this.props.Documents} />
          </Col>
        </Row>
        <Row justify="end">
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <CancelBtn onClick={this.props.onCancel} />
          </Col>
        </Row>
      </div>
    );
  }
}

ResourceView.propTypes = {
  onCancel: PropTypes.func,
  Title: PropTypes.string,
  Description: PropTypes.string,
  Documents: PropTypes.arrayOf(PropTypes.shape({})),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

ResourceView.defaultProps = {
  onCancel: () => {},
  Title: "",
  Description: "",
  Documents: [],
  match: {},
};

export default ResourceView;
