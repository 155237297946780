import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Tabs } from "antd";
import moment from "moment";
import { phoneNumberFormatter } from "../../../common/utils/phone-number-helper";

const { TabPane } = Tabs;

class LiteVictimDetails extends React.PureComponent {
  render() {
    const result = (
      <>
        <Tabs
          defaultActiveKey="1"
          className="p5-tabs"
          style={{ margin: "0 24px" }}
          size="large"
        >
          <TabPane tab="Victim Details" key="1" className="p5-tabs-pane">
            <div className="p5-page-container mx-auto">
              <Row gutter={[24, 6]}>
                <Col lg={24} md={24} sm={24} className="gutter-row">
                  <Row gutter={[24, 24]}>
                    <Col span={24} className="gutter-row">
                      <div className="view-form-container">
                        <Row>
                          <Col span={24}>
                            <h5 className="p5-form-h1-header">
                              Victim Details
                            </h5>
                          </Col>
                        </Row>
                        <hr />
                        <div>
                          <Row gutter={[16, 8]}>
                            <Col span={12}>
                              <div className="meta-data-header">
                                Entry Date:
                              </div>
                            </Col>
                            <Col span={12}>
                              <strong>
                                {moment(this.props.CreatedDate)
                                  .format("DD/MM/YYYY h:mm:ss")
                                  .toString()}
                              </strong>
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">Gender:</div>
                            </Col>
                            <Col span={12}>
                              <strong>{this.props.Gender}</strong>
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">
                                Phone Number:
                              </div>
                            </Col>
                            <Col span={12}>
                              <strong>
                                {phoneNumberFormatter(this.props.PhoneNumber)}
                              </strong>
                            </Col>
                            
                            <Col span={24}>
                              <hr />
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">
                                Risk Level:
                              </div>
                            </Col>
                            <Col span={12}>
                              <strong>{this.props.RiskLevel}</strong>
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">
                                Local Ref. #:
                              </div>
                            </Col>
                            <Col span={12}>
                              <strong>{this.props.CrimeRefNumber}</strong>
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">
                                Risk Categories:
                              </div>
                            </Col>
                            <Col span={12}>
                              {this.props.RiskCategories &&
                              this.props.RiskCategories.length > 0 ? (
                                <div>
                                  {this.props.RiskCategories.map(
                                    (risk, index) => (
                                      <strong key={risk.Id}>
                                        {risk.DisplayValue}
                                        {this.props.RiskCategories.length > 1 &&
                                        index + 1 !==
                                          this.props.RiskCategories.length
                                          ? ", "
                                          : ""}
                                      </strong>
                                    )
                                  )}
                                </div>
                              ) : (
                                <strong>None</strong>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} className="gutter-row">
                      <div className="view-form-container">
                        <Row>
                          <Col span={24}>
                            <h5 className="p5-form-h1-header">
                              Officer In The Case Details
                            </h5>
                          </Col>
                        </Row>
                        <hr />
                        <Row gutter={[16, 8]}>
                          <Col span={12}>
                            <div className="meta-data-header">Police Force:</div>
                          </Col>
                          <Col span={12}>
                            <strong>{this.props.LawEnforcementAgency_Name}</strong>
                          </Col>
                          <Col span={12}>
                            <div className="meta-data-header">Officer Name:</div>
                          </Col>
                          <Col span={12}>
                            <strong>{this.props.OIC_Name}</strong>
                          </Col>
                          <Col span={12}>
                            <div className="meta-data-header">Staff ID:</div>
                          </Col>
                          <Col span={12}>
                            <strong>{this.props.OIC_StaffID}</strong>
                          </Col>
                          <Col span={12}>
                            <div className="meta-data-header">
                              Authorising Officer:
                            </div>
                          </Col>
                          <Col span={12}>
                            <strong>{this.props.AuthorisingOfficer}</strong>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </TabPane>
        </Tabs>
      </>
    );

    return result;
  }
}

function mapStateToProps() {
  return {};
}

LiteVictimDetails.propTypes = {
  CreatedDate: PropTypes.string,
  Gender: PropTypes.string,
  PhoneNumber: PropTypes.string,
  RiskLevel: PropTypes.string,
  CrimeRefNumber: PropTypes.string,
  OIC_Name: PropTypes.string,
  OIC_StaffID: PropTypes.string,
  AuthorisingOfficer: PropTypes.string,
  RiskCategories: PropTypes.arrayOf(PropTypes.shape({})),
  LawEnforcementAgency_Name: PropTypes.string,
};

LiteVictimDetails.defaultProps = {
  CreatedDate: "",
  Gender: "",
  PhoneNumber: "",
  RiskLevel: "",
  CrimeRefNumber: "",
  OIC_Name: "",
  OIC_StaffID: "",
  AuthorisingOfficer: "",
  RiskCategories: [],
  LawEnforcementAgency_Name: "",
};

const connectedLiteVictimDetails = connect(mapStateToProps)(LiteVictimDetails);

export { connectedLiteVictimDetails as default };
