import c from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  noticeText: ""
};

export default function SystemInfo(state = initialState, action) {
  switch (action.type) {
    case c.GET_SYSTEM_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false
      };
    case c.GET_SYSTEM_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        noticeText: action.noticeText
      };
    case c.GET_SYSTEM_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true
      };
      case c.SET_SYSTEM_INFO_REQUEST:
        return {
          ...state,
          loading: true,
          hasError: false
        };
      case c.SET_SYSTEM_INFO_SUCCESS:
        return {
          ...state,
          loading: false,
          hasError: false,
          noticeText: action.noticeText
        };
      case c.SET_SYSTEM_INFO_FAILURE:
        return {
          ...state,
          loading: false,
          hasError: true
        };
    default:
      return state;
  }
}
