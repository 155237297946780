import c from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  vpsList: [],
  licensedUsedData: [],
  victimDetails: [],
  registeredDevices: [],
  activatedDevices: [],
  overdueRegistrations: [],
  genderByDeviceType: [],
  riskCategoryByDeviceType: [],
  noLocationReported: [],
  allocationDetails: [],
  allocationUsageDetails: [],
  alertCallDetails: [],
  error: {},
};

export default function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    case c.GET_VPS_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        vpsList: []
      };
    case c.GET_LICENSED_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_VPS_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_LICENSED_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_VPS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        vpsList: action.vpsList
      };
    case c.GET_LICENSED_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        licensedUsedData: action.licensedUsedData
      };
    case c.GET_VICTIM_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_VICTIM_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        victimDetails: action.victimDetails
      };
    case c.GET_VICTIM_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_REGISTERED_DEVICES_REQUEST:
      return {
        ...state,
        registeredDevices: [],
        loading: true,
        hasError: false,
      };
    case c.GET_REGISTERED_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        registeredDevices: action.registeredDevices
      };
    case c.GET_REGISTERED_DEVICES_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };   
    case c.GET_ACTIVATED_DEVICES_REQUEST:
      return {
        ...state,
        activatedDevices: [],
        loading: true,
        hasError: false,
      };
    case c.GET_ACTIVATED_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        activatedDevices: action.activatedDevices
      };
    case c.GET_ACTIVATED_DEVICES_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_OVERDUE_REGISTRATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        overdueRegistrations: [],
      };
    case c.GET_OVERDUE_REGISTRATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        overdueRegistrations: action.overdueRegistrations
      };
    case c.GET_OVERDUE_REGISTRATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_GENDER_DEVICES_REQUEST:
      return {
        ...state,
        genderByDeviceType: [],
        loading: true,
        hasError: false,
      };
    case c.GET_GENDER_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        genderByDeviceType: action.registeredDevices
      };
    case c.GET_GENDER_DEVICES_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };   
    case c.GET_RISK_CATEGORY_DEVICES_REQUEST:
      return {
        ...state,
        riskCategoryByDeviceType: [],
        loading: true,
        hasError: false,
      };
    case c.GET_RISK_CATEGORY_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        riskCategoryByDeviceType: action.registeredDevices
      };
    case c.GET_RISK_CATEGORY_DEVICES_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };   
    case c.GET_NO_LOCATION_REPORTED_REQUEST:
      return {
        ...state,
        noLocationReported: [],
        loading: true,
        hasError: false,
      };
    case c.GET_NO_LOCATION_REPORTED_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        noLocationReported: action.noLocationReported
      };
    case c.GET_NO_LOCATION_REPORTED_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_ALLOCATION_DETAILS_REQUEST:
      return {
        ...state,
        allocationDetails: [],
        loading: true,
        hasError: false,
      };
    case c.GET_ALLOCATION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        allocationDetails: action.allocationDetails
      };
    case c.GET_ALLOCATION_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };  
    case c.GET_ALLOCATION_USAGE_DETAILS_REQUEST:
      return {
        ...state,
        allocationUsageDetails: [],
        loading: true,
        hasError: false,
      };
    case c.GET_ALLOCATION_USAGE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        allocationUsageDetails: action.allocationUsageDetails
      };
    case c.GET_ALLOCATION_USAGE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_ALERT_CALL_DETAILS_REQUEST:
      return {
        ...state,
        alertCallDetails: [],
        loading: true,
        hasError: false,
      };
    case c.GET_ALERT_CALL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        alertCallDetails: action.alertCallDetails
      };
    case c.GET_ALERT_CALL_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}
