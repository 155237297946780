import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import AccountState from './features/account/reducer';
import RegisterUserFormState from './features/registeration/reducer';
import LEAState from './features/LEA/reducer'
import Hub from './features/hub/reducer';
import Navigation from "./common/components/navigation/reducer";
import ImageUpload from './common/components/image-upload/reducer';
import DocumentUpload from './common/components/file-upload/reducer';
import Users from './features/user/reducer';
import ResourceLibrary from './features/resourceLib/reducer';
import Area from "./features/area/reducer";
import Team from "./features/team/reducer";
import Search from "./features/search/reducer";
import RegistrationModal from "./common/components/registration-modal/reducer";
import DeregistrationModal from "./common/components/unregister-modal/reducer";
import SystemInfo from './features/admin/reducer';
import SystemDashboard from './features/home/reducer';
import GenderList from './common/components/gender-select/reducer';
import TourGuide from './features/guide-tour/reducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  AccountState,
  Area,
  DeregistrationModal,
  DocumentUpload,
  GenderList,
  Hub,
  ImageUpload,
  LEAState,
  Navigation,
  RegisterUserFormState,
  RegistrationModal,
  ResourceLibrary,
  Search,
  SystemDashboard,  
  SystemInfo,
  Team,
  Users,
  TourGuide
});
