import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout, PageHeader } from '../../common/components';
import {  ResourceCreatePage, ResourceIndexPage, ResourceEditPage, ResourceReadPage} from './page';

const ResourcesHeader = (
  <PageHeader title="Resources" />
)

const ResourceLib = ({ match }) => (
  <Layout PageHeader={ResourcesHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={ResourceIndexPage} />
      <Route exact path={`${match.url}/create`} component={ResourceCreatePage} />
      <Route exact path={`${match.url}/:id`} component={ResourceEditPage} />
      <Route exact path={`${match.url}/view/:id`} component={ResourceReadPage} />
    </Switch>
  </Layout>
);

ResourceLib.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

ResourceLib.defaultProps = {
  match: {
    url: '',
  },
};

export default withRouter(ResourceLib);
