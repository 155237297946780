import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import {
  Row,
  Col,
  Spin,
} from 'antd';
import { history } from "../../../store";
import { ResourceForm } from '../form';
import navigationActions from "../../../common/components/navigation/action";
import actions from '../actions';
import './ResourceLib.css';

class ResourceCreatePage extends React.Component {
  componentDidMount(){
    this.props.dispatch(navigationActions.changePage("ResourceLibrary"));
  }

  handleSubmit = (data) => {
    this.props.dispatch(actions.CreateResource(data, this.handleCallback));
  }

  handleCallback = () => {
    history.push('/ResourceLibrary');
  };
  
  handleCancel = () => {
    history.push('/ResourceLibrary');
  }

    render(){
        return(
          <div className="p5-page-container">
            <Row justify="center">
              <Col xs={20} md={20} lg={18}>
                <Spin spinning={this.props.loading}>
                  <ResourceForm
                    loading={this.props.loading}
                    onSubmit={this.handleSubmit}
                    onCancel={this.handleCancel}
                    currentResource={this.props.currentResource}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        );
    }
}

function mapStateToProps(state) {
  const { loading, currentResource } = state.ResourceLibrary;
  return {
    loading,
    currentResource,
  };
}

const connectedResourceCreatePage = connect(mapStateToProps)(ResourceCreatePage);

ResourceCreatePage.propTypes = {
  dispatch: () => {},
  currentResource: PropTypes.shape({}),
  loading: PropTypes.bool,
};

ResourceCreatePage.defaultProps = {
  dispatch: () => {},
  currentResource: {},
  loading: false,
};

export default connectedResourceCreatePage;
