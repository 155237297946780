
const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
          enabled: false
        }
      },
      useModalOverlay: true
};

const ResourceGrpTourSteps = [    
  {
    title: "Resource Library",
    id: "tour-resource-library-tab",
    text: [
      `
      <p>
      The Resource Library provides you with detailed documentation about TecSAFE products and the TecSAFE portal.       
      </p>
      <p>
      Click on the 'Eye' icon and it will take you to a PDF version of the document. Double-click on that PDF indicator and it will open the document in your computer browser.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-library-tab", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          document.getElementById("sh-show-news-btn").click();       
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {
          document.getElementById("sh-show-lib-btn").click(); 
        },
    },
  }, 
  {
    title: "Good News Stories",
    id: "tour-resource-good-news-tab",
    text: [
      `
      <p>
      Staff in forces can submit good news stories about cases they have used TecSAFE to support victims, and will be published here to enable good practice to be disseminated to other forces.         
      </p>
      <p>
      Any good news stories submitted must be anonymised before submission - i.e. use of terms such as 'Offender' and 'Victim' etc. instead of names.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-news-tab", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => { 
          document.getElementById("sh-show-lib-btn").click();     
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {
          document.getElementById("sh-show-news-btn").click();
        },
    },
  }, 
  {
    title: "Resouce Library/Good News Stories Complete",
    id: "tour-help-complete",
    text: [
      `
      <p>
      That concludes the Resource Library/Good News Stories page. 
      Please select Complete to this tour to mark as complete.
      </p>
      `,
    ],
    attachTo: { element: "", on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Complete",
        type: "complete"
      }
    ],
    when: {
      complete: () => {},
      hide: () => {
        document.getElementById("sh-show-news-btn").click();
      },
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
];

  export default {
    tourOptions,
    ResourceGrpTourSteps
  }