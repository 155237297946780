import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const ForceInput = (props) => {
  return(
    <Form.Item
      validateStatus={
      props.errors && props.errors.Force && props.touched.Force
        ? "error"
        : null
    }
      help={props.errors && props.touched.Force && props.errors.Force}
    >
      <h2 className="form-label">
        Force
      </h2>
      <Input 
        type="text" 
        id="Force" 
        className="form-input"
        value={props.values.Force} 
        onChange={props.onChange}
      />
    </Form.Item>
  );
}

ForceInput.propTypes = {
    onChange: () => {},
    errors: PropTypes.shape({
        Force: PropTypes.string,
    }),
    values: PropTypes.shape({
        Force: PropTypes.string,
    }),
    touched: PropTypes.shape({
        Force: PropTypes.bool,
    })
}

ForceInput.defaultProps = {
    onChange: () => {},
    errors: {},
    values: {},
    touched: {}
}

export default ForceInput;
