import React from 'react';
import { Menu } from "antd";
import { Link } from "react-router-dom";
import SOSLogo from '../../assets/img/TecSAFE_logo_light.png';
import Actions from '../../../features/account/actions';
import  IconHelper from '../../utils/icon-helper'
import './TopNavbar.css';

class TopNavbar extends React.PureComponent {
  handleLogout = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(Actions.Logout());
  }

  render() {
    const { SubMenu } = Menu;
    return (
      <div className="top-nav-panel">
        <div className="logo-container">
          <Link to="/">
            <img src={SOSLogo} alt="TechSOSLogo" className="logoimg" />
          </Link>
        </div>
        <div className="top-nav-items">
          <Menu defaultSelectedKeys={["dashboard"]} mode="horizontal">
            <Menu.Item key="Notification" icon={IconHelper.BellIcon}>
              <Link to="/" />
            </Menu.Item>
            <Menu.Item key="Settings" icon={IconHelper.CogIcon}>
              <Link to="/" />
            </Menu.Item>
            <SubMenu
              key="Profile"
              icon={IconHelper.ProfileIcon}
              title={IconHelper.ChevronDownIcon}
            >
              <Menu.ItemGroup>
                <Menu.Item key="logout">
                  <button
                    type="button"
                    id="logoutBtn"
                    onClick={(e) => this.handleLogout(e)}
                  >
                    Logout
                  </button>
                </Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
          </Menu>
        </div>
      </div>
    );
  }
}

TopNavbar.propTypes = {
  dispatch: () => {},
};

TopNavbar.defaultProps = {
  dispatch: () => {},
};

export { TopNavbar as default };
