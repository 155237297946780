import c from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  area: {},
  error: {},
};

export default function AreaReducer(state = initialState, action) {
  switch (action.type) {
    case c.GET_AREA_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_AREA_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        list: action.list,
      };
    case c.GET_AREA_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_AREA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        area: action.area.data,
      };
    case c.GET_AREA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.CREATE_AREA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.CREATE_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.CREATE_AREA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.UPDATE_AREA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.UPDATE_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.UPDATE_AREA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.DELETE_AREA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.DELETE_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.DELETE_AREA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}
