import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

// import "antd/dist/antd.css";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Tooltip } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import AreaGrid from "../area-grid";
import "./AreaPages.scss";

class AreaIndexPage extends React.Component {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("area"));
  }

  handleAddClick = () => {
    this.props.dispatch(push(`/area/create`));
  };

  render() {
    return (
      <div className="p5-page-container">
        <Row justify="center">
          <Col xs={24} md={20} lg={18}>
            <span>
              <h1 className="p5-page-h1-header">
                Areas
                <Tooltip title="Add Area">
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    id="AreaAdd"
                    className="area-add-icon"
                    alt="areaAddIcon"
                    onClick={() => this.handleAddClick()}
                    pull="right"
                    size="1x"
                  />
                </Tooltip>
              </h1>
            </span>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} md={20} lg={18}>
            <AreaGrid />
          </Col>
        </Row>
      </div>
    );
  }
}

AreaIndexPage.propTypes = {
  dispatch: PropTypes.func,
};

AreaIndexPage.defaultProps = {
  dispatch: () => {},
};

function mapStateToProps() {
  return {};
}

const connectedListView = connect(mapStateToProps)(AreaIndexPage);

export { connectedListView as default };
