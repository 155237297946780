import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCog,
  faUserCircle,
  faChevronDown,
  faUsers,
  faTachometer,
  faMobile,
  faShield,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { faHubspot } from "@fortawesome/free-brands-svg-icons";
import "antd/dist/antd.css";

const BellIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faBell}
      id="Notification"
      className="top-nav-icon"
      alt="NotificationIcon"
    />
  </span>
);

const CogIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faCog}
      id="Settings"
      className="top-nav-icon"
      alt="SettingsIcon"
    />
  </span>
);

const ProfileIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faUserCircle}
      id="ProfileInfo"
      className="top-nav-icon"
      alt="ProfileIcon"
    />
  </span>
);

const ChevronDownIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faChevronDown}
      id="ChevronDown"
      className="top-nav-secondary-icon"
      alt="ChevronDownIcon"
    />
  </span>
);

const DashboardIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faTachometer}
      id="Dashboard"
      className="side-nav-icon"
      alt="DashboardIcon"
    />
  </span>
);

const MobileIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faMobile}
      id="DeviceMgmt"
      className="side-nav-icon device-mgmt-icon"
      alt="DeviceMgmt"
    />
  </span>
);

const UserIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faUsers}
      id="Users"
      alt="UserIcon"
      className="side-nav-icon"
    />
  </span>
);

const HubSpotIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faHubspot}
      id="HubSpot"
      alt="HubSpotIcon"
      className="side-nav-icon"
    />
  </span>
);

const ShieldIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faShield}
      id="Shield"
      className="side-nav-icon"
      alt="ShieldIcon"
    />
  </span>
);

const HelpIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faQuestionCircle}
      id="Shield"
      className="side-nav-icon"
      alt="ShieldIcon"
    />
  </span>
);

export default {
  BellIcon,
  CogIcon,
  ProfileIcon,
  ChevronDownIcon,
  DashboardIcon,
  MobileIcon,
  UserIcon,
  HubSpotIcon,
  ShieldIcon,
  HelpIcon,
};
