import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,  
  Spin,
} from 'antd';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import navigationActions from "../../../common/components/navigation/action";
import { history } from "../../../store";
import { ResourceForm} from '../form';
import actions from '../actions';
import './ResourceLib.css';

class ResourceEditPage extends React.Component {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("ResourceLibrary"));
    this.props.dispatch(actions.GetResource(this.props.match.params.id));
  }

  updateResource = (val) => {
    this.props.dispatch(actions.UpdateResource(val, this.handleCallback))
  }

  handleCancel = () => {
    history.push('/ResourceLibrary');
  };
  
  handleCallback = () => {
    history.push('/ResourceLibrary');
  };

  deleteLibraryItem = () => {
    const { confirm } = Modal;
    const { currentResource, dispatch } = this.props;

    confirm({

      icon: <FontAwesomeIcon size="2x" pull="left" icon={faTrash} style={{ color: "#EF4E4E" }} />,
      // icon: <DeleteOutlined style={{ color: "#EF4E4E" }} />,
      /* eslint react/prop-types: 0 */
      content: <span style={{textAlign:"center"}}>Are you sure want to delete &quot;{currentResource.Title}&quot;</span>,
      onOk() {
        /* eslint react/prop-types: 0 */
        dispatch(actions.DeleteResource(currentResource.Id));
      },
    });
  };

    render(){
        return(
          <div className="p5-page-container">
            <Row justify="center">
              <Col xs={20} md={20} lg={18}>
                <Spin spinning={this.props.loading}>
                  <ResourceForm
                    loading={this.props.loading}
                    onSubmit={this.updateResource}
                    onCancel={this.handleCancel}
                    onDelete={this.deleteLibraryItem}
                    isUpdateMode
                    currentResource={this.props.currentResource}
                    {...this.props.currentResource}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        );
    }
}

function mapStateToProps(state) {
  const { currentResource, loading } = state.ResourceLibrary;
  return {
    currentResource,
    loading,
  };
}

const connectedResourceEditPage = connect(mapStateToProps)(ResourceEditPage);

ResourceEditPage.propTypes = {
  dispatch: () => {},
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    })
  }),
  currentResource: PropTypes.shape({}),
  loading: PropTypes.bool,
};

ResourceEditPage.defaultProps = {
  dispatch: () => {},
  match: {},
  currentResource: {},
  loading: false,
};

export default connectedResourceEditPage;
 