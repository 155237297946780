import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

// import "antd/dist/antd.css";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Tooltip } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import LEAGrid from "../grid";
// import "antd/dist/antd.css";
import "./LEAPage.css";

class ListView extends React.Component {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("force"));
  }

  handleAddClick = () => {
    this.props.dispatch(push("/force/create"));
  };

  render() {
    return (
      <div className="p5-page-container">
        <Row justify="center">
          <Col xs={24} md={20} lg={18}>
            <span>
              <h1 className="p5-page-h1-header">
                Forces
                <Tooltip title="Add Force">
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    id="forceAdd"
                    className="force-add-icon"
                    alt="forceAddIcon"
                    onClick={() => this.handleAddClick()}
                    pull="right"
                    size="1x"
                  />
                </Tooltip>
              </h1>
            </span>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} md={20} lg={18}>
            <LEAGrid />
          </Col>
        </Row>
      </div>
    );
  }
}

ListView.propTypes = {
  dispatch: PropTypes.func,
};

ListView.defaultProps = {
  dispatch: () => {},
};

function mapStateToProps() {
  return {};
}

const connectedListView = connect(mapStateToProps)(ListView);

export { connectedListView as default };
