import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Tooltip, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { ShepherdTour, TourMethods } from 'react-shepherd'
import {NewRegistrationModalSteps, LiteNewRegistrationModalSteps} from '../tour-steps';
import { LiteRegistrationTourModal } from "../modals";
import navigationActions from "../../../common/components/navigation/action";
import { RegisteredGrid, AdminRegisteredGrid, LiteAdminRegisteredGrid, LiteRegisteredGrid } from "../../registeration/grid";
import { RegistrationEditModal } from '../../registeration/registration-edit-modal';
import RegisterAction from "../../registeration/actions";
import TourRegAction from "../actions";
import TourGuideStartModal from "../components/tour-guide-start-modal";
import { agencyService, AreaService } from '../../../services';
import "../shepardtour.css";
import "../../registeration/page/RegisterPage.css";
import { hasRights, rights } from "../../../common/utils/role-right-helper";
import { IsLiteVersion, IsFullVersion } from "../../../common/utils/system-version-helper";

const { tourOptions, NewRegistrationSteps } = NewRegistrationModalSteps;
const { liteTourOptions, LiteNewRegistrationSteps } = LiteNewRegistrationModalSteps;

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class NewRegistrationTourPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ForceName: null,
      isRegisterVisible: false,
      registrationData: [],
      TourStartModalshow: false,
    };
  }

  

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("register", true));
    this.setState({ TourStartModalshow: true });
    this.props.dispatch(TourRegAction.GetCurrentTourGuide(
      2,
      this.props.userId
    ));
    agencyService
      .getForceList(`$filter=Id eq ${this.props.ForceId}`)
      .then((response) => {
        const FName = response.data.value[0].Name;
        if (this.props.AreaId !== null) {
          AreaService.getGridList(`$filter=Id eq ${this.props.AreaId}`).then(
            (aResponse) => {
              const AName = aResponse.data.value[0].Name;
              this.setState({
                ForceName: `${FName} - ${AName}`,
              });
            }
          );
        } else {
          this.setState({
            ForceName: FName,
          });
        }
      });
    this.getRegistrationData();
    this.interval = setInterval(() => this.getRegistrationData(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  hideTourGuideLandingModal = () => {
    this.setState({ TourStartModalshow: false });
  };

  pivotCompleteData = () => ({
    TourId: this.props.CurrentTourGuide.TourGuideId,
    UserId: this.props.userId
  });

  CompleteTourGuide = () => {
    this.props.dispatch(TourRegAction.MarkTourGuideAsCompleted(
      this.pivotCompleteData(),
      this.props.dispatch(TourRegAction.CheckUserActiveTourGuides(this.props.userId))
    ));
  }

  getRegistrationData = () => {
    let search = "$orderby=RegistrationId asc&$filter=IsRegistered eq true";

    if (
      hasRights([rights.CanManageAreaUsers]) &&
      this.props.areaFilter !== null &&
      this.props.areaFilter !== 0
    )
      search += ` and AreaId eq ${this.props.areaFilter}`;

    if (
      hasRights([rights.CanManageTeamUsers]) &&
      this.props.teamFilter !== null &&
      this.props.teamFilter !== 0
    )
      search += ` and TeamId eq ${this.props.teamFilter}`;

    this.props.dispatch(
      TourRegAction.GetRegistrationList(search, this.setRegistrationData)
    );
  };

  filterByArea = (id) => {
    this.props.dispatch(RegisterAction.RegistrationAreaFilter(id));
    let search = "$orderby=RegistrationId asc&$filter=IsRegistered eq true";

    if (hasRights([rights.CanManageAreaUsers]) && id !== null && id !== 0)
      search += ` and AreaId eq ${id}`;

    this.props.dispatch(
      RegisterAction.GetRegistrationList(search, this.setRegistrationData)
    );
  };

  filterByTeam = (id) => {
    this.props.dispatch(RegisterAction.RegistrationTeamFilter(id));
    let search = "$orderby=RegistrationId asc&$filter=IsRegistered eq true";

    if (
      hasRights([rights.CanManageAreaUsers]) &&
      this.props.areaFilter !== null &&
      this.props.areaFilter !== 0
    )
      search += ` and AreaId eq ${this.props.areaFilter}`;

    if (hasRights([rights.CanManageTeamUsers]) && id !== null && id !== 0)
      search += ` and TeamId eq ${id}`;

    this.props.dispatch(
      RegisterAction.GetRegistrationList(search, this.setRegistrationData)
    );
  };

  setRegistrationData = (data) => {
    this.setState({
      registrationData: data,
    });
  };

  openEditModal = (Id, ConsumerId) => {
    this.props.dispatch(
      RegisterAction.openRegistrationEditModal(Id, ConsumerId)
    );
  };

  RefreshGrids = () => {
    this.getRegistrationData();
  };

  hideRegisterModal = () => {
    this.setState({ isRegisterVisible: false });
    this.RefreshGrids();
  };

  handleRegisterOk = () => {
    this.setState({ isRegisterVisible: false });
    this.RefreshGrids();
  };

  openVictimDataModal = (Id) => {
    this.props.dispatch(RegisterAction.openVictimDetailModal(Id));
  };

  render() {
    return (
      <>
        <div className="p5-grid-page-container registration-tour-start">
          {(hasRights([rights.CanManageRegistration]) &&
          this.props.TeamId !== null &&
          this.props.TeamId !== 0) || (hasRights([rights.CanManageAreaUsers]) ||
          hasRights([rights.CanManageTeamUsers])) ? (
            <Row justify="center">
              <Col xs={24} md={24} lg={23}>
                <div>
                  <h1 className="register-grid-header">
                    {this.state.ForceName}
                    <Tooltip title="New Registration">
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        id="registerAdd"
                        className="register-add-icon sh-tour-reg-add-icon"
                        alt="registerAddIcon"
                        // onClick={() => this.setState({ isRegisterVisible: true })}
                        pull="right"
                        size="1x"
                      />
                    </Tooltip>
                  </h1>
                  <Button
                    id="sh-show-reg-btn"
                    style={{ display: "None" }}
                    onClick={() => this.setState({ isRegisterVisible: true })}
                  />
                  <Button
                    id="sh-hide-reg-btn"
                    style={{ display: "None" }}
                    onClick={() => this.setState({ isRegisterVisible: false })}
                  />
                </div>
              </Col>
            </Row>
          ) : (
            <Row justify="center">
              <Col xs={24} md={24} lg={23}>
                <div>
                  <h1 className="register-grid-header">
                    {this.state.ForceName}
                  </h1>
                </div>
              </Col>
            </Row>
          )}
          <Row justify="center">
            <Col xs={24} md={24} lg={23}>
              {(hasRights([rights.CanManageAreaUsers]) ||
                hasRights([rights.CanManageTeamUsers])) &&
                IsFullVersion() && (
                  <ShepherdTour
                    steps={NewRegistrationSteps}
                    tourOptions={tourOptions}
                  >
                    <TourMethods>
                      {(tourContext) => (
                        <TourGuideStartModal
                          startTour={tourContext}
                          hideModal={this.hideTourGuideLandingModal}
                          showModal={this.state.TourStartModalshow}
                          completeGuide={this.CompleteTourGuide}
                          tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                          userId={this.props.userId}
                          tourName="new registration"
                          title="Welcome to the New Registration Tour Guide"
                          description="In this tour guide, you will learn about how to register a new app or handset for your organisations. We will explain each column of the registration form and some important items to note while using the TecSAFE portal."
                        />
                      )}
                    </TourMethods>
                    <AdminRegisteredGrid
                      openModal={this.openEditModal}
                      registrationList={this.state.registrationData}
                      filterArea={this.filterByArea}
                      filterTeam={this.filterByTeam}
                      onGridRefresh={this.RefreshGrids}
                      IsTour
                    />
                  </ShepherdTour>
                )}
              {hasRights([rights.CanManageAreaUsers]) === false &&
                hasRights([rights.CanManageTeamUsers]) === false &&
                IsFullVersion() && (
                  <ShepherdTour
                    steps={NewRegistrationSteps}
                    tourOptions={tourOptions}
                  >
                    <TourMethods>
                      {(tourContext) => (
                        <TourGuideStartModal
                          startTour={tourContext}
                          hideModal={this.hideTourGuideLandingModal}
                          showModal={this.state.TourStartModalshow}
                          completeGuide={this.CompleteTourGuide}
                          tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                          userId={this.props.userId}
                          tourName="new registration"
                          title="Welcome to the New Registration Tour Guide"
                          description="In this tour guide, you will learn about how to register a new app or handset for your organisations. We will explain each column of the registration form and some important items to note while using the TecSAFE portal."
                        />
                      )}
                    </TourMethods>
                    <RegisteredGrid
                      viewDetails={this.openVictimDataModal}
                      openModal={this.openEditModal}
                      registrationList={this.state.registrationData}
                      onGridRefresh={this.RefreshGrids}
                      IsTour
                    />
                  </ShepherdTour>
                )}
              {(hasRights([rights.CanManageAreaUsers]) ||
                hasRights([rights.CanManageTeamUsers])) &&
                IsLiteVersion() && (
                  <ShepherdTour
                    steps={LiteNewRegistrationSteps}
                    tourOptions={liteTourOptions}
                  >
                    <TourMethods>
                      {(tourContext) => (
                        <TourGuideStartModal
                          startTour={tourContext}
                          hideModal={this.hideTourGuideLandingModal}
                          showModal={this.state.TourStartModalshow}
                          completeGuide={this.CompleteTourGuide}
                          tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                          userId={this.props.userId}
                          tourName="new registration"
                          title="Welcome to the New Registration Tour Guide"
                          description="In this tour guide, you will learn about how to register a new app or handset for your organisations. We will explain each column of the registration form and some important items to note while using the TecSAFE portal."
                        />
                      )}
                    </TourMethods>
                    <LiteAdminRegisteredGrid
                      openModal={this.openEditModal}
                      registrationList={this.state.registrationData}
                      filterArea={this.filterByArea}
                      filterTeam={this.filterByTeam}
                      onGridRefresh={this.RefreshGrids}
                      IsTour
                    />
                  </ShepherdTour>
                )}
              {hasRights([rights.CanManageAreaUsers]) === false &&
                hasRights([rights.CanManageTeamUsers]) === false &&
                IsLiteVersion() && (
                  <ShepherdTour
                    steps={LiteNewRegistrationSteps}
                    tourOptions={liteTourOptions}
                  >
                    <TourMethods>
                      {(tourContext) => (
                        <TourGuideStartModal
                          startTour={tourContext}
                          hideModal={this.hideTourGuideLandingModal}
                          showModal={this.state.TourStartModalshow}
                          completeGuide={this.CompleteTourGuide}
                          tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                          userId={this.props.userId}
                          tourName="new registration"
                          title="Welcome to the New Registration Tour Guide"
                          description="In this tour guide, you will learn about how to register a new app or handset for your organisations. We will explain each column of the registration form and some important items to note while using the TecSAFE portal."
                        />
                      )}
                    </TourMethods>
                    <LiteRegisteredGrid
                      viewDetails={this.openVictimDataModal}
                      openModal={this.openEditModal}
                      registrationList={this.state.registrationData}
                      onGridRefresh={this.RefreshGrids}
                      IsTour
                    />
                  </ShepherdTour>
                )}
            </Col>
          </Row>
        </div>
        <RegistrationEditModal onRefresh={this.RefreshGrids} />
        {IsLiteVersion() && (
          <LiteRegistrationTourModal
            onSubmit={this.addNewRegistration}
            handleOk={this.handleRegisterOk}
            handleCancel={this.hideRegisterModal}
            isVisible={this.state.isRegisterVisible}
          />
        )}
        {IsFullVersion() && (
          <LiteRegistrationTourModal
            onSubmit={this.addNewRegistration}
            handleOk={this.handleRegisterOk}
            handleCancel={this.hideRegisterModal}
            isVisible={this.state.isRegisterVisible}
          />
        )}
      </>
    );
  }
}

NewRegistrationTourPage.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  ForceId: PropTypes.number,
  TeamId: PropTypes.number,
  AreaId: PropTypes.number,
  teamFilter: PropTypes.number,
  areaFilter: PropTypes.number,
  userId: PropTypes.number,
  CurrentTourGuide: PropTypes.shape({
    TourGuideId: PropTypes.number
  }),
};

NewRegistrationTourPage.defaultProps = {
  dispatch: () => {},
  history: {
    push: () => {},
  },
  ForceId: null,
  AreaId: null,
  TeamId: null,
  teamFilter: null,
  areaFilter: null,
  userId: 0,
  CurrentTourGuide: {
    TourGuideId: 2
  },
};

function mapStateToProps(state) {
  const { teamFilter, areaFilter } = state.RegisterUserFormState;
  const { TeamId, ForceId, AreaId, Id } = state.AccountState.currentUser;
  const { CurrentTourGuide } = state.TourGuide;
  return {
    teamFilter,
    areaFilter,
    TeamId,
    ForceId,
    AreaId,
    userId: Id,
    CurrentTourGuide,
  };  
}

const connectedRegisterPage = connect(mapStateToProps)(NewRegistrationTourPage);

export { connectedRegisterPage as default };
