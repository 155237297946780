function formatMax(max) {
    return `Maximum of ${max} characters`;
  }
  
  function formatRequired(fieldName) {
    return `${fieldName} is required`;
  }
  
  function formatType(fieldName, type) {
    return `${fieldName} must be a ${type}`;
  }
  
  function formatValid(fieldName) {
    return `Please enter a valid ${fieldName}`;
  }
  
  export {
    formatMax,
    formatRequired,
    formatType,
    formatValid,
  };  