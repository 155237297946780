import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const DescriptionInput = (props) => {
  return(
    <Form.Item
      validateStatus={
        props.errors && props.errors.Description && props.touched.Description
          ? "error"
          : null
      }
      help={props.errors && props.touched.Description && props.errors.Description}
    >
      <h2 className="form-label">
        Description
      </h2>
      <TextArea
        id="Description" 
        className="form-input"
        value={props.values.Description} 
        onChange={props.onChange}
        autoSize
      />
    </Form.Item>
  );
}

DescriptionInput.propTypes = {
    onChange: () => {},
    errors: PropTypes.shape({
        Description: PropTypes.string,
    }),
    values: PropTypes.shape({
        Description: PropTypes.string,
    }),
    touched: PropTypes.shape({
        Description: PropTypes.bool,
    })
}

DescriptionInput.defaultProps = {
    onChange: () => {},
    errors: {},
    values: {},
    touched: {}
}

export default DescriptionInput;
