/* eslint-disable react/prop-types */
import { Button, Col, Form, Input, Row } from "antd";
import { Formik } from "formik";
import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import systemInfoActions from '../actions';

class AdminForm extends React.Component {
  onSubmit = (data) => {
    if (!this.props.loading) {
      const pivotData = {
        Id: 1,
        NoticeText: data.systemInfo
      };
      this.props.dispatch(systemInfoActions.SetSystemInfo(pivotData));
    }
  }

  render = () => (
    <div className="user-grid-container">
      <Formik
        enableReinitialize
        initialValues={{
          systemInfo: this.props.noticeText || ""
        }}
        onSubmit={(e) => {
          this.onSubmit(e)
        }}
      >
        {({
          values,
          handleBlur,
          setFieldValue,
          submitForm
        }) => (
          <Form
            className="adminGrid"
            name="adminGrid"
          >
            <Row>
              <Col xs={24} md={4}>
                <span>System Alert Text:</span>
              </Col>
              <Col xs={24} md={20}>
                <Form.Item>
                  <Input
                    value={values.systemInfo}
                    onChange={e => setFieldValue("systemInfo", e.target.value)}
                    onBlur={handleBlur}
                    name="systemInfo"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ offset: 0, span: 24 }} md={{ offset: 4, span: 18 }}>
                <Form.Item>
                  <Button
                    type="primary"
                    loading={this.props.loading}
                    onClick={submitForm}
                  >
                    Submit
                  </Button>
                  <Button
                    danger
                    loading={this.props.loading}
                    onClick={() => { setFieldValue("systemInfo", ""); submitForm() }}
                    style={{ marginLeft: 10 }}
                  >Clear
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          )}
      </Formik>
    </div>
  )
}

AdminForm.propTypes = {
  loading: PropTypes.bool,
};

AdminForm.defaultProps = {
  loading: false,
};

const mapStateToProps = (props) => {
  const { noticeText, loading } = props.SystemInfo;
  return {
    noticeText: noticeText && noticeText.NoticeText,
    loading
  };
}

const connectedAdminForm = connect(mapStateToProps)(AdminForm);

export { connectedAdminForm as default };