import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Layout, Menu } from "antd";
import {
  faUsers,
  faTachometer,
  faShieldAlt,
  faBookOpen,
  faQuestionCircle,
  faToolbox,
  faUsersCog,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import { faHubspot } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import navigationActions from "../navigation/action";
import { hasRights, rights } from "../../utils/role-right-helper";

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class SideNav extends React.PureComponent {
  onCollapse = (collapsed) => {
    this.props.dispatch(navigationActions.collapseSideNav(collapsed));
  };

  setMobileNav = (isMobile) => {
    const NavSize = isMobile ? 0 : 85;
    this.props.dispatch(navigationActions.setNavSize(NavSize));
  };

  handleSelect = (eventKey) => {
    const { dispatch } = this.props;
    switch (eventKey.key) {
      case "dashboard":
        dispatch(push("/"));
        break;
      case "search":
        dispatch(push("/search"));
        break;
      case "user":
        dispatch(push("/user"));
        break;
      case "register":
        dispatch(push("/register"));
        break;
      case "unregister":
        dispatch(push("/unregister"));
        break;
      case "hub":
        dispatch(push("/hub"));
        break;
      case "devicemgmt":
        dispatch(push("/device"));
        break;
      case "force":
        dispatch(push("/force"));
        break;
      case "area":
        dispatch(push("/area"));
        break;
      case "team":
        dispatch(push("/team"));
        break;
      case "resourceLib":
        dispatch(push("/resourceLibrary"));
        break;
      case "help":
        dispatch(push("/help"));
        break;
      case "admin":
        dispatch(push("/admin"));
        break;
      default:
        dispatch(push("/login"));
    }
  };

  render() {
    const { Sider } = Layout;
    const { SubMenu } = Menu;

    const DashboardIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faTachometer}
          id="Dashboard"
          className="side-nav-icon"
          alt="DashboardIcon"
        />
      </span>
    );

    const RegistrationIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faUsers}
          id="Users"
          alt="UserIcon"
          className="side-nav-icon"
        />
      </span>
    );

    const SearchIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faSearch}
          id="Search"
          alt="SearchIcon"
          className="side-nav-icon"
        />
      </span>
    );

    const UserIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faUsersCog}
          id="Users"
          alt="UserIcon"
          className="side-nav-icon"
        />
      </span>
    );

    const hubSpotIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faHubspot}
          id="HubSpot"
          alt="HubSpotIcon"
          className="side-nav-icon"
        />
      </span>
    );

    const LEAIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faShieldAlt}
          id="LEA"
          alt="LEAIcon"
          className="side-nav-icon"
        />
      </span>
    );
    
    const ResourceLibIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faBookOpen}
          id="ResourceLib"
          alt="ResourceLibIcon"
          className="side-nav-icon"
        />
      </span>
    );

    const HelpIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faQuestionCircle}
          id="Shield"
          className="side-nav-icon"
          alt="ShieldIcon"
        />
      </span>
    );

    const AdminIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faToolbox}
          id="Admin"
          alt="AdminIcon"
          className="side-nav-icon"
        />
      </span>
    );

    const openNav = (
      {backgroundColor:"white", color: '#243b53', fontSize: "28px", top: "-60px", right: '160px'}
    )

    const closeNav = (
      {backgroundColor:"white", color: '#243b53', fontSize: "28px", top: "-60px", right: '-40px', content: "X" }
    )

    return (
      <>
        <Sider
          collapsible
          collapsed={this.props.isCollapsed}
          onCollapse={this.onCollapse}
          breakpoint="md"
          collapsedWidth={this.props.collapsedSize}
          onBreakpoint={broken => {
            this.setMobileNav(broken);
          }}
          zeroWidthTriggerStyle={!this.props.isCollapsed ? openNav : closeNav}
        >
          <Menu
            defaultSelectedKeys={this.props.currentPage}
            mode="inline"
            selectedKeys={[this.props.currentPage]}
            onClick={(k) => this.handleSelect(k)}
          >
            { (hasRights([rights.CanManageSystemInfo, rights.CanManageForces]) || hasRights([rights.CanManageAreaUsers])) && (
            <Menu.Item key="dashboard" icon={DashboardIcon}>
              <span>Dashboard</span>
            </Menu.Item>
            )}
            { hasRights([rights.CanSearchVictims]) && (
            <Menu.Item key="search" icon={SearchIcon}>
              <span>Search</span>
            </Menu.Item>
            )}
            { hasRights([rights.CanManageRegistration]) && (
              <Menu.Item key="register" icon={RegistrationIcon}>
                <span>Registered</span>
              </Menu.Item>
            )}
            { hasRights([rights.CanManageForces, rights.CanManageAreas, rights.CanManageTeams]) && (
              <SubMenu
                key="lea"
                icon={LEAIcon}
                title="Organisation"
                className="tecsos-submenu"
              >
                { hasRights([rights.CanManageForces]) && (
                  <Menu.Item key="force">
                    <span>Force</span>
                  </Menu.Item>
                )}
                { hasRights([rights.CanManageAreas]) && (
                  <Menu.Item key="area">
                    <span>Area</span>
                  </Menu.Item>
                )}
                { hasRights([rights.CanManageTeams]) && (
                  <Menu.Item key="team">
                    <span>Team</span>
                  </Menu.Item>
                )}
              </SubMenu>
            )}
            { hasRights([rights.CanViewHub]) && (
              <Menu.Item key="hub" icon={hubSpotIcon}>
                <span>Hub</span>
              </Menu.Item>
            )}           
            { hasRights([rights.CanViewUsers, rights.CanManageUsers]) && (
              <Menu.Item key="user" icon={UserIcon}>
                <span>User</span>
              </Menu.Item>
            )}
            { hasRights([rights.CanManageSystemInfo]) && (
              <Menu.Item key="admin" icon={AdminIcon}>
                <span>Admin</span>
              </Menu.Item>
            )}
            <Menu.Item key="help" icon={HelpIcon} title="Help/Submit Good News or Suggestions">
              <span>Help/Submit Good News or Suggestions</span>
            </Menu.Item>
            { hasRights([rights.CanViewLibrary]) && (
              <Menu.Item key="resourceLib" icon={ResourceLibIcon} title="Resource Library">
                <span>Resource Library</span>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
      </>
    );
  }
}

SideNav.propTypes = {
  // dispatch: PropTypes.func,
  currentPage: PropTypes.string,
};

SideNav.defaultProps = {
  // dispatch: () => {},
  currentPage: "",
};

function mapStateToProps(state) {
  const { currentPage, isCollapsed, collapsedSize } = state.Navigation;
  return {
    currentPage,
    isCollapsed,
    collapsedSize
  };
}

const connectedSideNav = connect(mapStateToProps)(SideNav);

export { connectedSideNav as default };
