import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Empty } from "antd";
// import { CustomBarChart } from "../../../common/components/dashboard";

class AlertCallCountView extends React.Component {
  render() {
    return (
      <>
        <div className="p5-form-container">
          <Row className="text-center" justify="space-around" align="middle">
            <Col span={24}>
              <div>
                <h1 className="p5-dash-h1-header text-center">{this.props.title}</h1>
              </div>
            </Col>
          </Row> 
          <br />
          {
            this.props.data.length === 0 ? (
              <>
                <Empty />
              </>
              ) : (
                <Row key="alert-calls" justify="space-around" align="middle">
                  {
                    this.props.data.map((device) => (
                      <Col span={12}>
                        <div>
                          <h1 className="p5-dash-h1-header text-center">
                            <span className="dash-percent-value">
                              {device.AlertCallCount}
                            </span>
                          </h1>
                        </div>
                        <div>
                          <h1 className="p5-dash-h1-header text-center">
                            <span>
                              {device.AreaName}
                            </span>
                          </h1>
                        </div>                        
                      </Col>
                    ))
                    }
                </Row>  
              )
          }          
        </div>
      </>
    );
  }
}

AlertCallCountView.propTypes = {
//   loading: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

AlertCallCountView.defaultProps = {
//   loading: false,
  title: "",
  data: [],
};

export { AlertCallCountView as default };
