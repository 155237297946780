import { toast } from "react-toastify";
import { push } from 'connected-react-router';
import c from './constants';
import AuthService from '../../services/Auth';
import { history } from '../../store';
import { rights } from '../../common/utils/role-right-helper';

function GetCurrentUser() {
  function request() { return { type: c.GET_CURRENT_USER_REQUEST }; }
  function success(profile) {
    return { type: c.GET_CURRENT_USER_SUCCESS, profile };
  }
  function failure(error) { return { type: c.GET_CURRENT_USER_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    AuthService.getCurrentUser().then(
      (profile) => {
        dispatch(success(profile));
      },
      (error) => {
        dispatch(failure(error.toString()));
      },
    );
  };
}

function Login(userInput) {
  function request() { return { type: c.LOGIN_REQUEST }; }
  function success() { return { type: c.LOGIN_SUCCESS }; }
  function failure(error) { return { type: c.LOGIN_FAILURE, error }; }

  function profileRequest() { return { type: c.GET_CURRENT_USER_REQUEST }; }
  function profileSuccess(profile) {
    return { type: c.GET_CURRENT_USER_SUCCESS, profile };
  }
  function profileFailure(error) { return { type: c.GET_CURRENT_USER_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    AuthService.login(userInput)
    .then((status) => {
        if (status === 202) {
          history.push(`/mfa/${userInput.UserName}`);
        } else {
          dispatch(profileRequest());
          AuthService.getCurrentUser().then(
            (profile) => {
              dispatch(profileSuccess(profile));
              dispatch(success());

              const userRights = profile.data.Rights;

              if (userRights.includes(rights.CanManageSystemInfo) || userRights.includes(rights.CanManageForces)) {
                dispatch(push('/'));
              } else if(userRights.includes(rights.CanSearchVictims) && !userRights.includes(rights.CanViewRegistrationData)) {
                dispatch(push('/search'));
              } else {
                dispatch(push('/register'));
              }
            },
            (error) => {
              if (error.response.status === 202) {
                history.push(`/mfa/${userInput.UserName}`);
              } else {
              dispatch(profileFailure(error.toString()));
              }
            },
          )
        }
      },
      (error) => {
        if(error.response.data === "" || error.response.data === null)
          dispatch(failure("Invalid username or password"));
        else 
          dispatch(failure(error.response.data.toString()));

        history.push('/login');
      },
    );
  };
}

function VerifyMFA(username, code) {
  function request() { return { type: c.MFA_REQUEST }; }
  function success() { return { type: c.MFA_SUCCESS }; }
  function failure(error) { return { type: c.MFA_FAILURE, error }; }

  function profileRequest() { return { type: c.GET_CURRENT_USER_REQUEST }; }
  function profileSuccess(profile) {
    return { type: c.GET_CURRENT_USER_SUCCESS, profile };
  }
  function profileFailure(error) { return { type: c.GET_CURRENT_USER_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    AuthService.verifyMFA(username, code)
      .then(() => {
        dispatch(profileRequest());
        AuthService.getCurrentUser().then(
          (profile) => {
            dispatch(profileSuccess(profile));
            dispatch(success());
            history.push('/');
          },
          (error) => {
            dispatch(profileFailure(error.toString()));
          },
        )
      },
      (error) => {
        dispatch(failure(error.response.data));
        history.push(`/mfa/${username}`);
      },
    );
  };
}

function ConfirmEmail(data, callback) {
  function request() { return { type: c.CONFIRM_EMAIL_REQUEST }; }
  function success() {
    return { type: c.CONFIRM_EMAIL_SUCCESS };
  }
  function failure(error) { return { type: c.CONFIRM_EMAIL_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    AuthService.confirmEmail(data)
      .then(
        () => {
          dispatch(success());
          toast('Successfully set your password, please login')
          callback();
        },
        (error) => {
          dispatch(failure(error.toString()));
          toast.error('An Error occured, please try again or request a new confirmation email');
        },
      );
  };
}

function ResendConfirmationEmail(data, callback) {
  function request() { return { type: c.RESEND_EMAIL_REQUEST }; }
  function success() {
    return { type: c.RESEND_EMAIL_SUCCESS };
  }
  function failure(error) { return { type: c.RESEND_EMAIL_FAILURE, error }; }
  return (dispatch) => {
    dispatch(request());
    AuthService.resendConfirmLink(data)
      .then(
        () => {
          dispatch(success());
          toast('Successfully resent confirmation link')
          callback();
        },
        (error) => {
          dispatch(failure(error.toString()));
          toast.error('An Error occured, please try again or request a new confirmation email');
        },
      );
  };
}

function Logout() {
  function request() { return { type: c.LOGOUT_REQUEST }; }
  function success() { return { type: c.LOGOUT_SUCCESS }; }

  return (dispatch) => {
    dispatch(request());
    AuthService.logout()
      .then(
        () => {
          dispatch(success());
        }
      );
  };
}

function ResetPassword(creds) {
  function request() { return { type: c.RESET_PASSWORD_REQUEST }; }
  function success(data) { return { type: c.RESET_PASSWORD_SUCCESS, data }; }
  function failure(error) { return { type: c.RESET_PASSWORD_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    AuthService.resetPassword(creds)
      .then(
        (data) => {
          dispatch(success(data));
          toast("Successfully reset your password. Please log in.");   
          dispatch(push('/login'));
        },
        (error) => {
          if(error.response.data === "" || error.response.data === null)
          {
            dispatch(failure("Unable to reset password, please make sure you entered a valid password."));
            toast.error("Unable to reset password, please make sure you entered a valid password.")
          } else {
            dispatch(failure(error.response.data.toString()));
            toast.error(error.response.data.toString());
          }
          
        },
      );
  };
}

function ForgotPassword(creds) {
  function request() { return { type: c.FORGOT_PASSWORD_REQUEST }; }
  function success(data) { return { type: c.FORGOT_PASSWORD_SUCCESS, data }; }
  function failure(error) { return { type: c.FORGOT_PASSWORD_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());

    AuthService.forgotPassword(creds)
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        },
      );
  };
}

export default ({
  GetCurrentUser,
  ConfirmEmail,
  Login,
  VerifyMFA,
  Logout,
  ResetPassword,
  ForgotPassword,
  ResendConfirmationEmail,
});
