import React from "react";
import {
  faBatteryFull,
  faBatteryThreeQuarters,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryEmpty,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BatteryFull = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faBatteryFull}
    //   className="side-nav-icon"
      alt="BatteryFull"
    />
  </span>
);

const BatteryThreeQuarter = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faBatteryThreeQuarters}
      alt="BatteryThreeQuarter"
    //   className="side-nav-icon"
    />
  </span>
);

const BatteryHalf = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faBatteryHalf}
      alt="BatteryHalf"
    //   className="side-nav-icon"
    />
  </span>
);

const BatteryQuarter = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faBatteryQuarter}
      alt="BatteryQuarter"
    //   className="side-nav-icon"
    />
  </span>
);

const BatteryEmtpy = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faBatteryEmpty}
      alt="BatteryEmtpy"
    //   className="side-nav-icon"
    />
  </span>
);

function GetBatteryLevelIcon(level) {
  if (level < 5) return BatteryEmtpy;
  if (level <= 25) return BatteryQuarter;
  if (level <= 50) return BatteryHalf;
  if (level <= 75) return BatteryThreeQuarter;

  return BatteryFull;
}

export default {
  BatteryFull,
  BatteryThreeQuarter,
  BatteryHalf,
  BatteryQuarter,
  BatteryEmtpy,
  GetBatteryLevelIcon,
};
