
const tourFullOptions = {
    defaultStepOptions: {
        cancelIcon: {
          enabled: false
        }
      },
      useModalOverlay: true
};

const VictimTourSteps = [ 
  {
    title: "General",
    id: "tour-vic-general",
    text: [
      `
      <p>
      Any field with an asterisk <strong>'<span style="color:red">*</span>'</strong> is mandatory and must be completed.  
      If any asterisked field is not completed it will not allow the registration to be saved.          
      </p>
      <p>
      Where any field has a 'None' or Not Known' option, you must either tick the 'None' or 'Not Known' option if it applies, or complete the data in that field.
      </p>
      `,
    ],
    attachTo: { on: "center" },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "Name",
    id: "tour-vic-name",
    text: [
      `
      <p>
      The victim's name should already be populated in this field from the minimum victim information previously input.  If not, it can be added here.       
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-name", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },   
  {
    title: "Gender",
    id: "tour-vic-gender",
    text: [
      `
      <p>
      The victim's gender should already be populated from the minimum victim information previously input.
      </p>
      <p>
      This field is a dropdown menu containing a range of gender options.  
      From the dropdown you can select the relevant gender of the victim        
      </p>
      <p>
      <ul>
      <li>Female</li>
      <li>Male</li>
      <li>Non Binary</li>
      <li>Another Gender/Not Listed</li>
      <li>Unknown/Not Disclosed</li>
      </ul>
      </p>   
      `,
    ],
    attachTo: { element: ".sh-tour-vic-gender", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        next: () => {},
        show: () => {},
    },
  },   
  
  {
    title: "Victim Image Upload",
    id: "tour-vic-image",
    text: [
      `
      <p>
      This allows images of the victim to be uploaded, if available.  
      To do so, click in the field and select an image to be uploaded, or by dragging and dropping it into this field. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-image", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        next: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Risk Level",
    id: "tour-vic-risk-lvl",
    text: [
      `
      <p>
      The risk level for the victim should already be populated from the minimum victim information previously input.      
      </p>
      <p>
      This field is a dropdown menu containing three risk levels:
      </p>
      <p>
      <ul>
      <li>High</li>
      <li>Medium</li>
      <li>Standard</li>
      </ul>
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-risk-lvl", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Risk Category",
    id: "tour-vic-risk-cat",
    text: [
      `
      <p>
      There are a number of broad risk categories that can be selected in relation to an individual registration.  
      From the dropdown menu select any risk categories that may relate to the victim: 
      </p>
      <p>
      <ul>
      <li>DA - General</li>
      <li>DA - Stalking/Harassment</li>
      <li>DA - Honour Based Abuse</li>
      <li>DA - Force Marriage</li>
      <li>CSE</li>
      <li>MDS (Modern Day Slavery)</li>
      <li>Serious Sexual Assault</li>
      </ul>
      </p>
      <p>
      As each individual risk category is selected it will automatically populate in the field. Multiple categories may be selected.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-risk-cat", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Disabilities",
    id: "tour-vic-disability",
    text: [
      `
      <p>
      Where applicable, a number of disability conditions can be selected in relation to an individual registration. 
      From the dropdown menu select any condition(s) that may apply to the victim:
      </p>
      <p>
      <ul>
      <li>Learning difficulty</li>
      <li>Mental health condition</li>
      <li>Physical impairment</li>
      <li>Deafness or hearing loss</li>
      <li>Blindness or visual impairment</li>
      <li>Autism spectrum condition</li>
      </ul>
      </p>
      <p>
      As each individual disability is selected it will automatically populate in the field.  Multiple categories may be selected. 
      </p>
      <p>
      To remove any disability, click on the 'X' alongside that individual condition.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-disability", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        next: () => {},
        show: () => {
          const element = document.getElementById("SelectedDisabilities");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
    },
  }, 
  {
    title: "Disabilities Other",
    id: "tour-vic-disability-other",
    text: [
      `
      <p>
      This is a free text field.  If the victim has a condition that is not listed, you can describe it here.
      </p>
      <p>
      Type the disability condition or description and click add.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-disability-other", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Email",
    id: "tour-vic-email",
    text: [
      `
      <p>
      This section is for the victims email address, if relevant.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-email", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => { },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Phone Number",
    id: "tour-vic-phone",
    text: [
      `
      <p>
      This section is for the victims phone number.
      This may be a different phone number from the one used for the registration (i.e. an alternative means of contact).   
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-phone", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Local Reference Number",
    id: "tour-vic-ref-num",
    text: [
      `
      <p>
      This is the local case reference number.
      Enter that local reference number here.       
      </p>
      <p>
      If you do not know the local reference number, type 'None' in the field. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-ref-num", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimAddresses");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
          document.getElementById("VictimAddresses").click();   
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Address Type",
    id: "tour-vic-addr-type",
    text: [
      `
      <p>
      This field contains options for different address types that can be used.  Select the type of address you are adding from the dropdown menu:        
      </p>
      <p>
      <ul>
      <li>Home</li>
      <li>Work</li>
      <li>Additional Address</li>
      </ul>
      </p>
      <p>
      You can add multiple addresses for the victim, if relevant.     
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-addr-type-0", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimAddresses");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
          document.getElementById("VictimAddresses").click();   
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Address",
    id: "tour-vic-addr",
    text: [
      `
      <p>
      Type in the victim's street name and area here.   
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-addr-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimAddresses");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
          document.getElementById("VictimAddresses").click();   
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "City",
    id: "tour-vic-addr-city",
    text: [
      `
      <p>
      Type in the victim's town or city here.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-addr-city-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimAddresses");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
          document.getElementById("VictimAddresses").click();   
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Postal Code",
    id: "tour-vic-addr-post-code",
    text: [
      `
      <p>
      Type in the victim's postcode here.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-addr-post-code-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimAddresses");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
          document.getElementById("VictimAddresses").click();   
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "County",
    id: "tour-vic-addr-region",
    text: [
      `
      <p>
      Type in the relevant county for the victim's address here.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-addr-region-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimAddresses");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Child's Name",
    id: "tour-vic-child-name",
    text: [
      `
      <p>
      If the Victim has any children, you can add them in this section. 
      </p>
      <p> 
      Enter the child's name. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-child-name-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimChildren");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Child's Age",
    id: "tour-vic-child-age",
    text: [
      `
      <p>
      Enter the child's age. The field only accepts whole numbers, so enter 0 if the child is less than 1 year of age.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-child-age-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimChildren");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Child's Address",
    id: "tour-vic-child-addr",
    text: [
      `
      <p>
      You can copy a previously input address if the child's address is the same (i.e. the victim's address).     
      </p>
      <p>
      To do so, click on the 'copy' icon to the right of 'Address' and select the relevant address if more than one has previously been entered.  
      </p>
      <p>
      If an address needs to be manually added, enter the child's street name and area here.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-child-addr-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimChildren");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Child's City",
    id: "tour-vic-child-addr-city",
    text: [
      `
      <p>
      Enter the child's town or city here.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-child-addr-city-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimChildren");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Child's Postal Code",
    id: "tour-vic-child-addr-post-code",
    text: [
      `
      <p>
      Enter the child's postcode here.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-child-addr-post-code-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimChildren");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Child's County",
    id: "tour-vic-child-addr-region",
    text: [
      `
      <p>
      Type in the relevant county for the child's address here.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-child-addr-region-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimChildren");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "Vehicles VRM",
    id: "tour-vic-car-vrm",
    text: [
      `
      <p>
      This section allows you to add details for any vehicle(s) the victim has.
      You can add multiple vehicles. 
      </p>
      <p>
      This field is for the vehicle's Vehicle Registration Mark (VRM).
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-car-vrm-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimVehicleDetail");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Vehicles Colour",
    id: "tour-vic-car-colour",
    text: [
      `
      <p>
      Enter the colour of the victim's vehicle. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-car-colour-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimVehicleDetail");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Vehicles Make",
    id: "tour-vic-car-make",
    text: [
      `
      <p>
      Enter the make of the victim's vehicle.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-car-make-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimVehicleDetail");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Vehicles Model",
    id: "tour-vic-car-model",
    text: [
      `
      <p>
      Enter the model of the victim's vehicle. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-car-model-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimVehicleDetail");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Officer in the Case",
    id: "tour-vic-user-oic",
    text: [
      `
      <p>
      Type in the name of officer or staff member dealing with the case. 
      </p>
      <p>
      Usually the officer in the case is the person completing the registration. 
      </p>
      <p>
      This can be amended if the officer in the case changes.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-oic ", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Officer Staff ID",
    id: "tour-vic-oic-staffid",
    text: [
      `
      <p>
      Add the staff ID number here for the officer in the case. 
      </p>
      <p>
      This is a free text box and alphanumeric characters are allowed.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-oic-staff-id ", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Authorising Officer",
    id: "tour-vic-user-auth-oic",
    text: [
      `
      <p>
      Some forces require an authorising officer for the issue of TecSAFE devices (not mandatory). 
      </p>
      <p>
      If this is needed by your force, type in the name of the authorising officer in the text field. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-auth-oic ", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next",    
        
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "Perpetrator",
    id: "tour-perp-unknown",
    text: [
        `
        <p>
        This section is for the perpetrators details.
        </p>
        <p>
        If the perpetrator's details are mostly unknown, tick the 'Perpetrator Unknown' box.
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-perp-unknown", on: "bottom" },
    buttons: [
        {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {
          const element = document.getElementById("PerpetratorDetail");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
    },
  },
  {
    title: "Perpetrator Name",
    id: "tour-perp-name",
    text: [
        `
        <p>
        This field is for the perpetrator's name.
        </p>
        <p>
        If the perpetrator is unknown, you must tick the 'Not Known' box above the text field.
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-perp-name", on: "bottom" },
    buttons: [
        {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
  },
  {
    title: "Perpetrator Gender",
    id: "tour-perp-gender",
    text: [
      `
      <p>
      This is a dropdown menu containing a range of gender options for the perpetrator:      
      </p>
      <p>
      <ul>
      <li>Female</li>
      <li>Male</li>
      <li>Non Binary</li>
      <li>Another Gender/Not Listed</li>
      <li>Unknown/Not Disclosed</li>
      </ul>
      </p>   
      <p> 
      Select the relevant gender option for the perpetrator. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-perp-gender", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {
          const element = document.getElementById("PerpetratorDetail");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
    },
  },  
  {
    title: "Perpetrator Image Upload",
    id: "tour-perp-image",
    text: [
      `
      <p>
      This allows images of the perpetrator to be uploaded, where available.  
      </p>
      <p>
      This is done by either clicking in the field and selecting an image to be uploaded, or by dragging and dropping it into this field.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-perp-image", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {
          const element = document.getElementById("PerpetratorDetailImage");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
    },
  },
  {
    title: "Perpetrator DOB",
    id: "tour-perp-dob",
    text: [
        `
        <p>
        This field allows you to enter the perpetrator's date of birth (DOB).
        </p>
        <p>
        If the perpetrator is unknown, you must tick the 'Not Known' box above the text field.
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-perp-dob", on: "bottom" },
    buttons: [
        {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {
          const element = document.getElementById("PerpetratorDetailDobPNC");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
    },
  },
  {
    title: "Perpetrator PNC Id",
    id: "tour-perp-pncid",
    text: [
        `
        <p>
        This field allows you to add the perpetrator's PNC ID number where there is one.
        </p>
        <p>
        If the PNC ID is not known or the perpetrator does not have a PNC record, you must tick the 'Not Known' box above the text field.
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-perp-pncid", on: "bottom" },
    buttons: [
        {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {
          const element = document.getElementById("PerpetratorDetailDobPNC");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
    },
  },
  {
    title: "Perpetrator Description",
    id: "tour-perp-desc",
    text: [
        `
        <p>
        This field allows you to add the perpetrator's description.
        </p>
        <p>
        If the perpetrator is unknown and there is no description you must tick the 'Not Known' box above the text field.
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-perp-desc", on: "bottom" },
    buttons: [
        {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {
          const element = document.getElementById("PerpetratorDetailDesc");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
    },
  },
  {
    title: "MO (Modus Operandi)",
    id: "tour-perp-mo",
    text: [
        `
        <p>
        This field allows you to add details of the perpetrator's modus operandi (MO).
        </p>
        <p>
        If the perpetrator is unknown, you must tick the 'Not Known' box above the text field.
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-perp-mo", on: "bottom" },
    buttons: [
        {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {
            const element = document.getElementById("PerpetratorDetailMo");
            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
    },
  },
  {
    title: "Perpetrator Court Order/Bail Conditions",
    id: "tour-perp-court-condition",
    text: [
        `
        <p>
        This field allows you to add any Court Orders or Bail Conditions etc. that may relate to the perpetrator.
        </p>
        <p>
        It is advisable to add any expiry date of Court Orders or Bail Conditions etc.   
        </p>
        <p>
        If these details are unknown, you must tick the 'Not Known' box above the text field.
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-perp-court-condition", on: "bottom" },
    buttons: [
        {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {
            const element = document.getElementById("PerpetratorDetailCourtCon");
            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
    },
  },
  {
    title: "Perpetrator Deployment Consideration",
    id: "tour-perp-deploy-consideration",
    text: [
        `
        <p>
        This field allows you to add any operational deployment considerations that may be applicable to the perpetrator.  These will assist control rooms making informed deployment decisions. 
        </p>
        <p>
        If the perpetrator is unknown, leave the text empty and you must tick the 'None' box above the text field. 
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-perp-deploy-consideration", on: "bottom" },
    buttons: [
        {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {
            const element = document.getElementById("PerpetratorDetailDeployCon");
            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
    },
  },
  {
    title: "Perpetrator Warning Types",
    id: "tour-perp-warn-type",
    text: [
        `
        <p>
        This field contains a dropdown menu detailing PNC Warning Signals, allowing you to add any that apply to the perpetrator: 
        </p>
        <p>
        <ul>
        <li>Alleges (against Police)</li>
        <li>Used for both Ailments or Disabilities</li>
        <li>Conceals</li>
        <li>Contagious</li>
        <li>Drugs</li>
        <li>Escaper</li>
        <li>Firearms</li>
        <li>Impersonates Female</li>
        <li>Impersonates Male</li>
        <li>Mental health</li>
        <li>Self Harm</li>
        <li>Suicidal</li>
        <li>Violent</li>
        <li>Weapons</li>
        <li>Explosives</li>
        </ul>
        </p>
        <p>
        As each individual risk category is selected it will automatically populate in the field.  Multiple categories may be selected.
        </p>
        <p>
        If the PNC ID is not known or the perpetrator does not have a PNC record, you must tick the 'Not Known' box above the text field.
        </p>
        `,
    ],
    attachTo: { element: ".sh-tour-perp-warn-type", on: "top" },
    buttons: [
        {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {
            const element = document.getElementById("PerpetratorDetailWarnType");
            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
    },
  },
  {
    title: "Perpetrator Vehicle VRM",
    id: "tour-vic-car-vrm",
    text: [
      `
      <p>
      This section allows you to add details for any vehicle(s) the perpetrator has.  
      You can add multiple vehicles.  
      </p>
      <p>
      This field is for the vehicle's Vehicle Registration Mark (VRM).
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-perp-car-vrm-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimPerpetratorVehicleDetails");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Vehicles Colour",
    id: "tour-perp-car-colour",
    text: [
      `
      <p>
      This field is for the colour of the perpetrator's vehicle. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-perp-car-colour-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimPerpetratorVehicleDetails");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Perpetrator Vehicles Make",
    id: "tour-perp-car-make",
    text: [
      `
      <p>
      This field is for the make of the perpetrator's vehicle.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-perp-car-make-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimPerpetratorVehicleDetails");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Perpetrator Vehicles Model",
    id: "tour-perp-car-model",
    text: [
      `
      <p>
      This field is for the model of the perpetrator's vehicle.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-perp-car-model-0", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          const element = document.getElementById("VictimPerpetratorVehicleDetails");
          element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center"}); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Cancel/Save",
    id: "tour-vic-footer-btn",
    text: [
      `
      <p>
      Once you have completed all offender information click 'Save' to add the updated details, or 'Cancel' if you do not want to add the details at this time.      
      </p>
      <p>
      If you have missed any of the asterisked mandatory fields when you click 'Save' it will automatically take you back to the incomplete field, which will be highlighted with a red line around it. 
      </p>
      <p>
      Complete the highlighted field(s) and then click 'Save'. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-vic-footer-btn", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",  
        type: "next",   
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "Victim Data Form Complete",
    id: "tour-help-complete",
    text: [
      `
      <p>
      That concludes the Victim Form page. 
      Please select Complete to this tour to mark as complete.
      </p>
      `,
    ],
    attachTo: { element: "", on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Complete",
        type: "complete",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
];

  export default {
    tourFullOptions,
    VictimTourSteps
  }