import React from 'react';
import { connect } from "react-redux";
import { Col, Row } from 'antd';
import { Layout, PageHeader } from '../../../common/components';
import './AdminPage.css';
import { AdminForm } from '../form';
import navigationActions from "../../../common/components/navigation/action";


// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class AdminPage extends React.PureComponent {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("admin"));
  }

  render() {
    const HelpHeader = (
      <PageHeader title="Admin" />
    );

    return (
      <Layout PageHeader={HelpHeader}>
        <div className="help-page-container">
          <Row>
            <Col span={22}>
              <AdminForm />
            </Col>
          </Row>
        </div>
      </Layout>
    );
  }
}


function mapStateToProps() {
  return {};
}

const connectedAdminPage = connect(mapStateToProps)(AdminPage);

export { connectedAdminPage as default };