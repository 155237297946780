import React from 'react';
// import PropTypes from 'prop-types';
import 'antd/dist/antd.css';
import {
  Modal,
  Button,
} from 'antd';
import { CreateForm } from './forms';
import './AddUserModal.css';
import { UserService } from '../../services';

class AddUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    setTimeout(() => {
      this.setState({ visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleSubmit = (data) => {
    UserService.addUser(data).then(() => {
      this.handleOk();
    });
  }

  render() {
    const { visible } = this.state;
    return (
      <>
        <Button type="primary" onClick={this.showModal}>
          Add User Modal
        </Button>
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
          ]}
        >
          <h4 className="form-label"><b>Add New User</b></h4>
          <CreateForm
            onSubmit={this.handleSubmit}
          />
        </Modal>
      </>
    );
  }
}

export { AddUserModal as default };
