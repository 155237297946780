import { toast } from "react-toastify";
import { push } from 'connected-react-router';
import teamConstants from "./constants";
import { TeamService } from "../../services";
// import { history } from "../../store";

function GetGridList() {
  function request() {
    return { type: teamConstants.GET_TEAM_LIST_SUCCESS };
  }
  function success() {
    return { type: teamConstants.GET_TEAM_LIST_SUCCESS };
  }
  // function failure(error) {
  //   return { type: teamConstants.GET_TEAM_LIST_FAILURE, error };
  // }
  return (dispatch) => {
    dispatch(request());
    dispatch(success());
   
  };
}

function newTeam() {
  function request() {
    return { type: teamConstants.NEW_TEAM_REQUEST };
  }

  return (dispatch) => {
    dispatch(request());
  }
}

function CreateTeam(data, callback) {
  function request() {
    return { type: teamConstants.CREATE_TEAM_REQUEST };
  }
  function success(team) {
    return { type: teamConstants.CREATE_TEAM_SUCCESS, team };
  }
  function failure(error) {
    return { type: teamConstants.CREATE_TEAM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    TeamService.createTeam(data).then(
      (team) => {
        dispatch(success(team));
        toast("Successfully created a New Team"); 
        // history.push(`/team/${team.data.Id}`);
        callback(team.data.Id);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

function GetTeamById(data) {
  function request() {
    return { type: teamConstants.GET_TEAM_REQUEST };
  }
  function success(team) {
    return { type: teamConstants.GET_TEAM_SUCCESS, team };
  }
  function failure(error) {
    return { type: teamConstants.GET_TEAM_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());

    TeamService.getTeamById(data).then(
      (team) => {
        dispatch(success(team));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function UpdateTeam(data, callback) {
  function request() {
    return { type: teamConstants.UPDATE_TEAM_REQUEST };
  }
  function success() {
    return { type: teamConstants.UPDATE_TEAM_SUCCESS };
  }
  function failure(error) {
    return { type: teamConstants.UPDATE_TEAM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    TeamService.updateTeam(data).then(
      () => {        
        dispatch(success());
        toast("Successfully updated Team");     
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

function DeleteTeam(id) {
  function request() {
    return { type: teamConstants.DELETE_TEAM_REQUEST };
  }
  function success() {
    return { type: teamConstants.DELETE_TEAM_SUCCESS };
  }
  function failure(error) {
    return { type: teamConstants.DELETE_TEAM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
   
    TeamService.deleteTeam(id).then(
      () => {        
        dispatch(success());
        toast("Successfully deleted Team");
        dispatch(push(`/team`));  
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

export default {
  newTeam,
  GetGridList,
  CreateTeam,
  GetTeamById,
  UpdateTeam,
  DeleteTeam,
};
