import { toast } from "react-toastify";
import { push } from 'connected-react-router';
import c from "./constants";
import { ResourceLibService, FileService } from "../../services";

function CreateResource(val, callback) {
  function request() {
    return { type: c.CREATE_RESOURCE_REQUEST };
  }
  function success(data) {
    return { type: c.CREATE_RESOURCE_SUCCESS, data };
  }
  function failure(error) {
    return { type: c.CREATE_RESOURCE_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    ResourceLibService.createResource(val)
    .then(
      (response) => {
        dispatch(success(response.data));
        toast("Successfully created a new Resource Library");               
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function GetResourceList() {
  function request() {
    return { type: c.GET_RESOURCE_LIST_REQUEST };
  }
  function success(data) {
    return { type: c.GET_RESOURCE_LIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: c.GET_RESOURCE_LIST_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    ResourceLibService.getResourceList().then(
      (List) => {
        dispatch(success(List.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function GetResource(val) {
  function request() {
    return { type: c.GET_RESOURCE_REQUEST };
  }
  function success(data) {
    return { type: c.GET_RESOURCE_SUCCESS, data };
  }
  function failure(error) {
    return { type: c.GET_RESOURCE_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    ResourceLibService.getResource(val).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function DeleteResource(val) {
  function request() {
    return { type: c.DELETE_RESOURCE_REQUEST };
  }
  function success(data) {
    return { type: c.DELETE_RESOURCE_SUCCESS, data };
  }
  function failure(error) {
    return { type: c.DELETE_RESOURCE_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    ResourceLibService.deleteResource(val).then(
      (response) => {
        dispatch(success(response.data));
        toast("Successfully deleted Resource Library");               
        dispatch(push(`/ResourceLibrary`));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function UpdateResource(val, callback) {
  function request() {
    return { type: c.UPDATE_RESOURCE_REQUEST };
  }
  function success(data) {
    return { type: c.UPDATE_RESOURCE_SUCCESS, data };
  }
  function failure(error) {
    return { type: c.UPDATE_RESOURCE_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    ResourceLibService.updateResource(val).then(
      (response) => {
        dispatch(success(response.data));
        toast("Successfully updated a new Resource Library");               
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function GetFile(val) {
  function request() {
    return { type: c.GET_RESOURCE_REQUEST };
  }
  function success(data) {
    return { type: c.GET_RESOURCE_SUCCESS, data };
  }
  function failure(error) {
    return { type: c.GET_RESOURCE_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    FileService.get(val).then(
      (response) => {
        // eslint-disable-next-line no-console
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

export default ({
  CreateResource,
  GetResourceList,
  GetResource,
  UpdateResource,
  DeleteResource,
  GetFile,
});
