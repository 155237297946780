/* eslint import/prefer-default-export: 0 */
import api from '../common';

function getAddressTypes() {
  return api.get('api/Victim/GetVictimAddressTypes', { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export {
    getAddressTypes,
};
