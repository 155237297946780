import { toast } from "react-toastify";
import hubConstants from "./constants";
import { hubService } from "../../services";

function getHubData() {
  function request() {
    return { type: hubConstants.HUB_REQUEST };
  }
  function success(hub) {
    return { type: hubConstants.HUB_SUCCESS, hub };
  }
  function failure(error) {
    return { type: hubConstants.HUB_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    hubService.getList().then(
      (hub) => {
        dispatch(success(hub.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function createAllocation(data, callback) {
  function request() {
    return { type: hubConstants.HUB_CREATE_REQUEST };
  }
  function success(hub) {
    return { type: hubConstants.HUB_CREATE_SUCCESS, hub };
  }
  function failure(error) {
    return { type: hubConstants.HUB_CREATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    hubService.createAllocation(data).then(
      (hub) => {
        dispatch(success(hub));
        toast("Successfully created a new Allocation");
        // history.push(`/team/${team.data.Id}`);
        callback(hub.data.Id);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

function GetAllocationById(data) {
  function request() {
    return { type: hubConstants.HUB_GET_REQUEST };
  }
  function success(hub) {
    return { type: hubConstants.HUB_GET_SUCCESS, hub };
  }
  function failure(error) {
    return { type: hubConstants.HUB_GET_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    hubService.getAllocationById(data).then(
      (hub) => {
        dispatch(success(hub));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function UpdateAllocation(data, callback) {
  function request() {
    return { type: hubConstants.HUB_UPDATE_REQUEST };
  }
  function success() {
    return { type: hubConstants.HUB_UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: hubConstants.HUB_UPDATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    hubService.updateAllocation(data).then(
      () => {        
        dispatch(success());
        toast("Successfully updated Allocation");    
        callback();   
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

export default {
  getHubData,
  createAllocation,
  GetAllocationById,
  UpdateAllocation,  
};
