import { store } from '../../store';

const rights = {
  GlboalAdmin: 'Can View as Global Admin',
  CanManageForceUsers: 'Can Manage Force Users',
  CanManageAreaUsers: 'Can Manage Area Users',
  CanManageTeamUsers: 'Can Manage Team Users',
  CanManageSystemInfo: 'Can Manage System Info',
  CanManageHub: 'Can Manage Hub',
  CanViewHub: 'Can View Hub',
  CanManageRegistration: 'Can Manage Registration',
  CanManageRegistrationData: 'Can Manage Registration Data',
  CanViewRegistration: 'Can View Registration',
  CanViewRegistrationData: 'Can View Registration Data',
  CanManageUsers: 'Can Manage Users',
  CanViewUsers: 'Can View Users',
  CanManageForces: 'Can Manage Forces',
  CanManageAreas: 'Can Manage Areas',
  CanManageTeams: 'Can Manage Teams',
  CanManageLibrary: 'Can Manage Library',
  CanViewLibrary: 'Can View Library',
  CanSearchVictims: 'Can Search Victims',
}

const roles = {
  SystemAdmin: 1,
  ForceAdmin: 2,
  AreaAdmin: 3,
  TeamUser: 4,
  SearchUser: 5
}

function hasRights(rts) {
  const { currentUser } = store.getState().AccountState;
  // console.info(currentUser);
  if (currentUser && currentUser.Rights) {
    const found = rts.some(r => currentUser.Rights.indexOf(r) >= 0);
    return found;
  }

  return false;
}

function inRoles(role) {
  const { currentUser } = store.getState().AccountState;
  if (currentUser && currentUser.Roles) {
    const found = role.some(right => currentUser.Roles.indexOf(right) >= 0);
    return found;
  }

  return false;
}

export { hasRights, inRoles, rights, roles }