import React from 'react';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Spin,
} from 'antd';
import LEAForm from '../form';
import actions from '../actions';
import './LEAPage.css';

class CreatePage extends React.Component {

  createForce = (val) => {
    this.props.dispatch(actions.CreateForce(val, this.handleCallback));
  }

  handleCallback = () => {
    this.props.dispatch(push(`/force`));
  };

  handleCancel = () => {
    this.props.dispatch(push("/force"));
  };

    render(){
        return(
          <div className="p5-page-container-small">
            <Row justify="center">
              <Col xs={20} md={20} lg={18}>
                <Spin spinning={this.props.loading}>
                  <LEAForm
                    loading={this.props.loading}
                    onSubmit={this.createForce}
                    onCancel={this.handleCancel}
                    title="Create Force"
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        );
    }
}

function mapStateToProps(state) {
  const { loading } = state.LEAState;
  return {
    loading
  };
}

const connectedCreatePage = connect(mapStateToProps)(CreatePage);

CreatePage.propTypes = {
  dispatch: () => {},
  loading: PropTypes.bool,
};

CreatePage.defaultProps = {
  dispatch: () => {},
  loading: false,
};

export default connectedCreatePage;
