import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {
  Row,
  Col,
  Input,
  Form,
  Button,
} from 'antd';
import RiskLevelSelect from '../../../common/components/risk-level-select';
import '../AddUserModal.css';

class CreateForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      UserName: '',
      Password: '',
      EmailAddress: '',
      FirstName: '',
      LastName: '',
      PhoneNumber: '',
      RiskLevel: '',
    };
  }

  handleChange = (e) => {
    const { value, id } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          UserName: this.state.UserName,
          Password: this.state.Password,
          EmailAddress: this.state.EmailAddress,
          FirstName: this.state.FirstName,
          LastName: this.state.LastName,
          PhoneNumber: this.state.PhoneNumber,
          RiskLevel: this.state.RiskLevel,
        }}
        validationSchema={Yup.object().shape({
          UserName: Yup.string()
            .typeError('User Name is required')
            .required('User Name is required'),
          Password: Yup.string()
            .typeError('Password is required')
            .required('Password is required'),
          FirstName: Yup.string()
            .typeError('Name is required')
            .required('Name is required'),
          LastName: Yup.string()
            .typeError('Name is required')
            .required('Name is required'),
          EmailAddress: Yup.string()
            .typeError('Name is required')
            .required('Name is required'),
        })}
        onSubmit={(values) => { this.props.onSubmit(values); }}
        render={({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleSubmit,
        }) => (
          <Form
            id={this.props.name}
            name={this.props.name}
            autoComplete="off"
            onChange={handleChange}
            onSubmit={handleSubmit}
          >
            <Row type="flex" justify="center">
              <Col span={24}>
                <Row type="flex" justify="left">
                  <Col span={12}>
                    <FontAwesomeIcon size="3x" icon={faUser} className="mt-3" />
                  </Col>
                  <Col span={12}>
                    <Form.Item>
                      <div className="form-label">
                        <b>Risk Level</b>
                      </div>
                      <RiskLevelSelect
                        name="RiskLevel"
                        value={values.RiskLevel}
                        onChange={(val) => {
                          setFieldValue('RiskLevel', val);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form.Item
                  validateStatus={
                      errors && errors.UserName && touched.UserName
                        ? 'error'
                        : null
                    }
                  help={errors && touched.UserName && errors.UserName}
                >
                  <div className="form-label mt-3">
                    <b>Username</b>
                  </div>
                  <Input
                    type="text"
                    id="UserName"
                    name="UserName"
                    placeholder="Username"
                    value={values.UserName}
                    onChange={this.handleChange}
                    autoComplete="UserName"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  validateStatus={
                      errors && errors.Password && touched.Password
                        ? 'error'
                        : null
                    }
                  help={errors && touched.Password && errors.Password}
                >
                  <div className="form-label">
                    <b>Password</b>
                  </div>
                  <Input.Password
                    id="Password"
                    name="Password"
                    value={values.Password}
                    placeholder="Password"
                    onChange={this.handleChange}
                    autoComplete="Password"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  validateStatus={
                      errors && errors.EmailAddress && touched.EmailAddress
                        ? 'error'
                        : null
                    }
                  help={errors && touched.EmailAddress && errors.EmailAddress}
                >
                  <div className="form-label">
                    <b>Email Address</b>
                  </div>
                  <Input
                    id="EmailAddress"
                    name="EmailAddress"
                    value={values.EmailAddress}
                    placeholder="Email Address"
                    onChange={this.handleChange}
                    autoComplete="EmailAddress"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  validateStatus={errors && errors.FirstName && touched.FirstName ? 'error' : null}
                  help={errors && touched.FirstName && errors.FirstName}
                >
                  <div className="form-label"><b>First Name</b></div>
                  <Input
                    type="text"
                    id="FirstName"
                    name="FirstName"
                    placeholder="First Name"
                    value={values.FirstName}
                    onChange={handleChange}
                    autoComplete="FirstName"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  validateStatus={errors && errors.LastName && touched.LastName ? 'error' : null}
                  help={errors && touched.LastName && errors.LastName}
                >
                  <div className="form-label"><b>Last Name</b></div>
                  <Input
                    type="text"
                    id="LastName"
                    name="LastName"
                    placeholder="Last Name"
                    value={values.LastName}
                    onChange={handleChange}
                    autoComplete="LastName"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  validateStatus={errors && errors.PhoneNumber && touched.PhoneNumber ? 'error' : null}
                  help={errors && touched.PhoneNumber && errors.PhoneNumber}
                >
                  <div className="form-label"><b>Phone Number</b></div>
                  <Input
                    id="PhoneNumber"
                    name="PhoneNumber"
                    value={values.PhoneNumber}
                    onChange={handleChange}
                    type="number"
                    placeholder="Phone Number"
                    autoComplete="PhoneNumber"
                  />
                </Form.Item>
              </Col>
              <Button
                block
                id="registerBtn"
                type="submit"
                className="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Row>
          </Form>
        )}
      />
    );
  }
}

CreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string,
};

CreateForm.defaultProps = {
  name: '',
};

export default CreateForm;
