import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { RiskLevelService } from '../../../services';

const { Option } = Select;

class RiskLevelSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getRiskLevels();
  }

  getRiskLevels() {
    RiskLevelService.getRiskLevels().then((result) => {
      this.setState({
        data: result.data,
      });
    });
  }

  render() {
    return (
      <Select
        className={this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        {
          this.state.data.map((opt) => (
            <Option
              key={opt.Id}
              value={opt.Id}
            >
              {opt.Name}
            </Option>
          ))
        }
      </Select>
    );
  }
}

RiskLevelSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  className: PropTypes.string,
};

RiskLevelSelect.defaultProps = {
  value: 0,
  className: "",
};

export { RiskLevelSelect as default };
