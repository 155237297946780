import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Formik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Input, Form } from "antd";
import { SaveBtn, CancelBtn } from "../../../common/components/form";
import "./ChangePasswordForm.css";

class ChangePasswordForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      CurrentPassword: "",
      NewPassword: "",
      ConfirmPassword: "",
    };
  }

  handleChange = (e) => {
    const { value, id } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  validateHasLowercase = (value) => {
    const regex = /(?=.*[a-z])/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }

    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateHasUppercase = (value) => {
    const regex = /(?=.*[A-Z])/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateHasNumber = (value) => {
    const regex = /(?=.*[0-9])/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateHasSpecialCharacter = (value) => {
    // eslint-disable-next-line
    const regex = /(?=.*[?!@#\$%\^&\*])/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateHasMinCount = (value) => {
    const regex = /(?=.{6,})/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateConfirmPassword = (value, confirm) => {
    if(value === confirm && value !== "") {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  render() {
    // eslint-disable-next-line
    const passwordRegExp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?!@#\$%\^&\*])(?=.{6,})/;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          CurrentPassword: this.state.CurrentPassword,
          NewPassword: this.state.NewPassword,
          ConfirmPassword: this.state.ConfirmPassword,
          UserName: this.props.UserName,
        }}
        validationSchema={Yup.object().shape({
          CurrentPassword: Yup.string()
            .typeError("Current password is required")
            .required("Current password is required"),
          NewPassword: Yup.string()
            .matches(passwordRegExp,"Password is weak.")           
            .typeError("New password is required")
            .required("New password is required"),
          ConfirmPassword: Yup.string()
            .oneOf([Yup.ref("NewPassword"), null], "Passwords must match")
            .typeError("Confirm password is required")
            .required("Confirm password is required"),
        })}
        onSubmit={(values) => this.props.onSubmit(values)}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form
            id={this.props.name}
            className="UserForm"
            name={this.props.name}
            autoComplete="off"
            onChange={handleChange}
            onSubmit={handleSubmit}
          >
            <div id="profile-form-container">
              <Col span={24}>
                <Col className="ml-5 mr-5">
                  <Row justify="center">
                    <Col md={24} sm={22} xs={22}>
                      <div style={{ textAlign: "center" }}>
                        <h1 style={{ color: "#243b53", fontWeight: "500" }}>
                          Change Password
                        </h1>
                      </div>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col md={22} sm={22} xs={22}>
                      <Form.Item
                        validateStatus={
                        errors &&
                        errors.CurrentPassword &&
                        touched.CurrentPassword
                          ? "error"
                          : null
                      }
                        help={
                        errors &&
                        touched.CurrentPassword &&
                        errors.CurrentPassword
                      }
                      >
                        <div className="form-label">
                          <b>Current Password</b>
                        </div>
                        <Input.Password
                          type="password"
                          id="CurrentPassword"
                          className="inputField"
                          name="CurrentPassword"
                          value={values.CurrentPassword}
                          placeholder="Current Password"
                          onChange={handleChange}
                          autoComplete="Password"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col md={22} sm={22} xs={22}>
                      <Form.Item
                        className="profileNewPassword"
                        validateStatus={
                        errors && errors.NewPassword && touched.NewPassword
                          ? "error"
                          : null
                      }
                        help={errors && touched.NewPassword && errors.NewPassword}
                      >
                        <div className="form-label">
                          <b>New Password</b>
                        </div>
                        <Input.Password
                          type="password"
                          id="NewPassword"
                          className="inputField"
                          name="NewPassword"
                          value={values.NewPassword}
                          placeholder="New Password"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col md={22} sm={22} xs={22}>
                      <Form.Item
                        className="profileConfirmPassword"
                        validateStatus={
                        errors &&
                        errors.ConfirmPassword &&
                        touched.ConfirmPassword
                          ? "error"
                          : null
                      }
                        help={
                        errors &&
                        touched.ConfirmPassword &&
                        errors.ConfirmPassword
                      }
                      >
                        <div className="form-label">
                          <b>Confirm Password</b>
                        </div>
                        <Input.Password
                          type="password"
                          id="ConfirmPassword"
                          className="inputField"
                          name="ConfirmPassword"
                          value={values.ConfirmPassword}
                          placeholder="Confirm Password"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Col span={22}>
                      <div className="form-label" style={{ fontSize: 14 }}>Passwords must have at least one lowercase letter, one uppercase letter, one number, and one special character !?@#$%^&amp;*</div>
                    </Col>                    
                  </Row>
                  <br />
                  <Row justify="Center">
                    <Col span={22}>
                      <div className="form-label" style={{ fontSize: 14, marginLeft: "20px" }}>{this.validateHasMinCount(values.NewPassword)} <span>Contains at least 6 characters</span></div>
                      <div className="form-label" style={{ fontSize: 14, marginLeft: "20px" }}>{this.validateHasLowercase(values.NewPassword)} <span>Contains lowercase letter</span></div>
                      <div className="form-label" style={{ fontSize: 14, marginLeft: "20px" }}>{this.validateHasUppercase(values.NewPassword)} <span>Contains uppercase letter</span></div>
                      <div className="form-label" style={{ fontSize: 14, marginLeft: "20px" }}>{this.validateHasNumber(values.NewPassword)} <span>Contains number</span></div>
                      <div className="form-label" style={{ fontSize: 14, marginLeft: "20px" }}>{this.validateHasSpecialCharacter(values.NewPassword)} <span>Contains special character !?@#$%^&amp;*</span></div>
                      <div className="form-label" style={{ fontSize: 14 , marginLeft: "20px"}}>{this.validateConfirmPassword(values.NewPassword, values.ConfirmPassword)} <span>Passwords match</span></div>
                    </Col>
                  </Row>
                  <br />
                  <Row justify="center">
                    <Col span={22}>
                      <Row justify="end">
                        <Col md={6} sm={22} xs={22} style={{ margin: "5px" }}>
                          <CancelBtn onClick={this.props.onCancel} />
                        </Col>
                        <Col md={6} sm={22} xs={22} style={{ margin: "5px" }}>
                          <SaveBtn onClick={handleSubmit} loading={this.props.loading} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

ChangePasswordForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  name: PropTypes.string,
  UserName: PropTypes.string,
  loading: PropTypes.bool,
};

ChangePasswordForm.defaultProps = {
  name: "",
  history: {
    push: () => {},
  },
  UserName: "",
  onCancel: () => {},
  loading: false,
};

export default withRouter(ChangePasswordForm);
