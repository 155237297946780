import React from "react";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Spin, Button } from "antd";
// import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import SearchAction from "../actions";
import { VictimDetails, LiteVictimDetails } from "../component"
import { IsFullVersion, IsLiteVersion} from "../../../common/utils/system-version-helper";

class SearchView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount() {
    this.getVictimView();
  }

  handleCallback = (result) => {
    this.setState({
      data: result.data[0],
    });
  };

  getVictimView = () => {
    // const id = parseInt(this.props.match.params.id, 10);
    this.props.dispatch(SearchAction.GetVictimView(this.props.match.params.id, this.handleCallback));
  };

  backToSearch = () => {
    this.props.dispatch(push(`/search`));
  };

  render() {
    const result =
      this.props.loading && this.state.data !== null ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            textAlign: "center",
          }}
        >
          <Spin style={{ marginTop: "40vh" }} />
        </div>
      ) : (
        <>
          <div className="ml-2 mt-3 d-flex">
            <Button
              type="text"
              className="back-btn pl-0"
              onClick={() => this.backToSearch()}
            >
              <FontAwesomeIcon size="1x" icon={faChevronLeft} />
              <span className="ml-2">
                <b>Back to search</b>
              </span>
            </Button>
          </div>
          {IsFullVersion() && (
            <VictimDetails {...this.state.data} />
          )}
          {IsLiteVersion() && (
            <LiteVictimDetails {...this.state.data} />
          )}
          
        </>
      );

    return result;
  }
}

function mapStateToProps(state) {
  const { loading } = state.Search;
  return {
    loading,
  };
}

SearchView.propTypes = {
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  match: PropTypes.shape(),
};

SearchView.defaultProps = {
  dispatch: () => {},
  match: {},
  loading: false,
};

const connectedSearchView = connect(mapStateToProps)(SearchView);

export { connectedSearchView as default };
