import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Input, Form, Button, Divider } from "antd";
import "../page/RegisterUserPage.css";
import moment from "moment";
import RiskLevelSelect from "../../../common/components/risk-level-select";
import RiskCategoriesSelect from "../../../common/components/risk-categories-select";
import GenderSelect from "../../../common/components/gender-select";
import ErrorFocus from "../../../common/utils/error-focus";
import { CancelBtn, SaveBtn } from "../../../common/components/form";
import "./DataForm.scss";

class LiteDataForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.formIK = React.createRef();
    this.state = {
      isCopyVisible: false,
      PreviewVictimId: 0,
      Id: 0,
      RegistrationId: 0,
      GenderId: 1,
      CrimeRefNumber: "",
      RiskLevelId: 1,      
      OIC_Name: "",
      OIC_StaffID: "",
      AuthorisingOfficer: null,
      edit: false,
    };
  }

  pivotData = (data) => ({
    Id: this.props.Id,
    RegistrationId: this.props.RegistrationId,
    GenderId: data.GenderId,
    CrimeRefNumber: data.CrimeRefNumber,
    RiskLevelId: data.RiskLevelId,
    RiskCategories: data.RiskCategories,
    OIC_Name: data.OIC_Name,
    OIC_StaffID: data.OIC_StaffID,
    AuthorisingOfficer: data.AuthorisingOfficer,
    isLiteVersion: true,
  });
  
  handleChange = (data) => {
    const { value, id } = data.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  hasErrors = (errorField) => (
    <div style={{color: 'red'}}><b>{errorField}</b></div>
  );

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        initialValues={{
          Id: this.props.Id || this.state.Id,
          RegistrationId: this.props.RegistrationId || this.state.RegistrationIds,
          GenderId: this.props.GenderId || this.state.GenderId,
          CreatedDate: "",
          CrimeRefNumber:
            this.props.CrimeRefNumber || this.state.CrimeRefNumber,
          RiskLevelId: this.props.RiskLevelId || this.state.RiskLevelId,
          RiskCategories:
            this.props.RiskCategories || [],
          OIC_Name: this.props.OIC_Name || this.state.OIC_Name,
          OIC_StaffID: this.props.OIC_StaffID || this.state.OIC_StaffID,
          AuthorisingOfficer: this.props.AuthorisingOfficer || this.state.AuthorisingOfficer,
        }}
        validationSchema={Yup.object().shape({
          CrimeRefNumber: Yup.string()
            .typeError("Local Reference # is required")
            .required("Local Reference # is required"),
          RiskCategories: Yup.array()
            .of(
              Yup.number()
            ).min(1, 'At least 1 risk category required'),
          OIC_Name: Yup.string()
            .typeError("OIC Name is required")
            .required("OIC Name is required"),
          OIC_StaffID: Yup.string()
            .typeError("Staff ID # is required")
            .required("Staff ID # is required")
            .matches(
              /^[a-zA-Z0-9]+$/,
              "Officer Staff ID must be alphanumeric"
            ),
        })}
        onSubmit={(values) => {this.props.onSubmit(this.pivotData(values));}}
        render={({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <Form
            id="registerForm"
            name="registerForm"
            onSubmit={handleSubmit}
            onChange={handleChange}
            autoComplete={0}
          >
            <div className="p5-form-container">     
              {
                this.props.CreatedDate ? (                      
                  <div>
                    <Row>
                      <Col span={24}>
                        <h1 className="p5-form-h1-header">Victim Details</h1>
                      </Col>
                    </Row>
                    <hr />
                    <Row justify="end">
                      <Col xs={24} sm={24} md={14} lg={10} className="sh-tour-vic-doe">
                        <h4 className="p5-h4">{`Date of Entry: ${moment(this.props.CreatedDate).format("DD/MM/YYYY h:mm:ss").toString()}`} </h4>
                      </Col>
                    </Row>                    
                  </div>
                ):
                (
                  <div>
                    <Row>
                      <Col span={24}>
                        <h1 className="p5-form-h1-header">Victim Details</h1>
                      </Col>
                    </Row>
                    <hr />
                  </div>
                )
              }              
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>                
                <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-vic-gender">
                  <Form.Item>
                    <div className="p5-form-label"> Gender </div>
                    <GenderSelect
                      className="p5-form-dropdown"
                      name="Gender"
                      value={values.GenderId}
                      onChange={(val) => {
                        setFieldValue("GenderId", val);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-vic-ref-num">  
                  <Form.Item
                    validateStatus={
                      errors && errors.CrimeRefNumber && touched.CrimeRefNumber
                        ? "error"
                        : null
                    }
                    help={errors && touched.CrimeRefNumber && errors.CrimeRefNumber}
                  >
                    <div className="p5-form-label"> Local Reference # </div>
                    <Input
                      id="CrimeRefNumber"
                      className="p5-form-input"
                      name="CrimeRefNumber"
                      value={values.CrimeRefNumber}
                      placeholder="Enter 'None' if unavailable"
                      onChange={(val) => {
                        setFieldValue("CrimeRefNumber", val);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-vic-risk-lvl" style={{marginBottom: "20px"}}>
                  <div className="p5-form-label"> Risk Level </div>
                  <RiskLevelSelect
                    className="p5-form-dropdown"
                    name="RiskLevelId"
                    value={values.RiskLevelId}
                    onSelect={(val) => {
                        setFieldValue("RiskLevelId", val);
                      }}
                    onChange={(val) => {
                      setFieldValue("RiskLevelId", val);
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-vic-risk-cat" style={{marginBottom: "20px"}}>
                  <Form.Item
                    validateStatus={
                      errors && errors.RiskCategories && touched.RiskCategories
                        ? "error"
                        : "success"
                      }
                    help={
                      errors && touched.RiskCategories && errors.RiskCategories ? errors.RiskCategories : null
                    }
                  >
                    <div className="p5-form-label"> Risk Categories </div>
                    <RiskCategoriesSelect
                      touched={touched.RiskCategories}
                      className="p5-form-dropdown"
                      name="RiskCategories"
                      value={values.RiskCategories}
                      onBlur={handleBlur}
                      onChange={(val) => {
                        setFieldValue("RiskCategories", val);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row> 
              <Divider orientation="left" plain>
                Officer In The Case
              </Divider>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={16} className="gutter-row sh-tour-vic-oic">
                  <Form.Item
                    validateStatus={
                        errors && errors.OIC_Name && touched.OIC_Name
                        ? "error"
                        : null
                    }
                    help={errors && touched.OIC_Name && errors.OIC_Name}
                  >
                    <div className="p5-form-label"> Officer Name</div>
                    <Input
                      type="text"
                      className="p5-form-input"
                      id="OIC_Name"
                      name="OIC_Name"
                      placeholder="Officer Name"
                      value={values.OIC_Name}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} className="gutter-row sh-tour-vic-oic-staff-id">
                  <Form.Item
                    validateStatus={
                        errors && errors.OIC_StaffID && touched.OIC_StaffID
                        ? "error"
                        : null
                    }
                    help={errors && touched.OIC_StaffID && errors.OIC_StaffID}
                  >
                    <div className="p5-form-label"> Staff ID</div>
                    <Input
                      type="text"
                      className="p5-form-input"
                      id="OIC_StaffID"
                      name="OIC_StaffID"
                      placeholder="Staff ID #"
                      value={values.OIC_StaffID}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={16} className="gutter-row sh-tour-vic-auth-oic">
                  <Form.Item>
                    <div className="p5-form-label"> Authorising Officer</div>
                    <Input
                      type="text"
                      className="p5-form-input"
                      id="AuthorisingOfficer"
                      name="AuthorisingOfficer"
                      placeholder="Authorising Officer"
                      value={values.AuthorisingOfficer}
                    />
                  </Form.Item>
                </Col>
              </Row>                
              <Divider /> 
              <br />
              <Row justify="end" className="sh-tour-vic-footer-btn">
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  style={{ margin: "5px" }}
                >
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  style={{ margin: "5px" }}
                >
                  {this.props.isExtend ?
                  (
                    <Button 
                      block
                      type="submit"
                      id="extendBtn"
                      className="extendBtn"
                      onClick={handleSubmit}
                      loading={this.props.loading}
                    >      
                      Save and Extend                
                    </Button>
                  ) : (
                    <SaveBtn
                      onClick={handleSubmit}
                      loading={this.props.loading}
                    />
                  )}
                  
                </Col>
              </Row>
              <ErrorFocus />              
            </div>
          </Form>
        )}
      />
    );
  }
}

LiteDataForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isExtend: PropTypes.bool,
  Id: PropTypes.number,
  RegistrationId: PropTypes.number,
  CreatedDate: PropTypes.string,
  GenderId: PropTypes.number,
  CrimeRefNumber: PropTypes.string,
  RiskLevelId: PropTypes.number, 
  RiskCategories: PropTypes.arrayOf(PropTypes.number),
  OIC_Name: PropTypes.string,
  OIC_StaffID: PropTypes.string,
  AuthorisingOfficer: PropTypes.string,
  loading: PropTypes.bool,
};

LiteDataForm.defaultProps = {
  onCancel: () => {},
  isExtend: false,
  Id: 0,
  RegistrationId: 0,
  CreatedDate: "",
  GenderId: null,
  CrimeRefNumber: "",
  RiskLevelId: 0,
  RiskCategories: [],
  OIC_Name: "",
  OIC_StaffID: "",
  AuthorisingOfficer: "",
  loading: false,
};

export default LiteDataForm;
