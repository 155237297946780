import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import { Layout, PageHeader } from "../../common/components";
import { RegisterPage, DataCreatePage, DataEditPage, ExtendPage } from "./page";

const UserListHeader = (
  <PageHeader title="Registration" />
)

const Registration = ({ match }) => (
  <Layout PageHeader={UserListHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={RegisterPage} />
      <Route exact path={`${match.url}/:regid/create`} component={DataCreatePage} />
      <Route exact path={`${match.url}/:regid/edit`} component={DataEditPage} />
      <Route exact path={`${match.url}/:regid/extend`} component={ExtendPage} />
    </Switch>
  </Layout>
);

Registration.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string,
      }),
};

Registration.defaultProps = {
    match: {
        url: "",
      },
};

export default withRouter(Registration);
