import navigationConstants from './constants';
import tourGuideConstants from '../../../features/guide-tour/constants';

function changePage(page, isTour = false) {
    function request(newPage) { return { type: navigationConstants.CHANGE_PAGE_REQUEST, currentPage: newPage }; }
    function tourPageRequest() { return { type: tourGuideConstants.TOUR_REMOVE_MODAL_OVERRIDE };}

    return (dispatch) => {
        dispatch(request(page));

        if(isTour === false)
            dispatch(tourPageRequest());
    };
}

function collapseSideNav(nav) {
    function request(collapsed) { return { type: navigationConstants.COLLAPSE_NAV_REQUEST, isCollapsed: collapsed }; }
    return (dispatch) => {
        dispatch(request(nav));
    };
}

function setNavSize(isMobile) {
    function request(collapsedSize) { return { type: navigationConstants.NAV_SIZE_CHANGE, collapsedSize }; }
    return (dispatch) => {
        dispatch(request(isMobile));
    };
}

export default {
    changePage,
    collapseSideNav,
    setNavSize,
};
