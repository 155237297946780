export default {
    TOUR_CHECK_ACTIVE_REQUEST: "TOUR_CHECK_ACTIVE_REQUEST",
    TOUR_CHECK_ACTIVE_SUCCESS: "TOUR_CHECK_ACTIVE_SUCCESS",
    TOUR_CHECK_ACTIVE_FAILURE: "TOUR_CHECK_ACTIVE_FAILURE",
    TOUR_REGISTRATION_LIST_REQUEST: 'TOUR_REGISTRATION_LIST_REQUEST',
    TOUR_REGISTRATION_LIST_SUCCESS: 'TOUR_REGISTRATION_LIST_SUCCESS',
    TOUR_REGISTRATION_LIST_FAILURE: 'TOUR_REGISTRATION_LIST_FAILURE',
    TOUR_GET_CURRENT_REQUEST: "TOUR_GET_CURRENT_REQUEST",
    TOUR_GET_CURRENT_SUCCESS: "TOUR_GET_CURRENT_SUCCESS",
    TOUR_GET_CURRENT_FAILURE: "TOUR_GET_CURRENT_FAILURE",
    TOUR_GET_LIST_REQUEST: "TOUR_GET_LIST_REQUEST",
    TOUR_GET_LIST_SUCCESS: "TOUR_GET_LIST_SUCCESS",
    TOUR_GET_LIST_FAILURE: "TOUR_GET_LIST_FAILURE",
    TOUR_REMOVE_MODAL_OVERRIDE: "TOUR_REMOVE_MODAL_OVERRIDE",
    TOUR_MARK_COMPLETED_REQUEST: "TOUR_MARK_COMPLETED_REQUEST",
    TOUR_MARK_COMPLETED_SUCCESS: "TOUR_MARK_COMPLETED_SUCCESS",
    TOUR_MARK_COMPLETED_FAILURE: "TOUR_MARK_COMPLETED_FAILURE",
    TOUR_REGISTRATION_DETAIL_REQUEST: 'TOUR_REGISTRATION_DETAIL_REQUEST',
    TOUR_REGISTRATION_DETAIL_SUCCESS: 'TOUR_REGISTRATION_DETAIL_SUCCESS',
    TOUR_REGISTRATION_DETAIL_FAILURE: 'TOUR_REGISTRATION_DETAIL_FAILURE',
};