import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Tabs } from "antd";
import moment from "moment";
import { phoneNumberFormatter } from "../../../common/utils/phone-number-helper";

const { TabPane } = Tabs;

class VictimDetails extends React.PureComponent {
  render() {
    const result = (
      <>
        <Tabs
          defaultActiveKey="1"
          className="p5-tabs"
          style={{ margin: "0 24px" }}
          size="large"
        >
          <TabPane tab="Victim Details" key="1" className="p5-tabs-pane">
            <div className="p5-page-container mx-auto">
              <Row gutter={[24, 6]}>
                <Col lg={12} md={24} sm={24} className="gutter-row">
                  <Row gutter={[24, 24]}>
                    <Col span={24} className="gutter-row">
                      <div className="view-form-container">
                        <Row>
                          <Col span={24}>
                            <h5 className="p5-form-h1-header">
                              Victim Details
                            </h5>
                          </Col>
                        </Row>
                        <hr />
                        <div>
                          <Row gutter={[16, 8]}>
                            <Col span={12}>
                              <div className="meta-data-header">
                                Entry Date:
                              </div>
                            </Col>
                            <Col span={12}>
                              <strong>
                                {moment(this.props.CreatedDate)
                                  .format("DD/MM/YYYY h:mm:ss")
                                  .toString()}
                              </strong>
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">Name:</div>
                            </Col>
                            <Col span={12}>
                              <strong>{this.props.Name}</strong>
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">Gender:</div>
                            </Col>
                            <Col span={12}>
                              <strong>{this.props.Gender}</strong>
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">
                                Phone Number:
                              </div>
                            </Col>
                            <Col span={12}>
                              <strong>
                                {phoneNumberFormatter(this.props.PhoneNumber)}
                              </strong>
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">Email:</div>
                            </Col>
                            <Col span={12}>
                              <strong>{this.props.EmailAddress}</strong>
                            </Col>
                            <Col span={24}>
                              <hr />
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">
                                Risk Level:
                              </div>
                            </Col>
                            <Col span={12}>
                              <strong>{this.props.RiskLevel}</strong>
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">
                                Local Ref. #:
                              </div>
                            </Col>
                            <Col span={12}>
                              <strong>{this.props.CrimeRefNumber}</strong>
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">
                                Risk Categories:
                              </div>
                            </Col>
                            <Col span={12}>
                              {this.props.RiskCategories &&
                              this.props.RiskCategories.length > 0 ? (
                                <div>
                                  {this.props.RiskCategories.map(
                                    (risk, index) => (
                                      <strong key={risk.Id}>
                                        {risk.DisplayValue}
                                        {this.props.RiskCategories.length > 1 &&
                                        index + 1 !==
                                          this.props.RiskCategories.length
                                          ? ", "
                                          : ""}
                                      </strong>
                                    )
                                  )}
                                </div>
                              ) : (
                                <strong>None</strong>
                              )}
                            </Col>
                            <Col span={12}>
                              <div className="meta-data-header">
                                Disabilities:
                              </div>
                            </Col>
                            <Col span={12}>
                              {!this.props.DisabilitiesNone &&
                              this.props.Disabilities.length > 0 ? (
                                <div>
                                  {this.props.Disabilities.map(
                                    (disability, index) => (
                                      <strong key={disability.Id}>
                                        {disability.Name}
                                        {this.props.Disabilities.length > 1 &&
                                        index + 1 !==
                                          this.props.Disabilities.length
                                          ? ", "
                                          : ""}
                                      </strong>
                                    )
                                  )}
                                </div>
                              ) : (
                                <strong>None</strong>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                    <Col span={24} className="gutter-row">
                      <div className="view-form-container">
                        <Row>
                          <Col span={24}>
                            <h5 className="p5-form-h1-header">
                              Officer In The Case Details
                            </h5>
                          </Col>
                        </Row>
                        <hr />
                        <Row gutter={[16, 8]}>
                          <Col span={12}>
                            <div className="meta-data-header">Police Force:</div>
                          </Col>
                          <Col span={12}>
                            <strong>{this.props.LawEnforcementAgency_Name}</strong>
                          </Col>
                          <Col span={12}>
                            <div className="meta-data-header">Officer Name:</div>
                          </Col>
                          <Col span={12}>
                            <strong>{this.props.OIC_Name}</strong>
                          </Col>
                          <Col span={12}>
                            <div className="meta-data-header">Staff ID:</div>
                          </Col>
                          <Col span={12}>
                            <strong>{this.props.OIC_StaffID}</strong>
                          </Col>
                          <Col span={12}>
                            <div className="meta-data-header">
                              Authorising Officer:
                            </div>
                          </Col>
                          <Col span={12}>
                            <strong>{this.props.AuthorisingOfficer}</strong>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} md={24} sm={24} className="gutter-row">
                  <Row gutter={[24, 24]}>
                    <Col span={24} className="gutter-row">
                      <div className="view-form-container">
                        <Row>
                          <Col span={24}>
                            <h5 className="p5-form-h1-header">Address</h5>
                          </Col>
                        </Row>
                        <hr />
                        <div>
                          {this.props.VictimAddresses &&
                          this.props.VictimAddresses.length > 0 ? (
                            <div>
                              {this.props.VictimAddresses.map(
                                (address, index) => (
                                  <div key={`Addresses-${index + 0}`}>
                                    <strong key={address.Id}>
                                      <h5 className="view-form-header">
                                        Address #{index + 1}
                                      </h5>
                                    </strong>
                                    <Row gutter={[16, 8]}>
                                      <Col span={12}>
                                        <div className="meta-data-header">
                                          Type:
                                        </div>
                                      </Col>
                                      <Col span={12}>
                                        <strong>
                                          {address.AddressTypeId === 1
                                            ? "Home"
                                            : ""}
                                          {address.AddressTypeId === 2
                                            ? "Work"
                                            : ""}
                                          {address.AddressTypeId === 3
                                            ? "Additional Address"
                                            : ""}
                                        </strong>
                                      </Col>
                                      <Col span={12}>
                                        <div className="meta-data-header">
                                          Address:
                                        </div>
                                      </Col>
                                      <Col span={12}>
                                        <strong>{address.Address}</strong>
                                      </Col>
                                      <Col span={12}>
                                        <div className="meta-data-header">
                                          City/Town:
                                        </div>
                                      </Col>
                                      <Col span={12}>
                                        <strong>{address.City}</strong>
                                      </Col>
                                      <Col span={12}>
                                        <div className="meta-data-header">
                                          Postal Code:
                                        </div>
                                      </Col>
                                      <Col span={12}>
                                        <strong>{address.PostalCode}</strong>
                                      </Col>
                                      <Col span={12}>
                                        <div className="meta-data-header">
                                          County:
                                        </div>
                                      </Col>
                                      <Col span={12}>
                                        <strong>{address.County}</strong>
                                      </Col>
                                    </Row>
                                    {this.props.VictimAddresses &&
                                      this.props.VictimAddresses.length > 1 &&
                                      index + 1 !==
                                        this.props.VictimAddresses.length && (
                                        <hr />
                                      )}
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col span={24} className="gutter-row">
                      <div className="view-form-container">
                        <Row>
                          <Col span={24}>
                            <h5 className="p5-form-h1-header">
                              Vehicle Details
                            </h5>
                          </Col>
                        </Row>
                        <hr />
                        {this.props.VictimVehicleDetail &&
                          this.props.VictimVehicleDetail.length > 0 &&
                          this.props.VictimVehicleDetail.map(
                            (vehicle, index) => (
                              <>
                                <strong key={vehicle.Id}>
                                  <h5 className="view-form-header">
                                    Vehicle #{index + 1}
                                  </h5>
                                </strong>

                                <Row gutter={[16, 8]}>
                                  <Col span={12}>
                                    <div className="meta-data-header">VRM:</div>
                                  </Col>
                                  <Col span={12}>
                                    <strong>{vehicle.VRM}</strong>
                                  </Col>
                                  <Col span={12}>
                                    <div className="meta-data-header">
                                      Make:
                                    </div>
                                  </Col>
                                  <Col span={12}>
                                    <strong>{vehicle.Make}</strong>
                                  </Col>
                                  <Col span={12}>
                                    <div className="meta-data-header">
                                      Model:
                                    </div>
                                  </Col>
                                  <Col span={12}>
                                    <strong>{vehicle.Model}</strong>
                                  </Col>
                                  <Col span={12}>
                                    <div className="meta-data-header">
                                      Colour:
                                    </div>
                                  </Col>
                                  <Col span={12}>
                                    <strong>{vehicle.Colour}</strong>
                                  </Col>
                                </Row>
                                {this.props.VictimVehicleDetail &&
                                  this.props.VictimVehicleDetail.length > 1 &&
                                  index + 1 !==
                                    this.props.VictimVehicleDetail.length && (
                                    <hr />
                                  )}
                              </>
                            )
                          )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </TabPane>
          {this.props.VictimChildren && this.props.VictimChildren.length > 0 && (
            <TabPane tab="Children" key="2" className="p5-tabs-pane">
              <>
                <div className="p5-page-container mx-auto">
                  <Row gutter={[24, 16]}>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className="view-form-container">
                        <Row>
                          <Col span={24}>
                            <h5 className="p5-form-h1-header">Children</h5>
                          </Col>
                        </Row>
                        <hr />
                        {this.props.VictimChildren &&
                          this.props.VictimChildren.length > 0 &&
                          this.props.VictimChildren.map((child, index) => (
                            <div key={`child-${index + 0}`}>
                              <strong key={child.Id}>
                                <h5 className="view-form-header">
                                  Child #{index + 1}
                                </h5>
                              </strong>
                              <Row gutter={[16, 8]}>
                                <Col span={12}>
                                  <div className="meta-data-header">Name:</div>
                                </Col>
                                <Col span={12}>
                                  <strong>{child.Name}</strong>
                                </Col>
                                <Col span={12}>
                                  <div className="meta-data-header">Age:</div>
                                </Col>
                                <Col span={12}>
                                  <strong>{child.Age}</strong>
                                </Col>
                                <Col span={12}>
                                  <div className="meta-data-header">
                                    Address:
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <strong>{child.Address}</strong>
                                </Col>
                                <Col span={12}>
                                  <div className="meta-data-header">
                                    City/Town:
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <strong>{child.City}</strong>
                                </Col>
                                <Col span={12}>
                                  <div className="meta-data-header">
                                    Postal Code:
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <strong>{child.PostalCode}</strong>
                                </Col>
                                <Col span={12}>
                                  <div className="meta-data-header">
                                    County:
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <strong>{child.County}</strong>
                                </Col>
                              </Row>
                              {this.props.VictimChildren &&
                                this.props.VictimChildren.length > 1 &&
                                index + 1 !==
                                  this.props.VictimChildren.length && <hr />}
                            </div>
                          ))}
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            </TabPane>
          )}
          <TabPane tab="Perpetrator Details" key="3" className="p5-tabs-pane">
            <>
              <div className="p5-page-container mx-auto">
                <Row gutter={[24, 24]}>
                  <Col
                    xl={12}
                    lg={12}
                    md={24}
                    sm={24}
                    xs={24}
                    className="gutter-row"
                  >
                    <div className="view-form-container">
                      <Row>
                        <Col span={24}>
                          <h5 className="p5-form-h1-header">
                            Perpetrator Details
                          </h5>
                        </Col>
                      </Row>
                      <hr />
                      <Row gutter={[16, 8]}>
                        <Col span={12}>
                          <div className="meta-data-header">Name:</div>
                        </Col>
                        <Col span={12}>
                          <strong>
                            {this.props.VictimPerpetratorDetails
                              ? this.props.VictimPerpetratorDetails.Name
                              : "Not Known"}
                          </strong>
                        </Col>
                        <Col span={12}>
                          <div className="meta-data-header">Gender:</div>
                        </Col>
                        <Col span={12}>
                          <strong>
                            {this.props.VictimPerpetratorDetails
                              ? this.props.VictimPerpetratorDetails.Gender
                              : "Not Known"}
                          </strong>
                        </Col>
                        <Col span={12}>
                          <div className="meta-data-header">DOB:</div>
                        </Col>
                        <Col span={12}>
                          <strong>
                            {this.props.VictimPerpetratorDetails
                              ? moment(this.props.VictimPerpetratorDetails.DOB)
                                  .format("DD/MM/YYYY")
                                  .toString()
                              : "Not Known"}
                          </strong>
                        </Col>
                        <Col span={12}>
                          <div className="meta-data-header">PNC ID:</div>
                        </Col>
                        <Col span={12}>
                          <strong>
                            {this.props.VictimPerpetratorDetails
                              ? this.props.VictimPerpetratorDetails.PNCID
                              : null}
                          </strong>
                        </Col>
                      </Row>
                      <hr />

                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div className="meta-data-header">Description:</div>
                        </Col>
                        <Col span={24}>
                          <span>
                            {this.props.VictimPerpetratorDetails
                              ? this.props.VictimPerpetratorDetails.Description
                              : "Not Known"}
                          </span>
                        </Col>
                        <Col span={24}>
                          <div className="meta-data-header">
                            MO (Modus Operandi):
                          </div>
                        </Col>
                        <Col span={24}>
                          <span>
                            {this.props.VictimPerpetratorDetails
                              ? this.props.VictimPerpetratorDetails.MO
                              : "Not Known"}
                          </span>
                        </Col>
                        <Col span={24}>
                          <div className="meta-data-header">
                            Relevant court order or bail conditions:
                          </div>
                        </Col>
                        <Col span={24}>
                          <span>
                            {this.props.VictimPerpetratorDetails
                              ? this.props.VictimPerpetratorDetails
                                  .CourtOrderConditions
                              : "None"}
                          </span>
                        </Col>
                        <Col span={24}>
                          <div className="meta-data-header">
                            Deployment Considerations:
                          </div>
                        </Col>
                        <Col span={24}>
                          <span>
                            {this.props.VictimPerpetratorDetails
                              ? this.props.VictimPerpetratorDetails
                                  .DeploymentConsiderations
                              : "Not Known"}
                          </span>
                        </Col>
                        <Col span={24}>
                          <div className="meta-data-header">
                            Warning Markers:
                          </div>
                        </Col>
                        <Col span={24}>
                          {this.props.PerpWarningTypes &&
                          this.props.PerpWarningTypes.length > 0 ? (
                            <div>
                              {this.props.PerpWarningTypes.map(
                                (warning, index) => (
                                  <span key={warning.Id}>
                                    {warning.Description}
                                    {this.props.PerpWarningTypes.length > 1 &&
                                    index + 1 !==
                                      this.props.PerpWarningTypes.length
                                      ? ", "
                                      : ""}
                                  </span>
                                )
                              )}
                            </div>
                          ) : (
                            "None"
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col
                    xl={12}
                    lg={12}
                    md={24}
                    sm={24}
                    xs={24}
                    className="gutter-row"
                  >
                    <div className="view-form-container">
                      <Row>
                        <Col span={24}>
                          <h5 className="p5-form-h1-header">
                            Perpetrator Vehicle Details
                          </h5>
                        </Col>
                      </Row>
                      <hr />
                      {this.props.PerpVehicleDetails &&
                        this.props.PerpVehicleDetails.length > 0 &&
                        this.props.PerpVehicleDetails.map((vehicle, index) => (
                          <>
                            <strong key={vehicle.Id}>
                              <h5 className="view-form-header">
                                Vehicle #{index + 1}
                              </h5>
                            </strong>

                            <Row gutter={[16, 8]}>
                              <Col span={12}>
                                <div className="meta-data-header">VRM:</div>
                              </Col>
                              <Col span={12}>
                                <strong>{vehicle.VRM}</strong>
                              </Col>
                              <Col span={12}>
                                <div className="meta-data-header">Make:</div>
                              </Col>
                              <Col span={12}>
                                <strong>{vehicle.Make}</strong>
                              </Col>
                              <Col span={12}>
                                <div className="meta-data-header">Model:</div>
                              </Col>
                              <Col span={12}>
                                <strong>{vehicle.Model}</strong>
                              </Col>
                              <Col span={12}>
                                <div className="meta-data-header">Colour:</div>
                              </Col>
                              <Col span={12}>
                                <strong>{vehicle.Colour}</strong>
                              </Col>
                            </Row>
                            {this.props.PerpVehicleDetails &&
                              this.props.PerpVehicleDetails.length > 1 &&
                              index + 1 !==
                                this.props.PerpVehicleDetails.length && <hr />}
                          </>
                        ))}
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          </TabPane>
        </Tabs>
      </>
    );

    return result;
  }
}

function mapStateToProps() {
  return {};
}

VictimDetails.propTypes = {
  CreatedDate: PropTypes.string,
  Name: PropTypes.string,
  Gender: PropTypes.string,
  PhoneNumber: PropTypes.string,
  EmailAddress: PropTypes.string,
  RiskLevel: PropTypes.string,
  CrimeRefNumber: PropTypes.string,
  OIC_Name: PropTypes.string,
  OIC_StaffID: PropTypes.string,
  AuthorisingOfficer: PropTypes.string,
  RiskCategories: PropTypes.arrayOf(PropTypes.shape({})),
  VictimAddresses: PropTypes.arrayOf(PropTypes.shape({})),
  VictimVehicleDetail: PropTypes.arrayOf(PropTypes.shape({})),
  VictimChildren: PropTypes.arrayOf(PropTypes.shape({})),
  VictimPerpetratorDetails: PropTypes.shape({
    Name: PropTypes.string,
    Gender: PropTypes.string,
	DOB: PropTypes.string,
	PNCID: PropTypes.string,
	Description: PropTypes.string,
	MO: PropTypes.string,
	CourtOrderConditions: PropTypes.string,
	DeploymentConsiderations: PropTypes.string,
  }),
  PerpWarningTypes: PropTypes.arrayOf(PropTypes.shape({})),
  PerpVehicleDetails: PropTypes.arrayOf(PropTypes.shape({})),
  Disabilities: PropTypes.arrayOf(PropTypes.shape({})),
  DisabilitiesNone: PropTypes.bool,
  LawEnforcementAgency_Name: PropTypes.string,
};

VictimDetails.defaultProps = {
  CreatedDate: "",
  Name: "",
  Gender: "",
  PhoneNumber: "",
  EmailAddress: "",
  RiskLevel: "",
  CrimeRefNumber: "",
  OIC_Name: "",
  OIC_StaffID: "",
  AuthorisingOfficer: "",
  RiskCategories: [],
  VictimAddresses: [],
  VictimVehicleDetail: [],
  VictimChildren: [],
  VictimPerpetratorDetails: {
    Name: "",
    Gender: "",
	DOB: "",
	PNCID: "",
	Description: "",
	MO: "",
	CourtOrderConditions: "",
	DeploymentConsiderations: "",
  },
  PerpWarningTypes: [],
  PerpVehicleDetails: [],
  Disabilities: [],
  DisabilitiesNone: false,
  LawEnforcementAgency_Name: "",
};

const connectedVictimDetails = connect(mapStateToProps)(VictimDetails);

export { connectedVictimDetails as default };
