import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Button, Row, Col } from "antd";
import TourGuideAction from "../actions";

class TourGuides extends React.Component {
  componentDidMount() {
    this.props.dispatch(TourGuideAction.GetTourListByUser(this.props.userId));
  }

  // componentDidUpdate = (oldProps) => {
  //   if (oldProps.userId === 0 && this.props.userId > 0) {
  //     this.props.dispatch(TourGuideAction.GetTourListByUser(this.props.userId));
  //   }
  // };

  GoToTourGuide = (tourId, route) => {
    const reRoute = this.props.dispatch(push(`/${route}`));
    this.props.dispatch(TourGuideAction.GoToTourGuidePage(tourId, this.props.userId, reRoute));
  };

  render() {
    return (
      <div className="p5-form-container">
        {this.props.TourGuideList.map((tour, index) => (
          <div key={`tour-${tour.Id ?? index}`}>
            <span>
              <h6>
                {tour.Guide.Name}
              </h6>
            </span>
            <Row gutter={[32, 8]}>
              <Col span={16}>
                <p>{tour.Guide.Description}</p>
              </Col>
              <Col span={8}>
                <Button
                  style={{width: "80%"}}
                  onClick={() => this.GoToTourGuide(tour.Guide.Id, tour.Guide.PageRoute)}
                >
                  Start {tour.Guide.Name} Tour
                </Button>
              </Col>
            </Row>
            <br />
          </div>
        ))}
      </div>
    );
  }
}

TourGuides.propTypes = {
  dispatch: PropTypes.func,
  userId: PropTypes.number,
  TourGuideList: PropTypes.arrayOf(PropTypes.shape({})),
};

TourGuides.defaultProps = {
  dispatch: () => {},
  userId: 0,
  TourGuideList: [],
};

function mapStateToProps(state) {
  const { Id } = state.AccountState.currentUser;
  const { TourGuideList } = state.TourGuide;
  return {
    userId: Id,
    TourGuideList,
  };
}

const connectedTourGuidelModal = connect(mapStateToProps)(TourGuides);

export { connectedTourGuidelModal as default };
