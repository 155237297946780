import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Table,
  Row,
  Col,
  Tooltip,
  Tag,
  Input,
  Button,
  Space,
} from "antd";
import {
  AndroidFilled,
  AppleFilled,
  MobileOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import { hasRights, rights } from "../../../common/utils/role-right-helper";

class UnregisteredGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchedColumn: '',
    };
  }

  GetDeviceType = (deviceTypeId) => {
    if (deviceTypeId === "Apple") {
      return (
        <span>
          <Tooltip title="Apple">
            <AppleFilled />
          </Tooltip>
        </span>
      );
    }
    if (deviceTypeId === "Android") {
      return (
        <span>
          <Tooltip title="Android">
            <AndroidFilled />
          </Tooltip>
        </span>
      );
    }
    return (
      <span>
        <Tooltip title="Handset">
          <MobileOutlined />
        </Tooltip>
      </span>
    );
  };

  GetStatusDetails = (id, Text) => {
    const statusColors = { 1: "#ff0000", 2: "#FFBF00", 3: "#2dc937" };
    const statusIcons = {
      1: <CloseCircleOutlined />,
      2: <WarningOutlined />,
      3: <CheckCircleOutlined />,
    };
    return (
      <span style={{ margin: "5px 0" }}>
        <Tag
          style={{ width: "60px" }}
          color={statusColors[id] || "#ff0000"}
          icon={statusIcons[id] || <CloseCircleOutlined />}
        >
          {Text}
        </Tag>
      </span>
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const registeredUsersColumns = [
      {
        title: "Type",
        dataIndex: "RegistrationType",
        key: "RegistrationType",
        sorter: (a, b) => a.RegistrationType.length - b.RegistrationType.length,
        sortDirections: ["descend", "ascend"],
        filters: [
          {
            text: "WN App",
            value: "WN App",
          },
          {
            text: "Handset",
            value: "Handset",
          },
        ],
        onFilter: (value, record) => record.RegistrationType === value,
      },
      {
        title: "Device",
        dataIndex: "DeviceName",
        key: "DeviceName",
        sorter: (a, b) => a.DeviceName.length - b.DeviceName.length,
        sortDirections: ["descend", "ascend"],
        render: (value) => this.GetDeviceType(value),
        filters: [
          {
            text: "Apple",
            value: "Apple",
          },
          {
            text: "Android",
            value: "Android",
          },
          {
            text: "Handset",
            value: "Handset",
          },
        ],
        onFilter: (value, record) => record.DeviceName === value,
      },
      {
        title: "Confirmation Code",
        dataIndex: "ConfirmationCode",
        key: "ConfirmationCode",
        sorter: (a, b) => a.ConfirmationCode.length - b.ConfirmationCode.length,
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps('ConfirmationCode'),
      },
      {
        title: "",
        dataIndex: "RegistrationId",
        key: "RegistrationId",
        witdh: "4px",
        render: (text, record) =>
          this.props.registrationList.length >= 1 &&
          hasRights([rights.CanManageRegistration]) ? (
            <>
              <div>
                <Tooltip title="Actions">
                  <FontAwesomeIcon
                    className="ml-2"
                    onClick={() => this.props.openModal(record.RegistrationId, record.ConsumerId)}
                    size="1x"
                    icon={faEllipsisH}
                  />
                </Tooltip>
              </div>
            </>
          ) : null,
      },
    ];

    return (
      <>
        <div className="p5-grid-page-container">
          <Row justify="center">
            <Col span={24}>
              <Table
                dataSource={this.props.registrationList}
                columns={registeredUsersColumns}
                loading={this.props.loading}
                scroll={{ x: 700 }}
                size="large"
                rowKey="ConsumerId"
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

UnregisteredGrid.propTypes = {
    openModal: PropTypes.func,
    registrationList: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
};

UnregisteredGrid.defaultProps = {
    openModal: () => {},
    registrationList: [{}],
    loading: false,
};

function mapStateToProps(state) {
    const { registrationList, loading } = state.RegisterUserFormState;
    return {
      registrationList,
      loading,
    };  
  }

const connectedUnregisteredGrid = connect(mapStateToProps)(UnregisteredGrid);

export { connectedUnregisteredGrid as default };
