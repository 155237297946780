import React from "react";
import { connect } from "react-redux";
// import "antd/dist/antd.css";
import { Table, Tooltip } from "antd";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { history } from "../../../store";
import { hubService } from "../../../services";
import { hasRights, rights } from "../../../common/utils/role-right-helper";
import "./HubGrid.scss";

class HubGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getHubData();
  }

  getHubData() {
    hubService.getList().then((result) => {
      this.setState({
        data: result.data.value,
        loading: false,
      });
    });
  }

  editAllocation = (key) => {
    history.push(`hub/${key.Id}`);
  };

  render() {
    const columns = [
      {
        title: "Force",
        className: "sh-tour-hub-force",
        dataIndex: "LawEnforcementAgencyName",
        defaultSortOrder: "descend",
        sorter: (a, b) =>
          a.LawEnforcementAgencyName - b.LawEnforcementAgencyName,
      },  

      {
        title: 'APP',
        className: 'AppSection sh-tour-hub-app',
        children: [
          {
            title: "Start Date",
            dataIndex: "StartDate",
            className: "AppSectionLeft sh-tour-hub-start-date",
            sorter: {
              compare: (a, b) => a.StartDate - b.StartDate,
            },
            render: (text, record) =>
              this.state.data.length >= 1 ? (
                <>
                  <span>{moment(record.StartDate).format('DD MMM, YYYY')}</span>
                </>
              ) : null,
          },
          {
            title: "End Date",
            className: "sh-tour-hub-end-date",
            dataIndex: "EndDate",
            sorter: {
              compare: (a, b) => a.EndDate - b.EndDate,
            },
            render: (text, record) =>
            this.state.data.length >= 1 ? (
              <>
                <span>{moment(record.EndDate).format('DD MMM, YYYY')}</span>
              </>
            ) : null,
          },
          {
            title: "Used",     
            className: "sh-tour-hub-app-used",       
            dataIndex: "Used",
          },
          {
            title: "Remaining",
            className: "AppSectionRight sh-tour-hub-app-remaining",
            dataIndex: "Remaining",
          }
        ]
      }, 
      {
        title: 'Handset',
        className: "sh-tour-hub-handset",
        children: [
          {
            title: "Start Date",
            className: "sh-tour-hub-handset-start-date",
            dataIndex: "HandsetStartDate",
            sorter: {
              compare: (a, b) => a.HandsetStartDate - b.HandsetStartDate,
            },
            render: (text, record) =>
              this.state.data.length >= 1 ? (
                <>
                  <span>{moment(record.HandsetStartDate).format('DD MMM, YYYY')}</span>
                </>
              ) : null,
          },
          {
            title: "End Date",
            className: "sh-tour-hub-handset-end-date",
            dataIndex: "HandsetEndDate",
            sorter: {
              compare: (a, b) => a.HandsetEndDate - b.HandsetEndDate,
            },
            render: (text, record) =>
            this.state.data.length >= 1 ? (
              <>
                <span>{moment(record.HandsetEndDate).format('DD MMM, YYYY')}</span>
              </>
            ) : null,
          },
          {
            title: "Used",
            className: "sh-tour-hub-handset-used",
            dataIndex: "HandsetUsed",
          },
          {
            title: "Remaining",
            className: "sh-tour-hub-handset-remaining",
            dataIndex: "HandsetRemaining",
          }
        ]
      },
      {
        title: "",
        dataIndex: "Id",
        key: "Id",
        render: (text, record) =>
          this.state.data.length >= 1 && hasRights([rights.CanManageHub]) ? (
            <>
              <span>
                <Tooltip title={`Edit ${record.LawEnforcementAgencyName} Allocation`}> 
                  <FontAwesomeIcon
                    onClick={() => this.editAllocation(record)}
                    size="1x"
                    icon={faEdit}
                    pull="right"
                  />
                </Tooltip>
              </span>
            </>
          ) : null,   
      },
    ];

    const result = (
      <div className="hub-grid">
        <Table
          columns={columns}
          dataSource={this.state.data}
          scroll={{ x: 700 }}
          loading={this.state.loading}
          rowKey="Id"
        />
      </div>
    );

    return result;
  }
}

function mapStateToProps() {
  return {};
}

const connectedHubGrid = connect(mapStateToProps)(HubGrid);

export { connectedHubGrid as default };
