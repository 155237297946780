import UploadConstants from './constants';

const initialState = ({ loading: false, uploading: false, hasError: false, url: '' });

export default function DocumentUpload(state = initialState, action) {
  switch (action.type) {
    case UploadConstants.GET_REQUEST:
      return {
          ...state,
          loading: true,
          hasError: false,
        };
    case UploadConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        url: action.url,
      };
    case UploadConstants.GET_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    case UploadConstants.CREATE_REQUEST:
      return {
        ...state,
        uploading: true,
        hasError: false,
      };
    case UploadConstants.CREATE_SUCCESS:
      return {
        ...state,
        uploading: false,
        hasError: false,
      };
    case UploadConstants.CREATE_FAILURE:
      return {
        ...state,
        uploading: false,
        hasError: true,
      };
    default:
      return state;
  }
}
