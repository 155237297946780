import api from '../common';

function getResourceList(queryString) {
  // $select=Id,Title,Description&
  return api.get(`/odata/ResourceLibraryOData?${queryString}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getResourceTypes() {
  return api.get('/odata/ResourceTypesOData?$select=Id,Name&', { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function createResource(data) {
  return api.post('/api/ResourceLibrary/Create',data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getResource(id) {
  return api.get(`/api/ResourceLibrary/${id}`,{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
}

function deleteResource(id) {
  return api.delete(`/api/ResourceLibrary/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch(error => Promise.reject(error));
}

function updateResource(data) {
  return api.put('api/ResourceLibrary/Update',data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export default ({
  getResourceList,
  getResourceTypes,
  createResource,
  getResource,
  deleteResource,
  updateResource,
});
