import { toast } from "react-toastify";
import c from "./constants";
import {
   UserService } from "../../services";



function GetUser(data) {
  function request() {
    return { type: c.GET_FORCE_REQUEST };
  }
  function success(user) {
    return { type: c.GET_FORCE_SUCCESS, user };
  }
  function failure(error) {
    return { type: c.GET_FORCE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.getUser(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function GetUserById(data) {
  function request() {
    return { type: c.GET_USER_ID_REQUEST };
  }
  function success(user) {
    return { type: c.GET_USER_ID_SUCCESS, user };
  }
  function failure(error) {
    return { type: c.GET_USER_ID_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    
    UserService.getUserById(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function ChangePassword(data, callback) {
  function request() {
    return { type: c.UPDATE_USER_REQUEST };
  }
  function success() {
    return { type: c.UPDATE_USER_SUCCESS };
  }
  function failure(error) {
    return { type: c.UPDATE_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.changePassword(data).then(
      () => {
        dispatch(success());
        toast("Successfully Changed Password");
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

function UpdateUser(id, data) {
  function request() {
    return { type: c.UPDATE_USER_REQUEST };
  }
  function success() {
    return { type: c.UPDATE_USER_SUCCESS };
  }
  function failure(error) {
    return { type: c.UPDATE_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.updateUser(id, data).then(
      () => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}



export default {
  GetUser,
  GetUserById,
  UpdateUser,
  ChangePassword,
};
