import c from './constants';

const initialState = {
  loading: false,
  hasError: false,
  currentUser: {},
  list: {},
  error: {},
  validUsername: false,
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case c.GET_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        list: action.list,
      };
    case c.GET_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        currentUser: action.data,
      };
    case c.CREATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_USER_ID_REQUEST:
      return {
        ...state,
        currentUser: {},
        loading: true,
        hasError: false,
      };
    case c.GET_USER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        currentUser: action.user.data,
      };
    case c.GET_USER_ID_FAILURE:
      return {
        ...state,
        currentUser: {},
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.VALIDATE_USER_REQUEST:
      return {
        ...state,
        validUsername: false,
        loading: true,
        hasError: false,
      };
    case c.VALIDATE_USER_SUCCESS:
      return {
        ...state,
        validUsername: action.valid,
        loading: false,
        hasError: false,
      };
    case c.VALIDATE_USER_FAILURE:
      return {
        ...state,
        validUsername: false,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}
