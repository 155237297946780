import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Modal, Button, Row, Col, Spin } from "antd";
import TourGuideAction from "../actions";

class TourGuidelModal extends React.Component {
  componentDidMount() {
    this.props.dispatch(TourGuideAction.CheckUserActiveTourGuides());
    this.props.dispatch(TourGuideAction.GetTourListByUser());
  }

  componentDidUpdate = (oldProps) => {
    if (oldProps.userId === 0 && this.props.userId > 0) {
      this.props.dispatch(TourGuideAction.GetTourListByUser(this.props.userId));
      this.props.dispatch(TourGuideAction.CheckUserActiveTourGuides(this.props.userId));
    }
  };


  GoToTourGuide = (tourId, route) => {
    const reRoute = this.props.dispatch(push(`/${route}`));
    this.props.dispatch(TourGuideAction.GoToTourGuidePage(tourId, this.props.userId, reRoute));
  };

  render() {
    return (
      <Spin spinning={this.props.loading}>
        <Modal
          visible={this.props.TourGuideRequired && !this.props.overrideShowTourModal}
          title="Unfinised Tour guide"
          centered        
          footer={null}
          style={{ top: 20 }}
          width={1000}
        >
          <div>
            <p>You have an unfinised tour guide that need to be completed. 
              These guides are setup to help you better understand the TecSAFE system. 
              Please take a look at the list below and click `Start Tour` button to complete that tour guide.
            </p>
          </div>        
          {this.props.TourGuideList.filter(f => f.Completed === false && f.IsRequired === true).map((tour, index) => (
            <div key={`tour-${tour.Id ?? index}`}>
              <span>
                <h6>{tour.Guide.Name} - {tour.IsRequired ? "Required" : "Optional"}</h6>
              </span>
              <Row gutter={[32, 8]}>
                <Col span={16}>
                  <p>{tour.Guide.Description}</p>
                </Col>
                <Col span={8}>
                  <Button style={{width: "80%"}} onClick={() => this.GoToTourGuide(tour.Guide.Id, tour.Guide.PageRoute)}>
                    Start {tour.Guide.Name} Tour
                  </Button>
                </Col>
              </Row>
              <br />
            </div>
          ))}
        </Modal>
      </Spin>
    );
  }
}

TourGuidelModal.propTypes = {
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  TourGuideRequired: PropTypes.bool,
  overrideShowTourModal: PropTypes.bool,
  userId: PropTypes.number,
  TourGuideList: PropTypes.arrayOf(PropTypes.shape({})),
};

TourGuidelModal.defaultProps = {
  loading: false,
  dispatch: () => {},
  TourGuideRequired: false,
  overrideShowTourModal: false,
  userId: 0,
  TourGuideList: [],
};

function mapStateToProps(state) {
  const { Id } = state.AccountState.currentUser;
  const { TourGuideList, overrideShowTourModal, TourGuideRequired, loading } = state.TourGuide;
  return {
    userId: Id,
    TourGuideRequired,
    TourGuideList,
    overrideShowTourModal,
    loading,
  };
}

const connectedTourGuidelModal = connect(mapStateToProps)(TourGuidelModal);

export { connectedTourGuidelModal as default };
