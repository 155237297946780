import React from "react";
import { Tooltip } from "antd";
import {
    AndroidFilled,
    AppleFilled,
    MobileOutlined,
} from "@ant-design/icons";

const DeviceTypes = {
    Apple: "Apple",
    Android: "Android",
    Handset: "Handset",
  }

const AppleDeviceIcon = (
  <span>
    <Tooltip title="Apple">
      <AppleFilled />
    </Tooltip>
  </span>
);

const AndroidDeviceIcon = (
  <span>
    <Tooltip title="Android">
      <AndroidFilled />
    </Tooltip>
  </span>
  );

const HandsetDeviceIcon = (
  <span>
    <Tooltip title="Handset">
      <MobileOutlined />
    </Tooltip>
  </span>
);

function GetDeviceType(deviceTypeId) {
  if (deviceTypeId === DeviceTypes.Apple) return AppleDeviceIcon;
  if (deviceTypeId === DeviceTypes.Android) return AndroidDeviceIcon;
  if (deviceTypeId === DeviceTypes.Handset) return HandsetDeviceIcon;

  return null;
  };

export default {
  AppleDeviceIcon,
  AndroidDeviceIcon,
  HandsetDeviceIcon,
  GetDeviceType,
};
