import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "antd";

import { RegistrationModal, LiteRegistrationModal } from "../../../common/components/registration-modal";
import navigationActions from "../../../common/components/navigation/action";
import { RegisteredGrid, AdminRegisteredGrid, LiteAdminRegisteredGrid, LiteRegisteredGrid } from "../grid";
import { RegistrationEditModal } from '../registration-edit-modal'
import { RegisterAddButton } from '../register-add-button'
import RegisterAction from "../actions";
import TourGuideAction from "../../guide-tour/actions";
import { agencyService, AreaService } from '../../../services';
import "./RegisterPage.css";
import { hasRights, rights } from "../../../common/utils/role-right-helper";
import { IsLiteVersion, IsFullVersion } from "../../../common/utils/system-version-helper";

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class RegisterPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ForceName: null,
      isRegisterVisible: false,
      registrationData: [],
      registrationType: "",
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("register"));
    this.props.dispatch(TourGuideAction.CheckUserActiveTourGuides(this.props.userId));
    agencyService.getForceList(`$filter=Id eq ${this.props.ForceId}`).then(
      (response) => {    
        const FName = response.data.value[0].Name;
        if(this.props.AreaId !== null)
        {
          AreaService.getGridList(`$filter=Id eq ${this.props.AreaId}`).then(
            (aResponse) => {
              const AName = aResponse.data.value[0].Name;
              this.setState({                 
                ForceName: `${FName} - ${AName}`, 
              });
            }
          );
        }
        else{
          this.setState({                 
            ForceName: FName, 
          });
        }
             
      }
    );
    this.getRegistrationData();
    this.interval = setInterval(() => this.getRegistrationData(), 60000)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getRegistrationData = () => {
    let search = "$orderby=RegistrationId asc&$filter=IsRegistered eq true";
    
    if(hasRights([rights.CanManageAreaUsers]) && this.props.areaFilter !== null && this.props.areaFilter  !== 0)
      search += ` and AreaId eq ${this.props.areaFilter}`;

    if(hasRights([rights.CanManageTeamUsers]) && this.props.teamFilter !== null && this.props.teamFilter  !== 0)
      search += ` and TeamId eq ${this.props.teamFilter}`;

    this.props.dispatch(
      RegisterAction.GetRegistrationList(
        search,
        this.setRegistrationData
      )
    );
  }

  filterByArea = (id) => { 
    this.props.dispatch(RegisterAction.RegistrationAreaFilter(id));
    let search = "$orderby=RegistrationId asc&$filter=IsRegistered eq true";
    
    if(hasRights([rights.CanManageAreaUsers]) && id !== null && id  !== 0)
      search += ` and AreaId eq ${id}`;

    this.props.dispatch(
      RegisterAction.GetRegistrationList(
        search,
        this.setRegistrationData
      )
    );
  }

  filterByTeam = (id) => {
    this.props.dispatch(RegisterAction.RegistrationTeamFilter(id));
    let search = "$orderby=RegistrationId asc&$filter=IsRegistered eq true";
    
    if(hasRights([rights.CanManageAreaUsers]) && this.props.areaFilter !== null && this.props.areaFilter  !== 0)
      search += ` and AreaId eq ${this.props.areaFilter}`;

    if(hasRights([rights.CanManageTeamUsers]) && id !== null && id  !== 0)
      search += ` and TeamId eq ${id}`;

    this.props.dispatch(
      RegisterAction.GetRegistrationList(
        search,
        this.setRegistrationData
      )
    );
  }

  setRegistrationData = (data) => {
    this.setState({         
      registrationData: data 
    });   
  }

  openEditModal = (Id, ConsumerId) => {
    this.props.dispatch(RegisterAction.openRegistrationEditModal(Id, ConsumerId));
  };

  RefreshGrids = () => {
    this.getRegistrationData();
  };

  hideRegisterModal = () => {
    this.setState({ isRegisterVisible: false });
    this.RefreshGrids();
  };

  handleRegisterOk = () => {
    this.setState({ isRegisterVisible: false });
    this.RefreshGrids();
  };

  openVictimDataModal = (Id) => {
    this.props.dispatch(RegisterAction.openVictimDetailModal(Id));
  }

  render() {
    return (
      <>
        <div className="p5-grid-page-container">
          {(hasRights([rights.CanManageRegistration]) && this.props.TeamId !== null && this.props.TeamId !== 0) ? (
            <Row justify="center">
              <Col xs={24} md={24} lg={23}>
                <div>
                  <h1 className="register-grid-header">
                    {this.state.ForceName}
                    <RegisterAddButton
                      onClick={(deviceType) => this.setState({ isRegisterVisible: true, registrationType: deviceType })}
                    />
                  </h1>
                </div>
              </Col>
            </Row>
          ) : (
            <Row justify="center">
              <Col xs={24} md={24} lg={23}>
                <div>
                  <h1 className="register-grid-header">
                    {this.state.ForceName}                    
                  </h1>
                </div>
              </Col>
            </Row>            
          )}
          <Row justify="center">
            <Col xs={24} md={24} lg={23}>
              {((hasRights([rights.CanManageAreaUsers]) ||
              hasRights([rights.CanManageTeamUsers])) && IsFullVersion()) && (
                <AdminRegisteredGrid
                  openModal={this.openEditModal}
                  registrationList={this.state.registrationData}
                  filterArea={this.filterByArea}
                  filterTeam={this.filterByTeam}
                  onGridRefresh={this.RefreshGrids}
                />
              )}
              {(hasRights([rights.CanManageAreaUsers]) === false &&
              hasRights([rights.CanManageTeamUsers]) === false && IsFullVersion()) && (
                <RegisteredGrid
                  viewDetails={this.openVictimDataModal}
                  openModal={this.openEditModal}
                  registrationList={this.state.registrationData}
                  onGridRefresh={this.RefreshGrids}
                />
              )}
              {((hasRights([rights.CanManageAreaUsers]) ||
              hasRights([rights.CanManageTeamUsers])) && IsLiteVersion()) && (
                <LiteAdminRegisteredGrid
                  openModal={this.openEditModal}
                  registrationList={this.state.registrationData}
                  filterArea={this.filterByArea}
                  filterTeam={this.filterByTeam}
                  onGridRefresh={this.RefreshGrids}
                />
              )}
              {(hasRights([rights.CanManageAreaUsers]) === false &&
              hasRights([rights.CanManageTeamUsers]) === false && IsLiteVersion()) && (
                <LiteRegisteredGrid
                  viewDetails={this.openVictimDataModal}
                  openModal={this.openEditModal}
                  registrationList={this.state.registrationData}
                  onGridRefresh={this.RefreshGrids}
                />
              )}
            </Col>
          </Row>
        </div>
        <RegistrationEditModal onRefresh={this.RefreshGrids} />
        {IsLiteVersion() && (
          <LiteRegistrationModal
            onSubmit={this.addNewRegistration}
            handleOk={this.handleRegisterOk}
            handleCancel={this.hideRegisterModal}
            isVisible={this.state.isRegisterVisible}
            deviceType={this.state.registrationType}
          />
        )}
        {IsFullVersion() && (
          <RegistrationModal
            onSubmit={this.addNewRegistration}
            handleOk={this.handleRegisterOk}
            handleCancel={this.hideRegisterModal}
            isVisible={this.state.isRegisterVisible}
            deviceType={this.state.registrationType}
          />
        )}
      </>
    );
  }
}

RegisterPage.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  ForceId: PropTypes.number,
  TeamId: PropTypes.number,
  AreaId: PropTypes.number,
  teamFilter: PropTypes.number,
  areaFilter: PropTypes.number,  
  userId: PropTypes.number,
};

RegisterPage.defaultProps = {
  dispatch: () => {},
  history: {
    push: () => {},
  },
  ForceId: null,
  AreaId: null,
  TeamId: null,
  teamFilter: null,
  areaFilter: null,
  userId: 0,
};

function mapStateToProps(state) {
  const { teamFilter, areaFilter } = state.RegisterUserFormState;
  const { TeamId, ForceId, AreaId, Id } = state.AccountState.currentUser;
  return {
    teamFilter,
    areaFilter,
    TeamId,
    ForceId,
    AreaId,
    userId: Id,
  };  
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);

export { connectedRegisterPage as default };
