import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { HelperService } from '../../../services';

const { Option } = Select;

class DisabilityMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getDisabilities();
  }

  getDisabilities() {
    HelperService.getDisabilityList().then((result) => {
      this.setState({
        data: result.data,
      });
    });
  }

  render() {
    return (
      <Select
        id={this.props.id}
        className={this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
        mode="multiple"
      >
        {
          this.state.data.filter((d) => d.IncludeFreeText === false).map((opt) => (
            <Option
              key={opt.Id}
              value={opt.Id}
              title={opt.Name}
            >
              {opt.Name.trim()}
            </Option>
          ))
        }
      </Select>
    );
  }
}

DisabilityMultiSelect.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

DisabilityMultiSelect.defaultProps = {
  id: "Disabilities",
  value: null,
  className: "",
  disabled: false,
};

export { DisabilityMultiSelect as default };
