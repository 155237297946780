import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button, Tooltip } from "antd";
import {
  faFile,
  faFileWord,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import fileUploadActions from "./action";

import "./DocumentUpload.scss";

class DocumentDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.downloadRef = React.createRef();
  }

  fileDownload = (response, document) => {
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const fileName = document.File.Name;
    if (document.File.ContentType === "application/pdf") {
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank")
    } else if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      this.downloadRef.current.href = URL.createObjectURL(blob);
      this.downloadRef.current.download = fileName;
      this.downloadRef.current.click();
    }
  };

  handleDownload = (document) => {
    this.props.dispatch(
      fileUploadActions.download(document, this.fileDownload)
    );
  };

  getFileIcon = (type) => {
    const fileIcon = (
      <span>
        <FontAwesomeIcon
          style={{ fontSize: "15px", marginRight: "5px", color: "#ef4e4e" }}
          icon={faFile}
        />
      </span>
    );

    const pdfIcon = (
      <span>
        <FontAwesomeIcon
          style={{ fontSize: "15px", marginRight: "5px", color: "#ff0000" }}
          icon={faFilePdf}
        />
      </span>
    );

    const wordIcon = (
      <span>
        <FontAwesomeIcon
          style={{ ffontSize: "15px", marginRight: "5px", color: "#0078d7" }}
          icon={faFileWord}
        />
      </span>
    );

    const excelIcon = (
      <span>
        <FontAwesomeIcon
          style={{ fontSize: "15px", marginRight: "5px", color: "#1D6F42" }}
          icon={faFileExcel}
        />
      </span>
    );

    const powerPointIcon = (
      <span>
        <FontAwesomeIcon
          style={{ fontSize: "15px", marginRight: "5px", color: "#D04423" }}
          icon={faFilePowerpoint}
        />
      </span>
    );
    switch (type) {
      case "application/pdf":
        return pdfIcon;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return wordIcon;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return excelIcon;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return powerPointIcon;
      default:
        return fileIcon;
    }
  };

  render() {
    return (
      <div>
        {this.props.Documents && this.props.Documents.length > 0 && (
          <aside>
            <h4>Documents</h4>
            <ul>
              {this.props.Documents.map((document) => (
                <li
                  key={document.File.Id}
                  style={{ listStyle: "none", display: "inline-block" }}
                >
                  <div style={{ position: "relative", margin: "15px" }}>
                    <Tooltip title={`Download ${document.File.Name}`}>
                      <Button
                        className="document-btns"
                        onClick={() => this.handleDownload(document)}
                        size="large"                        
                        icon={this.getFileIcon(document.File.ContentType)}
                      >
                        {document.File.Name.length > 20 ? `${document.File.Name.substring(0, 17)}...` : document.File.Name}
                      </Button>
                    </Tooltip>
                  </div>
                </li>
              ))}
            </ul>
          </aside>
        )}
        <a
          style={{ display: "none" }}
          href={process.env.DOWNLOAD_URL}
          ref={this.downloadRef}
        >
          Download Link
        </a>
      </div>
    );
  }
}

DocumentDisplay.propTypes = {
  dispatch: () => {},
  Documents: PropTypes.arrayOf(PropTypes.shape({})),
};

DocumentDisplay.defaultProps = {
  dispatch: () => {},
  Documents: [],
};

function mapStateToProps() {
  return {};
}

const connectedDocumentDisplay = connect(mapStateToProps)(DocumentDisplay);

export { connectedDocumentDisplay as default };
