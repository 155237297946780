import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  Home,
  LoginPage,
  Account,
  NotFound,
  Sample,
  Hub,
  User,
  Registration,
  // Unregistered,
  LEA,
  Search,
  ChangePassword,
  HelpPage,
  ResourceLibrary,
  Team,
  Admin,
  Area,
  // RegistrationEditModal,
  ExtendModal,
  VictimDataModal,
  InstallPage,
  TourGuideRoute,
  TourGuideModal,
} from "./features";
import { AutoLogoutModal } from "./common/components";
import accountActions from "./features/account/actions";
// import TourGuideAction from "./features/guide-tour/actions";
import PrivateRoute from "./common/utils/private-route";
import PublicRoute from "./common/utils/public-route";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import "./App.css";
import "./OverrideStyle.scss";
import "./OverrideStyle.css";
import MFAPage from "./features/login/page/MFAPage";

class App extends React.PureComponent {
  componentDidMount() {
    this.props.dispatch(accountActions.GetCurrentUser());
    // if(this.props.auth) 
    // {
    //   this.props.dispatch(TourGuideAction.CheckUserActiveTourGuides());
    //   this.props.dispatch(TourGuideAction.GetTourListByUser());
    // }
  }

  render() {
    return (
      <>
        <ToastContainer autoclose={9000} position={toast.POSITION.TOP_RIGHT} />
        <ExtendModal />
        <VictimDataModal />
        <TourGuideModal />
        <AutoLogoutModal />
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/mfa/:UserName" component={MFAPage} />
          <Route exact path="/install" component={InstallPage} />
          <PublicRoute 
            authenticated={this.props.auth} 
            path="/account" 
            component={Account} 
          />
          <PrivateRoute
            authenticated={this.props.auth}
            path="/help"
            component={HelpPage}
          />
          <PrivateRoute
            authenticated={this.props.auth}
            path="/resourceLibrary"
            component={ResourceLibrary}
          />
          <PrivateRoute
            authenticated={this.props.auth}
            path="/force"
            component={LEA}
          />
          <PrivateRoute
            authenticated={this.props.auth}
            path="/area"
            component={Area}
          />
          <PrivateRoute
            authenticated={this.props.auth}
            path="/team"
            component={Team}
          />
          <PrivateRoute
            authenticated={this.props.auth}
            path="/sample"
            component={Sample}
          />
          <PrivateRoute
            authenticated={this.props.auth}
            path="/register"
            component={Registration}
          />
          {/* <PrivateRoute
            authenticated={this.props.auth}
            path="/unregister"
            component={Unregistered}
          /> */}
          <PrivateRoute
            authenticated={this.props.auth}
            path="/user"
            component={User}
          />
          <PrivateRoute
            authenticated={this.props.auth}
            path="/hub"
            component={Hub}
          />
          <PrivateRoute
            authenticated={this.props.auth}
            path="/profile"
            component={ChangePassword}
          />
          <PrivateRoute
            authenticated={this.props.auth}
            path="/admin"
            component={Admin}
          />
          <PrivateRoute
            authenticated={this.props.auth}
            path="/search"
            component={Search}
          />
          <PrivateRoute 
            authenticated={this.props.auth}
            path="/tour"
            component={TourGuideRoute}
          />
          <PrivateRoute
            authenticated={this.props.auth}
            path="/"
            component={Home}
          />
          
          <Route component={NotFound} />
        </Switch>
      </>
    );
  }
}

App.propTypes = {
  auth: PropTypes.bool.isRequired,
  dispatch: PropTypes.func,
};

App.defaultProps = {
  dispatch: () => {},
};

const mapStateToProps = (state) => {
  return { auth: state.AccountState.loggedIn };
};

const connectedApp = connect(mapStateToProps)(App);

export { connectedApp as default };
