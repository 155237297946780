import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Input, Tooltip } from "antd";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DynamicSelect } from "../../../common/components/dynamic-select";
import { agencyService, AreaService } from "../../../services";
import { SaveBtn, CancelBtn } from "../../../common/components/form";

import "./TeamForm.scss";

class TeamForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Id: 0,
      Name: "",
      Force: { Id: 0, Name: "" },
      Area: { Id: 0, Name: "" },
    };
  }

  pivotData = (data) => ({
    Id: this.props.Id,
    Name: data.Name,
    LawEnforcementAgencyId: data.Force.Id,
    LawEnforcementAgencyAreaId: data.Area.Id === 0 ? null : data.Area.Id,
  });

  render() {
    const ValidationSchema = Yup.object().shape({
      Id: Yup.number(),
      Name: Yup.string()
        .typeError("Name is required")
        .required("Name is required"),
    });

    return (
      <Formik
        enableReinitialize
        initialValues={{
          Id: this.props.Id || this.state.Id,
          Name: this.props.Name || this.state.Name,
          Force: this.props.Force || this.state.Force,
          Area: this.props.Area || this.state.Area,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({ values, errors, touched, setFieldValue, handleSubmit }) => (
          <Form
            autoComplete={0}
            name="TeamForm"
            id="TeamForm"
            onChange={this.handleChange}
            onSubmit={handleSubmit}
          >
            <div className="p5-form-container">
              <Row justify="center">
                {this.props.isUpdateMode ? (
                  <Col span={12}>
                    <h1 className="form-label">{this.props.title}</h1>
                  </Col>
                ) : (
                  <Col span={22}>
                    <h1 className="form-label">{this.props.title}</h1>
                  </Col>
                )}
                {this.props.isUpdateMode && (
                  <Col span={10}>
                    <Tooltip title="Delete Team">
                      <FontAwesomeIcon
                        onClick={this.props.onDelete}
                        className="team-delete-btn"
                        size="2x"
                        icon={faTrash}
                        pull="right"
                      />
                    </Tooltip>
                  </Col>
                )}
              </Row>
              <Row justify="center">
                <Col xs={24} sm={24} md={22}>
                  <Form.Item
                    className="forcePickerBox"
                    validateStatus={
                      errors && errors.Force && touched.Force ? "error" : null
                    }
                    help={errors && touched.Force && errors.Force}
                  >
                    <div className="p5-form-label">
                      <span>Force</span>
                    </div>
                    <DynamicSelect
                      hasNone={false}
                      className="p5-form-dropdown"
                      key="ForcePicker"
                      getData={agencyService.getForceList}
                      displayFieldName="Name"
                      valueFieldName="Id"
                      value={values.Force.Id}
                      onChange={(val) => {
                        setFieldValue("Force.Id", val);
                        setFieldValue("Area.Id", 0);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col xs={24} sm={24} md={22}>
                  <Form.Item
                    className="areaPickerBox"
                    validateStatus={
                      errors && errors.Area && touched.Area ? "error" : null
                    }
                    help={errors && touched.Area && errors.Area}
                  >
                    <div className="p5-form-label">
                      <span>Area</span>
                    </div>
                    <DynamicSelect
                      key="AreaPicker"
                      className="p5-form-dropdown"
                      getData={AreaService.getAreaList}
                      displayFieldName="Name"
                      valueFieldName="Id"
                      value={values.Area.Id}
                      filters={{ force: values.Force.Id }}
                      onChange={(val) => {
                        setFieldValue("Area.Id", val);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col xs={24} sm={24} md={22}>
                  <Form.Item
                    validateStatus={
                      errors && errors.Name && touched.Name ? "error" : null
                    }
                    help={errors && touched.Name && errors.Name}
                  >
                    <div className="p5-form-label">
                      <span>Team Name</span>
                    </div>
                    <Input
                      type="text"
                      id="Name"
                      className="p5-form-input"
                      value={values.Name}
                      onChange={(val) => {
                        setFieldValue("Name", val.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <br />
              <Row justify="end">
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  style={{ margin: "5px" }}
                >
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  style={{ margin: "5px" }}
                >
                  <SaveBtn
                    onClick={handleSubmit}
                    loading={this.props.loading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

TeamForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  Id: PropTypes.number,
  Name: PropTypes.string,
  Force: PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string,
  }),
  Area: PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string,
  }),
  isUpdateMode: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
};

TeamForm.defaultProps = {
  // onSubmit: () => {},
  onCancel: () => {},
  onDelete: () => {},
  Id: 0,
  Name: "",
  Force: {
    Id: 1,
    Name: "",
  },
  Area: {
    Id: 0,
    Name: "",
  },
  isUpdateMode: false,
  title: "",
  loading: false,
};

export default TeamForm;
