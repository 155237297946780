import React from 'react';
import PropTypes from 'prop-types';
import 'antd/dist/antd.css';
import {
  Modal,
  Button,
  Select,
  Tooltip,
} from 'antd';

const { Option } = Select;

class AddressCopyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
        loading: false,
        address: "",
    }
  }

  handleChange = (value) => {
    this.setState({ address: value })
  }


  onOk = () => {
    this.setState({ loading: true });
    this.props.handleOk(this.state.address);
    this.setState({ loading: false });
  };

render() {
  const { loading } = this.state;
  return (
    <>
      <Modal
        title="Copy Address"
        visible={this.props.isVisible}
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        footer={[
          <Tooltip title="Copy">
            <Button key="submit" type="primary" loading={loading} onClick={this.onOk}>
              Copy
            </Button>
          </Tooltip>,
        ]}
      >
        <Select
          onChange={this.handleChange}
          size="large"
          className="mt-3"
        >
          {
          this.props.data.map((opt) => (
            <Option
              key={opt.Address}
              value={opt.Address}
            >
              {opt.Address}, {opt.City}, {opt.County}, {opt.PostalCode}
            </Option>
          ))
        }
        </Select>
      </Modal>
    </>
  );
}
}

AddressCopyModal.propTypes = {
  isVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

AddressCopyModal.defaultProps = {
  isVisible: false,
  handleOk: () => {},
  handleCancel: () => {},
  data: [],
};


export { AddressCopyModal as default };