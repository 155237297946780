/* eslint import/prefer-default-export: 0 */
import api from '../common';


function editUser(id, data) {
  return api.put(`/api/Victim/Update/${id}`, data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function addUser(data) {
  return api.post('/api/User/Create', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getUserById(id) {
  return api.get(`/api/User/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function validateUser(username) {
  return api.get(`/api/User/Validate/${username}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteUser(id) {
  return api.delete(`/api/User/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateUser(id, data) {
  return api.put(`/api/User/${id}`, data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
}

function changePassword(data) {
  return api.post('/api/Auth/ChangePassword', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
}

function getGridList() {
  return api.get('/odata/Users',{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getUserRoles() {
  return api.get('/odata/RoleListOData',{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getForces() {
  return api.get('/odata/LawEnforcementAgencyOData?$select=Id,Name&',{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getAgencySubGroups(agencyId) {
  return api.get(`/odata/LawEnforcementAgencySubGroupOData?$filter=LawEnforcementAgencyId eq ${agencyId}&$select=Id,Name&`,{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getAgencyTeams(agencyId,areaId) {
  return api.get(`/odata/LawEnforcementAgencyTeamOData?$filter=LawEnforcementAgencyId eq ${agencyId} and LawEnforcementAgencyAreaId eq ${areaId}&$select=Id,Name&`,{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

export {
  editUser,
  addUser,
  getUserById,
  getGridList,
  updateUser,
  changePassword,
  getUserRoles,
  getForces,
  getAgencySubGroups,
  getAgencyTeams,
  deleteUser,
  validateUser,
};
