import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const IdInput = (props) => {
  return(
    <Form.Item
      validateStatus={
      props.errors && props.errors.id && props.touched.id
        ? "error"
        : null
    }
      help={props.errors && props.touched.id && props.errors.id}
    >
      <h2 className="form-label">
        Id
      </h2>
      <Input
        type="text" 
        id="Id" 
        className="form-input"
        value={props.values.id} 
        onChange={props.onChange}
      />
    </Form.Item>
  );
}

IdInput.propTypes = {
    onChange: () => {},
    errors: PropTypes.shape({
        id: PropTypes.string,
    }),
    values: PropTypes.shape({
        id: PropTypes.string,
    }),
    touched: PropTypes.shape({
        id: PropTypes.bool,
    })
}

IdInput.defaultProps = {
    onChange: () => {},
    errors: {},
    values: {},
    touched: {}
}

export default IdInput;
