import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Button, Input, Select, Tooltip } from "antd";
import { AndroidFilled, AppleFilled, MobileOutlined } from "@ant-design/icons";
import {
  // isValidPhoneNumber,
  phoneNumberCountryCodes,
  getCountryCallingCode,
  phoneNumberFormatter,
} from "../../../common/utils/phone-number-helper";

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      Id: 0,
    };
  }

  handleChange = (e) => {
    const { value, id } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  GetDeviceType = (deviceTypeId) => {
    if (deviceTypeId === 1) {
      return (
        <span>
          <Tooltip title="Apple">
            <AppleFilled />
          </Tooltip>
        </span>
      );
    }
    if (deviceTypeId === 2) {
      return (
        <span>
          <Tooltip title="Android">
            <AndroidFilled />
          </Tooltip>
        </span>
      );
    }
    return (
      <span>
        <Tooltip title="Handset">
          <MobileOutlined />
        </Tooltip>
      </span>
    );
  };

  pivotData = (data) => ({
    ConsumerId: data.ConsumerId,
    RegistrationTypeId: data.RegistrationTypeId,
    PhoneCountryCode: getCountryCallingCode(data.CountryCode),
    PhoneNumber: data.PhoneNumber.replace(/[^\d]/g, ""),
  });

  render() {
    return (
      <Formik
        innerRef={this.formRef}
        enableReinitialize
        validateOnMount
        initialValues={{
          ConsumerId: this.props.ConsumerId || null,
          RegistrationTypeId: this.props.RegistrationTypeId || null,
          CountryCode: this.props.PhoneCountryCode || null,
          PhoneNumber: this.props.PhoneNumber || null,
        }}
        validationSchema={Yup.object().shape({
          Page: Yup.string(),
          CountryCode: Yup.string()
            .typeError("Country Code is required")
            .required("Country Code is required"),
          PhoneNumber: Yup.string()
            .typeError("Phone Number is required")
            .required("Phone Number is required")
            .matches(
              /^[1-9].*$/,
              "Phone number cannot begin with a 0"
            ),
            // .test(
            //   "phoneNumberValidation",
            //   "Enter a valid phone number and country code",
            //   isValidPhoneNumber
            // ),
        })}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form
            id="editForm"
            name="editForm"
            onSubmit={handleSubmit}
            onChange={handleChange}
            autoComplete={0}
          >
            <div className="">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
                <Col xs={24} sm={24} md={12} className="gutter-row">
                  <span className="p5-modal-form-label">{`Device Type: `}</span>
                  <span className="p5-modal-text">{this.GetDeviceType(this.props.DeviceTypeId)}{` ${this.props.DeviceName}`}</span>
                </Col>
                <Col xs={24} sm={24} md={12} className="gutter-row">
                  <span className="p5-modal-form-label">{`Confirmation Code: `}</span>
                  <span className="p5-modal-text">{this.props.ConfirmationCode}</span>
                </Col>
              </Row>
              <br />
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
                <Col xs={24} sm={24} md={12} className="gutter-row">
                  <Form.Item
                    validateStatus={
                      errors && errors.CountryCode && touched.CountryCode
                        ? "error"
                        : null
                    }
                    help={errors && touched.CountryCode && errors.CountryCode}
                  >
                    <div className="p5-form-label"> Country Code </div>
                    <Select
                      showSearch
                      id="CountryCode"
                      name="CountryCode"
                      value={values.CountryCode || null}
                      placeholder="Ex. UK (+44)"
                      onChange={(v) => setFieldValue("CountryCode", v)}
                      onBlur={handleBlur}
                    >
                      {phoneNumberCountryCodes.map((code) => (
                        <Select.Option key={code} value={code}>
                          {code} (+{getCountryCallingCode(code)})
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} className="gutter-row">
                  <Form.Item
                    validateStatus={
                      errors && errors.PhoneNumber && touched.PhoneNumber
                        ? "error"
                        : null
                    }
                    help={errors && touched.PhoneNumber && errors.PhoneNumber
                      ? errors.PhoneNumber
                      : null}
                  >
                    <div className="p5-form-label"> Phone Number</div>
                    <Input
                      type="text"
                      id="PhoneNumber"
                      name="PhoneNumber"
                      value={phoneNumberFormatter(values.PhoneNumber)}
                      placeholder="Phone #"
                      onChange={(val) => {
                        setFieldValue('PhoneNumber', val.replace(/[^\d]/g, ""));
                      }}
                      onBlur={handleBlur}
                      autoComplete="Phone Number"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <br />
              <hr />
              <Row justify="end">
                <Col>
                  <Button
                    block
                    type="primary"
                    id="RegisterCLIBtn"
                    className="RegisterCLIBtn"
                    onClick={handleSubmit}
                    disabled={errors && (errors.PhoneNumber || errors.CountryCode )}
                  >
                    Register CLI
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

RegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  ConsumerId: PropTypes.number,
  PhoneCountryCode: PropTypes.string,
  PhoneNumber: PropTypes.string,
  DeviceTypeId: PropTypes.number,
  DeviceName: PropTypes.string,
  ConfirmationCode: PropTypes.string,
  RegistrationTypeId: PropTypes.number,
};

RegisterForm.defaultProps = {
  ConsumerId: 0,
  PhoneCountryCode: "",
  PhoneNumber: "",
  DeviceTypeId: 0,
  DeviceName: "",
  ConfirmationCode: "",
  RegistrationTypeId: 0,
};

export default RegisterForm;
