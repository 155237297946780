export default ({
    GET_TEAM_LIST_REQUEST: "GET_TEAM_LIST_REQUEST",
    GET_TEAM_LIST_SUCCESS: "GET_TEAM_LIST_SUCCESS",
    GET_TEAM_LIST_FAILURE: "GET_TEAM_LIST_FAILURE",
    NEW_TEAM_REQUEST: "NEW_TEAM_REQUEST",
    CREATE_TEAM_REQUEST: 'CREATE_TEAM_REQUEST',
    CREATE_TEAM_SUCCESS: 'CREATE_TEAM_SUCCESS',
    CREATE_TEAM_FAILURE: 'CREATE_TEAM_FAILURE',
    GET_TEAM_REQUEST: 'GET_TEAM_REQUEST',
    GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
    GET_TEAM_FAILURE: 'GET_TEAM_FAILURE',
    UPDATE_TEAM_REQUEST: 'UPDATE_TEAM_REQUEST',
    UPDATE_TEAM_SUCCESS: 'UPDATE_TEAM_SUCCESS',
    UPDATE_TEAM_FAILURE: 'UPDATE_TEAM_FAILURE',
    DELETE_TEAM_REQUEST: 'DELETE_TEAM_REQUEST',
    DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
    DELETE_TEAM_FAILURE: 'DELETE_TEAM_FAILURE',
  });