import api from '../common/utils/api';

function create(file){
  const form = new FormData();
  form.set('File', file, file.name);
  return api.post('api/File', form, { headers: { 'content-type': 'multipart/form-data'}, withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error));
}

function get(id) {
  return api.get(`api/File/${id}`, { responseType: 'arraybuffer', withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
}

function remove(id) {
  return api.delete(`/api/File/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getImageResize(id) {
  return api.get(`api/File/Image/${id}?width=250&height=0`, { responseType: 'arraybuffer', withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
}

export default {
  create,
  get,
  getImageResize,
  remove,
};
