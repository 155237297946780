import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const SendBtn = (props) => {
  return(
    <Button
      block
      type="submit"
      id="sendBtn"
      className="sendBtn"
      onClick={props.onClick}
    >
      Send
    </Button>
  );
}

SendBtn.propTypes = {
    onClick: PropTypes.func,
}

SendBtn.defaultProps = {
    onClick: () => {},
}

export default SendBtn;
