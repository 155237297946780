import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Col, Row, Button} from "antd";
import { ExtendForm } from "../form";
import registrationActions from "../actions";

class ExtendModal extends React.Component {
  CloseExtendModal = () => {
    const { dispatch } = this.props;
    dispatch(registrationActions.hideExtendModal());
  };

  UpdateAndExtend = (val) => {
    this.props.dispatch(registrationActions.UpdateAndExtendRegistration(val, this.handleCallback));
  };

  handleCallback = () => {
    this.props.dispatch(registrationActions.openRegistrationEditModal(this.props.data.RegistrationId, 0));
    this.CloseExtendModal();    
  }

  render() {
    return (
      <Modal
        visible={this.props.show}
        onCancel={this.CloseExtendModal}
        footer={null}
        style={{ top: 20 }}
        width={1000}
      >
        <div>
          <Row justify="center">
            <Col xs={24} md={24} lg={24}>
              <Button id="sh-hide-reg-extend-btn" style={{display: "None"}} onClick={() => this.CloseExtendModal()} />
              <ExtendForm
                loading={this.props.loading}
                onSubmit={this.UpdateAndExtend}
                onCancel={this.CloseExtendModal}                
                {...this.props.data}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

ExtendModal.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  show: PropTypes.bool,
  data: PropTypes.shape({
    RegistrationId: PropTypes.number
  }),
};

ExtendModal.defaultProps = {
  dispatch: () => {},
  loading: false,
  show: false,
  data: {
    RegistrationId: 0,
  },
};

function mapStateToProps(state) {
  const { loading, show, data } = state.RegisterUserFormState.ExtendModalData;
  return {
    loading,
    show,
    data,
  };
}

const connectedExtendtModal = connect(mapStateToProps)(ExtendModal);

export { connectedExtendtModal as default };
