import api from '../common';

function getCurrentUser() {
  return api.get('/api/Auth/CurrentUser', { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function login(data) {
  return api.post('/api/Auth/Login', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response.status === 202 ? response.status : Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
}

function verifyMFA(username, code) {
  return api.get(`/api/Auth/VerifyMFA?username=${username}&code=${code}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
  .then((response) => response)
  .catch((error) => Promise.reject(error));
}

function resendMFA(username) {
  return api.get(`/api/Auth/ResendMFA?username=${username}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
  .then((response) => response)
  .catch((error) => Promise.reject(error));
}

function logout() {
  return api.post('/api/Auth/Logout', null, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch(error => Promise.reject(error));
}

function resetPassword(data) {
  return api.post('api/Auth/ResetPassword', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function forgotPassword(data) {
  return api.post('api/Auth/ForgotPassword', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function resetLockout(data){
  return api.post(`api/Auth/ResetLockout?username=${data}`, null, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function registerUser() {
  return api.get('api/Auth/RegisterUser', { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function confirmEmail(data) {
  return api.post('/api/Auth/ConfirmUserEmail', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => (response.data))
    .catch(error => Promise.reject(error));
}

function resendConfirmLink(data) {
  return api.post('/api/Auth/GenerateConfirmationToken', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => (response.data))
    .catch(error => Promise.reject(error));
}

export default ({
  getCurrentUser,
  login,
  verifyMFA,
  resendMFA,
  logout,
  resetPassword,
  forgotPassword,
  resetLockout,
  registerUser,
  confirmEmail,
  resendConfirmLink
});
