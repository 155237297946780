import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const TitleInput = (props) => {
  return(
    <Form.Item
      validateStatus={
        props.errors && props.errors.Title && props.touched.Title
          ? "error"
          : null
      }
      help={props.errors && props.touched.Title && props.errors.Title}
    >
      <h2 className="form-label">
        Title
      </h2>
      <Input 
        type="text" 
        id="Title" 
        className="form-input"
        value={props.values.Title} 
        onChange={props.onChange}
      />
    </Form.Item>
  );
}

TitleInput.propTypes = {
  onChange: () => {},
  errors: PropTypes.shape({
    Title: PropTypes.string,
  }),
  values: PropTypes.shape({
    Title: PropTypes.string,
  }),
  touched: PropTypes.shape({
    Title: PropTypes.bool,
  })
}

TitleInput.defaultProps = {
  onChange: () => {},
  errors: {},
  values: {},
  touched: {}
}

export default TitleInput;
