import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal } from "antd";

class TourGuideStartModal extends React.Component {
  componentDidMount() {
    const tours = this.props.startTour;    
    tours.on("complete", (param) => {
      const { tour } = param;
      this.props.completeGuide();
      tour.isActive(false);
    });
  }

  startTourGuide = () => {
    this.props.hideModal();
    this.props.startTour.start();
  };

  render() {
    return (
      <Modal
        visible={this.props.showModal}
        centered
        title={this.props.title}
        footer={[
          <div>
            <Button className="button dark" onClick={this.startTourGuide}>
              Start Tour
            </Button>
          </div>,
        ]}
        style={{ top: 20 }}
        width={1000}
      >
        <div>
          <p>
            {this.props.description}
          </p>
          <p>Click `Start Tour` to begin the {this.props.tourName} tour guide.</p>
        </div>
      </Modal>
    );
  }
}

TourGuideStartModal.propTypes = {
  startTour: PropTypes.func,
  hideModal: PropTypes.func,
  completeGuide: PropTypes.func,
  showModal: PropTypes.bool,
  tourName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,

};

TourGuideStartModal.defaultProps = {
  startTour: () => {},
  hideModal: () => {},
  completeGuide: () => {},
  showModal: false,
  tourName: "",
  title: "",
  description: "",
};

function mapStateToProps() {
  return {};
}

const connectedTourGuideStartModal =
  connect(mapStateToProps)(TourGuideStartModal);

export { connectedTourGuideStartModal as default };
