import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import { DataForm } from "../form";
import actions from "../actions";
import "./DataPage.scss";

class ExtendPage extends React.Component {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("register-extend"));
    this.props.dispatch(actions.GetVictimDataById(this.props.match.params.regid));
  }

  UpdateData = (val) => {
    this.props.dispatch(actions.UpdateVictimData(val));
  };

  handleCancel = () => {
    this.props.dispatch(push(`/register`));
  };

  render() {
    return (
      <div className="p5-page-container">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <DataForm
              loading={this.props.loading}
              onSubmit={this.UpdateData}
              onCancel={this.handleCancel}
              isExtend
              {...this.props.victimData}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ExtendPage.propTypes = {
  dispatch: () => {},
  match: PropTypes.shape(),
  victimData: PropTypes.shape({}),
  loading: PropTypes.bool,
};

ExtendPage.defaultProps = {
  dispatch: () => {},
  match: {},
  victimData: {},
  loading: false,
};

function mapStateToProps(state) {
  const { victimData, loading } = state.RegisterUserFormState;
  return {
    victimData,
    loading
  };
}

const connectedDataEditPage = connect(mapStateToProps)(ExtendPage);

export default connectedDataEditPage;
