/* eslint import/prefer-default-export: 0 */
import api from "../common";

function getGenderList() {
  return api
    .get("api/Helper/GenderList", {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getUnallocatedForceList() {
  return api.get('/odata/UnallocatedForcesOData?$orderby=Name',{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getDisabilityList() {
  return api
    .get("api/Helper/DisabilityList", {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export { getGenderList, getUnallocatedForceList, getDisabilityList };
