import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Row, Col, Modal, Spin } from "antd";
// import { DeleteOutlined } from '@ant-design/icons';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import navigationActions from "../../../common/components/navigation/action";
import AreaForm from "../area-form";
import actions from "../actions";
import "./AreaPages.scss";

class AreaEditPage extends React.Component {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("area"));
    this.props.dispatch(actions.GetAreaById(this.props.match.params.id));
  }

  updateArea = (val) => {
    this.props.dispatch(actions.UpdateArea(val, this.handleCallback));
  };

  // deleteArea = (id) => {
  //   const { dispatch } = this.props;
  //   /* eslint react/prop-types: 0 */
  //   dispatch(actions.DeleteArea(id, this.handleCallback));
  // };

  handleCancel = () => {
    this.props.dispatch(push("/area"));
  };

  handleCallback = () => {
    this.props.dispatch(push("/area"));
  };

  deleteArea = () => {
    const { confirm } = Modal;
    const { area, dispatch } = this.props;

    confirm({

      icon: <FontAwesomeIcon size="2x" pull="left" icon={faTrash} style={{ color: "#EF4E4E" }} />,
      // icon: <DeleteOutlined style={{ color: "#EF4E4E" }} />,
      /* eslint react/prop-types: 0 */
      content: <span style={{textAlign:"center"}}>All Teams and Users will be deleted and all registrations will be deregistered that are associated with this Area. Are you sure want to delete Area {area.Name}?</span>,
      onOk() {
        /* eslint react/prop-types: 0 */
        dispatch(actions.DeleteArea(area.Id));
      },
    });
  };

  render() {
    return (
      <div className="p5-page-container-small">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <Spin spinning={this.props.loading}>
              <AreaForm
                loading={this.props.loading}
                onSubmit={this.updateArea}
                onCancel={this.handleCancel}
                onDelete={this.deleteArea}
                // eslint-disable-next-line react/jsx-boolean-value
                isUpdateMode={true}
                title="Edit Area"
                {...this.props.area}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { area, loading } = state.Area;
  area.Force = { Id: area.LawEnforcementAgencyId ?? 1, Name: "" };
  return {
    area,
    loading,
  };
}

const connectedAreaEditPage = connect(mapStateToProps)(AreaEditPage);

AreaEditPage.propTypes = {
  dispatch: () => {},
  match: PropTypes.shape(),
  area: PropTypes.shape({}),
  loading: PropTypes.bool,
};

AreaEditPage.defaultProps = {
  dispatch: () => {},
  match: {},
  area: {},
  loading: false,
};

export default connectedAreaEditPage;
