
const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
          enabled: false
        }
      },
      useModalOverlay: true
};

const UserManageTourSteps = [    
  {
    title: "Add New Police User",
    id: "tour-user-manage-add-icon",
    text: [
      `
      <p>
      To add a new police user, click on the 'User' page on the left side of the screen.  This will take you to the list of police users already created for your Force.       
      </p>
      <p>
      Click on the '+' icon at the top right of the User page
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-user-add-icon", on: "left" },
    beforeShowPromise: () => {
      document.getElementById("sh-show-user-grid-btn").click();
    },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          document.getElementById("sh-show-user-form-btn").click(); 
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Username",
    id: "tour-user-manage-username",
    text: [
      `
      <p>
      Type in a valid username in the agreed format for the police user you are creating.
      The agreed format is:       
      </p>
      <p>
      <ul>
      <li>Team User  -  firstname.lastname</li>
      <li>Admin  -  firstname.lastname_admin</li>
      </ul>
      </p>
      <p>
      If a username already exists in the system the field will be circled with a red line and it will state that username is already in existence.  
      It is legitimate to include a staff number to differentiate the username - i.e. firstname.lastname1234 etc.  
      </p>
      <p>
      There is validation in place to make sure you do not type in an already existing username.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-user-username", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "First Name",
    id: "tour-user-manage-first-name",
    text: [
      `
      <p>
      Add the new user's first name here.     
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-user-first-name", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Last Name",
    id: "tour-user-manage-last-name",
    text: [
      `
      <p>
      Add the new user's last name here.    
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-user-last-name", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Email",
    id: "tour-user-manage-email",
    text: [
      `
      <p>
      Add the new user's police email address here.      
      </p>
      <p>
      They will receive a 'system-generated' email to confirm their email address and enable them to create their password to access the TecSAFE portal.   
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-user-email", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Role",
    id: "tour-user-manage-role",
    text: [
      `
      <p>
      From the dropdown list you can select the new user's TecSAFE role - i.e. Team User or Admin.     
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-user-role", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Assign Force",
    id: "tour-user-manage-force",
    text: [
      `
      <p>
      From the dropdown list you can select the newly created user's Force.     
      </p>
      <p>
      You will see only your Force to choose from. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-user-force", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Assign Area",
    id: "tour-user-manage-area",
    text: [
      `
      <p>
      Where Areas have been created for your Force, from the dropdown list select the new police user's Area.     
      </p>
      <p>
      The Area Admin role can only be assigned in Forces in which Areas have been created.  
      Where no Areas have been created for your Force this will default to 'None'.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-user-area", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "Assign Team",
    id: "tour-user-manage-team",
    text: [
      `
      <p>
      Where multiple Teams have been created for your Force, from the dropdown list select the new police user's Team.
      </p>
      <p>
      Where multiple Teams have not been created, the Team name will default to the Area name when Areas have been created. 
      If no Areas have been created, the Team name defaults to the Force name.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-user-team", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Trained by PDS",
    id: "tour-user-manage-trained",
    text: [
      `
      <p>
      Ensure you tick this box to confirm the police user being created has been trained by the Police Digital Service TecSAFE team.
      You cannot create the new police user without this field being ticked.     
      </p>
      <p>
      You will see your details appear in the declaration to confirm this user has been trained by the Police Digital service TecSAFE team. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-user-trained", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "Cancel/Save",
    id: "tour-user-manage-foot-btn",
    text: [
      `
      <p>
      Once all the new police user's information has been input, click 'Save' to add the new user or 'Cancel' if you don't want to add the user at this time.    
      </p>
      <p>
      This will generate a 'systems' email to the newly created police user, confirming their email address and enabling them to create their own individual password to access the TecSAFE portal. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-user-footer-btn", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "Delete User",
    id: "tour-user-manage-delete-btn",
    arrow: false,
    text: [
      `
      <p>
      To remove a police user from the TecSAFE system, click on the 'User' page in the column on the left of the screen.    
      </p>
      <p>
      Search for the police user to be removed either by scrolling down the list of users or by clicking on the magnifying glass and typing in their TecSAFE username.   
      </p>
      <p>
      Click on the 'Edit' icon at the right side of the police users details. This opens up a new window containing that police users information. 
      </p>
      Click on the pink shaded 'Trashcan' icon in the top right corner of the window.  A confirmation box will appear asking if you are sure you want to delete .....  Click 'OK'. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-user-delete-btn", on: "bottom" },
    beforeShowPromise: () => {
      document.getElementById("sh-show-user-delete-btn").click();
    },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {
          document.getElementById("sh-hide-user-delete-btn").click();
        },
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "User Management Complete",
    id: "tour-user-manage-complete",
    text: [
      `
      <p>
      That concludes the User Management process. 
      Please select 'Complete' to this tour to mark as complete.
      </p>
      `,
    ],
    attachTo: { element: "", on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Complete",
        type: "complete",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
];

  export default {
    tourOptions,
    UserManageTourSteps
  }