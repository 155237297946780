import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { RegisterService } from '../../../services';

const { Option } = Select;

class RegistrationTypeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getRegistrationTypeSelect();
  }

  getRegistrationTypeSelect() {
    RegisterService.getRegistrationTypes().then((result) => {
      this.setState({
        data: result.data.value,
      });
    });
  }

  render() {
    return (
      <Select
        className="p5-form-dropdown sh-tour-reg-type-select"
        value={this.props.value}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
        size="middle"
      >
        {
          this.state.data.map((opt) => (
            <Option
              key={opt.Id}
              value={opt.Id}
            >
              {opt.Name}
            </Option>
          ))
        }
      </Select>
    );
  }
}

RegistrationTypeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  disabled: PropTypes.bool,
};

RegistrationTypeSelect.defaultProps = {
  value: 0,
  disabled: false,
};

export { RegistrationTypeSelect as default };
