import api from '../common';

function getGridList(query = '') {
  return api.get(`/odata/LawEnforcementAgencySubGroupOData?$select=Id,Name,LawEnforcementAgency&${query}`,{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function createArea(data) {
  return api.post('/api/LawEnforcementAgencySubGroup', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getAreaById(id) {
  return api.get(`/api/LawEnforcementAgencySubGroup/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateArea(data) {
  return api.put('/api/LawEnforcementAgencySubGroup', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteArea(id) {
  return api.delete(`/api/LawEnforcementAgencySubGroup/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getAreaList(filter) {
  return api.get(`/odata/LawEnforcementAgencySubGroupOData?$filter=LawEnforcementAgencyId eq ${filter.force}&$select=Id,Name&`,{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

export default ({
    getGridList,
    createArea,
    getAreaById,
    updateArea,
    deleteArea,
    getAreaList,
  });
  