import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout, PageHeader } from '../../common/components';
import { TeamIndexPage, TeamCreatePage, TeamEditPage } from './pages';

const TeamHeader = (
  <PageHeader title="Team Settings" />
)

const Team = ({ match }) => (
  <Layout PageHeader={TeamHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={TeamIndexPage} />   
      <Route exact path={`${match.url}/create`} component={TeamCreatePage} /> 
      <Route exact path={`${match.url}/:id`} component={TeamEditPage} /> 
    </Switch>
  </Layout>
);

Team.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Team.defaultProps = {
  match: {
    url: '',
  },
};

export default withRouter(Team);
