import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import navigationActions from "../../../common/components/navigation/action";

class SamplePage extends React.Component {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("registration"));
  }

  render() {
    return (
      <div className="homeContainer">
        <div className="textContainer">
          <h1>Tec SOS</h1>
          <p>Sample Page</p>
        </div>
      </div>
    );
  }
}

SamplePage.propTypes = {
  dispatch: PropTypes.func,
};

SamplePage.defaultProps = {
  dispatch: () => {},
};

function mapStateToProps() {
  return {};
}

const connectedSamplePage = connect(mapStateToProps)(SamplePage);

export { connectedSamplePage as default };
