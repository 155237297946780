import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Row, Col } from "antd";
import { ShepherdTour, TourMethods } from 'react-shepherd'
import { SearchSteps } from '../tour-steps';
import navigationActions from "../../../common/components/navigation/action";
import TourRegAction from "../actions";
import TourGuideStartModal from "../components/tour-guide-start-modal";
import SearchGrid from "../../search/search-grid";
import { hasRights, rights } from "../../../common/utils/role-right-helper";
import "../../search/pages/SearchPage.scss";

const { tourOptions, SearchTourSteps } = SearchSteps;

class SearchTourPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          TourStartModalshow: false,
        };
      }

  componentDidMount() {
    if(!hasRights([rights.CanSearchVictims]))
      this.props.dispatch(push("/"));

    this.props.dispatch(navigationActions.changePage("search"));
    this.setState({ TourStartModalshow: true });
    this.props.dispatch(TourRegAction.GetCurrentTourGuide(
      5,
      this.props.userId
    ));
  }

  hideTourGuideLandingModal = () => {
    this.setState({ TourStartModalshow: false });
  };

  pivotCompleteData = () => ({
    TourId: this.props.CurrentTourGuide.TourGuideId,
    UserId: this.props.userId
  });

  CompleteTourGuide = () => {
    this.props.dispatch(TourRegAction.MarkTourGuideAsCompleted(
      this.pivotCompleteData(),
      this.props.dispatch(TourRegAction.CheckUserActiveTourGuides(this.props.userId))
    ));
  }

  render() {
    return (
      <div className="p5-page-container mt-3">
        <ShepherdTour steps={SearchTourSteps} tourOptions={tourOptions}>
          <TourMethods>
            {(tourContext) => (
              <TourGuideStartModal
                startTour={tourContext}
                hideModal={this.hideTourGuideLandingModal}
                showModal={this.state.TourStartModalshow}
                completeGuide={this.CompleteTourGuide}
                tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                userId={this.props.userId}
                tourName="Search"
                title="Welcome to the Search Tour Guide"
                description="In this tour guide, you will learn about the Search section where your organisations search and view information about registered victims. We will explain each column of the grid and some important items to note while using the TecSAFE portal."
              />
            )}
          </TourMethods>
          <Row justify="center">
            <Col xs={24} md={20} lg={18}>
              <SearchGrid isLoading={this.props.loading} />
            </Col>
          </Row>
        </ShepherdTour>
      </div>
    );
  }
}

SearchTourPage.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  userId: PropTypes.number,
  CurrentTourGuide: PropTypes.shape({
    TourGuideId: PropTypes.number
  }),
};

SearchTourPage.defaultProps = {
  dispatch: () => {},
  loading: false,
  userId: 0,
  CurrentTourGuide: {
    TourGuideId: 4
  },
};

function mapStateToProps(state) {
  const { loading } = state.Search;
  const { Id } = state.AccountState.currentUser;
  const { CurrentTourGuide } = state.TourGuide;

  return {
    loading,
    userId: Id,
    CurrentTourGuide
  };
}

const connectedIndexPage = connect(mapStateToProps)(SearchTourPage);

export { connectedIndexPage as default };
