import api from '../common';

function getList(queryString) {
    return api.get(`odata/RegistrationAllocationOData?$count=true&${queryString}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
      .then(data => Promise.resolve(data))
      .catch(error => Promise.reject(error));
  }

function createAllocation(data) {
    return api.post('/api/RegistrationAllocation', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
      .then((response) => response)
      .catch((error) => Promise.reject(error));
  }

function getAllocationById(id) {
    return api.get(`/api/RegistrationAllocation/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
      .then((response) => response)
      .catch((error) => Promise.reject(error));
  }

  function updateAllocation(data) {
    return api.put('/api/RegistrationAllocation', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
      .then((response) => response)
      .catch((error) => Promise.reject(error));
  }

export default ({
    getList,
    createAllocation,
    getAllocationById,
    updateAllocation,
});
