import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from 'connected-react-router';
import "antd/dist/antd.css";
import { Layout, Row, Col, Alert } from "antd";
import "./PageHeader.scss";
import sysInfoActions from '../../../features/admin/actions';
import SearchAction from "../../../features/search/actions";
// import QuickFind from "../quick-find";

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class PageHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state= {
      VictimSearch: {
        SearchTerm: null,
      },
    }
  }

  componentDidMount() {
    this.props.dispatch(sysInfoActions.GetSystemInfo());
  }

  handleSearch = async (e) => {
    await this.setState({
      VictimSearch: {
        SearchTerm: e.SearchTerm,
      }
    });
    this.props.dispatch(
      SearchAction.Search(this.state.VictimSearch, this.handleCallback)
    );
  };

  handleCallback = () => {
    if (!window.location.href.includes('search')) {
      this.props.dispatch(push(`/search`));  
    } else {
      this.props.dispatch(push(`/`));
      this.props.dispatch(push(`/search`));
    }
  };

  render() {
    const { Content } = Layout;

    return (
      <div className="PageHeader">
        {this.props.title && (
          <Content>
            <Row>
              <Col xs={24}>
                {this.props.SystemInfoText && (  
                  <Alert
                    className='sys-info-banner'
                    type="warning"
                    banner
                    message={this.props.SystemInfoText}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} xs={24}>
                <div className="pg-header-title">
                  <span>{this.props.title}</span>
                </div>
              </Col>
              {/* <Col lg={12} sm={24} xs={24} className="mt-3">
                <QuickFind id="page-header-search" handleSearch={(e) => this.handleSearch(e)} />
              </Col> */}
            </Row>
          </Content>
        )}
        {this.props.customHeader}
      </div>
    );
  }
}

PageHeader.propTypes = {
  dispatch: PropTypes.func,
};

PageHeader.defaultProps = {
  dispatch: () => {},
};

const mapStateToProps = (props) => {
  const { noticeText } = props.SystemInfo;
  return { SystemInfoText: noticeText && noticeText.NoticeText };
}

const connectedPageHeader = connect(mapStateToProps)(PageHeader);

export { connectedPageHeader as default };
