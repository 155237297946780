import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  Row,
  Col,
  Tooltip,
  Tabs,
  Button
} from "antd";
import { ShepherdTour, TourMethods } from 'react-shepherd'
import { ResourceGrpSteps } from '../tour-steps';
import navigationActions from "../../../common/components/navigation/action";
import TourRegAction from "../actions";
import TourGuideStartModal from "../components/tour-guide-start-modal";
import "../shepardtour.css";
import { history } from "../../../store";
import ResourceGrid from "../../resourceLib/grid";
import { ResourceLibService } from "../../../services";
import "../../resourceLib/page/ResourceLib.css";
import { hasRights, rights } from "../../../common/utils/role-right-helper";

const { TabPane } = Tabs;

const { tourOptions, ResourceGrpTourSteps } = ResourceGrpSteps;

class ResourceGrpTourPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeTab: "library",
      header: 'Resource Library',
      loading: true,
      TourStartModalshow: false,
    };
  }

  componentDidMount() {
    if(!hasRights([rights.CanViewLibrary]))
      this.props.dispatch(push("/"));

    this.props.dispatch(navigationActions.changePage("resourceLib"));
    this.UpdateTabPage(this.state.activeTab);

    this.setState({ TourStartModalshow: true });
    this.props.dispatch(TourRegAction.GetCurrentTourGuide(
      6,
      this.props.userId
    ));
  }
  

  getResourceData(queryString){
    ResourceLibService.getResourceList(queryString)
      .then((result) => {
        this.setState({
          data: result.data.value,
          loading: false
        });
    });
  }

  hideTourGuideLandingModal = () => {
    this.setState({ TourStartModalshow: false });
  };

  pivotCompleteData = () => ({
    TourId: this.props.CurrentTourGuide.TourGuideId,
    UserId: this.props.userId
  });

  CompleteTourGuide = () => {
    this.props.dispatch(TourRegAction.MarkTourGuideAsCompleted(
      this.pivotCompleteData(),
      this.props.dispatch(TourRegAction.CheckUserActiveTourGuides(this.props.userId))
    ));
  }

  handleAddClick = () => { history.push('/ResourceLibrary/create'); };

  UpdateTabPage = (tab) => {
    this.setState({ activeTab: tab });
    if (tab === "library") {
      this.setState({
        header: 'Resource Library',
        loading: true
      });
      this.getResourceData("$filter=IsGoodNews eq false");
    } else {
      this.setState({
        header: 'Communications',
        loading: true
      });
      this.getResourceData("$filter=IsGoodNews eq true");
    }
  };

  render() {
    return (
      <div className="p5-page-container">
        <Button
          id="sh-show-lib-btn"
          style={{ display: "None" }}
          onClick={() => this.UpdateTabPage("library")}
        />
        <Button
          id="sh-show-news-btn"
          style={{ display: "None" }}
          onClick={() => this.UpdateTabPage("news")}
        />
        <ShepherdTour steps={ResourceGrpTourSteps} tourOptions={tourOptions}>
          <TourMethods>
            {(tourContext) => (
              <TourGuideStartModal
                startTour={tourContext}
                hideModal={this.hideTourGuideLandingModal}
                showModal={this.state.TourStartModalshow}
                completeGuide={this.CompleteTourGuide}
                tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                userId={this.props.userId}
                tourName="Resource Library/Good News"
                title="Welcome to the Resource Library/Good News Tour Guide"
                description="In this tour guide, you will learn about the Resource Library/Good News section where your organisations can view useful documents, information, and good news stories. We will explain each part of the Resource Library/Good News and some important items to note while using the TecSAFE portal."
              />
            )}
          </TourMethods>
          <Row justify="center">
            <Col xs={20} md={20} lg={18}>
              <span>
                <h1 className="p5-page-h1-header">
                  {this.state.header}
                  {hasRights([rights.CanManageLibrary]) && (
                    <Tooltip title="New Resource">
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        id="resourceLibraryAdd"
                        className="resource-add-icon"
                        alt="resourceLibraryAddIcon"
                        onClick={() => this.handleAddClick()}
                        pull="right"
                        size="1x"
                      />
                    </Tooltip>
                  )}
                </h1>
              </span>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={20} md={20} lg={18}>
              <Tabs
                className={`p5-tabs sh-tour-${this.state.activeTab}-tab`}
                size="large"
                activeKey={this.state.activeTab}
                onTabClick={(e) => this.UpdateTabPage(e)}
              >
                <TabPane
                  className="p5-tabs-pane sh-tour-resource-lib-tab"
                  tab="Resource Library"
                  key="library"
                >
                  <ResourceGrid
                    data={this.state.data}
                    loading={this.state.loading}
                    disabled
                  />
                </TabPane>
                <TabPane
                  className="p5-tabs-pane sh-tour-good-news-tab"
                  tab="Communications"
                  key="news"
                >
                  <ResourceGrid
                    data={this.state.data}
                    loading={this.state.loading}
                    disabled
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </ShepherdTour>
      </div>
    );
  }
}

ResourceGrpTourPage.propTypes = {
  dispatch: PropTypes.func,
  userId: PropTypes.number,
  CurrentTourGuide: PropTypes.shape({
    TourGuideId: PropTypes.number
  }),
};

ResourceGrpTourPage.defaultProps = {
  dispatch: () => {},
  userId: 0,
  CurrentTourGuide: {
    TourGuideId: 6
  },
};

function mapStateToProps(state) {
    const { Id } = state.AccountState.currentUser;
    const { CurrentTourGuide } = state.TourGuide;
  
    return {
      userId: Id,
      CurrentTourGuide,
    };
  }

const connectedResourceIndexPage = connect(mapStateToProps)(ResourceGrpTourPage);

export { connectedResourceIndexPage as default };
