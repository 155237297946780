/* eslint import/prefer-default-export: 0 */
import api from '../common';

function getWarningTypes() {
  return api.get('api/WarningType/GetWarningTypes', { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export {
    getWarningTypes,
};
