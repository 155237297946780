import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { RegionDropdown } from 'react-country-region-selector';

const CountyInput = (props) => {
  return(
    <Form.Item
      validateStatus={
      props.errors && props.errors.County && props.touched.County
        ? "error"
        : null
    }
      help={props.errors && props.touched.County && props.errors.County}
    >
      <h2 className="form-label">
        County
      </h2>
      <RegionDropdown
        country="United Kingdom"
        className="form-input county-dropdown"
        id="County"
        value={props.values.County}
        onChange={(val) => props.onChange(val)}
      />
    </Form.Item>
  );
}

CountyInput.propTypes = {
    onChange: () => {},
    errors: PropTypes.shape({
        County: PropTypes.string,
    }),
    values: PropTypes.shape({
        County: PropTypes.string,
    }),
    touched: PropTypes.shape({
        County: PropTypes.bool,
    })
}

CountyInput.defaultProps = {
    onChange: () => {},
    errors: {},
    values: {},
    touched: {}
}

export default CountyInput;
