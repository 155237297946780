import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Button, Input } from "antd";

class TapActivateForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      Id: 0,
    };
  }

  handleChange = (e) => {
    const { value, id } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  render() {
    return (
      <Formik
        innerRef={this.formRef}
        enableReinitialize
        validateOnMount
        initialValues={{
          ConsumerId: this.props.ConsumerId || null,
          ActivationCode: this.props.TrackAPhone || null,
        }}
        validationSchema={Yup.object().shape({
          ActivationCode: Yup.string()
            .typeError("Activation Code is invalid")
            .required("Activation Code is required")
            .length(6, "Activation Code is 6 characters"),
        })}
        onSubmit={this.props.onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form
            id="TAPActivateForm"
            name="TAPActivateForm"
            onSubmit={handleSubmit}
            onChange={handleChange}
            autoComplete={0}
          >
            <div className="">
              <br />
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col>
                  <span className="p5-form-label">Activation Code: </span>
                </Col>
                <Col xs={24} sm={24} md={12} className="gutter-row">
                  <Form.Item
                    validateStatus={
                      errors && errors.ActivationCode && touched.ActivationCode
                        ? "error"
                        : null
                    }
                    help={errors && touched.ActivationCode && errors.ActivationCode}
                  >
                    <Input
                      id="TrackAPhone"
                      name="TrackAPhone"
                      className="p5-form-input"
                      placeholder="Track@Phone Activation Code"
                      onBlur={handleBlur}
                      onChange={(v) => setFieldValue("ActivationCode", v.target.value)}
                      value={values.ActivationCode}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <hr />
              <Row gutter={[16, 8]} justify="end">
                <Col>
                  <Button
                    block
                    type="primary"
                    id="TAPActivateBtn"
                    className="TAPActivateBtn"
                    onClick={handleSubmit}
                    loading={this.props.isLoading}
                    disabled={errors && errors.ActivationCode}
                  >
                    Activate
                  </Button>
                </Col>
                <Col>
                  <Button
                    block
                    className="p5-default-secondary-btn"
                    key="Close"
                    style={{ width: "100%" }}
                    onClick={() => this.props.onCancel()}
                  >
                    Close
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

TapActivateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  ConsumerId: PropTypes.number,
  TrackAPhone: PropTypes.string,
};

TapActivateForm.defaultProps = {
  isLoading: false,
  onCancel: () => {},
  ConsumerId: 0,
  TrackAPhone: "",
};

export default TapActivateForm;
