import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Modal, Row, Col, Form, Input, Divider, Button  } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import RiskLevelSelect from "../../../common/components/risk-level-select";
import GenderSelect from "../../../common/components/gender-select";
import { phoneCodeAndNumberFormatter } from "../../../common/utils/phone-number-helper";

class MinimumDataForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      Id: 0,
      RegistrationId: 0,
      Name: "",
      GenderId: 1,
      CreatedDate: "",
      FCR_EmailAddress: "",
      FCR_PhoneNumber: "",
      CrimeRefNumber: "",
      OIC_Name: "",
      OIC_StaffId: "",
      AuthorisingOfficer: "",
      RiskLevelId: 3,
    };
  }

  pivotData = (data) => ({
    ...data,
    FCR_PhoneNumber: data.FCR_PhoneNumber.replace(/[^\d]/g, ""),
  });

  handleChange = (e) => {
    const { value, id } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  confirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to exit before completion? All data will be lost?',
      icon: <ExclamationCircleOutlined />,
      onOk: this.props.onCancel(),
      okText: 'Yes',
      cancelText: 'No',
    });
  };

  render() {
    return (
      <Formik
        innerRef={this.formRef}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.state.Id,
          RegistrationId: this.props.Id,
          Name: this.props.isTour ? "Victim Name" : this.state.Name,
          GenderId: this.state.GenderId,
          FCR_EmailAddress: this.props.isTour ? "first.last@email.com" : this.state.FCR_EmailAddress,
          FCR_PhoneNumber: this.props.isTour ? "55555555555" : this.state.FCR_PhoneNumber,
          CrimeRefNumber: this.props.isTour ? "None" : this.state.CrimeRefNumber,
          OIC_Name: this.props.isTour ? "John Doe" : this.state.OIC_Name,
          OIC_StaffId: this.props.isTour ? "00000" : this.state.OIC_StaffId,
          AuthorisingOfficer: this.props.isTour ? "John Doe" : this.state.AuthorisingOfficer,
          RiskLevelId: this.props.isTour ? 1 : this.state.RiskLevelId,
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          RegistrationId: Yup.number(),
          Name: Yup.string()
            .typeError("Name is required")
            .required("Name is Required"),
          GenderId: Yup.number(),
          FCR_EmailAddress: Yup.string()
            .email("Invalid email address"),
          FCR_PhoneNumber: Yup.string(),
          CrimeRefNumber: Yup.string()
            .typeError("Crime Ref is Required")
            .required("Crime Ref is required"),
          OIC_Name: Yup.string()
            .typeError("Officer Name is required")
            .required("Officer Name is required"),
          OIC_StaffId: Yup.string()
          .typeError("Officer Staff ID is required")
          .required("Officer Staff ID is required")
          .matches(
              /^[a-zA-Z0-9]+$/,
              "Officer Staff ID must be alphanumeric"
            ),
          AuthorisingOfficer: Yup.string()
            .typeError("Authorising Officer is required"),
          RiskLevelId: Yup.number()
            .typeError("RiskLevelId is required")
            .required("RiskLevelId is required"),
        })}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form
            id="MinimumDataFrom"
            name="MinimumDataFrom"
            onSubmit={handleSubmit}
            onChange={handleChange}
            autoComplete={0}
          >
            <div className="">
              <br />
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={18} className="gutter-row">
                  <Form.Item
                    validateStatus={
                      errors && errors.Name && touched.Name
                        ? "error"
                        : "success"
                    }
                    help={
                      errors && touched.Name && errors.Name ? errors.Name : null
                    }
                  >
                    <span className="p5-form-label required-field">Name</span>
                    <Input
                      id="Name"
                      name="Name"
                      className="p5-form-input sh-tour-reg-min-name"
                      placeholder="Name"
                      onBlur={handleBlur}
                      onChange={(v) => setFieldValue("Name", v.target.value)}
                      value={values.Name}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} className="gutter-row">
                  <Form.Item>
                    <span className="p5-form-label  required-field">Gender</span>
                    <GenderSelect
                      id="GenderId"
                      className="p5-form-dropdown sh-tour-reg-min-gender"
                      name="Gender"
                      value={values.GenderId}
                      onChange={(v) => setFieldValue("GenderId", v)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={12} className="gutter-row">
                  <Form.Item
                    validateStatus={
                      errors &&
                      errors.FCR_EmailAddress &&
                      touched.FCR_EmailAddress
                        ? "error"
                        : "success"
                    }
                    help={
                      errors &&
                      touched.FCR_EmailAddress &&
                      errors.FCR_EmailAddress
                        ? errors.FCR_EmailAddress
                        : null
                    }
                  >
                    <span className="p5-form-label">Email</span>
                    <Input
                      id="FCR_EmailAddress"
                      name="Email"
                      className="p5-form-input sh-tour-reg-min-email"
                      placeholder="Email"
                      onBlur={handleBlur}
                      onChange={(v) => setFieldValue("FCR_EmailAddress", v.target.value)}
                      value={values.FCR_EmailAddress}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} className="gutter-row">
                  <Form.Item
                    validateStatus={
                      errors && errors.FCR_PhoneNumber && touched.FCR_PhoneNumber
                        ? "error"
                        : "success"
                    }
                    help={
                      errors && touched.FCR_PhoneNumber && errors.FCR_PhoneNumber
                        ? errors.FCR_PhoneNumber
                        : null
                    }
                  >
                    <span className="p5-form-label">Phone Number</span>
                    <Input
                      id="FCR_PhoneNumber"
                      name="FCR_PhoneNumber"
                      className="p5-form-input sh-tour-reg-min-phone-num"
                      placeholder="PhoneNumber"
                      onBlur={handleBlur}
                      onChange={(v) => setFieldValue("FCR_PhoneNumber", v.target.value)}
                      value={phoneCodeAndNumberFormatter(values.FCR_PhoneNumber)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={12} className="gutter-row">
                  <Form.Item
                    validateStatus={
                      errors && errors.RiskLevelId && touched.RiskLevelId
                        ? "error"
                        : "success"
                    }
                    help={
                      errors && touched.RiskLevelId && errors.RiskLevelId
                        ? errors.RiskLevelId
                        : null
                    }
                  >
                    <span className="p5-form-label required-field">Risk Level</span>
                    <RiskLevelSelect
                      id="RiskLevelId"
                      className="p5-form-dropdown sh-tour-reg-min-risk-level"
                      name="RiskLevelId"
                      value={values.RiskLevelId}
                      onSelect={(val) => {
                        setFieldValue("RiskLevelId", val);
                      }}
                      onChange={(val) => {
                        setFieldValue("RiskLevelId", val);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} className="gutter-row">
                  <Form.Item
                    validateStatus={
                      errors && errors.CrimeRefNumber && touched.CrimeRefNumber
                        ? "error"
                        : "success"
                    }
                    help={
                      errors && touched.CrimeRefNumber && errors.CrimeRefNumber
                        ? errors.CrimeRefNumber
                        : null
                    }
                  >
                    <span className="p5-form-label required-field">Local Reference #</span>
                    <Input
                      id="CrimeRefNumber"
                      name="CrimeRefNumber"
                      className="p5-form-input sh-tour-reg-min-refnum"                      
                      placeholder='Enter "None" if unavailable'
                      onBlur={handleBlur}
                      onChange={(v) => setFieldValue("CrimeRefNumber", v.target.value)}
                      value={values.CrimeRefNumber}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={12} className="gutter-row">
                  <Form.Item
                    validateStatus={
                      errors && errors.OIC_Name && touched.OIC_Name
                        ? "error"
                        : "success"
                    }
                    help={
                      errors && touched.OIC_Name && errors.OIC_Name
                        ? errors.OIC_Name
                        : null
                    }
                  >
                    <span className="p5-form-label required-field">Officer Name</span>
                    <Input
                      id="OIC_Name"
                      name="OIC_Name"
                      className="p5-form-input sh-tour-reg-min-oic"
                      placeholder="Officer Name"
                      onBlur={handleBlur}
                      onChange={(v) => setFieldValue("OIC_Name", v.target.value)}
                      value={values.OIC_Name}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} className="gutter-row">
                  <Form.Item
                    validateStatus={
                      errors && errors.OIC_StaffId && touched.OIC_StaffId
                        ? "error"
                        : "success"
                    }
                    help={
                      errors && touched.OIC_StaffId && errors.OIC_StaffId
                        ? errors.OIC_StaffId
                        : null
                    }
                  >
                    <span className="p5-form-label required-field">Officer Staff ID</span>
                    <Input 
                      id="OIC_StaffId"
                      size="small"
                      name="OIC_StaffId"
                      className="p5-form-input sh-tour-reg-min-oic-staffid"
                      placeholder="Staff ID"
                      onBlur={handleBlur}
                      onChange={(v) => {
                        setFieldValue(
                          "OIC_StaffId",v);
                      }}
                      value={values.OIC_StaffId}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={12} className="gutter-row">
                  <Form.Item
                    validateStatus={
                      errors &&
                      errors.AuthorisingOfficer &&
                      touched.AuthorisingOfficer
                        ? "error"
                        : "success"
                    }
                    help={
                      errors &&
                      touched.AuthorisingOfficer &&
                      errors.AuthorisingOfficer
                        ? errors.AuthorisingOfficer
                        : null
                    }
                  >
                    <span className="p5-form-label">Authorising Officer</span>
                    <Input
                      id="AuthorisingOfficer"
                      name="AuthorisingOfficer"
                      className="p5-form-input sh-tour-reg-min-auth-oic"
                      placeholder="Authorising Officer"
                      onBlur={handleBlur}
                      onChange={(v) => setFieldValue("AuthorisingOfficer", v.target.value)}
                      value={values.AuthorisingOfficer}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row gutter={{ xs: 5, sm: 5, md: 5 }} justify="end">
                <Col
                  xs={24}
                  sm={24}
                  md={6}
                  lg={3}
                  xl={3}
                  className="gutter-row"
                >
                  <Button
                    className="sh-tour-reg-min-save"
                    type="primary"
                    style={{ width: "100%", marginTop: "5px" }}
                    key="Submit"
                    onClick={handleSubmit}
                    loading={this.props.isLoading}
                  >
                    Save
                  </Button>
                </Col>
                {this.props.showClose && (
                <Col
                  xs={24}
                  sm={24}
                  md={6}
                  lg={3}
                  xl={3}
                  className="gutter-row"
                >
                  <Button
                    key="Close"
                    style={{ width: "100%", marginTop: "5px" }}
                    onClick={() => this.confirm()}
                  >
                    Close
                  </Button>
                </Col>
                )}           
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

MinimumDataForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  showClose: PropTypes.bool,
  Id: PropTypes.number,
  isTour: PropTypes.bool,
};

MinimumDataForm.defaultProps = {
  onCancel: () => {},
  isLoading: false,
  showClose: true,
  Id: 0,
  isTour: false,
};

export default MinimumDataForm;
