import tourConstants from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  overrideShowTourModal: true,
  TourGuideRequired: false,
  // victimData: {},
  // regDetail: {},
  registrationList: [],
  // areaFilter: 0,
  // teamFilter: 0,
  // RegistrationEditModalData: {
  //   show: false,
  //   loading: false,
  //   hasError: false,
  //   data: {},
  // },
  // ExtendModalData: {
  //   show: false,
  //   loading: false,
  //   hasError: false,
  //   data: {},
  // },
  // VictimModalData: {
  //   show: false,
  //   loading: false,
  //   hasError: false,
  //   data: {},
  // },
  // TapActivationLoading: false,
  CurrentTourGuide: {},
  TourGuideList: [],
};

export default function tourReducer(state = initialState, action) {
  switch (action.type) {
    case tourConstants.TOUR_CHECK_ACTIVE_REQUEST:      
      return {
        ...state,
        loading: true,
        hasError: false,
        TourGuideRequired: false,
      };
    case tourConstants.TOUR_CHECK_ACTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        TourGuideRequired: action.isActive,
      };
    case tourConstants.TOUR_CHECK_ACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        TourGuideRequired: false,
      };
    case tourConstants.TOUR_REGISTRATION_LIST_REQUEST:      
      return {
        ...state,
        loading: true,
        hasError: false,
        registrationList: [],
      };
    case tourConstants.TOUR_REGISTRATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        // registrationList: action.registrationList,
      };
    case tourConstants.TOUR_REGISTRATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        registrationList: [],
      };
    case tourConstants.TOUR_GET_CURRENT_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        CurrentTourGuide: {},
        overrideShowTourModal: false,
      };
    case tourConstants.TOUR_GET_CURRENT_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        CurrentTourGuide: action.TourDetail,
        overrideShowTourModal: true,
      };
    case tourConstants.TOUR_GET_CURRENT_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        CurrentTourGuide: {},
        overrideShowTourModal: false,
      };
    case tourConstants.TOUR_GET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        TourGuideList: [],
      };
    case tourConstants.TOUR_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        TourGuideList: action.TourListDetail,
        overrideShowTourModal: action.TourListDetail.length === 0,
      };
    case tourConstants.TTOUR_GET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        TourGuideList: [],
      };
    case tourConstants.TOUR_REMOVE_MODAL_OVERRIDE:
      return {
        ...state,
        overrideShowTourModal: false,
      };
    case tourConstants.TOUR_MARK_COMPLETED_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case tourConstants.TOUR_MARK_COMPLETED_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        overrideShowTourModal: false,
        CurrentTourGuide: {},
      };
    case tourConstants.TTOUR_MARK_COMPLETED_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    default:
      return state;
  }
}
