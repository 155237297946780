import GenderConstants from "./constants";
import { HelperService } from "../../../services";

function getGenderList() {
  function request() {
    return { type: GenderConstants.LIST_REQUEST };
  }
  function success(genders) {
    return { type: GenderConstants.LIST_SUCCESS, genders };
  }
  function failure(error) {
    return { type: GenderConstants.LIST_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    HelperService.getGenderList().then(
      (genders) => {
        dispatch(success(genders.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

export default {
  getGenderList,
};
