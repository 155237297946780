import React from "react";
import PropTypes from "prop-types";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import {
  Row,
  Col,
  Input,
  Form,
  Collapse,
  Tooltip,
  Checkbox,
  DatePicker,
  Button,
  List,
  Divider,
  Modal,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPlusCircle,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMinusCircle,
} from "@fortawesome/pro-regular-svg-icons";
import "../page/RegisterUserPage.css";
import moment from "moment";
import { formatMax } from "../../../common/utils/validation-helper";
import RiskLevelSelect from "../../../common/components/risk-level-select";
import WarningTypeSelect from "../../../common/components/warning-types-select";
import AddressTypeSelect from "../../../common/components/address-type-select";
import RiskCategoriesSelect from "../../../common/components/risk-categories-select";
import { DisabilityMultiSelect } from '../../../common/components/disability-select';
import GenderSelect from "../../../common/components/gender-select";
import ImageUpload from "../../../common/components/image-upload";
import ErrorFocus from "../../../common/utils/error-focus";
import AddressCopyModal from "../../../common/components/address-copy-modal";
import { FileService, HelperService } from "../../../services";
import { CancelBtn } from "../../../common/components/form";
import { phoneCodeAndNumberFormatter } from "../../../common/utils/phone-number-helper";
import "./DataForm.scss";

class ExtendForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.formIK = React.createRef();
    this.state = {
      isCopyVisible: false,
      childIndex: 0,
      PreviewVictimId: 0,
      PreviewPerpId: 0,
      Id: 0,
      RegistrationId: 0,
      Name: "",
      GenderId: 1,
      CreatedDate: "",
      FCR_EmailAddress: "",
      FCR_PhoneNumber: "",
      CrimeRefNumber: "",
      PerpetratorName: "",
      ImageIds: [],
      VictimAddresses: [
        {
          Id: 0,
          VictimId: 0,
          AddressTypeId: 1,
          Address: "",
          PostalCode: "",
          County: "",
          City: "",
        },
      ],
      VictimPerpetratorDetails: {
        Id: 0,
        VictimId: 0,
        PerpUnknown: false,
        Name: "",
        GenderId: 1,
        NameNotKnown: false,
        DescriptionNotKnown: false,
        MONotKnown: false,
        PNCNotKnown: false,
        DeployConsidNotKnown: false,
        DOB: null,
        DOBNotKnown: false,
        PNCID: "",
        WarningTypeIds: [],
        WarningTypeNone: false,
        RiskCategories: [],
        Description: "",
        MO: "",
        CourtOrderConditions: "",
        CourtOrderConditionsNotKnown: false,
        DeploymentConsiderations: "",
        ImageIds: [],
        VictimPerpetratorVehicleDetails: [
          {
            Id: 0,
            PerpetratorId: 0,
            VRM: "",
            Make: "",
            Model: "",
            Colour: "",
          },
        ],
      },
      VictimChildren: [
        {
          Id: 0,
          VictimId: 0,
          Name: "",
          Age: 0,
          Address: "",
          PostalCode: "",
          County: "",
          City: "",
        },
      ],
      VictimVehicleDetail: [
        {
          Id: 0,
          VictimId: 0,
          VRM: "",
          Make: "",
          Model: "",
          Year: 0,
          Colour: "",
        },
      ],
      RiskLevelId: 1,
      OIC_Name: "",
      OIC_StaffID: "",
      AuthorisingOfficer: null,

      disabilityOptions: [{}],
      // isOtherDisability: false,
      edit: false,
    };
  }

  componentDidMount() {
    HelperService.getDisabilityList().then((result) => {
      this.setState({
        disabilityOptions: result.data,
      });
    });
  }

  // checkDisabilityOther = (selected) => {
  //   const data =
  //     Object.keys(
  //       this.state.disabilityOptions.filter(
  //         (d) => d.IncludeFreeText === true && d.Id === selected
  //       )
  //     ).length > 0;
  //   this.formIK.current.setFieldValue("isOtherDisability", data);
  // };

  getDisabilityName = (Id) => {
    if (Id === undefined) return null;

    const data = this.state.disabilityOptions.filter((d) => d.Id === Id);
    // this.formIK.current.setFieldValue("SelectedDisabilityName", data[0].Name);
    if (Object.keys(data).length > 0) return data[0].Name;

    return null;
  };

  pivotData = (data) => ({
    Id: this.props.Id,
    RegistrationId: this.props.RegistrationId,
    Name: data.Name,
    GenderId: data.GenderId,
    FCR_EmailAddress: data.FCR_EmailAddress,
    FCR_PhoneNumber: data.FCR_PhoneNumber.replace(/[^\d]/g, ""),
    CrimeRefNumber: data.CrimeRefNumber,
    ImageIds: data.ImageIds.map((x) => parseInt(x.src, 10)),
    VictimAddresses: data.VictimAddresses,
    VictimPerpetratorDetails: {
      Id: data.VictimPerpetratorDetails.Id,
      VictimId: data.VictimPerpetratorDetails.VictimId,
      PerpUnknown: data.VictimPerpetratorDetails.PerpUnknown,
      Name: data.PerpetratorName,
      GenderId: data.VictimPerpetratorDetails.PerpetratorGender,
      NameNotKnown: data.VictimPerpetratorDetails.NameNotKnown,
      DescriptionNotKnown: data.VictimPerpetratorDetails.DescriptionNotKnown,
      MONotKnown: data.VictimPerpetratorDetails.MONotKnown,
      PNCNotKnown: data.VictimPerpetratorDetails.PNCNotKnown,
      DeployConsidNotKnown: data.VictimPerpetratorDetails.DeployConsidNotKnown,
      Description: data.VictimPerpetratorDetails.Description,
      MO: data.VictimPerpetratorDetails.MO,
      DOB: data.VictimPerpetratorDetails.DOB ? data.VictimPerpetratorDetails.DOB.format("l") : null,
      DOBNotKnown: data.VictimPerpetratorDetails.DOBNotKnown,
      CourtOrderConditions: data.VictimPerpetratorDetails.CourtOrderConditions,
      CourtOrderConditionsNotKnown: data.VictimPerpetratorDetails.CourtOrderConditionsNotKnown,
      PNCID: data.PNC_ID,
      DeploymentConsiderations:
        data.VictimPerpetratorDetails.DeploymentConsiderations,
      ImageIds: data.VictimPerpetratorDetails.ImageIds.map((x) =>
        parseInt(x.src, 10)
      ),
      WarningTypes: data.WarningTypeIds,
      WarningTypeNone: data.WarningTypeNone,
      VictimPerpetratorVehicleDetails:
        data.VictimPerpetratorDetails.VictimPerpetratorVehicleDetails,
    },
    VictimChildren: data.VictimChildren?.map(child => ({...child, Age: parseInt(child.Age, 10)})),
    VictimVehicleDetail: data.VictimVehicleDetail,
    RiskLevelId: data.RiskLevelId,
    WarningTypeIds: data.WarningTypeIds,
    WarningTypeNone: data.WarningTypeNone,
    RiskCategories: data.RiskCategories,
    PNC_ID: data.PNC_ID,
    OIC_Name: data.OIC_Name,
    OIC_StaffID: data.OIC_StaffID,
    AuthorisingOfficer: data.AuthorisingOfficer,
    Disabilities: [...data.SelectedDisabilities, ...data.OtherDisabilities],
    DisabilitiesNone: data.DisabilityNotKnow,
  });

  changeVictimPic = (data) => {
    const { ImageIds } = this.formIK.current.values;
    const newImages = ImageIds.concat({
      src: `${data}`,
      height: 4,
      width: 3,
      selected: false,
    });
    this.formIK.current.setFieldValue("ImageIds", newImages);
  };

  changePerpPic = (data) => {
    const { VictimPerpetratorDetails } = this.formIK.current.values;
    const newImages = VictimPerpetratorDetails.ImageIds.concat({
      src: `${data}`,
      height: 4,
      width: 3,
      selected: false,
    });
    this.formIK.current.setFieldValue(
      "VictimPerpetratorDetails.ImageIds",
      newImages
    );
  };

  removeVictimImage = (id) => {
    FileService.remove(id).then(() => {
      const { ImageIds } = this.formIK.current.values;
      this.formIK.current.setFieldValue(
        "ImageIds",
        ImageIds.filter((x) => x.src !== `${id}`)
      );
    });
  };

  removePerpImage = (id) => {
    FileService.remove(id).then(() => {
      const { VictimPerpetratorDetails } = this.formIK.current.values;
      this.formIK.current.setFieldValue(
        "VictimPerpetratorDetails.ImageIds",
        VictimPerpetratorDetails.ImageIds.filter((x) => x.src !== `${id}`)
      );
    });
  };

  handleChange = (data) => {
    const { value, id } = data.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  onCopy = (value) => {
    this.setState({ isCopyVisible: false });
    const addressCopy = this.formIK.current.values.VictimAddresses.filter(
      (x) => x.Address === value
    );
    if(addressCopy[0].Address === null || addressCopy[0].Address === "" 
    || addressCopy[0].City === null || addressCopy[0].City === ""
    || addressCopy[0].PostalCode === null || addressCopy[0].PostalCode === ""
    || addressCopy[0].County === null || addressCopy[0].County === ""
    )
    {
      const { warning } = Modal;
      warning({
        content: (
          <span style={{ textAlign: "center" }}>
            This Address is missing a required data, please complete the address data before using the copy function.
          </span>
        )
      });
    }
    else {
      const childAddress = this.formIK.current;
      childAddress.setFieldValue(
        `VictimChildren[${this.state.childIndex}].Address`,
        addressCopy[0].Address
      );
      childAddress.setFieldValue(
        `VictimChildren[${this.state.childIndex}].City`,
        addressCopy[0].City
      );
      childAddress.setFieldValue(
        `VictimChildren[${this.state.childIndex}].PostalCode`,
        addressCopy[0].PostalCode
      );
      childAddress.setFieldValue(
        `VictimChildren[${this.state.childIndex}].County`,
        addressCopy[0].County
      );
    }
  };

  hasErrors = (errorField) => (
    <div style={{ color: "red" }}>
      <b>{errorField}</b>
    </div>
  );

  selectCounty(val) {
    this.setState({ County: val });
  }

  render() {
    const dateFormat = ["DD/MM/YYYY", "DD/MM/YY"];
    const { Panel } = Collapse;
    // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        initialValues={{
          Id: this.props.Id || this.state.Id,
          RegistrationId:
            this.props.RegistrationId || this.state.RegistrationIds,
          Name: this.props.Name || this.state.Name,
          GenderId: this.props.GenderId || this.state.GenderId,
          CreatedDate: "",
          FCR_EmailAddress:
            this.props.FCR_EmailAddress || this.state.FCR_EmailAddress,
          FCR_PhoneNumber:
            this.props.FCR_PhoneNumber || this.state.FCR_PhoneNumber,
          CrimeRefNumber:
            this.props.CrimeRefNumber || this.state.CrimeRefNumber,
          ImageIds:
            Object.keys(this.props.ImageIds).length > 0
              ? this.props.ImageIds.map((id) => ({
                  src: `${id}`,
                  height: 1,
                  width: 1,
                  selected: false,
                }))
              : [],
          PerpetratorName:
            this.props.VictimPerpetratorDetails.Name ||
            this.state.PerpetratorName,
          VictimAddresses: this.props.VictimAddresses
            ? this.props.VictimAddresses.map((obj) => ({
                Id: obj.Id,
                VictimId: obj.VictimId,
                AddressTypeId: obj.AddressTypeId,
                Address: obj.Address,
                PostalCode: obj.PostalCode,
                County: obj.County,
                City: obj.City,
              }))
            : this.state.VictimAddresses,
          VictimChildren: this.props.VictimChildren
            ? this.props.VictimChildren.map((obj) => ({
                Id: obj.Id,
                VictimId: obj.VictimId,
                Name: obj.Name,
                Age: obj.Age,
                Address: obj.Address,
                PostalCode: obj.PostalCode,
                County: obj.County,
                City: obj.City,
              }))
            : [],
          VictimVehicleDetail: this.props.VictimVehicleDetail
            ? this.props.VictimVehicleDetail.map((obj) => ({
                Id: obj.Id,
                VictimId: obj.VictimId,
                VRM: obj.VRM,
                Make: obj.Make,
                Model: obj.Model,
                Year: obj.Year,
                Colour: obj.Colour,
              }))
            : [],
          VictimPerpetratorDetails: {
            Id: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.Id
              : this.state.VictimPerpetratorDetails.Id,
            VictimId: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.VictimId
              : this.state.VictimPerpetratorDetails.VictimId,
            PerpUnknown:
              this.props.VictimPerpetratorDetails ?
              this.props.VictimPerpetratorDetails.PerpUnknown :
              this.state.VictimPerpetratorDetails.PerpUnknown,
            PerpetratorName: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.Name
              : this.state.VictimPerpetratorDetails.Name,
            PerpetratorGender: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.GenderId
              : this.state.VictimPerpetratorDetails.GenderId,
            DOBNotKnown: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.DOBNotKnown
              : this.state.VictimPerpetratorDetails.DOBNotKnown,
            NameNotKnown: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.NameNotKnown
              : this.state.VictimPerpetratorDetails.NameNotKnown,
            DescriptionNotKnown: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.DescriptionNotKnown
              : this.state.VictimPerpetratorDetails.DescriptionNotKnown,
            MONotKnown: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.MONotKnown
              : this.state.VictimPerpetratorDetails.MONotKnown,
            PNCNotKnown: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.PNCNotKnown
              : this.state.VictimPerpetratorDetails.PNCNotKnown,
            DeployConsidNotKnown: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.DeployConsidNotKnown
              : this.state.VictimPerpetratorDetails.DeployConsidNotKnown,
            Description: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.Description
              : this.state.VictimPerpetratorDetails.Description,
            PNC_ID: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.PNCID
              : this.state.VictimPerpetratorDetails.PNCID,
            DOB:
              this.props.VictimPerpetratorDetails &&
              this.props.VictimPerpetratorDetails.DOB
                ? moment(this.props.VictimPerpetratorDetails.DOB)
                : this.state.VictimPerpetratorDetails.DOB,
            MO: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.MO
              : this.state.VictimPerpetratorDetails.MO,
            CourtOrderConditions: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.CourtOrderConditions
              : this.state.VictimPerpetratorDetails.CourtOrderConditions,
              CourtOrderConditionsNotKnown: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.CourtOrderConditionsNotKnown
              : this.state.VictimPerpetratorDetails.CourtOrderConditionsNotKnown,
            DeploymentConsiderations: this.props.VictimPerpetratorDetails
              ? this.props.VictimPerpetratorDetails.DeploymentConsiderations
              : this.state.VictimPerpetratorDetails.DeploymentConsiderations,
            ImageIds:
              this.props.VictimPerpetratorDetails &&
              this.props.VictimPerpetratorDetails.ImageIds
                ? this.props.VictimPerpetratorDetails.ImageIds.map((id) => ({
                    src: `${id}`,
                    height: 1,
                    width: 1,
                    selected: false,
                  }))
                : [],
            VictimPerpetratorVehicleDetails:
              this.props.VictimPerpetratorDetails &&
              this.props.VictimPerpetratorDetails
                .VictimPerpetratorVehicleDetails
                ? this.props.VictimPerpetratorDetails.VictimPerpetratorVehicleDetails.map(
                    (obj) => ({
                      Id: obj.Id,
                      PerpetratorId: obj.PerpetratorId,
                      VRM: obj.VRM,
                      Make: obj.Make,
                      Model: obj.Model,
                      Year: obj.Year,
                      Colour: obj.Colour,
                    })
                  )
                : [],
          },
          RiskLevelId: this.props.RiskLevelId || this.state.RiskLevelId,
          PNC_ID: this.props.VictimPerpetratorDetails
            ? this.props.VictimPerpetratorDetails.PNCID
            : this.state.VictimPerpetratorDetails.PNCID,
          WarningTypeIds:
            this.props.VictimPerpetratorDetails.WarningTypes || [],
          WarningTypeNone:
            this.props.VictimPerpetratorDetails.WarningTypeNone ||
            this.state.VictimPerpetratorDetails.WarningTypeNone,
          RiskCategories: this.props.RiskCategories || [],
          Disabilities: this.props.Disabilities || [],
          DisabilityNotKnow: this.props.DisabilitiesNone || false,
          SelectedDisabilities: this.props.Disabilities.filter((d) => d.DisabilityId !== 7) || [],
          OtherDisabilities: this.props.Disabilities.filter((d) => d.DisabilityId === 7) || [],
          OtherDisabilityDetail: "",
          OIC_Name: this.props.OIC_Name || this.state.OIC_Name,
          OIC_StaffID: this.props.OIC_StaffID || this.state.OIC_StaffID,
          AuthorisingOfficer:
            this.props.AuthorisingOfficer || this.state.AuthorisingOfficer,
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string()
            .typeError("Name is required")
            .required("Name is required"),
          FCR_EmailAddress: Yup.string().email("Invalid email address"),
          FCR_PhoneNumber: Yup.string(),
          CrimeRefNumber: Yup.string()
            .typeError("Local Reference # is required")
            .required("Local Reference # is required"),
          RiskCategories: Yup.array()
            .of(Yup.number())
            .min(1, "At least 1 risk category required"),
          VictimAddresses: Yup.array()
            .of(
              Yup.object()
                .shape({
                  Address: Yup.string()
                    .typeError("Address Line is required")
                    .required("Address Line is required")
                    .max(255, formatMax(255)),
                  PostalCode: Yup.string()
                    .typeError("Postal Code is required")
                    .required("Postal Code is required"),
                  City: Yup.string()
                    .typeError("City is required")
                    .required("City is required"),
                })
                .required("Address Required")
            )
            .required("Address Required"),
          VictimChildren: Yup.array()
          .of(
            Yup.object()
              .shape({
                Name: Yup.string(),
                Age: Yup.number()
                  .typeError("Please enter only whole numbers for Age.")
                  .integer("Please enter only whole numbers for Age."),
                Address: Yup.string(),
                City: Yup.string(),
                County: Yup.string(),
                PostalCode: Yup.string()
              })
          ),
          DOB: Yup.date().min(new Date(), "Invalid DOB"),
          PerpetratorName: Yup.string()
            .typeError("Perpetrator Name is required")
            .required("Perpetrator Name is required"),
          PNC_ID: Yup.string()
            .typeError("PNC_ID is required")
            .required("PNC_ID is required"),
          OIC_Name: Yup.string()
            .typeError("OIC Name is required")
            .required("OIC Name is required"),
          OIC_StaffID: Yup.string()
            .typeError("Staff ID # is required")
            .required("Staff ID # is required")
            .matches(/^[a-zA-Z0-9]+$/, "Officer Staff ID must be alphanumeric"),
          // SelectedDisabilities: Yup.number(),
          OtherDisabilityDetail: Yup.string()
          .nullable(),
          DisabilityNotKnow: Yup.bool(),
          SelectedDisabilities: Yup.array()
          .of(
            Yup.object().shape({}))
            .when("DisabilityNotKnow", {
              is: (isNone) => isNone === true,
              then: Yup.array()
              .of(
                Yup.object().shape({})),
              otherwise: Yup.array()
              .of(
                Yup.object().shape({})               
                )
                .test("DisabilityTest","Disability selection is required, else check 'None'", (value) => {
                  const { OtherDisabilities } = this.formIK.current.values;
                  if(Object.keys(value).length > 0 || Object.keys(OtherDisabilities).length > 0)
                    return true;
                  
                  return false;
                })
            }),
        })}
        onSubmit={(values) => {
          if (this.props.IsTour)
            console.log('this action is disabled during tour');
          else
            this.props.onSubmit(this.pivotData(values));
        }}
        render={({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <Form
            id="registerForm"
            name="registerForm"
            onSubmit={handleSubmit}
            onChange={handleChange}
            autoComplete={0}
          >
            {this.props.IsTour && (
              <Button id="sh-hide-reg-extend-modal" style={{display: "None"}} onClick={() => this.props.onCancel()} />
            )}
            <div style={{ marginTop: "25px" }}>
              <Row>
                <Col xs={24} sm={24} md={18}>
                  <h1 className="p5-form-h1-header">Extend Registration</h1>
                </Col>
                <Col xs={24} sm={24} md={6}>
                  <Button
                    block
                    type="submit"
                    id="extendBtn"
                    className="extendBtn"
                    onClick={handleSubmit}
                    loading={this.props.loading}
                  >
                    Save and Extend
                  </Button>
                </Col>
              </Row>
              <hr />
              <Row justify="end">
                <Col xs={24} sm={24} md={14} lg={10}>
                  <h4 className="p5-h4">
                    {`Date of Entry: ${moment(this.props.CreatedDate)
                      .format("DD/MM/YYYY h:mm:ss")
                      .toString()}`}{" "}
                  </h4>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={18} className="gutter-row sh-tour-vic-name">
                  <Form.Item
                    validateStatus={
                      errors && errors.Name && touched.Name ? "error" : null
                    }
                    help={errors && touched.Name && errors.Name}
                  >
                    <div className="p5-form-label"> Name </div>
                    <Input
                      type="text"
                      id="Name"
                      className="p5-form-input"
                      name="Name"
                      placeholder="Name"
                      value={values.Name}
                      onChange={(val) => {
                        setFieldValue("Name", val);
                      }}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} className="gutter-row sh-tour-vic-gender">
                  <Form.Item>
                    <div className="p5-form-label"> Gender </div>
                    <GenderSelect
                      className="p5-form-dropdown"
                      name="Gender"
                      value={values.GenderId}
                      onChange={(val) => {
                        setFieldValue("GenderId", val);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={24} className="sh-tour-vic-image">
                  <ImageUpload
                    Id={values.ImageIds.length === 1 ? parseInt(values.ImageIds[0].src, 10) : null}
                    Images={values.ImageIds}
                    onUpload={this.changeVictimPic}
                    onRemove={this.removeVictimImage}
                    multiple
                  />
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-vic-risk-lvl" style={{marginBottom: "20px"}}>
                  <div className="p5-form-label"> Risk Level </div>
                  <RiskLevelSelect
                    className="p5-form-dropdown"
                    name="RiskLevelId"
                    value={values.RiskLevelId}
                    onSelect={(val) => {
                        setFieldValue("RiskLevelId", val);
                      }}
                    onChange={(val) => {
                      setFieldValue("RiskLevelId", val);
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-vic-risk-cat" style={{marginBottom: "20px"}}>
                  <Form.Item
                    validateStatus={
                      errors && errors.RiskCategories && touched.RiskCategories
                        ? "error"
                        : "success"
                      }
                    help={
                      errors && touched.RiskCategories && errors.RiskCategories ? errors.RiskCategories : null
                    }
                  >
                    <div className="p5-form-label"> Risk Categories </div>
                    <RiskCategoriesSelect
                      touched={touched.RiskCategories}
                      className="p5-form-dropdown"
                      name="RiskCategories"
                      value={values.RiskCategories}
                      onBlur={handleBlur}
                      onChange={(val) => {
                        setFieldValue("RiskCategories", val);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-vic-disability" style={{marginBottom: "20px"}}> 
                  <Form.Item
                    validateStatus={
                      errors && errors.SelectedDisabilities && touched.SelectedDisabilities
                        ? "error"
                        : "success"
                      }
                    help={
                      errors && touched.SelectedDisabilities && errors.SelectedDisabilities ? errors.SelectedDisabilities : null
                    }
                  >
                    <div className="p5-form-label d-flex">Disabilities
                      <div className="ml-3">
                        <Checkbox
                          checked={values.DisabilityNotKnow}
                          value={values.DisabilityNotKnow}
                          onChange={(val) => {
                            setFieldValue('DisabilityNotKnow', val.target.checked);                            
                            if(val.target.checked && Object.keys(values.Disabilities).length > 0)
                            {
                                this.disabilityClearWarning();
                            }                                                            
                          }}
                        >
                          None/Not Disclosed
                        </Checkbox>
                      </div>
                    </div>
                    <DisabilityMultiSelect
                      id="SelectedDisabilities"
                      touched={touched.Disabilities}
                      className="p5-form-dropdown"
                      name="DisabilityMultiSelect"
                      value={Object.keys(values.SelectedDisabilities).length > 0 ?
                        values.SelectedDisabilities.map(a => a.DisabilityId) : []}
                      disabled={values.DisabilityNotKnow}
                      onBlur={handleBlur}
                      onChange={(val) => {
                        const dis = [];
                        val.map((d) => {
                          const match = values.Disabilities.find(f => f.DisabilityId === d) || {};
                          const temp ={
                            Id: 0,
                            DisabilityId: d,
                            OtherDetail: null,
                          };
                          if(Object.keys(match).length > 0) {
                            dis.push(match);
                          } else {
                            dis.push(temp);
                          }                          
                          return dis;
                        });
                        setFieldValue("SelectedDisabilities", dis);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-vic-disability-other" style={{marginBottom: "20px"}}>
                  <Form.Item
                    validateStatus={
                      errors && errors.OtherDisabilityDetail
                        ? "error"
                        : null
                    }
                    help={
                      errors && errors.OtherDisabilityDetail
                    }
                  >
                    <div style={{marginTop: "39px"}}>
                      <Input
                        className="p5-form-input"
                        id="OtherDisability"
                        name="OtherDisability"
                        onBlur={handleBlur}
                        value={values.OtherDisabilityDetail}
                        disabled={values.DisabilityNotKnow}
                        placeholder="Add Other Disabilities"
                        onChange={(val) => {
                          setFieldValue("OtherDisabilityDetail", val.target.value);
                        }}
                      />
                    </div>
                  </Form.Item>  
                  <FieldArray 
                    name="OtherDisabilities"
                    render={(arrayHelper) => (
                      <>                        
                        <Row gutter={{ xs: 8, sm: 16, md: 15, lg: 15 }}>
                          <Col xs={24} sm={24} md={12} className="gutter-row">
                            <div>
                              <Button
                                style={{margin: "auto 5px"}}
                                type="primary"
                                disabled={(values.OtherDisabilityDetail === null || values.OtherDisabilityDetail === "" || values.DisabilityNotKnow)}
                                onClick={() => {
                                  arrayHelper.push({
                                    Id: 0,
                                    DisabilityId: 7,
                                    OtherDetail: values.OtherDisabilityDetail
                                  })
                                  setFieldValue("OtherDisabilityDetail", null);
                                }}
                              >
                                Add
                              </Button>
                              <Button
                                danger
                                style={{margin: "auto 5px"}}
                                disabled={(values.OtherDisabilityDetail === null || values.OtherDisabilityDetail === "" || values.DisabilityNotKnow)}
                                onClick={() => {
                                  setFieldValue("OtherDisabilityDetail", null);
                                }}
                              >
                                Clear
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Divider orientation="left" plain>
                          Other Disabilities
                        </Divider>
                        {values.OtherDisabilities.map((disability, index) => (
                          <div id={`Disabilities-${index + 0}`} key={`Disabilities-${index + 0}`}>
                            <List>
                              <Row>
                                <Col>
                                  <span className="meta-data-header" style={{marginRight: "10px"}}>
                                    <Button 
                                      type="link"  
                                      danger                             
                                      key={`Disabilities-Remove-${index + 0}`}
                                      onClick={() => {
                                        arrayHelper.remove(index);
                                      }}
                                    >
                                      <Tooltip placement="right" title={`Remove ${this.getDisabilityName(disability.DisabilityId)}${disability.OtherDetail !== null ? ` - ${disability.OtherDetail}` : ``}`}>
                                        <FontAwesomeIcon
                                          icon={faMinusCircle}
                                          id=""
                                          className=""
                                          alt=""
                                        />
                                      </Tooltip>                                    
                                    </Button>
                                  </span>
                                  <span className="meta-data-header">{disability.OtherDetail !== null ? `${disability.OtherDetail}` : ``}</span>
                                </Col>                                
                              </Row>
                            </List>                            
                          </div>
                        ))}
                      </>                                  
                    )}                    
                  />
                </Col>
              </Row>     
              <Divider />         
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-vic-email">
                  <Form.Item
                    validateStatus={
                      errors && errors.FCR_EmailAddress && touched.FCR_EmailAddress
                        ? "error"
                        : null
                    }
                    help={
                      errors && touched.FCR_EmailAddress && errors.FCR_EmailAddress
                    }
                  >
                    <div className="p5-form-label"> Email </div>
                    <Input
                      className="p5-form-input"
                      id="FCR_EmailAddress"
                      name="FCR_EmailAddress"
                      value={values.FCR_EmailAddress}
                      placeholder="Email Address"
                      onChange={(val) => {
                          setFieldValue("FCR_EmailAddress", val);
                      }}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-vic-phone">              
                  <Form.Item
                    validateStatus={
                      errors && errors.FCR_PhoneNumber && touched.FCR_PhoneNumber
                        ? "error"
                        : null
                    }
                    help={errors && touched.FCR_PhoneNumber && errors.FCR_PhoneNumber}
                  >
                    <div className="p5-form-label"> Phone Number</div>
                    <Input
                      type="text"
                      className="p5-form-input"
                      id="FCR_PhoneNumber"
                      name="FCR_PhoneNumber"
                      value={phoneCodeAndNumberFormatter(values.FCR_PhoneNumber)}
                      placeholder="Phone #"
                      onChange={(val) => {
                          setFieldValue("FCR_PhoneNumber", val);
                      }}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-vic-ref-num">  
                  <Form.Item
                    validateStatus={
                      errors && errors.CrimeRefNumber && touched.CrimeRefNumber
                        ? "error"
                        : null
                    }
                    help={errors && touched.CrimeRefNumber && errors.CrimeRefNumber}
                  >
                    <div className="p5-form-label"> Local Reference # </div>
                    <Input
                      id="CrimeRefNumber"
                      className="p5-form-input"
                      name="CrimeRefNumber"
                      value={values.CrimeRefNumber}
                      placeholder="Enter 'None' if unavailable"
                      onChange={(val) => {
                        setFieldValue("CrimeRefNumber", val);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Collapse>
                <Panel id="VictimAddresses" header={errors.VictimAddresses ? this.hasErrors('Address *') : 'Address *'} key="1">                  
                  <FieldArray
                    name="VictimAddresses"
                    render={(arrayHelpers) => (
                      <div>
                        {values.VictimAddresses &&
                        values.VictimAddresses.length > 0 ? (
                          values.VictimAddresses.map((address, index) => (
                            <div key={`addr-${index + 1}`}>
                              <Row>
                                <Col xs={20} sm={20} md={22}>
                                  <h4 className="p5-form-h4-header">
                                    {`Address #${index + 1}`}
                                  </h4> 
                                </Col>                                
                                {values.VictimAddresses.length > 1 && (
                                  <Col xs={4} sm={4} md={2}>
                                    <button
                                      key={`addr-delete-btn-${index + 1}`}
                                      type="button"
                                      className="btn btn-outline-danger"
                                      onClick={() =>
                                        arrayHelpers.remove(index)}
                                    >
                                      <FontAwesomeIcon
                                        key={`addr-delete-input-${index + 1}`}
                                        size="1x"
                                        icon={faTrash}
                                      />
                                    </button>
                                  </Col>
                                  )}                                
                              </Row>
                              <hr />
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xs={24} sm={24} md={6} className={`gutter-row sh-tour-vic-addr-type-${index}`}>
                                  <div className="p5-form-label">                                          
                                    Type
                                  </div>
                                  <AddressTypeSelect
                                    className="p5-form-dropdown"
                                    key={`addr-type-input-${index + 1}`}
                                    value={address.AddressTypeId}
                                    onChange={(val) => {
                                      setFieldValue(
                                        `VictimAddresses[${index}].AddressTypeId`,
                                        val
                                      );
                                    }}
                                  />
                                </Col>
                                <Col xs={24} sm={24} md={18} className={`gutter-row sh-tour-vic-addr-${index}`}>
                                  <div className="p5-form-label">                                          
                                    Address
                                  </div>
                                  <Form.Item
                                    key={`addr-street-form-item-${index + 1}`}
                                    touched={`VictimAddresses[${index}].Address`}
                                    validateStatus={
                                      errors.VictimAddresses &&
                                      errors.VictimAddresses[index] &&
                                      errors.VictimAddresses[index].Address &&
                                      touched.VictimAddresses &&
                                      touched.VictimAddresses[index]
                                        ? "error"
                                        : ""
                                    }
                                  >
                                    <Input
                                      key={`addr-street-input-${index + 1}`}
                                      id={`VictimAddresses[${index}].Address`}
                                      className="p5-form-input"
                                      name={`VictimAddresses[${index}].Address`}
                                      value={address.Address}
                                      placeholder="123 Example St"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xs={24} sm={24} md={12} className={`gutter-row sh-tour-vic-addr-city-${index}`}>
                                  <div className="p5-form-label">                                          
                                    City/Town
                                  </div>
                                  <Form.Item
                                    key={`addr-city-form-item-${index + 1}`}
                                    touched={`VictimAddresses[${index}].City`}
                                    validateStatus={
                                      errors.VictimAddresses &&
                                      errors.VictimAddresses[index] &&
                                      errors.VictimAddresses[index].City &&
                                      touched.VictimAddresses &&
                                      touched.VictimAddresses[index]
                                        ? "error"
                                        : ""
                                    }
                                  >
                                    <Input
                                      key={`addr-city-input-${index + 1}`}
                                      id={`VictimAddresses[${index}].City`}
                                      className="p5-form-input"
                                      name={`VictimAddresses[${index}].City`}
                                      value={address.City}
                                      placeholder="City/Town"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} className={`gutter-row sh-tour-vic-addr-post-code-${index}`}>
                                  <div className="p5-form-label">                                          
                                    Postal Code
                                  </div>
                                  <Form.Item
                                    key={`addr-postal-form-item-${index + 1}`}
                                    touched={`VictimAddresses[${index}].PostalCode`}
                                    validateStatus={
                                      errors.VictimAddresses &&
                                      errors.VictimAddresses[index] &&
                                      errors.VictimAddresses[index]
                                        .PostalCode &&
                                      touched.VictimAddresses &&
                                      touched.VictimAddresses[index]
                                        ? "error"
                                        : ""
                                    }
                                  >
                                    <Input
                                      key={`addr-postalcode-input-${index + 1}`}
                                      id={`VictimAddresses[${index}].PostalCode`}
                                      className="p5-form-input"
                                      name={`VictimAddresses[${index}].PostalCode`}
                                      value={address.PostalCode}
                                      placeholder="Postal Code"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>  
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xs={24} sm={24} md={12} className={`gutter-row sh-tour-vic-addr-region-${index}`}>
                                  <div className="p5-form-label">                                          
                                    County/Borough
                                  </div>
                                  <Form.Item>
                                    <Input
                                      key={`addr-county-input-${index + 1}`}
                                      id={`VictimAddresses[${index}].County`}
                                      className="p5-form-input"
                                      name={`VictimAddresses[${index}].County`}
                                      value={address.County}
                                      placeholder="County"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>   
                              {values.VictimAddresses.length === 1 ? (
                                <button
                                  type="button"
                                  className="btn btn-outline-success mt-1"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      Id: 0,
                                      AddressTypeId: 1,
                                      Address: "",
                                      City: "",
                                      County: "",
                                      PostalCode: ""
                                    })}
                                >
                                  <FontAwesomeIcon
                                    size="1x"
                                    icon={faPlusCircle}
                                  />
                                </button>
                              ) : (
                                values.VictimAddresses.length ===
                                  index + 1 && (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-outline-success mt-1"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          Id: 0,
                                          AddressTypeId: 3,
                                          Address: "",
                                          City: "",
                                          County: "",
                                          PostalCode: ""
                                        })}
                                    >
                                      <FontAwesomeIcon
                                        size="1x"
                                        icon={faPlusCircle}
                                      />
                                    </button>
                                  </>
                                )
                              )}
                            </div>
                          ))
                        ) : (
                          <button
                            className="btn btn-outline-danger"
                            type="button"
                            onClick={() => arrayHelpers.push({
                              Id: 0,
                              AddressTypeId: 1,
                              Address: "",
                              City: "",
                              County: "",
                              PostalCode: ""
                            })}
                          >
                            <FontAwesomeIcon
                              size="1x"
                              icon={faPlusCircle}
                            />
                          </button>
                        )}
                      </div>
                    )}
                  />
                </Panel>
                <Panel id="VictimChildren" header={errors.VictimChildren ? this.hasErrors('Children') : 'Children'} key="2">
                  <FieldArray
                    name="VictimChildren"
                    render={(arrayHelpers) => (
                      <div>
                        {values.VictimChildren &&
                        values.VictimChildren.length > 0 ? (
                          values.VictimChildren.map((child, index) => (
                            <div id={index} key={`child-${index + 1}`}>
                              <Row>
                                <Col xs={20} sm={20} md={22}>
                                  <h4 className="p5-form-h4-header">
                                    {`Child #${index + 1}`}
                                  </h4> 
                                </Col>                                
                                {values.VictimChildren.length > 1 && (
                                  <Col xs={4} sm={4} md={2}>
                                    <button
                                      key={`child-delete-btn-${index + 1}`}
                                      type="button"
                                      className="btn btn-outline-danger"
                                      onClick={() =>
                                        arrayHelpers.remove(index)}
                                    >
                                      <FontAwesomeIcon
                                        key={`child-delete-input-${index + 1}`}
                                        size="1x"
                                        icon={faTrash}
                                      />
                                    </button>
                                  </Col>
                                  )}                                
                              </Row>
                              <hr />
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xs={24} sm={24} md={18} className={`gutter-row sh-tour-vic-child-name-${index}`}>
                                  <Form.Item
                                    touched={`VictimChildren[${index}].Name`}                                  
                                  >
                                    <div className="p5-form-label"> Name</div>
                                    <Input
                                      key={`child-name-input-${index + 1}`}
                                      id={`VictimChildren[${index}].Name`}
                                      className="p5-form-input"
                                      name={`VictimChildren[${index}].Name`}
                                      value={child.Name}
                                    />
                                  </Form.Item>
                                </Col> 
                                <Col xs={24} sm={24} md={6} className={`gutter-row sh-tour-vic-child-age-${index}`}>
                                  <Form.Item
                                    validateStatus={
                                      errors && errors.VictimChildren?.[index].Age && touched.VictimChildren?.[index].Age ? "error" : null
                                    }
                                    help={errors && touched.VictimChildren?.[index].Age && errors.VictimChildren?.[index].Age}
                                  >
                                    <div className="p5-form-label"> Age</div>
                                    <Input
                                      key={`child-age-input-${index + 1}`}
                                      id={`VictimChildren[${index}].Age`}
                                      className="p5-form-input"
                                      name={`VictimChildren[${index}].Age`}
                                      value={child.Age}
                                    />
                                  </Form.Item>
                                </Col>                                  
                              </Row>
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xs={24} sm={24} md={24} className={`gutter-row sh-tour-vic-child-addr-${index}`}>                                  
                                  <Form.Item
                                    touched={`VictimChildren[${index}].Address`}
                                    validateStatus={
                                      errors.VictimChildren &&
                                      errors.VictimChildren[index] &&
                                      errors.VictimChildren[index].Address &&
                                      touched.VictimChildren &&
                                      touched.VictimChildren[index]
                                        ? "error"
                                        : ""
                                    }
                                  >
                                    <div className={`p5-form-label sh-tour-vic-child-addr-copy-${index}`}>
                                      Address
                                      <span>
                                        <Tooltip title="Copy Address">
                                          <button  
                                            key={`copy-btn-${index + 1}`}                                        
                                            className="btn btn-outline-info ml-2"
                                            type="button"
                                            onClick={() => this.setState({ isCopyVisible: true, childIndex: index })}
                                          >
                                            <FontAwesomeIcon
                                              size="1x"
                                              icon={faCopy}
                                            />
                                          </button>
                                        </Tooltip>
                                      </span>
                                    </div>
                                    <Input
                                      key={`child-address-input-${index + 1}`}
                                      id={`VictimChildren[${index}].Address`}
                                      className="ant-input mt-2"
                                      name={`VictimChildren[${index}].Address`}
                                      value={child.Address}
                                      placeholder="Address"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>  
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xs={24} sm={24} md={12} className={`gutter-row sh-tour-vic-child-addr-city-${index}`}>
                                  <Form.Item
                                    touched={`VictimChildren[${index}].City`}
                                    validateStatus={
                                      errors.VictimChildren &&
                                      errors.VictimChildren[index] &&
                                      errors.VictimChildren[index].City &&
                                      touched.VictimChildren &&
                                      touched.VictimChildren[index]
                                        ? "error"
                                        : ""
                                    }
                                  >
                                    <div className="p5-form-label"> City/Town</div>
                                    <Input
                                      key={`child-city-input-${index + 1}`}
                                      id={`VictimChildren[${index}].City`}
                                      className="p5-form-input"
                                      name={`VictimChildren[${index}].City`}
                                      value={child.City}
                                      placeholder="City/Town"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} className={`gutter-row sh-tour-vic-child-addr-post-code-${index}`}>
                                  <Form.Item
                                    touched={`VictimChildren[${index}].PostalCode`}
                                    validateStatus={
                                      errors.VictimChildren &&
                                      errors.VictimChildren[index] &&
                                      errors.VictimChildren[index]
                                        .PostalCode &&
                                      touched.VictimChildren &&
                                      touched.VictimChildren[index]
                                        ? "error"
                                        : ""
                                    }
                                  >
                                    <div className="p5-form-label">Postal Code</div>
                                    <Input
                                      key={`child-postal-input-${index + 1}`}
                                      id={`VictimChildren[${index}].PostalCode`}
                                      className="p5-form-input"
                                      name={`VictimChildren[${index}].PostalCode`}
                                      value={child.PostalCode}
                                      placeholder="Ex. 15201"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>  
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xs={24} sm={24} md={12} className={`gutter-row sh-tour-vic-child-addr-region-${index}`}>
                                  <Form.Item>
                                    <div className="p5-form-label">                                      
                                      County/Borough
                                    </div>
                                    <Input
                                      key={`addr-county-input-${index + 1}`}
                                      id={`VictimChildren[${index}].County`}
                                      className="p5-form-input"
                                      name={`VictimChildren[${index}].County`}
                                      value={child.County}
                                      placeholder="County"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              {values.VictimChildren.length === 1 ? (
                                <button
                                  type="button"
                                  className="btn btn-outline-success mt-1"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      Id: 0,
                                      Name: "",
                                      Age: 0,
                                      Address: "",
                                      City: "",
                                      County: "",
                                      PostalCode: ""
                                    })}
                                >
                                  <FontAwesomeIcon
                                    size="1x"
                                    icon={faPlusCircle}
                                  />
                                </button>
                              ) : (
                                values.VictimChildren.length ===
                                  index + 1 && (
                                    <button
                                      type="button"
                                      className="btn btn-outline-success mt-1"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          Id: 0,
                                          Name: "",
                                          Age: 0,
                                          Address: "",
                                          City: "",
                                          County: "",
                                          PostalCode: ""
                                        })}
                                    >
                                      <FontAwesomeIcon
                                        size="1x"
                                        icon={faPlusCircle}
                                      />
                                    </button>
                                )
                              )}
                            </div>
                          ))
                        ) : (
                          <button
                            className="btn btn-outline-success"
                            type="button"
                            onClick={() => arrayHelpers.push({
                              Id: 0,
                              Name: "",
                              Age: 0,
                              Address: "",
                              City: "",
                              County: "",
                              PostalCode: ""
                            })}
                          >
                            <FontAwesomeIcon
                              size="1x"
                              icon={faPlusCircle}
                            />
                          </button>
                        )}
                      </div>
                    )}
                  />
                </Panel>
                <Panel id="VictimVehicleDetail" header="Vehicle Details" key="3">
                  <FieldArray
                    name="VictimVehicleDetail"
                    render={(arrayHelpers) => (
                      <div>
                        {values.VictimVehicleDetail &&
                        values.VictimVehicleDetail.length > 0 ? (
                          values.VictimVehicleDetail.map(
                            (vehicle, index) => (
                              <div id={index} key={`vehicle-${index + 1}`}>
                                <Row>
                                  <Col xs={20} sm={20} md={22}>
                                    <h4 className="p5-form-h4-header">
                                      {`Vehicle #${index + 1}`}
                                    </h4> 
                                  </Col>                                
                                  {values.VictimVehicleDetail.length > 1 && (
                                    <Col xs={4} sm={4} md={2}>
                                      <button
                                        key={`vehicle-delete-btn-${index + 1}`}
                                        type="button"
                                        className="btn btn-outline-danger"
                                        onClick={() =>
                                          arrayHelpers.remove(index)}
                                      >
                                        <FontAwesomeIcon
                                          key={`vehicle-delete-input-${index + 1}`}
                                          size="1x"
                                          icon={faTrash}
                                        />
                                      </button>
                                    </Col>
                                    )}                                
                                </Row>
                                <hr />
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                  <Col xs={24} sm={24} md={12} className="gutter-row">
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                      <Col className={`gutter-row sh-tour-vic-car-vrm-${index}`} span={24}>
                                        <Form.Item>
                                          <div className="p5-form-label">VRM</div>
                                          <Input
                                            key={`vehicle-vrm-input-${index + 1}`}
                                            id={`VictimVehicleDetail[${index}].VRM`}
                                            className="p5-form-input"
                                            name={`VictimVehicleDetail[${index}].VRM`}
                                            value={vehicle.VRM}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={24} className={`gutter-row sh-tour-vic-car-colour-${index}`}>
                                        <Form.Item>
                                          <div className="p5-form-label">Colour</div>
                                          <Input
                                            key={`vehicle-colour-input-${index + 1}`}
                                            id={`VictimVehicleDetail[${index}].Colour`}
                                            className="p5-form-input"
                                            name={`VictimVehicleDetail[${index}].Colour`}
                                            value={vehicle.Colour}
                                          />
                                        </Form.Item>
                                      </Col>                                      
                                    </Row>
                                  </Col>

                                  <Col xs={24} sm={24} md={12} className="gutter-row">
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                      <Col className={`gutter-row sh-tour-vic-car-make-${index}`} span={24}>
                                        <Form.Item>
                                          <div className="p5-form-label">Make</div>
                                          <Input
                                            key={`vehicle-make-input-${index + 1}`}
                                            id={`VictimVehicleDetail[${index}].Make`}
                                            className="p5-form-input"
                                            name={`VictimVehicleDetail[${index}].Make`}
                                            value={vehicle.Make}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={24} className={`gutter-row sh-tour-vic-car-model-${index}`}>
                                        <Form.Item>
                                          <div className="p5-form-label">Model</div>
                                          <Input
                                            key={`vehicle-model-input-${index + 1}`}
                                            id={`VictimVehicleDetail[${index}].Model`}
                                            className="p5-form-input"
                                            name={`VictimVehicleDetail[${index}].Model`}
                                            value={vehicle.Model}
                                          />
                                        </Form.Item>
                                      </Col>                                      
                                    </Row>
                                  </Col>
                                </Row>
                                {values.VictimVehicleDetail.length === 1 ? (
                                  <button
                                    type="button"
                                    className="btn btn-outline-success mt-1"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        Id: 0,
                                        VRM: "",
                                        Make: "",
                                        Model: "",
                                        Colour: ""
                                      })}
                                  >
                                    <FontAwesomeIcon
                                      size="1x"
                                      icon={faPlusCircle}
                                    />
                                  </button>
                                ) : (
                                  values.VictimVehicleDetail.length ===
                                    index + 1 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-outline-success mt-1"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            Id: 0,
                                            VRM: "",
                                            Make: "",
                                            Model: "",
                                            Colour: ""
                                          })}
                                      >
                                        <FontAwesomeIcon
                                          size="1x"
                                          icon={faPlusCircle}
                                        />
                                      </button>
                                    </>
                                  )
                                )}
                              </div>
                            )
                          )
                        ) : (
                          <button
                            className="btn btn-outline-danger"
                            type="button"
                            onClick={() => arrayHelpers.push({
                              Id: 0,
                              VRM: "",
                              Make: "",
                              Model: "",
                              Colour: ""
                            })}
                          >
                            <FontAwesomeIcon
                              size="1x"
                              icon={faPlusCircle}
                            />
                          </button>
                        )}
                      </div>
                    )}
                  />
                </Panel>
                <Panel header={errors.OIC_Name || errors.OIC_StaffID ? this.hasErrors('Officer In The Case Details *') : 'Officer In The Case Details *'} key="4">
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={16} className="gutter-row sh-tour-vic-oic">
                      <Form.Item
                        validateStatus={
                          errors && errors.OIC_Name && touched.OIC_Name
                            ? "error"
                            : null
                        }
                        help={errors && touched.OIC_Name && errors.OIC_Name}
                      >
                        <div className="p5-form-label">Officer Name</div>
                        <Input
                          type="text"
                          className="p5-form-input"
                          id="OIC_Name"
                          name="OIC_Name"
                          placeholder="Officer Name"
                          value={values.OIC_Name}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} className="gutter-row sh-tour-vic-oic-staff-id">
                      <Form.Item
                        validateStatus={
                          errors && errors.OIC_StaffID && touched.OIC_StaffID
                            ? "error"
                            : null
                        }
                        help={errors && touched.OIC_StaffID && errors.OIC_StaffID}
                      >
                        <div className="p5-form-label"> Staff ID</div>
                        <Input
                          type="text"
                          className="p5-form-input"
                          id="OIC_StaffID"
                          name="OIC_StaffID"
                          placeholder="Staff ID #"
                          value={values.OIC_StaffID}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={16} className="gutter-row sh-tour-vic-auth-oic">
                      <Form.Item>
                        <div className="p5-form-label"> Authorising Officer</div>
                        <Input
                          type="text"
                          className="p5-form-input"
                          id="AuthorisingOfficer"
                          name="AuthorisingOfficer"
                          placeholder="Authorising Officer"
                          value={values.AuthorisingOfficer}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
                <Panel id="PerpetratorDetail" header={errors.PerpetratorName || errors.PNC_ID || errors.WarningTypeIds ? this.hasErrors('Perpetrator Details *') : 'Perpetrator Details *'} key="5">
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} className="gutter-row sh-tour-perp-unknown">
                      <Form.Item div className="p5-form-label">
                        <Checkbox
                          checked={values.VictimPerpetratorDetails.PerpUnknown}
                          value={values.VictimPerpetratorDetails.PerpUnknown}
                          onChange={(val) => {
                            setFieldValue('VictimPerpetratorDetails.PerpUnknown', val.target.checked);

                            if(val.target.checked){
                              setFieldValue('VictimPerpetratorDetails.NameNotKnown', true);
                              setFieldValue('PerpetratorName', "Not Known");
                              
                              setFieldValue('VictimPerpetratorDetails.DOBNotKnown', true);
                              setFieldValue('VictimPerpetratorDetails.DOB', null);
                              
                              setFieldValue('VictimPerpetratorDetails.PNCNotKnown', true);
                              setFieldValue('PNC_ID', 'Not Known');
                              
                              setFieldValue('VictimPerpetratorDetails.CourtOrderConditionsNotKnown', true);
                              setFieldValue('VictimPerpetratorDetails.CourtOrderConditions', 'Not Known');
                              
                              setFieldValue('WarningTypeNone', true);
                            }
                          }}
                        >
                          Perpetrator Unknown?
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={18} className="gutter-row sh-tour-perp-name">
                      <Form.Item
                        validateStatus={
                          errors &&
                              errors.PerpetratorName &&
                              touched.PerpetratorName
                              ? "error"
                              : null
                        }
                        help={
                            errors &&
                            touched.PerpetratorName &&
                            errors.PerpetratorName
                        }
                      >
                        <div className="p5-form-label d-flex">
                          Name
                          <div className="ml-3">
                            <Checkbox
                              checked={values.VictimPerpetratorDetails.NameNotKnown}
                              value={values.VictimPerpetratorDetails.NameNotKnown}
                              disabled={values.VictimPerpetratorDetails.PerpUnknown}
                              onChange={(val) => {
                                setFieldValue('VictimPerpetratorDetails.NameNotKnown', val.target.checked);
                                setFieldValue('PerpetratorName', val.target.checked ? "Not Known" : "");
                              }}
                            >
                              Not Known?
                            </Checkbox>
                          </div>
                        </div>
                        <Input
                          className="p5-form-input"
                          type="text"
                          id="PerpetratorName"
                          name="PerpetratorName"
                          placeholder="Perpetrator Name"
                          disabled={values.VictimPerpetratorDetails.NameNotKnown}
                          value={values.PerpetratorName}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} className="gutter-row sh-tour-perp-gender">
                      <Form.Item>
                        <div className="p5-form-label"> Gender </div>
                        <GenderSelect
                          className="p5-form-dropdown"
                          name="Gender"
                          value={values.VictimPerpetratorDetails.PerpetratorGender}
                          onChange={(val) => {
                            setFieldValue("VictimPerpetratorDetails.PerpetratorGender", val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row id="PerpetratorDetailImage" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} md={24} className="gutter-row sh-tour-perp-image">
                      <ImageUpload
                        Id={
                          values.VictimPerpetratorDetails.ImageIds.length === 1 
                            ? parseInt(values.VictimPerpetratorDetails.ImageIds[0].src, 10) 
                            : null
                        }
                        Images={values.VictimPerpetratorDetails.ImageIds}
                        onUpload={this.changePerpPic}
                        onRemove={this.removePerpImage}
                        multiple
                      />
                    </Col>
                  </Row>
                  <Row id="PerpetratorDetailDobPNC" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-perp-dob">
                      <Form.Item
                        validateStatus={
                          errors && errors.DOB && touched.DOB
                            ? "error"
                            : "success"
                        }
                        help={errors && touched.DOB && errors.DOB ? errors.DOB : null}
                      >
                        <div className="p5-form-label d-flex">
                          DOB
                          <div className="ml-3">
                            <Checkbox
                              checked={values.VictimPerpetratorDetails.DOBNotKnown}
                              value={values.VictimPerpetratorDetails.DOBNotKnown}
                              disabled={values.VictimPerpetratorDetails.PerpUnknown}
                              onChange={(val) => {
                                  setFieldValue('VictimPerpetratorDetails.DOBNotKnown', val.target.checked);
                                  setFieldValue('VictimPerpetratorDetails.DOB', val.target.checked ? null : null);
                                }}
                            >
                              Not Known?
                            </Checkbox>
                          </div>
                        </div>
                        <DatePicker
                          id="perpDOB"
                          className="p5-form-datepicker-input"
                          value={values.VictimPerpetratorDetails.DOB}
                          disabled={values.VictimPerpetratorDetails.DOBNotKnown}
                          format={dateFormat}
                          onBlur={handleBlur}
                          onChange={(val) => {
                                setFieldValue("VictimPerpetratorDetails.DOB", val ?? null);
                              }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} className="gutter-row sh-tour-perp-pncid">
                      <Form.Item
                        validateStatus={
                          errors && errors.PNC_ID && touched.PNC_ID
                            ? "error"
                            : null
                        }
                        help={errors && touched.PNC_ID && errors.PNC_ID}
                      >
                        <div className="p5-form-label d-flex">
                          PNC ID
                          <div className="ml-3">
                            <Checkbox
                              checked={values.VictimPerpetratorDetails.PNCNotKnown}
                              value={values.VictimPerpetratorDetails.PNCNotKnown}
                              disabled={values.VictimPerpetratorDetails.PerpUnknown}
                              onChange={(val) => {
                                setFieldValue('VictimPerpetratorDetails.PNCNotKnown', val.target.checked);
                                setFieldValue('PNC_ID', val.target.checked ? 'Not Known' : "");
                              }}
                            >
                              Not Known?
                            </Checkbox>
                          </div>
                        </div>
                        <Input
                          type="text"
                          className="p5-form-input"
                          id="PNC_ID"
                          name="PNC_ID"
                          placeholder="PNC ID"
                          disabled={values.VictimPerpetratorDetails.PNCNotKnown}
                          value={values.PNC_ID}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row id="PerpetratorDetailDesc" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={24} className="gutter-row sh-tour-perp-desc">
                      <Form.Item>
                        <div className="p5-form-label d-flex">
                          Description
                          <div className="ml-3">
                            <Checkbox
                              checked={values.VictimPerpetratorDetails.DescriptionNotKnown}
                              value={values.VictimPerpetratorDetails.DescriptionNotKnown}
                              onChange={(val) => {
                                setFieldValue('VictimPerpetratorDetails.DescriptionNotKnown', val.target.checked);
                                setFieldValue('VictimPerpetratorDetails.Description', val.target.checked ? 'Not Known' : "");
                              }}
                            >
                              Not Known?
                            </Checkbox>
                          </div>
                        </div>
                        <TextArea
                          id="VictimPerpetratorDetails.Description"
                          name="VictimPerpetratorDetails.Description"
                          placeholder="Description"
                          disabled={values.VictimPerpetratorDetails.DescriptionNotKnown}
                          value={values.VictimPerpetratorDetails.Description}
                          autoSize
                        />  
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row id="PerpetratorDetailMo" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={24} className="gutter-row sh-tour-perp-mo">
                      <Form.Item>
                        <div className="p5-form-label d-flex">
                          MO (Modus Operandi)
                          <div className="ml-3">
                            <Checkbox
                              checked={values.VictimPerpetratorDetails.MONotKnown}
                              value={values.VictimPerpetratorDetails.MONotKnown}
                              onChange={(val) => {
                                setFieldValue('VictimPerpetratorDetails.MONotKnown', val.target.checked);
                                setFieldValue('VictimPerpetratorDetails.MO', val.target.checked ? 'Not Known' : "");
                              }}
                            >
                              Not Known?
                            </Checkbox>
                          </div>
                        </div>
                        <TextArea
                          id="VictimPerpetratorDetails.MO"
                          name="VictimPerpetratorDetails.MO"
                          placeholder="MO (Modus Operandi)"
                          disabled={values.VictimPerpetratorDetails.MONotKnown}
                          value={values.VictimPerpetratorDetails.MO}
                          autoSize
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row id="PerpetratorDetailCourtCon" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={24} className="gutter-row sh-tour-perp-court-condition">
                      <Form.Item>
                        <div className="p5-form-label d-flex">Relevant court order or bail conditions
                          <div className="ml-3">
                            <Checkbox
                              checked={values.VictimPerpetratorDetails.CourtOrderConditionsNotKnown}
                              value={values.VictimPerpetratorDetails.CourtOrderConditionsNotKnown}
                              disabled={values.VictimPerpetratorDetails.PerpUnknown}
                              onChange={(val) => {
                                setFieldValue('VictimPerpetratorDetails.CourtOrderConditionsNotKnown', val.target.checked);
                                setFieldValue('VictimPerpetratorDetails.CourtOrderConditions', val.target.checked ? 'Not Known' : "");
                              }}
                            >
                              Not Known?
                            </Checkbox>
                          </div>
                        </div>
                        <TextArea
                          id="VictimPerpetratorDetails.CourtOrderConditions"
                          name="VictimPerpetratorDetails.CourtOrderConditions"
                          placeholder="Court order or bail conditions"
                          value={values.VictimPerpetratorDetails.CourtOrderConditions}
                          disabled={values.VictimPerpetratorDetails.CourtOrderConditionsNotKnown}
                          autoSize
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row id="PerpetratorDetailDeployCon" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={24} className="gutter-row sh-tour-perp-deploy-consideration">
                      <Form.Item>
                        <div className="p5-form-label d-flex">
                          {" "}
                          Deployment Considerations
                          <div className="ml-3">
                            <Checkbox
                              checked={values.VictimPerpetratorDetails.DeployConsidNotKnown}
                              value={values.VictimPerpetratorDetails.DeployConsidNotKnown}
                              onChange={(val) => {
                                setFieldValue('VictimPerpetratorDetails.DeployConsidNotKnown', val.target.checked);
                                setFieldValue('VictimPerpetratorDetails.DeploymentConsiderations', val.target.checked ? 'Not Known' : "");
                              }}
                            >
                              None
                            </Checkbox>
                          </div>
                        </div>
                        <TextArea
                          id="VictimPerpetratorDetails.DeploymentConsiderations"
                          name="VictimPerpetratorDetails.DeploymentConsiderations"
                          placeholder="Deployment Considerations"
                          disabled={values.VictimPerpetratorDetails.DeployConsidNotKnown}
                          value={
                            values.VictimPerpetratorDetails
                              .DeploymentConsiderations
                          }
                          autoSize
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row id="PerpetratorDetailWarnType" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={24} className="gutter-row sh-tour-perp-warn-type">
                      <Form.Item
                        validateStatus={
                          errors && errors.WarningTypeIds && touched.WarningTypeIds
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && touched.WarningTypeIds && errors.WarningTypeIds ? errors.WarningTypeIds : null
                        }
                      >
                        <div className="p5-form-label d-flex mt-2">
                          PNC Warning Markers
                          <div className="ml-3">
                            <Checkbox
                              checked={values.WarningTypeNone}
                              value={values.WarningTypeNone}
                              disabled={values.VictimPerpetratorDetails.PerpUnknown}
                              onChange={(val) => {
                                setFieldValue('WarningTypeNone', val.target.checked);
                              }}
                            >
                              None
                            </Checkbox>
                          </div>
                        </div>
                        <WarningTypeSelect
                          className="p5-form-dropdown"
                          name="WarningTypeIds"
                          value={values.WarningTypeIds}
                          disabled={values.WarningTypeNone}
                          onBlur={handleBlur}
                          onChange={(val) => {
                            setFieldValue("WarningTypeIds", val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
                <Panel id="VictimPerpetratorVehicleDetails" header="Perpetrator Vehicle Details" key="6">
                  <FieldArray
                    name="VictimPerpetratorDetails.VictimPerpetratorVehicleDetails"
                    render={(arrayHelpers) => (
                      <div>
                        {values.VictimPerpetratorDetails
                          .VictimPerpetratorVehicleDetails &&
                        values.VictimPerpetratorDetails
                          .VictimPerpetratorVehicleDetails.length > 0 ? (
                          values.VictimPerpetratorDetails.VictimPerpetratorVehicleDetails.map(
                            (vehicle, index) => (
                              <div id={index} key={`perp-vehicle-${index + 1}`}>
                                <Row>
                                  <Col xs={20} sm={20} md={22}>
                                    <h4 className="p5-form-h4-header">
                                      {`Vehicle #${index + 1}`}
                                    </h4> 
                                  </Col>                                
                                  {values.VictimPerpetratorDetails.VictimPerpetratorVehicleDetails.length > 1 && (
                                    <Col xs={4} sm={4} md={2}>
                                      <button
                                        key={`perp-vehicle-delete-btn-${index + 1}`}
                                        type="button"
                                        className="btn btn-outline-danger"
                                        onClick={() =>
                                          arrayHelpers.remove(index)}
                                      >
                                        <FontAwesomeIcon
                                          key={`perp-vehicle-delete-input-${index + 1}`}
                                          size="1x"
                                          icon={faTrash}
                                        />
                                      </button>
                                    </Col>
                                    )}                                
                                </Row>
                                <hr />
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                  <Col xs={24} sm={24} md={12} className="gutter-row">
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                      <Col span={24} className={`gutter-row sh-tour-perp-car-vrm-${index}`}>
                                        <Form.Item>
                                          <div className="p5-form-label">VRM</div>
                                          <Input
                                            key={`perp-vehicle-vrm-input-${index + 1}`}
                                            id={`VictimPerpetratorDetails.VictimPerpetratorVehicleDetails[${index}].VRM`}
                                            className="p5-form-input"
                                            name={`VictimPerpetratorDetails.VictimPerpetratorVehicleDetails[${index}].VRM`}
                                            value={vehicle.VRM}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={24} className={`gutter-row sh-tour-perp-car-colour-${index}`}>
                                        <Form.Item>
                                          <div className="p5-form-label">Colour</div>
                                          <Input
                                            key={`perp-vehicle-colour-input-${index + 1}`}
                                            id={`VictimPerpetratorDetails.VictimPerpetratorVehicleDetails[${index}].Colour`}
                                            className="p5-form-input"
                                            name={`VictimPerpetratorDetails.VictimPerpetratorVehicleDetails[${index}].Colour`}
                                            value={vehicle.Colour}
                                          />
                                        </Form.Item>
                                      </Col>                                      
                                    </Row>
                                  </Col>

                                  <Col xs={24} sm={24} md={12} className="gutter-row">
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                      <Col span={24} className={`gutter-row sh-tour-perp-car-make-${index}`}>
                                        <Form.Item>
                                          <div className="p5-form-label">Make</div>
                                          <Input
                                            key={`perp-vehicle-make-input-${index + 1}`}
                                            id={`VictimPerpetratorDetails.VictimPerpetratorVehicleDetails[${index}].Make`}
                                            className="p5-form-input"
                                            name={`VictimPerpetratorDetails.VictimPerpetratorVehicleDetails[${index}].Make`}
                                            value={vehicle.Make}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={24} className={`gutter-row sh-tour-perp-car-model-${index}`}>
                                        <Form.Item>
                                          <div className="p5-form-label">Model</div>
                                          <Input
                                            key={`perp-vehicle-model-input-${index + 1}`}
                                            id={`VictimPerpetratorDetails.VictimPerpetratorVehicleDetails[${index}].Model`}
                                            className="p5-form-input"
                                            name={`VictimPerpetratorDetails.VictimPerpetratorVehicleDetails[${index}].Model`}
                                            value={vehicle.Model}
                                          />
                                        </Form.Item>
                                      </Col>                                      
                                    </Row>
                                  </Col>
                                </Row>

                                {values.VictimPerpetratorDetails
                                  .VictimPerpetratorVehicleDetails.length ===
                                1 ? (
                                  <button
                                    type="button"
                                    className="btn btn-outline-success mt-1"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        Id: 0,
                                        VRM: "",
                                        Make: "",
                                        Model: "",
                                        Colour: ""
                                      })}
                                  >
                                    <FontAwesomeIcon
                                      size="1x"
                                      icon={faPlusCircle}
                                    />
                                  </button>
                                ) : (
                                  values.VictimPerpetratorDetails
                                    .VictimPerpetratorVehicleDetails
                                    .length ===
                                    index + 1 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-outline-success mt-1"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            Id: 0,
                                            VRM: "",
                                            Make: "",
                                            Model: "",
                                            Colour: ""
                                          })}
                                      >
                                        <FontAwesomeIcon
                                          size="1x"
                                          icon={faPlusCircle}
                                        />
                                      </button>
                                    </>
                                  )
                                )}
                              </div>
                            )
                          )
                        ) : (
                          <button
                            className="btn btn-outline-danger"
                            type="button"
                            onClick={() => arrayHelpers.push({
                              Id: 0,
                              VRM: "",
                              Make: "",
                              Model: "",
                              Colour: ""
                            })}
                          >
                            <FontAwesomeIcon size="1x" icon={faPlusCircle} />
                          </button>
                        )}
                      </div>
                    )}
                  />
                </Panel>
              </Collapse>
              <br />
              <Row justify="end" id="Extend-footer-btns" className="sh-tour-vic-footer-btn">
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  style={{ margin: "5px" }}
                >
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  style={{ margin: "5px" }}                  
                >
                  <Button
                    block
                    type="submit"
                    id="extendBtn"
                    className="extendBtn"
                    onClick={handleSubmit}
                    loading={this.props.loading}
                  >
                    Save and Extend
                  </Button>
                </Col>
              </Row>
              <ErrorFocus />
              <AddressCopyModal
                name="AddressCopyModal"
                isVisible={this.state.isCopyVisible}
                handleOk={(value) => this.onCopy(value)}
                handleCancel={() => this.setState({ isCopyVisible: false })}
                data={values.VictimAddresses}
              />
            </div>
          </Form>
        )}
      />
    );
  }
}

ExtendForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  Id: PropTypes.number,
  RegistrationId: PropTypes.number,
  CreatedDate: PropTypes.string,
  Name: PropTypes.string,
  GenderId: PropTypes.number,
  FCR_EmailAddress: PropTypes.string,
  FCR_PhoneNumber: PropTypes.string,
  CrimeRefNumber: PropTypes.string,
  ImageIds: PropTypes.arrayOf(PropTypes.number),
  VictimAddresses: PropTypes.arrayOf(PropTypes.shape({})),
  VictimPerpetratorDetails: PropTypes.shape({
    Id: PropTypes.number,
    VictimId: PropTypes.number,
    PerpUnknown: PropTypes.bool,
    Description: PropTypes.string,
    DOB: PropTypes.string,
    Name: PropTypes.string,
    GenderId: PropTypes.number,
    DOBNotKnown: PropTypes.bool,
    NameNotKnown: PropTypes.bool,
    DescriptionNotKnown: PropTypes.bool,
    MONotKnown: PropTypes.bool,
    PNCNotKnown: PropTypes.bool,
    DeployConsidNotKnown: PropTypes.bool,
    MO: PropTypes.string,
    CourtOrderConditions: PropTypes.string,
    CourtOrderConditionsNotKnown: PropTypes.bool,
    DeploymentConsiderations: PropTypes.string,
    ImageIds: PropTypes.arrayOf(PropTypes.number),
    VictimPerpetratorVehicleDetails: PropTypes.arrayOf(PropTypes.shape({})),
    PNCID: PropTypes.string,
    WarningTypes: PropTypes.arrayOf(PropTypes.number),
    WarningTypeNone: PropTypes.bool,
  }),
  VictimChildren: PropTypes.arrayOf(PropTypes.shape({})),
  VictimVehicleDetail: PropTypes.arrayOf(PropTypes.shape({})),
  RiskLevelId: PropTypes.number,
  RiskCategories: PropTypes.arrayOf(PropTypes.number),
  Disabilities: PropTypes.arrayOf(PropTypes.shape({})),
  DisabilitiesNone: PropTypes.bool,
  OIC_Name: PropTypes.string,
  OIC_StaffID: PropTypes.string,
  AuthorisingOfficer: PropTypes.string,
  IsTour: PropTypes.bool,
  loading: PropTypes.bool,
};

ExtendForm.defaultProps = {
  onCancel: () => {},
  Id: 0,
  RegistrationId: 0,
  CreatedDate: "",
  Name: "",
  GenderId: null,
  FCR_EmailAddress: "",
  FCR_PhoneNumber: "",
  CrimeRefNumber: "",
  ImageIds: [],
  VictimAddresses: null,
  VictimPerpetratorDetails: {
    Id: 0,
    VictimId: 0,
    PerpUnknown: false,
    Name: "",
    GenderId: null,
    DOB: null,
    DOBNotKnown: false,
    WarningTypes: [],
    WarningTypeNone: false,
    CourtOrderConditions: "",
    CourtOrderConditionsNotKnown: false,
    NameNotKnown: false,
    DescriptionNotKnown: false,
    MONotKnown: false,
    PNCNotKnown: false,
    DeployConsidNotKnown: false,
  },
  VictimChildren: null,
  VictimVehicleDetail: null,
  RiskLevelId: 0,
  RiskCategories: [],
  Disabilities: [],
  DisabilitiesNone: false,
  OIC_Name: "",
  OIC_StaffID: "",
  AuthorisingOfficer: "",
  IsTour: false,
  loading: false,
};

export default ExtendForm;
