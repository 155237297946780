import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Input, Button, Form } from "antd";
import TechSosLogo from "../../../common/assets/img/TecSAFE_logo_light.png";

class LoginForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();

    this.state = {
      UserName: "",
      Password: "",
    };
  }

  componentDidUpdate() {
    this.formIK.current.handleReset();
  }

  handleChange = (e) => {
    const { value, id } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        initialValues={{
          UserName: this.state.UserName,
          Password: this.state.Password,
        }}
        validationSchema={Yup.object().shape({
          UserName: Yup.string()
            .typeError("Username is required")
            .required("Username is required"),
          Password: Yup.string()
            .typeError("Password is required")
            .required("Password is required"),
        })}
        onSubmit={(values) => this.props.onSubmit(values)}
      >
        {({ values, errors, touched, handleChange, handleSubmit, handleBlur, }) => (
          <div className="account-layout-form-container">
            <Row justify="center">
              <Col span={20}>
                <div className="login-logo-container">
                  <img style={{"maxWidth": "450px"}} src={TechSosLogo} width="100%" id="LoginLogo" alt="Logo" />
                </div>
              </Col>
            </Row>
            <Form
              name={this.props.name}
              className="login-form"
              id={this.props.name}
              onChange={handleChange}
              onSubmit={handleSubmit}
              autoComplete={0}
            >
              <Row justify="center">
                <Col span={20}>
                  <h1 className="p5-form-h1-header"> Login </h1>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={20}>
                  <Form.Item
                    validateStatus={
                      errors && errors.UserName && touched.UserName
                        ? "error"
                        : null
                    }
                    help={errors && touched.UserName && errors.UserName}
                  >
                    <div className="p5-form-label"> Username </div>
                    <Input
                      type="text"
                      id="UserName"
                      className="p5-form-input"
                      name="UserName"
                      placeholder="Username"
                      value={values.UserName}
                      onChange={this.handleChange}
                      onBlur={handleBlur}
                      autoComplete="UserName"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={20}>
                  <Form.Item
                    validateStatus={
                      errors && errors.Password && touched.Password
                        ? "error"
                        : null
                    }
                    help={errors && touched.Password && errors.Password}
                  >
                    <div className="p5-form-label"> Password </div>
                    <Input.Password
                      id="Password"
                      className="p5-form-input"
                      name="Password"
                      value={values.Password}
                      placeholder="Password"
                      onChange={this.handleChange}
                      onBlur={handleBlur}
                      autoComplete="Password"
                      onPressEnter={handleSubmit}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={20}>
                  <Form.Item
                    validateStatus={this.props.hasError ? "error" : null}
                    help={this.props.hasError ? this.props.error : null}
                  >
                    <Button
                      block
                      type="submit"
                      id="loginBtn"
                      className="loginBtn"
                      onClick={handleSubmit}
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={20}>
                  <div className="login-forgot-password">
                    <a href="/account/forgot-password">forgot password</a>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </Formik>
    );
  }
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  error: PropTypes.string,
};

LoginForm.defaultProps = {
  hasError: false,
  error: "Invalid username or password",
};

export default LoginForm;
