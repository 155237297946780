export default ({
  GET_LEA_LIST_REQUEST: "GET_LEA_LIST_REQUEST",
  GET_LEA_LIST_SUCCESS: "GET_LEA_LIST_SUCCESS",
  GET_LEA_LIST_FAILURE: "GET_LEA_LIST_FAILURE",
  CREATE_LEA_REQUEST: 'CREATE_LEA_REQUEST',
  CREATE_LEA_SUCCESS: 'CREATE_LEA_SUCCESS',
  CREATE_LEA_FAILURE: 'CREATE_LEA_FAILURE',
  GET_LEA_REQUEST: 'GET_LEA_REQUEST',
  GET_LEA_SUCCESS: 'GET_LEA_SUCCESS',
  GET_LEA_FAILURE: 'GET_LEA_FAILURE',
  GET_LEA_ID_REQUEST: 'GET_LEA_ID_REQUEST',
  GET_LEA_ID_SUCCESS: 'GET_LEA_ID_SUCCESS',
  GET_LEA_ID_FAILURE: 'GET_LEA_ID_FAILURE',
  UPDATE_LEA_REQUEST: 'UPDATE_LEA_REQUEST',
  UPDATE_LEA_SUCCESS: 'UPDATE_LEA_SUCCESS',
  UPDATE_LEA_FAILURE: 'UPDATE_LEA_FAILURE',
  DELETE_LEA_REQUEST: 'DELETE_LEA_REQUEST',
  DELETE_LEA_SUCCESS: 'DELETE_LEA_SUCCESS',
  DELETE_LEA_FAILURE: 'DELETE_LEA_FAILURE',
});