/* eslint import/prefer-default-export: 0 */
import api from '../common';

function getVpsHelped(startDate, endDate){
  return api.get(`api/SystemAdminDashboard/GetVPsHelped?startDate=${startDate}&endDate=${endDate}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response)
    .catch(error => Promise.reject(error));
}

function getLicensesUsed(startDate, endDate){
    return api.get(`api/SystemAdminDashboard/GetLicensesUsed?startDate=${startDate}&endDate=${endDate}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
      .then(response => response)
      .catch(error => Promise.reject(error));
}

function getOverdueRegistrations(startDate, endDate,  forceId, areaId, teamId, allForces = false){
    return api.get(`api/SystemAdminDashboard/GetOverdueReviews?startDate=${startDate}&endDate=${endDate}&forceId=${forceId}&areaId=${areaId}&teamId=${teamId}&viewAll=${allForces}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
      .then(response => response)
      .catch(error => Promise.reject(error));
}

function getVictimDashDetails(startDate, endDate){
  return api.get(`api/SystemAdminDashboard/VictimDetails?startDate=${startDate}&endDate=${endDate}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response)
    .catch(error => Promise.reject(error));
}

function getDevicesRegistered(startDate, endDate, forceId, areaId, teamId, allForces = false){
  return api.get(`api/SystemAdminDashboard/DevicesRegistered?startDate=${startDate}&endDate=${endDate}&forceId=${forceId}&areaId=${areaId}&teamId=${teamId}&viewAll=${allForces}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response)
    .catch(error => Promise.reject(error));
}

function getActivatedDevices(startDate, endDate, forceId, areaId, teamId, allForces = false){
  return api.get(`api/SystemAdminDashboard/ActivatedDevices?startDate=${startDate}&endDate=${endDate}&forceId=${forceId}&areaId=${areaId}&teamId=${teamId}&viewAll=${allForces}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response)
    .catch(error => Promise.reject(error));
}

function getGendersByRegisteredDevice(startDate, endDate, forceId, areaId, teamId, allForces = false){
  return api.get(`api/SystemAdminDashboard/RegisteredDeviceGenders?startDate=${startDate}&endDate=${endDate}&forceId=${forceId}&areaId=${areaId}&teamId=${teamId}&viewAll=${allForces}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response)
    .catch(error => Promise.reject(error));
}

function getRiskCategoryByRegisteredDevice(startDate, endDate, forceId,areaId, teamId, allForces = false){
  return api.get(`api/SystemAdminDashboard/RegisteredDeviceRiskCategory?startDate=${startDate}&endDate=${endDate}&forceId=${forceId}&areaId=${areaId}&teamId=${teamId}&viewAll=${allForces}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response)
    .catch(error => Promise.reject(error));
}

function getNoLocationReported(startDate, endDate, forceId, areaId, teamId, allForces = false){
  return api.get(`api/SystemAdminDashboard/NoLocationReported?startDate=${startDate}&endDate=${endDate}&forceId=${forceId}&areaId=${areaId}&teamId=${teamId}&viewAll=${allForces}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response)
    .catch(error => Promise.reject(error));
}

function getRegistrationAllocationDetails(startDate, endDate, forceId, allForces = false){
  return api.get(`api/SystemAdminDashboard/RegistrationAllocationDetails?startDate=${startDate}&endDate=${endDate}&forceId=${forceId}&viewAll=${allForces}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response)
    .catch(error => Promise.reject(error));
}

function getRegistrationUsageDetails(startDate, endDate, forceId, areaId, teamId, allForces = false){
  return api.get(`api/SystemAdminDashboard/RegistrationUsageDetails?startDate=${startDate}&endDate=${endDate}&forceId=${forceId}&areaId=${areaId}&teamId=${teamId}&viewAll=${allForces}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response)
    .catch(error => Promise.reject(error));
}
function getAlertCallDetails(startDate, endDate, forceId, areaId, teamId, allForces = false){
  return api.get(`api/SystemAdminDashboard/AlertCallDetails?startDate=${startDate}&endDate=${endDate}&forceId=${forceId}&areaId=${areaId}&teamId=${teamId}&viewAll=${allForces}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response)
    .catch(error => Promise.reject(error));
}

export {
    getVpsHelped,
    getLicensesUsed,
    getOverdueRegistrations,
    getVictimDashDetails,
    getDevicesRegistered,
    getActivatedDevices,
    getGendersByRegisteredDevice,
    getRiskCategoryByRegisteredDevice,
    getNoLocationReported,
    getRegistrationAllocationDetails,
    getRegistrationUsageDetails,
    getAlertCallDetails
  }