import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout, PageHeader } from '../../common/components';
import { AreaIndexPage, AreaCreatePage, AreaEditPage } from './pages';

const AreaHeader = (
  <PageHeader title="Area Settings" />
)

const Area = ({ match }) => (
  <Layout PageHeader={AreaHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={AreaIndexPage} />   
      <Route exact path={`${match.url}/create`} component={AreaCreatePage} /> 
      <Route exact path={`${match.url}/:id`} component={AreaEditPage} /> 
    </Switch>
  </Layout>
);

Area.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Area.defaultProps = {
  match: {
    url: '',
  },
};

export default withRouter(Area);
