import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import { Layout, PageHeader } from "../../common/components";
import { RegistrationTourPage, NewRegistrationTourPage, HubAllocationPage, HelpTourPage, SearchTourPage, ResourceGroupTourPage, UserManagementPageTour, VictimDataTourPage, DashboardTourPage, RegDetailModalTourPage } from "./page";

const UserListHeader = (title) => <PageHeader title={`Tour - ${title}`} />;

const TourGuideRoute = ({ match }) => (
  <Layout PageHeader={UserListHeader("Registration")}>
    <Switch>
      <Route
        exact
        path={`${match.url}/register`}
        component={RegistrationTourPage}
      />
      <Route
        exact
        path={`${match.url}/register/new`}
        component={NewRegistrationTourPage}
      />
      <Route
        exact
        path={`${match.url}/hub`}
        component={HubAllocationPage}
      />
      <Route
        exact
        path={`${match.url}/help`}
        component={HelpTourPage}
      />
      <Route
        exact
        path={`${match.url}/search`}
        component={SearchTourPage}
      />
      <Route
        exact
        path={`${match.url}/resourceLibrary`}
        component={ResourceGroupTourPage}
      />
      <Route
        exact
        path={`${match.url}/user`}
        component={UserManagementPageTour}
      />
      <Route
        exact
        path={`${match.url}/register/data`}
        component={VictimDataTourPage}
      />
      <Route
        exact
        path={`${match.url}/dashboard`}
        component={DashboardTourPage}
      />
      <Route
        exact
        path={`${match.url}/register/detail`}
        component={RegDetailModalTourPage}
      />
      {/* <Route exact path={`${match.url}/:regid/create`} component={DataCreatePage} />
        <Route exact path={`${match.url}/:regid/edit`} component={DataEditPage} />
        <Route exact path={`${match.url}/:regid/extend`} component={ExtendPage} /> */}
    </Switch>
  </Layout>
);

TourGuideRoute.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  // title: PropTypes.string,
};

TourGuideRoute.defaultProps = {
  match: {
    url: "",
  },
  // title: "",
};

export default withRouter(TourGuideRoute);
