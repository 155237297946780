import constants from "./constants";
import { SearchService } from '../../services';

function Search(data, callback) {
  function request() {
    return { type: constants.GET_VICTIM_LIST_REQUEST };
  }
  function success(details) {
    return { type: constants.GET_VICTIM_LIST_SUCCESS, details };
  }
  function failure(error) {
    return { type: constants.GET_VICTIM_LIST_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SearchService.search(data).then(
      (result) => {
        dispatch(success(result));  
        callback(result);      
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function GetVictimView(id, callback) {
  function request() {
    return { type: constants.GET_VICTIM_VIEW_REQUEST };
  }
  function success(data) {
    return { type: constants.GET_VICTIM_VIEW_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.GET_VICTIM_VIEW_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    SearchService.getVictimView(id).then(
      (result) => {
        dispatch(success(result));  
        callback(result);      
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

export default {
  Search,
  GetVictimView
};
