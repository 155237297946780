import React from "react";
import PropTypes from "prop-types";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { Dropdown, Tooltip, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class RegisterAddButton extends React.PureComponent {
  handleDropdownSelect = (selectedItem) => {
    const device = selectedItem.key;
    this.props.onClick(device);
  }

  render() {
    const menuItems = (
      <Menu onClick={this.handleDropdownSelect}>
        <Menu.Item key="Android">
          Android
        </Menu.Item>
        <Menu.Item key="Apple">
          iPhone
        </Menu.Item>
        <Menu.Item key="Handset">
          Handset
        </Menu.Item>
      </Menu>
    )

    return (
      <Dropdown trigger={["click"]} overlay={menuItems} placement="bottomRight">
        <Tooltip title="New Registration">
          <FontAwesomeIcon
            icon={faPlusCircle}
            id="registerAdd"
            className="register-add-icon"
            alt="registerAddIcon"
            pull="right"
            size="1x"
          />
        </Tooltip>
      </Dropdown>
    );
  }
}

RegisterAddButton.propTypes = {
  onClick: PropTypes.func
}

RegisterAddButton.defaultProps = {
  onClick: () => { }
}

export default RegisterAddButton;