import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Form, Row, Col, Input, Button
} from 'antd';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import AccountLayout from '../layout/layout';
import Actions from '../actions';
import TechSosLogo from "../../../common/assets/img/TecSAFE_logo_light.png";
import './ResetForm.css';

class ResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: '',
      NewPassword: '',
      Token: '',
    };
  }

  handleChange = (data) => {
    const { value, id } = data.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  handleSubmit = () => {
    const { dispatch } = this.props;
    if (!this.props.hasError) {

      const parsedToken = this.getTokenFromQueryString();

      this.setState({Token: parsedToken }); 
      dispatch(Actions.ResetPassword(this.state));
    }
  }

  getTokenFromQueryString = () => {
    
    let parsedToken = "";

    const urlToParse = window.location.href;

    const tokenQueryStringIndicator = 'token=';
    const tokenStartIndex = urlToParse.indexOf(tokenQueryStringIndicator);
    const tokenValueStartIndex = tokenStartIndex + tokenQueryStringIndicator.length;

    parsedToken = urlToParse.substring(tokenValueStartIndex);

    return parsedToken;
  }

  validatePassword = value =>{
    // eslint-disable-next-line
    const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?!@#\$%\^&\*])(?=.{6,})/;

    return regex.test(value);
  }

  validateHasLowercase = (value) => {
    const regex = /(?=.*[a-z])/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }

    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateHasUppercase = (value) => {
    const regex = /(?=.*[A-Z])/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateHasNumber = (value) => {
    const regex = /(?=.*[0-9])/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateHasSpecialCharacter = (value) => {
    // eslint-disable-next-line
    const regex = /(?=.*[?!@#\$%\^&\*])/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateHasMinCount = (value) => {
    const regex = /(?=.{6,})/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  render() {
    const TokenRegex = /(?=^\/account\/reset\/$)/g;

    if (TokenRegex.test(window.location.href)) {
      const Token = TokenRegex.exec(window.location.href);
      const newLocation = window.location.href.split(`/${Token}`)[0];

      this.setState((prevState) => ({
        ...prevState,
        Token,
      }), (
        window.location.href = newLocation
      ));
    }
    return (
      <AccountLayout
        loading={this.props.loading}
        form={(
          <Formik
            enableReinitialize
            initialValues={{
              UserName: this.state.UserName,
              NewPassword: this.state.NewPassword,
            }}
            validationSchema={Yup.object().shape({
              UserName: Yup.string()
                .typeError('Username is required')
                .required('Username is required'),
              NewPassword: Yup.string()
                .typeError('Password is required')
                .test('passwordValidation', 
                      'Invalid password', 
                      (value) => {

                        function isLowerCase(charCode){
                          return (charCode > 96 && charCode < 123); // lower alpha (a-z)
                        }

                        function isUpperCase(charCode){
                          return (charCode > 64 && charCode < 91); // upper alpha (A-Z)
                        }

                        let isValid = false;
                        let hasUpperCase = false;
                        let hasLowerCase = false;
                        let hasNonAlphaNumericChar = false;

                        if(value){
                          for(let i=0; i <= value.length; i+=1) {
                            
                            const currentCharacterCode = value.charCodeAt(i);
                            if(!hasUpperCase){
                              hasUpperCase = isUpperCase(currentCharacterCode);
                            }

                            if(!hasLowerCase){
                              hasLowerCase = isLowerCase(currentCharacterCode);
                            }

                            if(!hasNonAlphaNumericChar){
                              hasNonAlphaNumericChar = 
                              !(currentCharacterCode > 47 && currentCharacterCode < 58) // numeric (0-9)
                                && !isUpperCase(currentCharacterCode)
                                && !isLowerCase(currentCharacterCode); 
                            }

                            isValid = 
                              hasUpperCase
                                && hasNonAlphaNumericChar
                                && hasLowerCase;

                            if(isValid){
                              break;
                            }
                          };
                        }

                        return isValid;
                      })
                .required('Password is required')
            })}
            onSubmit={(values) => this.handleSubmit(values)}
            validateOnChange
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
            }) => (
              <div className="account-layout-form-container">
                <div>
                  <Row justify="center">
                    <Col span={20}>
                      <div className="confirm-logo-container">
                        <img src={TechSosLogo} style={{"maxWidth": "450px"}} width="100%" id="Logo" alt="Logo" />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Form
                    name="Reset"
                    className="reset-form"
                    id="Reset"
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                    autoComplete="off"
                  >
                    <Row type="flex" justify="Center">
                      <Col span={24}>
                        <Row>
                          <h1 className="p5-form-h1-header"> Reset Password </h1>
                        </Row>
                      </Col>
                    </Row>
                    <Row type="flex" justify="Center">
                      <Col span={24}>
                        <Form.Item
                          validateStatus={errors && errors.UserName && touched.UserName ? 'error' : null}
                          help={errors && touched.UserName && errors.UserName}
                        >
                          <div className="p5-form-label"> Username</div>
                          <Input
                            type="text"
                            id="UserName"
                            className="p5-form-input"
                            name="UserName"
                            placeholder="Username"
                            value={values.UserName}
                            onChange={this.handleChange}
                            autoComplete="UserName"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="Center">
                      <Col span={24}>
                        <Form.Item
                          validateStatus={errors && errors.NewPassword && touched.NewPassword ? 'error' : null}
                          help={errors && touched.NewPassword && errors.NewPassword}
                        >
                          <div className="p5-form-label">  New Password </div>
                          <Input.Password
                            id="NewPassword"
                            className="p5-form-input"
                            name="NewPassword"
                            placeholder="NewPassword"
                            value={values.NewPassword}
                            onChange={this.handleChange}
                            autoComplete="NewPassword"
                          />
                        </Form.Item>
                      </Col>
                    </Row>                  
                    <Row style={{ paddingTop: 0, paddingBottom: 20, marginTop: 10, marginBottom: -5 }}>
                      <div className="form-label" style={{ fontSize: 14 }}>Passwords must have at least one lowercase letter, one uppercase letter, one number, and one special character !?@#$%^&amp;*</div>
                    </Row>
                    <Row justify="Center">
                      <Col span={24}>
                        <div className="form-label" style={{ fontSize: 14 }}>{this.validateHasMinCount(values.NewPassword)} <span>Contains at least 6 characters</span></div>
                        <div className="form-label" style={{ fontSize: 14 }}>{this.validateHasLowercase(values.NewPassword)} <span>Contains lowercase letter</span></div>
                        <div className="form-label" style={{ fontSize: 14 }}>{this.validateHasUppercase(values.NewPassword)} <span>Contains uppercase letter</span></div>
                        <div className="form-label" style={{ fontSize: 14 }}>{this.validateHasNumber(values.NewPassword)} <span>Contains number</span></div>
                        <div className="form-label" style={{ fontSize: 14 }}>{this.validateHasSpecialCharacter(values.NewPassword)} <span>Contains special character !?@#$%^&amp;*</span></div>
                      </Col>
                    </Row>
                    <br />
                    <Row className="btn-row">
                      <Button onClick={handleSubmit} type="submit" className="resetBtn" disabled={!this.validatePassword(values.NewPassword)}>
                        Reset
                      </Button>
                    </Row>                  
                  </Form>
                </div>
              </div>
            )}
          />
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  const { loading, hasError } = state;
  return {
    loading,
    hasError,
  };
}

const connectedResetForm = connect(mapStateToProps)(ResetForm);

ResetForm.propTypes = {
  dispatch: () => {},
  hasError: PropTypes.bool,
  loading: PropTypes.bool,
};

ResetForm.defaultProps = {
  dispatch: () => {},
  hasError: false,
  loading: false,
};

export default connectedResetForm;
