import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { ResourceLibService } from '../../../services';

const { Option } = Select;

class ResourceTypeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getResourceTypes();
  }

  getResourceTypes() {
    ResourceLibService.getResourceTypes().then((result) => {
      this.setState({
        data: result.data.value,
      });
    });
  }

  render() {
    return (
      <>
        <h2 className="form-label">
          Type
        </h2>
        <Select
          id="TypeId"
          className={this.props.className}
          value={this.props.value}
          onChange={this.props.onChange}        
        >
          {
            this.state.data.map((opt) => (
              <Option
                key={opt.Id}
                value={opt.Id}
              >
                {opt.Name}
              </Option>
            ))
          }
        </Select>
      </>
    );
  }
}

ResourceTypeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  className: PropTypes.string,
};

ResourceTypeSelect.defaultProps = {
  value: null,
  className: ""
};

export { ResourceTypeSelect as default };
