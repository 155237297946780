/* eslint import/prefer-default-export: 0 */
import api from '../common';

function getRegistrationsList(querystring) {
  return api.get(`/odata/RegistrationOData?${querystring}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function newRegistration(data) {
  return api.post('/api/Registration/Create', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getRegisteredVictim(id) {
  return api.get(`api/Victim/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getRegisteredVictims() {
  return api.get('api/Victim/GetRegisteredVictims', { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deregisterUser(id) {
  return api.post(`/api/Registration/deregister/${id}`, id, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function registerUser(data) {
  return api.post('/api/Victim/Create', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function extendRegistration(id) {
  return api.post(`/api/Registration/extend/${id}`, id, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function editUser(id, data) {
  return api.put(`/api/Victim/Update/${id}`, data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getRegistrationTypes() {
  return api.get('/odata/RegistrationTypesOData?$select=Id,Name&',{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function editVictimData(id, data) {
  return api.put(`/api/Victim/Update/${id}`, data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function createVictimData(data) {
  return api.post('/api/Victim/Create', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getVictimDataById(id) {
  return api.get(`/api/Victim/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function activateTrackaphone(data) {
  return api.post('/api/Registration/register/trackaphone', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getRegistrationbyId(id, consumerId) {
  return api.get(`/api/Registration/${id}/${consumerId}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function editAndExtendRegistration(id, data) {
  return api.put(`/api/Registration/ExtendUpdate/${id}`, data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function addMinData(data) {
  return api.post('/api/Registration/min/data', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getConfirmationCodes(querystring) {
  return api.get(`/odata/ConsumerOData?${querystring}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function registerGmail(data) {
  return api.post('/api/Registration/RegisterGmail', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export {
  getRegistrationsList,
  newRegistration,
  getRegisteredVictim,
  getRegisteredVictims,
  getRegistrationTypes,
  registerUser,
  extendRegistration,
  editUser,
  deregisterUser,
  editVictimData,
  createVictimData,
  getVictimDataById,
  activateTrackaphone,
  getRegistrationbyId,
  editAndExtendRegistration,
  addMinData,
  getConfirmationCodes,
  registerGmail,
};
