import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const StateInput = (props) => {
        return(
          <Form.Item
            validateStatus={
              props.errors && props.errors.City && props.touched.City
                  ? "error"
                  : null
              }
            help={props.errors && props.touched.City && props.errors.City}
          >
            <h2 className="form-label">
              City
            </h2>
            <Input 
              type="text" 
              id="City" 
              className="form-input"
              value={props.values.City} 
              onChange={props.onChange}
            />
          </Form.Item>
        );
}

StateInput.propTypes = {
    onChange: () => {},
    errors: PropTypes.shape({
        City: PropTypes.string,
    }),
    values: PropTypes.shape({
        City: PropTypes.string,
    }),
    touched: PropTypes.shape({
        City: PropTypes.bool,
    })
}

StateInput.defaultProps = {
    onChange: () => {},
    errors: {},
    values: {},
    touched: {}
}

export default StateInput;
