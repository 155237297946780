import imageUploadConstants from './constants';
import { FileService } from '../../../services';


function getFile(id) {
  function request() { return { type: imageUploadConstants.GET_REQUEST }; }
  function success(url) {
    return { type: imageUploadConstants.GET_SUCCESS, url };
  }
  function failure(error) { return { type: imageUploadConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    FileService.get(id)
      .then(
        (res) => {
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          const url = `data:${res.headers["content-type"]};base64,${base64}`;
          dispatch(success(url));
        },
        (error) => {
          dispatch(failure(error.toString()));
        },
      );
  };
}

function create(files, callback) {
  function request() {
    return { type: imageUploadConstants.CREATE_REQUEST };
  }
  function success() {
    return { type: imageUploadConstants.CREATE_SUCCESS };
  }
  function failure(error) {
    return { type: imageUploadConstants.CREATE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(files));
    files.forEach(file => {
      FileService.create(file).then(
        id => {
          dispatch(success(id));
          callback(id);
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
    });
  };
}

function getImage(id) {
  function request() { return { type: imageUploadConstants.GET_REQUEST }; }
  function success(url) {
    return { type: imageUploadConstants.GET_SUCCESS, url };
  }
  function failure(error) { return { type: imageUploadConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    FileService.getImageResize(id)
      .then(
        (res) => {
          const base64 = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          const url = `data:${res.headers["content-type"]};base64,${base64}`;
          dispatch(success(url));
        },
        (error) => {
          dispatch(failure(error.toString()));
        },
      );
  };
}


export default {
    create,
    getFile,
    getImage,
};
