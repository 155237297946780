import React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Col,
  Row,
  Modal,
  Button,
  Form,
  Steps,
  // message,
  Input,
  Result,
  Select,
  Spin
} from "antd";
import RegistrationTypeSelect from "../registration-type-select";
import ConfirmationCodeSelect from "../confirmation-code-select";
import { MinimumDataForm, LiteMinimumDataForm } from '../../../features/registeration/form';
import regAction from "./action";
import registrationAction from '../../../features/registeration/actions';
import { phoneNumberCountryCodes, getCountryCallingCode, UKPhoneNumberFormatter, longPhoneNumberFormatter } from "../../utils/phone-number-helper";
import { IsLiteVersion, IsFullVersion } from "../../utils/system-version-helper";

const { Step } = Steps;

class LiteRegistrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.props.handleCancel;
    this.handleOk = this.props.handleOk;
    this.state = {
      current: 0,
      ConsumerId: null,
      CountryCode: "",
      Id: null,
      PhoneNumber: "",
      RegistrationId: null,
    };
  }

  handleRegisterGmail(email, phoneNumber, countryCode) {
    const parsedPhoneNumber = countryCode !== 'UK' ? longPhoneNumberFormatter(phoneNumber) : UKPhoneNumberFormatter(phoneNumber);
    const finalPhoneNumber = `${getCountryCallingCode(countryCode)}${parsedPhoneNumber}`
    const pivotData = {
      Email: email,
      PhoneNumber: finalPhoneNumber
    };

    this.props.dispatch(regAction.registerGmail(pivotData, () => {
      this.next();
    }));
  }

  getRegistrationFromProps = () => {
    let stateId = 2;
    switch (this.props.deviceType) {
      case "Android":
      case "Apple":
      default:
        stateId = 1
        break;
      case "Handset":
        stateId = 2
        break;
    }

    return stateId;
  }

  onOk = () => {
    this.setState((prevState) => ({
      ...prevState,
      current: 0,
      CountryCode: "",
      Id: null,
      PhoneNumber: "",
    }));
    this.props.handleOk();
  };

  canCloseModal = () => {
    this.props.handleCancel();
  }

  onModalClose = () => {
    if (this.state.current === 3) {
      this.onOk();
    }
    this.setState({
      current: 0,
      ConsumerId: null,
      CountryCode: "",
      Id: null,
      PhoneNumber: null,
      RegistrationId: null,
    })
  }

  editVictimData = () => {
    this.props.dispatch(push(`/register/${this.props.dataId}/edit`));
  };

  handleChange = (data) => {
    const { value, id } = data.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  newRegistrationCallback = (RegistrationId) => {
    this.setState((prevState) => ({
      ...prevState,
      RegistrationId,
    }));

    this.next();
  }

  newRegistrationErrorCallback = (response) => {
    const WarnConfig = {
      Title: 'Unable to Complete Registration',
      content: (
        <>
          <div>
            <span>
              Unable to complete registration
            </span>
            <br />
            <br />
            <span>
              {`${response}`}
            </span>
          </div>
        </>
      )
    };

    const { warning } = Modal;
    warning(WarnConfig);
  }

  AddMinData = (data) => {
    this.props.dispatch(registrationAction.AddMinData(data, this.handlAddMinCallback));
  };

  handlAddMinCallback = () => {
    this.next();
  }

  next() {
    const current = this.state.current + 1;
    this.setState((prevState) => ({
      ...prevState,
      current,
    }));
  }

  CreateNewRegistration(vals) {
    const registrationId = this.getRegistrationFromProps();
    const pivotData = {
      PhoneCountryCode: getCountryCallingCode(vals.CountryCode),
      PhoneNumber: vals.CountryCode !== 'UK' ? longPhoneNumberFormatter(vals.PhoneNumber) : UKPhoneNumberFormatter(vals.PhoneNumber),
      RegistrationTypeId: registrationId,
      Id: vals.Id || 0,
      ConsumerId: registrationId === 2 ? null : vals.ConsumerId,
      GoogleEmail: vals.GoogleEmail
    };

    this.props.dispatch(regAction.newRegistration(pivotData, this.newRegistrationCallback, this.newRegistrationErrorCallback));
  }

  prev() {
    const current = this.state.current - 1;
    this.setState((prevState) => ({
      ...prevState,
      current,
    }));
  }

  render() {
    const { current } = this.state;

    const registrationId = this.getRegistrationFromProps();

    const needsGoogleVerification = this.props.deviceType === "Android";

    const steps = [
      {
        title: "New Registration",
      },
      {
        title: "Victim Data",
      },
      {
        title: "Complete",
      },
    ];

    if(needsGoogleVerification){
      steps.unshift({
        title: "Register Gmail"
      });
    }

    return (
      <>
        <Modal
          width={650}
          visible={this.props.isVisible}
          onOk={this.props.handleOk}
          onCancel={this.canCloseModal}
          afterClose={this.onModalClose}
          footer={false}
          // closable={steps[current].title !== "Victim Data"}
          destroyOnClose
        >
          <div className="mb-3">
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              RegistrationTypeId: registrationId,
              GoogleEmail: "",
              Id: this.state.Id,
              ConsumerId: this.state.ConsumerId,
              PhoneNumber: this.state.PhoneNumber,
            }}
            validationSchema={Yup.object().shape({
              RegistrationTypeId: Yup.string()
                .typeError("Registration Type is required")
                .required("Registration Type is required"),
              GoogleEmail: Yup.string()
                .email("Email must be in the correct form")
                .test("DeviceTest", "Email is required",
                  (value) => needsGoogleVerification ? (value && value.length > 0) : true
                ),
              CountryCode: Yup.string()
                .typeError("Country Code is required")
                .required("Country Code is required"),
              PhoneNumber: Yup.string().when("CountryCode", {
                is: (code) => code === "UK" || code === null || code === "",
                then: Yup.string()
                  .typeError("Phone Number is required")
                  .required("Phone Number is required")
                  .matches(/^[1-9].*$/, 'Phone number cannot begin with a 0')
                  .test("min-count-test", "Phone number is too short", (value) => {
                    const regextest = /(?=^[0-9].*$)(?=.{10,})/;
                    return value && regextest.test(value);
                  }),
                otherwise: Yup.string()
                  .typeError("Phone Number is required")
                  .required("Phone Number is required")
                  .matches(/^[1-9].*$/, 'Phone number cannot begin with a 0')
                  .max(25, 'Phone number cannot exceed more that 25 characters'),
              }),
              // ConsumerId: Yup.number()
              //   .typeError("Confirmation Code is required")
              //   .required("Confirmation Code is required")
            })}
            onSubmit={(values) => {
              this.CreateNewRegistration(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleSubmit,
              handleBlur
            }) => (
              <Form
                id="registerForm"
                name="registerForm"
                onSubmit={handleSubmit}
                onChange={handleChange}
                autoComplete={0}
              >
                {steps[current].title === "Register Gmail" && (
                  <>
                    <Row gutter={[16, 32]}>
                      <Col xs={24}>
                        <Form.Item
                          validateStatus={
                            errors && errors.GoogleEmail && touched.GoogleEmail
                              ? "error"
                              : "success"
                          }
                          help={
                            errors && touched.GoogleEmail && errors.GoogleEmail
                              ? errors.GoogleEmail
                              : null
                          }
                        >
                          <div className="form-label"> Email: </div>
                          <Input
                            type="email"
                            id="GoogleEmail"
                            name="GoogleEmail"
                            placeholder="Email"
                            value={values.GoogleEmail}
                            onChange={(val) => {
                              setFieldValue("GoogleEmail", val.target.value);
                            }}
                            onBlur={handleBlur}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 32]}>
                      <Col span={12}>
                        <Form.Item
                          validateStatus={
                            errors && errors.CountryCode && touched.CountryCode
                              ? "error"
                              : null
                          }
                          help={
                            errors && touched.CountryCode && errors.CountryCode
                          }
                        >
                          <div className="form-label"> Country Code: </div>
                          <Select
                            showSearch
                            placeholder="Ex. US (+1)"
                            onChange={(val) => {
                              setFieldValue("CountryCode", val)
                              setFieldValue("PhoneNumber", val !== 'UK' ? longPhoneNumberFormatter(values.PhoneNumber) : UKPhoneNumberFormatter(values.PhoneNumber));
                            }}
                            onBlur={handleBlur}
                          >
                            {phoneNumberCountryCodes
                              .map((code) => (
                                <Select.Option value={code} key={code}>
                                  {code} (+{getCountryCallingCode(code)})
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          validateStatus={
                            errors && errors.PhoneNumber && touched.PhoneNumber
                              ? "error"
                              : "success"
                          }
                          help={
                            errors && touched.PhoneNumber && errors.PhoneNumber
                              ? errors.PhoneNumber
                              : null
                          }
                        >
                          <div className="form-label">Phone Number: </div>
                          <Input
                            type="phone"
                            id="PhoneNumber"
                            name="PhoneNumber"
                            placeholder="Phone Number"
                            value={values.PhoneNumber}
                            onChange={(val) => {
                              setFieldValue("PhoneNumber", val.target.value);
                            }}
                            onBlur={handleBlur}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
                {steps[current].title === "New Registration" && (
                  <>
                    <Row gutter={[16, 32]}>
                      <Col span={12}>
                        <Form.Item
                          validateStatus={
                            errors &&
                              errors.RegistrationTypeId &&
                              touched.RegistrationTypeId
                              ? "error"
                              : null
                          }
                          help={
                            errors &&
                            touched.RegistrationTypeId &&
                            errors.RegistrationTypeId
                          }
                        >
                          <div className="form-label"> Registration Type: </div>
                          <RegistrationTypeSelect
                            key={values.RegistrationTypeId}
                            value={values.RegistrationTypeId}
                            disabled={!!this.props.deviceType}
                            onChange={(val) => {
                              if(!this.props.deviceType){
                                setFieldValue("RegistrationTypeId", val);
                                this.setState({ RegistrationTypeId: val });
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                      {
                        values.RegistrationTypeId === 1 && (
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                errors && errors.ConsumerId && touched.ConsumerId
                                  ? "error"
                                  : "success"
                              }
                              help={
                                errors && touched.ConsumerId && errors.ConsumerId
                                  ? errors.ConsumerId
                                  : null
                              }
                            >
                              <div className="form-label"> Confirmation Code(s): </div>
                              <ConfirmationCodeSelect
                                className="p5-form-dropdown sh-tour-confirm-code"
                                name="ConsumerId"
                                value={values.ConsumerId}
                                onChange={(val) => {
                                  setFieldValue("ConsumerId", val);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        )
                      }
                    </Row>
                    <Row gutter={[16, 32]}>
                      <Col span={12}>
                        <Form.Item
                          validateStatus={
                            errors && errors.CountryCode && touched.CountryCode
                              ? "error"
                              : null
                          }
                          help={
                            errors && touched.CountryCode && errors.CountryCode
                          }
                        >
                          <div className="form-label"> Country Code: </div>
                          <Select
                            className="sh-tour-country-code-select"
                            showSearch
                            placeholder="Ex. US (+1)"
                            onChange={(val) => {
                              setFieldValue("CountryCode", val)
                              setFieldValue("PhoneNumber", val !== 'UK' ? longPhoneNumberFormatter(values.PhoneNumber) : UKPhoneNumberFormatter(values.PhoneNumber));
                            }}
                            onBlur={handleBlur}
                            disabled={needsGoogleVerification}
                          >
                            {phoneNumberCountryCodes
                              .map((code) => (
                                <Select.Option value={code} key={code}>
                                  {code} (+{getCountryCallingCode(code)})
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          validateStatus={
                            errors && errors.PhoneNumber && touched.PhoneNumber
                              ? "error"
                              : "success"
                          }
                          help={
                            errors && touched.PhoneNumber && errors.PhoneNumber
                              ? errors.PhoneNumber
                              : null
                          }
                        >
                          <div className="form-label"> Phone Number:</div>
                          <Input
                            type="text"
                            id="PhoneNumber"
                            className="sh-tour-phone-number-cli"
                            name="PhoneNumber"
                            value={values.CountryCode !== 'UK' ? longPhoneNumberFormatter(values.PhoneNumber) : UKPhoneNumberFormatter(values.PhoneNumber)}
                            placeholder="Phone #"
                            onChange={(val) => {
                              setFieldValue("PhoneNumber", val.target.value);
                            }}
                            onBlur={handleBlur}
                            autoComplete="off"
                            disabled={needsGoogleVerification}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}

                {steps[current].title === "Complete" && (
                  <>
                    <Row justify="center">
                      <Col span={24}>
                        {this.state.loading ?
                          <Spin size="large" /> :
                          <Result status="success" title="Registration Complete" />}
                      </Col>
                    </Row>
                  </>
                )}
                <div className="steps-content">{steps[current].content}</div>
                <div className="steps-action">
                  {steps[current].title === "Register Gmail" && (
                    <Button
                      type="primary"
                      onClick={() => this.handleRegisterGmail(values.GoogleEmail, values.PhoneNumber, values.CountryCode)}
                      className="sh-tour-add-new-reg-btn"
                      disabled={!(touched.GoogleEmail && touched.PhoneNumber) || (errors.GoogleEmail || errors.PhoneNumber || errors.CountryCode)}
                      loading={this.props.loading}
                    >
                      Register Gmail Account
                    </Button>
                  )}
                  {steps[current].title === "New Registration" && (
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      className="sh-tour-add-new-reg-btn"
                      disabled={errors && (errors.CountryCode || errors.PhoneNumber) || values.RegistrationTypeId === 1 && values.ConsumerId === null}
                      loading={this.props.loading}
                    >
                      Add New Registration
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={this.onOk}
                      loading={this.props.loading}
                    >
                      Complete
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
          {current < steps.length - 1 &&
            IsFullVersion() &&
            steps[current].title === "Victim Data" && (
              <div>
                <MinimumDataForm Id={this.state.RegistrationId} isLoading={this.props.minDataLoading} onSubmit={this.AddMinData} showClose onCancel={() => this.canCloseModal} />
              </div>
            )}
          {current < steps.length - 1 &&
            IsLiteVersion() &&
            steps[current].title === "Victim Data" && (
              <div>
                <LiteMinimumDataForm Id={this.state.RegistrationId} isLoading={this.props.minDataLoading} onSubmit={this.AddMinData} showClose onCancel={() => this.canCloseModal} />
              </div>
            )}
        </Modal>
      </>
    );
  }
}

LiteRegistrationModal.propTypes = {
  // onSubmit: PropTypes.func.isRequired,
  dispatch: () => { },
  isVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  dataId: PropTypes.number,
  loading: PropTypes.bool,
  minDataLoading: PropTypes.bool,
  deviceType: PropTypes.string,
};

LiteRegistrationModal.defaultProps = {
  dispatch: () => { },
  isVisible: false,
  handleOk: () => { },
  handleCancel: () => { },
  dataId: 0,
  loading: false,
  minDataLoading: false,
  deviceType: ""
};

function mapStateToProps(state) {
  const { dataId, loading } = state.RegistrationModal;

  const minDataLoading = state.RegisterUserFormState.loading;
  return {
    dataId,
    loading,
    minDataLoading,
  };
}

const connectedLiteRegistrationModal = connect(mapStateToProps)(LiteRegistrationModal);

export { connectedLiteRegistrationModal as default };
