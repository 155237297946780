import c from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  list: [],
  victim: {},
  error: {},
};

export default function SearchReducer(state = initialState, action) {
  switch (action.type) {
    case c.GET_VICTIM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_VICTIM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        list: action.details.data.Data,
      };
    case c.GET_VICTIM_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_VICTIM_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_VICTIM_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        victim: action.data.Data,
      };
    case c.GET_VICTIM_VIEW_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}
