import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const NumberInput = (props) => {
  return(
    <Form.Item
      validateStatus={
        props.errors && props.errors.Number && props.touched.Number
          ? "error"
          : null
      }
      help={props.errors && props.touched.Number && props.errors.Number}
    >
      <h2 className="form-label">
        Number
      </h2>
      <Input 
        type="text" 
        id="Number" 
        className="form-input"
        value={props.values.Number} 
        onChange={props.onChange}
      />
    </Form.Item>
  );
}

NumberInput.propTypes = {
    onChange: () => {},
    errors: PropTypes.shape({
        Number: PropTypes.string,
    }),
    values: PropTypes.shape({
        Number: PropTypes.string,
    }),
    touched: PropTypes.shape({
        Number: PropTypes.bool,
    })
}

NumberInput.defaultProps = {
    onChange: () => {},
    errors: {},
    values: {},
    touched: {}
}

export default NumberInput;
