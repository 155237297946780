import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "antd";
import actions from "../../../features/account/actions";

const IDLE_TIME_BEFORE_MODAL = 60 * 20; // 20 minutes before the modal appears
const IDLE_TIME_WITIN_MODAL = 60; // 60 seconds before the modal automatically logs a user out

const transformTime = (time) => Math.floor((time + 500) / 1000);

class AutoLogoutModal extends React.Component {
  constructor(props) {
    super(props);

    this.interval = null;

    this.defaultState = {
      lastActionTime: 0,
      modalInitTime: 0,
      modalIdleCountdown: 0,
      showModal: false,
      forLocationKey: "",
    }

    this.state = {
      ...this.defaultState,
    }
  }

  componentDidMount() {
    if (process.env.DISABLE_LOGOUT) {
      return;
    }

    if (this.interval) {
      clearTimeout(this.interval);
    }

    this.interval = setInterval(() => {
      this.setState((oldState) => {
        const currentTime = transformTime(Date.now());
        const { lastActionTime, modalInitTime } = oldState;

        if (!this.props.loggedIn) {
          // Don't bother logged-in users
          return {
            ...this.defaultState
          };
        }

        if (lastActionTime === 0) {
          // Record initial time
          return {
            ...oldState,
            lastActionTime: currentTime
          }
        }

        const actionTimeDifference = currentTime - lastActionTime;

        const shouldShowModal = oldState.showModal || actionTimeDifference >= IDLE_TIME_BEFORE_MODAL;
        const shouldLogout = actionTimeDifference > (IDLE_TIME_BEFORE_MODAL + IDLE_TIME_WITIN_MODAL);

        if (shouldLogout) {
          this.logoutUser(false);
          return {
            ...this.defaultState
          };
        }

        if (shouldShowModal) {
          // Calculate time difference since the interval last fired
          let newModalInitTime = modalInitTime;
          let modalTimeDifference = currentTime - modalInitTime;

          if (!oldState.showModal) {
            // This is the first time the interval fired, so initialize values
            newModalInitTime = lastActionTime + IDLE_TIME_BEFORE_MODAL;
            modalTimeDifference = currentTime - newModalInitTime;
          }

          if (modalTimeDifference >= IDLE_TIME_WITIN_MODAL) {
            // The countdown has hit 0
            this.logoutUser(false);
            return {
              ...this.defaultState
            }
          }

          return {
            ...oldState,
            modalInitTime: newModalInitTime,
            modalIdleCountdown: modalTimeDifference,
            showModal: shouldShowModal
          }
        }

        return {
          ...oldState,
        }
      });
    }, 1000 * 1);

    window.document.onclick = this.resetTimer.bind(this);
    window.document.onkeydown = this.resetTimer.bind(this);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearTimeout(this.interval);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location && props.location.key && props.location.key !== state.forLocationKey) {
      return {
        lastActionTime: 0,
        modalInitTime: 0,
        showModal: false,
        modalIdleCountdown: 0,
        forLocationKey: props.location.key,
      }
    }
    return null;
  }

  hideModal() {
    this.setState({
      ...this.defaultState
    })
  }

  logoutUser(shouldUpdateState) {
    const { dispatch } = this.props;

    if (shouldUpdateState) {
      this.hideModal();
    }

    dispatch(actions.Logout());
  }

  resetTimer() {
    this.setState({
      lastActionTime: transformTime(Date.now()),
    });
  }

  render() {
    const logoutTime = IDLE_TIME_WITIN_MODAL - this.state.modalIdleCountdown;

    return (
      <>
        <Modal
          title="Inactivity Warning"
          visible={this.state.showModal}
          onOk={() => this.hideModal()}
          onCancel={() => this.logoutUser()}
          closable={false}
          okText="Continue Session"
          cancelText="Logout"
        >
          <p>You have been inactive for {IDLE_TIME_BEFORE_MODAL / 60} minutes.</p>
          <p>You will be logged out in {logoutTime} second{logoutTime === 1 ? "" : "s"}.</p>
          <p>If you wish to stay logged in, you may do so by pressing Continue Session.</p>
        </Modal>
      </>
    );
  }
}

AutoLogoutModal.propTypes = {
  dispatch: PropTypes.func,
  location: PropTypes.shape({
    key: PropTypes.string
  }),
  loggedIn: PropTypes.bool,
};

AutoLogoutModal.defaultProps = {
  dispatch: () => { },
  location: {},
  loggedIn: false,
};

function mapStateToProps(state) {
  const { location } = state.router;
  const { loggedIn } = state.AccountState;
  return {
    location,
    loggedIn,
  };
}

const connectedAutoLogoutModal = connect(mapStateToProps)(AutoLogoutModal);

export { connectedAutoLogoutModal as default };