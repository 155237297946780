import c from './constants';

const initialState = {
  loading: false,
  hasError: false,
  currentForce: {},
  list: {},
  error: {},
};

export default function LEAReducer(state = initialState, action) {
  switch (action.type) {
    case c.GET_LEA_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_LEA_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        list: action.list,
      };
    case c.GET_LEA_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.CREATE_LEA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.CREATE_LEA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        currentForce: action.data,
      };
    case c.CREATE_LEA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_LEA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_LEA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.GET_LEA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.GET_LEA_ID_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_LEA_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        currentForce: action.force.data,
      };
    case c.GET_LEA_ID_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.UPDATE_LEA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.UPDATE_LEA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.UPDATE_LEA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.DELETE_LEA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.DELETE_LEA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.DELETE_LEA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}
