import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Empty } from "antd";
import { CustomBarChart } from "../../../common/components/dashboard";

class RegisteredDeviceBarChartView extends React.Component {
  render() {
    return (
      <>
        <div className="p5-form-container">
          <Row className="text-center" justify="space-around" align="middle">
            <Col span={24}>
              <div>
                <h1 className="p5-dash-h1-header text-center">{this.props.title}</h1>
              </div>
            </Col>
          </Row> 
          <br />
          {
            this.props.data.length === 0 ? (
              <>
                <Empty />
              </>
              ) : (
                <CustomBarChart data={this.props.data} xField="RegisteredDate" yField="AllocatedCount" seriesField="AreaName" />
              )
          }          
        </div>
      </>
    );
  }
}

RegisteredDeviceBarChartView.propTypes = {
//   loading: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

RegisteredDeviceBarChartView.defaultProps = {
//   loading: false,
  title: "",
  data: [],
};

export { RegisteredDeviceBarChartView as default };
