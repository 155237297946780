import React from "react";
import { connect } from "react-redux";

class InstallPage extends React.Component {
  render() {
    return (
      <>
        <div>
          <h1 style={{ textAlign: "center", fontSize: "30px" }}>
            Welcome to Aspire Mobile
          </h1>
          <br />
          <br />
          <br />
          <br />
          <h1 style={{ textAlign: "center", fontSize: "30px" }}>
            Upgrade <a href={process.env.INSTALL_LINK}>Here</a>
          </h1>
        </div>
      </>            
    );
  }
}

function mapStateToProps() {
  return {};
}

const connectedInstallPage = connect(mapStateToProps)(InstallPage);

export { connectedInstallPage as default };
