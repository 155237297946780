import c from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  detail: {},
  error: {},
  dataId: 0,
};

export default function RegistrationModalReducer(state = initialState, action) {
  switch (action.type) {
    case c.NEW_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.NEW_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        dataId: action.result.data,
      };
    case c.NEW_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.REGISTER_GMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.REGISTER_GMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        dataId: action.result.data,
      };
    case c.REGISTER_GMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}