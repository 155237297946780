import { toast } from "react-toastify";
import { push } from 'connected-react-router';
import moment from "moment";
import registrationConstants from './constants';
import { RegisterService, FileService, SearchService } from '../../services';
import { TourRegistrationData } from '../guide-tour/tour-data';

function CreateVictimData(data, callback) {
  function request() {
    return { type: registrationConstants.CREATE_VICTIM_REQUEST };
  }
  function success(Id) {
    return { type: registrationConstants.CREATE_VICTIM_SUCCESS, Id };
  }
  function failure(error) {
    return { type: registrationConstants.CREATE_VICTIM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.createVictimData(data).then(
      (response) => {
        dispatch(success(response));
        dispatch(push('/register'));
        toast("Successfully added Victim Data");          
        callback(response.data);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

function GetVictimDataById(Id) {
  function request() {
    return { type: registrationConstants.GET_VICTIM_REQUEST };
  }
  function success(victim) {
    return { type: registrationConstants.GET_VICTIM_SUCCESS, victim };
  }
  function failure(error) {
    return { type: registrationConstants.GET_VICTIM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    RegisterService.getVictimDataById(Id).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function getVictim(id, callback) {
  function request() { return { type: registrationConstants.GET_REQUEST }; }
  function success() {
    return { type: registrationConstants.GET_SUCCESS };
  }
  function failure(error) { return { type: registrationConstants.GET_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    RegisterService.getRegisteredVictim(id)
      .then(
        (result) => {
          dispatch(success());
          callback(result.data);
        },
        (error) => {
          dispatch(failure(error.toString()));
        },
      );
  };
}

function DeregisterVictim(id, callback) {
  function request() { return { type: registrationConstants.DELETE_VICTIM_REQUEST }; }
  function success() {
    return { type: registrationConstants.DELETE_VICTIM_SUCCESS };
  }
  function failure(error) { return { type: registrationConstants.DELETE_VICTIM_FAILURE, error }; }
  return (dispatch) => {
    dispatch(request());
    RegisterService.deregisterUser(id)
      .then(
        (response) => {
          dispatch(success());
          if(response.data.HasError)
            toast.error(response.data.Error);
          else
            toast("Device Successfully Deregistered");

          callback();
        },
        (error) => {
          dispatch(failure(error.toString()));
          callback();
        },
      );
  };
}

function UpdateVictimData(data) {
  function request() {
    return { type: registrationConstants.UPDATE_VICTIM_REQUEST };
  }
  function success() {
    return { type: registrationConstants.UPDATE_VICTIM_SUCCESS };
  }
  function failure(error) {
    return { type: registrationConstants.UPDATE_VICTIM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.editVictimData(data.Id, data).then(
      () => {        
        dispatch(success());
        dispatch(push('/register'));
        toast("Successfully updated Victim Data");       
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

function ExtendRegistration(id, callback) {
  function request() {
    return { type: registrationConstants.EXTEND_REGISTRATION_REQUEST };
  }
  function success() {
    return { type: registrationConstants.EXTEND_REGISTRATION_SUCCESS };
  }
  function failure(error) {
    return { type: registrationConstants.EXTEND_REGISTRATION_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.extendRegistration(id).then(
      () => {        
        dispatch(success());
        // toast("You have successfully extended this registration.");   
        callback();    
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function DeleteImage(id) {
  function request() { return { type: registrationConstants.DELETE_REQUEST }; }
  function success() {
    return { type: registrationConstants.DELETE_SUCCESS };
  }
  function failure(error) { return { type: registrationConstants.DELETE_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request());
    FileService.get(id)
      .then(
        () => {
          dispatch(success());
        },
        (error) => {
          dispatch(failure(error.toString()));
        },
      );
  };
}

function SetCreateDataForm(Id) {
  function request(RegId) {
    return { type: registrationConstants.CREATE_REQUEST, RegId };
  }

  return (dispatch) => {
    dispatch(request({RegistrationId: Id}));    
  };
}

function Register(data, callback, closeModal) {
  function request() {
    return { type: registrationConstants.REGISTER_REQUEST };
  }
  function success() {
    return { type: registrationConstants.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: registrationConstants.REGISTER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.newRegistration(data).then(
      (response) => {
        if(response.data.HasError)
        {
          dispatch(failure(response.data.Error));
          toast.error(response.data.Error);
          closeModal();
        }
        else
        {
          dispatch(success(response));
          toast("Successfully added new registration");
          callback(response.data);
        }   
        // dispatch(success());
        // toast("Successfully created a New Registration");
        // callback(response.data);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };
}

function GetRegisterDetails(Id, callback) {
  function request() {
    return { type: registrationConstants.GET_REGISTER_REQUEST };
  }
  function success(detail) {
    return { type: registrationConstants.GET_REGISTER_SUCCESS, detail };
  }
  function failure(error) {
    return { type: registrationConstants.GET_REGISTER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.getRegistrationbyId(Id, 0).then(
      (data) => {
        dispatch(success(data));  
        callback();      
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function TrackaphoneActivate(data, callback) {
  function request() {
    return { type: registrationConstants.TRACKAPHONE_ACTIVATE_REQUEST };
  }
  function success() {
    return { type: registrationConstants.TRACKAPHONE_ACTIVATE_SUCCESS };
  }
  function failure(error) {
    return { type: registrationConstants.TRACKAPHONE_ACTIVATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.activateTrackaphone(data).then(
      (response) => {
        dispatch(success());
        if(response.data.HasError)
          toast.error(response.data.Error);
        else
          toast("Device activated with TrackaPhone");    

        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function hideRegistrationEditModal(callback) {
  function hide() { return { type: registrationConstants.HIDE_REGISTRATION_EDIT_MODAL }; }
  return (dispatch) => {
    dispatch(hide());
    callback();
  }
}

function openRegistrationEditModal(Id, consumerId) {
  function request() {
    return { type: registrationConstants.REGISTRATION_DETAIL_REQUEST };
  }
  function success(registrationData) {
    return { type: registrationConstants.REGISTRATION_DETAIL_SUCCESS, registrationData };
  }
  function failure(error) {
    return { type: registrationConstants.REGISTRATION_DETAIL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.getRegistrationbyId(Id, consumerId).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function openTourRegistrationEditModal(Id, consumerId) {
  function request() {
    return { type: registrationConstants.TOUR_REGISTRATION_DETAIL_REQUEST };
  }
  function success(registrationData) {
    return { type: registrationConstants.TOUR_REGISTRATION_DETAIL_SUCCESS, registrationData };
  }

  return (dispatch) => {
    dispatch(request());
    console.log('tour modal detail', TourRegistrationData.RegistrationData.filter(f => f.RegistrationId === Id && f.ConsumerId === consumerId));
    dispatch(success(TourRegistrationData.RegistrationData.filter(f => f.RegistrationId === Id && f.ConsumerId === consumerId)));
  }
}

function openExtendModal(Id) {
  function request() {
    return { type: registrationConstants.EXTEND_DETAIL_REQUEST };
  }
  function success(ExtendData) {
    return { type: registrationConstants.EXTEND_DETAIL_SUCCESS, ExtendData };
  }
  function failure(error) {
    return { type: registrationConstants.EXTEND_DETAIL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.getVictimDataById(Id).then(
      (response) => {        
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function openTourExtendModal() {
  function request() {
    return { type: registrationConstants.TOUR_EXTEND_DETAIL_REQUEST };
  }
  function success(ExtendData) {
    return { type: registrationConstants.TOUR_EXTEND_DETAIL_SUCCESS, ExtendData };
  }

  return (dispatch) => {
    dispatch(request());
    const sampleData =  {
      Id: 0,
      RegistrationId: 0,
      Name: "",      
      GenderId: 1,
      CreatedDate: "",
      FCR_EmailAddress: "",
      FCR_PhoneNumber: "",
      CrimeRefNumber: "",
      PerpetratorName: "",
      ImageIds: [],
      VictimAddresses: [
        {
          Id: 0,
          VictimId: 0,
          AddressTypeId: 1,
          Address: "",
          PostalCode: "",
          County: "",
          City: "",
        },
      ],
      VictimPerpetratorDetails: {
        Id: 0,
        VictimId: 0,
        Name: "",
        GenderId: 1,
        NameNotKnown: false,
        DescriptionNotKnown: false,
        MONotKnown: false,
        PNCNotKnown: false,
        DeployConsidNotKnown: false,
        DOB: moment(),
        DOBNotKnown: false,
        PNCID: "",
        WarningTypeIds: [],
        WarningTypeNone: false,
        RiskCategories: [],
        Description: "",
        MO: "",
        CourtOrderConditions: "",
        DeploymentConsiderations: "",
        ImageIds: [],
        VictimPerpetratorVehicleDetails: [
          {
            Id: 0,
            PerpetratorId: 0,
            VRM: "",
            Make: "",
            Model: "",
            Colour: "",
          },
        ],
      },
      VictimChildren: [
        {
          Id: 0,
          VictimId: 0,
          Name: "",
          Age: 0,
          Address: "",
          PostalCode: "",
          County: "",
          City: "",
        },
      ],
      VictimVehicleDetail: [
        {
          Id: 0,
          VictimId: 0,
          VRM: "",
          Make: "",
          Model: "",
          Year: 0,
          Colour: "",
        },
      ],
      RiskLevelId: 1,      
      OIC_Name: "",
      OIC_StaffID: "",
      AuthorisingOfficer: null,

      disabilityOptions: [{}],
      IsTour: true,
    };
    dispatch(success(sampleData));    
  }
}


function hideExtendModal() {
  function hide() { return { type: registrationConstants.HIDE_EXTEND_MODAL }; }
  return (dispatch) => {
    dispatch(hide());
  }
}

function GetRegistrationList(querystring, callback) {
  function request() {
    return { type: registrationConstants.REGISTRATION_LIST_REQUEST };
  }
  function success() {
    return { type: registrationConstants.REGISTRATION_LIST_SUCCESS };
  }
  function failure(error) {
    return { type: registrationConstants.REGISTRATION_LIST_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.getRegistrationsList(querystring).then(
      (response) => {
        dispatch(success());
        callback(response.data.value);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function UpdateAndExtendRegistration(data, callback) {
  function request() {
    return { type: registrationConstants.UPDATE_EXTEND_REQUEST };
  }
  function success() {
    return { type: registrationConstants.UPDATE_EXTEND_SUCCESS };
  }
  function failure(error) {
    return { type: registrationConstants.UPDATE_EXTEND_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.editAndExtendRegistration(data.RegistrationId, data).then(
      () => {        
        dispatch(success());
        toast("Successfully updated Updated and Extended Registration");  
        callback();     
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

function AddMinData(data, callback) {
  function request() {
    return { type: registrationConstants.ADD_MIN_DATA_REQUEST };
  }
  function success() {
    return { type: registrationConstants.ADD_MIN_DATA_SUCCESS };
  }
  function failure(error) {
    return { type: registrationConstants.ADD_MIN_DATA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    RegisterService.addMinData(data).then(
      () => {
        dispatch(success());
        toast("Successfully created add victim data");
        callback(data.RegistrationId);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function RegistrationAreaFilter(data)
{
  function request(area) { 
    return { type: registrationConstants.REGISTRATION_AREA_FILTER, area };
  }

  return (dispatch) => {
    dispatch(request(data));
  }
}

function RegistrationTeamFilter(data)
{
  function request(team) { 
    return { type: registrationConstants.REGISTRATION_TEAM_FILTER, team };
  }

  return (dispatch) => {
    dispatch(request(data));
  }
}

function openVictimDetailModal(Id) {
  function request() {
    return { type: registrationConstants.VICTIM_DETAIL_REQUEST };
  }
  function success(VictimData) {
    return { type: registrationConstants.VICTIM_DETAIL_SUCCESS, VictimData };
  }
  function failure(error) {
    return { type: registrationConstants.VICTIM_DETAIL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SearchService.getVictimView(Id).then(
      (response) => {        
        dispatch(success(response.data[0]));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}


function hideVictimDetailModal() {
  function hide() { return { type: registrationConstants.HIDE_VICTIM_MODAL }; }
  return (dispatch) => {
    dispatch(hide());
  }
}

export default {
  CreateVictimData,
  DeregisterVictim,
  getVictim,
  DeleteImage,
  SetCreateDataForm,
  GetVictimDataById,
  UpdateVictimData,
  ExtendRegistration,
  Register,
  TrackaphoneActivate,
  GetRegisterDetails,
  hideRegistrationEditModal,
  openRegistrationEditModal,
  GetRegistrationList,
  openExtendModal,
  hideExtendModal,
  UpdateAndExtendRegistration,
  AddMinData,
  RegistrationAreaFilter,
  RegistrationTeamFilter,
  openVictimDetailModal,
  hideVictimDetailModal,
  openTourRegistrationEditModal,
  openTourExtendModal,
};
