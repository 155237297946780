import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const AppActivationKeyInput = (props) => {
        return(
          <Form.Item
            validateStatus={
              props.errors && props.errors.AppActivationKey && props.touched.AppActivationKey
                  ? "error"
                  : null
              }
            help={props.errors && props.touched.AppActivationKey && props.errors.AppActivationKey}
          >
            <h2 className="form-label">
              App Activation Key
            </h2>
            <Input 
              type="text"                                                           
              id="AppActivationKey" 
              className="form-input"
              value={props.values.AppActivationKey} 
              onChange={props.onChange}
            />
          </Form.Item>
        );
}

AppActivationKeyInput.propTypes = {
    onChange: () => {},
    errors: PropTypes.shape({
        AppActivationKey: PropTypes.string,
    }),
    values: PropTypes.shape({
        AppActivationKey: PropTypes.string,
    }),
    touched: PropTypes.shape({
        AppActivationKey: PropTypes.bool,
    })
}

AppActivationKeyInput.defaultProps = {
    onChange: () => {},
    errors: {},
    values: {},
    touched: {}
}

export default AppActivationKeyInput;
