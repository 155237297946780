import GenderConstants from './constants';

const initialState = ({ loading: false, hasError: false, genders: [] });

export default function inquiryContactPicker(state = initialState, action) {
  switch (action.type) {
    case GenderConstants.LIST_REQUEST:
        return {
            ...state,
            loading: true,
            hasError: false,
          };
    case GenderConstants.LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            hasError: false,
            genders: action.genders
          };
    case GenderConstants.LIST_FAILURE:
        return {
            ...state,
            loading: false,
            hasError: true,
          };
    default:
      return state;
  }
}