
const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
          enabled: false
        }
      },
      useModalOverlay: true
};

const HubAllocationSteps = [    
  {
    title: "Hub View",
    id: "tour-hub-start",
    text: [
      `
      <p>
      The Hub allows you to view the number of WN App licences and TecSAFE handsets that the force has, and also indicates how many are currently in use and how many are available. 
      </p>
      `,
    ],
    attachTo: { element: "", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Force Name",
    id: "tour-hub-force",
    text: [
      `
      <p>
      This is the name of the Force you are part of. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-hub-force", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Who's News Apps",
    id: "tour-hub-app",
    text: [
      `
      <p>
      Who's News Mobile App Licence (WN App).
      </p>
      <p>
      This indicates the number of WN App licences the Force has available and the number currently in use.  
      Each licence licenses enables the use of one WN App emergency functionality at any one time.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-hub-app", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Apps Licenses Start Date",
    id: "tour-hub-app-start",
    text: [
      `
      <p>
      This shows the date the Force started its contract for TecSAFE WN App licences.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-hub-start-date", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Apps Licenses End Date",
    id: "tour-hub-app-end",
    text: [
      `
      <p>
      This shows the date the Force ends its contract for TecSAFE WN App licences.
      </p>
      <p>
      After this date you will no longer be able to register WN Apps unless the contract is renewed, and neither will you be able to extend an exisiting registered App beyond this date. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-hub-end-date", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "App Licenses Used",
    id: "tour-hub-app-used",
    text: [
      `
      <p>
      This shows the number WN App licences currently in use. 
      <p>
      `,
    ],
    attachTo: { element: ".sh-tour-hub-app-used", on: "top" },
    buttons: [
        {
            classes: "shepherd-button-secondary",
            text: "Back",
            type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "App Licenses Remaining",
    id: "tour-hub-app-remaining",
    text: [
      `
      <p>
      This shows the number WN App licences currently remaining.  
      <p>
      `,
    ],
    attachTo: { element: ".sh-tour-hub-app-remaining", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  },
  {
    title: "Handsets",
    id: "tour-hub-handset",
    text: [
      `
      <p>
      This shows the number of TecSAFE handsets the Force has available and the number currently in use.  
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-hub-handset", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Handset Licenses Start Date",
    id: "tour-hub-handset-start",
    text: [
      `
      <p>
      This shows the date the Force started its contract for TecSAFE handsets.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-hub-handset-start-date", on: "top" },
    buttons: [
        {
            classes: "shepherd-button-secondary",
            text: "Back",
            type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Handset End Date",
    id: "tour-hub-handset-end",
    text: [
      `
      <p>
      This shows the date the Force ends its contract for TecSAFE handsets.
      </p>
      <p>
      After this date you will no longer be able to register TecSAFE handsets unless the contract is renewed, and neither will you be able to extend an existing registered Handset beyond this date.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-hub-handset-end-date", on: "top" },
    buttons: [
        {
            classes: "shepherd-button-secondary",
            text: "Back",
            type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Handset Licenses Used",
    id: "tour-hub-handset-used",
    text: [
      `
      <p>
      This shows the current number of TecSAFE handsets in use. 
      <p>
      `,
    ],
    attachTo: { element: ".sh-tour-hub-handset-used", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Handset Licenses Remaining",
    id: "tour-hub-handset-remaining",
    text: [
      `
      <p>
      This shows the current number of TecSAFE handsets remaining.    
      <p>
      `,
    ],
    attachTo: { element: ".sh-tour-hub-handset-remaining", on: "top" },
    buttons: [
        {
            classes: "shepherd-button-secondary",
            text: "Back",
            type: "back",
        },
        {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
        },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Allocation Hub Complete",
    id: "tour-hub-complete",
    text: [
      `
      <p>
      That concludes the Allocation Hub Tour. 
      Please select Complete to this tour to mark as complete.
      </p>
      `,
    ],
    attachTo: { element: "", on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Complete",
        type: "complete",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
];

  export default {
    tourOptions,
    HubAllocationSteps
  }