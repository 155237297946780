import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option } = Select;

class ConfirmationCodeTourSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getInactiveCodes();
  }

  getInactiveCodes() {
    this.setState({
        data: [{"Id":1,"ConfirmationCode":"123ABC"}, {"Id":2,"ConfirmationCode":"456DEF"}],
      });
  }

  render() {
    return (
      <Select
        showSearch
        className={this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}  
        onFocus={() => this.getInactiveCodes()}
        optionFilterProp="children"  
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}    
      >
        {
          this.state.data.map((opt) => (
            <Option
              key={opt.ConfirmationCode}
              value={opt.Id}
            >
              {opt.ConfirmationCode}
            </Option>
          ))
        }
      </Select>
    );
  }
}

ConfirmationCodeTourSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  className: PropTypes.string,
};

ConfirmationCodeTourSelect.defaultProps = {
  value: null,
  className: ""
};

export { ConfirmationCodeTourSelect as default };
