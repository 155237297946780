import systemConstants from './constants';
import { SystemAdminService } from '../../services';

function GetVpsList(startDate, endDate) {
  function request() {
    return { type: systemConstants.GET_VPS_REQUEST };
  }
  function success(vpsList) {
    return { type: systemConstants.GET_VPS_SUCCESS, vpsList };
  }
  function failure(error) {
    return { type: systemConstants.GET_VPS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemAdminService.getVpsHelped(startDate, endDate).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function GetLicensedUsedList(startDate, endDate) {
  function request() {
    return { type: systemConstants.GET_LICENSED_REQUEST };
  }
  function success(licensedUsedData) {
    return { type: systemConstants.GET_LICENSED_SUCCESS, licensedUsedData };
  }
  function failure(error) {
    return { type: systemConstants.GET_LICENSED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemAdminService.getLicensesUsed(startDate, endDate).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function GetOverdueRegistrations(startDate, endDate, forceId, areaId, teamId, allForces = false) {
  function request() {
    return { type: systemConstants.GET_OVERDUE_REGISTRATIONS_REQUEST };
  }
  function success(overdueRegistrations) {
    return { type: systemConstants.GET_OVERDUE_REGISTRATIONS_SUCCESS, overdueRegistrations };
  }
  function failure(error) {
    return { type: systemConstants.GET_OVERDUE_REGISTRATIONS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemAdminService.getOverdueRegistrations(startDate, endDate, forceId, areaId ?? 0, teamId ?? 0, allForces).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function GetVictimDashDetails(startDate, endDate) {
  function request() {
    return { type: systemConstants.GET_VICTIM_DETAILS_REQUEST };
  }
  function success(victimDetails) {
    return { type: systemConstants.GET_VICTIM_DETAILS_SUCCESS, victimDetails };
  }
  function failure(error) {
    return { type: systemConstants.GET_VICTIM_DETAILS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemAdminService.getVictimDashDetails(startDate, endDate).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function GetDevicesRegistered(startDate, endDate, forceId, areaId, teamId, allForces = false) {
  function request() {
    return { type: systemConstants.GET_REGISTERED_DEVICES_REQUEST };
  }
  function success(registeredDevices) {
    return { type: systemConstants.GET_REGISTERED_DEVICES_SUCCESS, registeredDevices };
  }
  function failure(error) {
    return { type: systemConstants.GET_REGISTERED_DEVICES_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemAdminService.getDevicesRegistered(startDate, endDate, forceId, areaId ?? 0, teamId ?? 0, allForces).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function GetActivatedDevices(startDate, endDate, forceId, areaId, teamId, allForces = false) {
  function request() {
    return { type: systemConstants.GET_ACTIVATED_DEVICES_REQUEST };
  }
  function success(activatedDevices) {
    return { type: systemConstants.GET_ACTIVATED_DEVICES_SUCCESS, activatedDevices };
  }
  function failure(error) {
    return { type: systemConstants.GET_ACTIVATED_DEVICES_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemAdminService.getActivatedDevices(startDate, endDate, forceId, areaId ?? 0, teamId ?? 0, allForces).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function GetGendersByDeviceType(startDate, endDate, forceId, areaId, teamId, allForces = false) {
  function request() {
    return { type: systemConstants.GET_GENDER_DEVICES_REQUEST };
  }
  function success(registeredDevices) {
    return { type: systemConstants.GET_GENDER_DEVICES_SUCCESS, registeredDevices, allForces };
  }
  function failure(error) {
    return { type: systemConstants.GET_GENDER_DEVICES_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemAdminService.getGendersByRegisteredDevice(startDate, endDate, forceId, areaId ?? 0, teamId ?? 0, allForces).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function GetRiskCategoryByDeviceType(startDate, endDate, forceId, areaId, teamId, allForces = false) {
  function request() {
    return { type: systemConstants.GET_RISK_CATEGORY_DEVICES_REQUEST };
  }
  function success(registeredDevices) {
    return { type: systemConstants.GET_RISK_CATEGORY_DEVICES_SUCCESS, registeredDevices };
  }
  function failure(error) {
    return { type: systemConstants.GET_RISK_CATEGORY_DEVICES_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemAdminService.getRiskCategoryByRegisteredDevice(startDate, endDate, forceId, areaId ?? 0, teamId ?? 0, allForces).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function GetNoLocationsReported(startDate, endDate, forceId, areaId, teamId, allForces = false) {
  function request() {
    return { type: systemConstants.GET_NO_LOCATION_REPORTED_REQUEST };
  }
  function success(noLocationReported) {
    return { type: systemConstants.GET_NO_LOCATION_REPORTED_SUCCESS, noLocationReported };
  }
  function failure(error) {
    return { type: systemConstants.GET_NO_LOCATION_REPORTED_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemAdminService.getNoLocationReported(startDate, endDate, forceId, areaId ?? 0, teamId ?? 0, allForces).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function GetAllocationDetails(startDate, endDate, forceId, allForces = false) {
  function request() {
    return { type: systemConstants.GET_ALLOCATION_DETAILS_REQUEST };
  }
  function success(allocationDetails) {
    return { type: systemConstants.GET_ALLOCATION_DETAILS_SUCCESS, allocationDetails };
  }
  function failure(error) {
    return { type: systemConstants.GET_ALLOCATION_DETAILS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemAdminService.getRegistrationAllocationDetails(startDate, endDate, forceId,allForces).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function GetAllocationUsageDetails(startDate, endDate, forceId, areaId, teamId, allForces = false) {
  function request() {
    return { type: systemConstants.GET_ALLOCATION_USAGE_DETAILS_REQUEST };
  }
  function success(allocationUsageDetails) {
    return { type: systemConstants.GET_ALLOCATION_USAGE_DETAILS_SUCCESS, allocationUsageDetails };
  }
  function failure(error) {
    return { type: systemConstants.GET_ALLOCATION_USAGE_DETAILS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemAdminService.getRegistrationUsageDetails(startDate, endDate, forceId, areaId ?? 0, teamId ?? 0, allForces).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

function GetAlertCallDetails(startDate, endDate, forceId, areaId, teamId, allForces = false) {
  function request() {
    return { type: systemConstants.GET_ALERT_CALL_DETAILS_REQUEST };
  }
  function success(alertCallDetails) {
    return { type: systemConstants.GET_ALERT_CALL_DETAILS_SUCCESS, alertCallDetails };
  }
  function failure(error) {
    return { type: systemConstants.GET_ALERT_CALL_DETAILS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemAdminService.getAlertCallDetails(startDate, endDate, forceId, areaId ?? 0, teamId ?? 0, allForces).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  }
}

export default {
  GetVpsList,
  GetLicensedUsedList,
  GetOverdueRegistrations,
  GetVictimDashDetails,
  GetDevicesRegistered,
  GetActivatedDevices,
  GetGendersByDeviceType,
  GetRiskCategoryByDeviceType,
  GetNoLocationsReported,
  GetAllocationDetails,
  GetAllocationUsageDetails,
  GetAlertCallDetails,
};
