import React from "react";

const message = "The content you are looking for cannot be found.";
const NotFound = () => (
  <>
    <div className="container">
      <div className="not-found-container">
        <div className="not-found-error">{message}</div>
      </div>
    </div>
  </>
);

export default NotFound;
