import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router';

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        authenticated === true
            ? <Component {...props} />
            : (
              <Redirect to={{
                pathname: '/login',
                state: { from: props.location },
              }}
              />
            )
        )}
    />
  )
};

export default PrivateRoute;
