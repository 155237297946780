import adminConstants from "./constants";
import { SystemInfoService } from "../../services";

function GetSystemInfo() {
  function request() {
    return { type: adminConstants.GET_SYSTEM_INFO_REQUEST };
  }
  function success(noticeText) {
    return { type: adminConstants.GET_SYSTEM_INFO_SUCCESS, noticeText };
  }
  function failure(error) {
    return { type: adminConstants.GET_SYSTEM_INFO_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemInfoService.getSystemInfo()
      .then(data => {
        dispatch(success(data.data));
      })
      .catch(error => {
        dispatch(failure(error));
      })
  };
}

function SetSystemInfo(newData, callback){
  function request() {
    return { type: adminConstants.SET_SYSTEM_INFO_REQUEST };
  }
  function success(noticeText) {
    return { type: adminConstants.SET_SYSTEM_INFO_SUCCESS, noticeText };
  }
  function failure(error) {
    return { type: adminConstants.SET_SYSTEM_INFO_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    SystemInfoService.updateSystemInfo(newData)
      .then(() => {
        dispatch(success(newData));
        callback(newData);
      })
      .catch(error => {
        dispatch(failure(error));
      })
  };
}

export default {
  GetSystemInfo,
  SetSystemInfo
};
