import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Row, Col, Spin } from "antd";
import TeamForm from "../team-form";
import actions from "../actions";
import "./TeamPages.scss";

class TeamCreatePage extends React.Component {
  componentDidMount() {
    this.props.dispatch(actions.newTeam());
  }

  createArea = (val) => {
    this.props.dispatch(actions.CreateTeam(val, this.handleCallback));
  };

  handleCancel = () => {
    this.props.dispatch(push("/team"));
  };

  handleCallback = () => {
    this.props.dispatch(push(`/team`));
  };

  render() {
    return (
      <div className="p5-page-container-small">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <Spin spinning={this.props.loading}>
              <TeamForm
                loading={this.props.loading}
                onSubmit={this.createArea}
                onCancel={this.handleCancel}
                title="New Team"
                {...this.props.team}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

TeamCreatePage.propTypes = {
  dispatch: () => {},
  team: PropTypes.shape({}),
  loading: PropTypes.bool,
};

TeamCreatePage.defaultProps = {
  dispatch: () => {},
  team: {},
  loading: false,
};

function mapStateToProps(state) {
  const { loading, team } = state.Team;
  return {
    team,
    loading,
  };
}

const connectedTeamCreatePage = connect(mapStateToProps)(TeamCreatePage);

export default connectedTeamCreatePage;
