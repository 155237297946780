import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Row, Col,Spin } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import HubForm from "../hub-form";
import action from "../action";
import { hasRights, rights } from "../../../common/utils/role-right-helper";
import "./HubPage.scss";

class HubCreatePage extends React.Component {
  componentDidMount() {
    if(hasRights([rights.CanManageHub])) {
      this.props.dispatch(navigationActions.changePage("hub"));
      this.props.dispatch(action.GetAllocationById(this.props.match.params.id));
    }
    else
      this.props.dispatch(push("/hub"));
  }

  updateTeam = (val) => {     
    this.props.dispatch(action.UpdateAllocation(val, this.handleCallback));
  };

  handleCancel = () => {
    this.props.dispatch(push("/hub"));
  };

  handleCallback = () => {     
    this.props.dispatch(push(`/hub`));
  };

  render() {
    return (
      <div className="p5-page-container">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <Spin spinning={this.props.loading}>
              <HubForm
                loading={this.props.loading}
                onSubmit={this.updateTeam}
                onCancel={this.handleCancel}
                // eslint-disable-next-line react/jsx-boolean-value
                isUpdateMode={true}
                title="Edit Allocation"
                {...this.props.allocation}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

HubCreatePage.propTypes = {
  dispatch: () => {},
  allocation: PropTypes.shape({}),
  match: PropTypes.shape(),
  loading: PropTypes.bool,
};

HubCreatePage.defaultProps = {
  dispatch: () => {},
  match: {},
  allocation: {},
  loading: false,
};

function mapStateToProps(state) {
  const { loading, allocation } = state.Hub;
  allocation.Force = { Id: allocation.LawEnforcementAgencyId ?? 0, Name: "" };
  return {
    allocation,
    loading,
  };
}

const connectedHubCreatePage = connect(mapStateToProps)(HubCreatePage);

export default connectedHubCreatePage;
