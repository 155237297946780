import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Row, Col, Spin } from "antd";
import AreaForm from "../area-form";
import actions from "../actions";
import "./AreaPages.scss";

class AreaCreatePage extends React.Component {
  createArea = (val) => {
    this.props.dispatch(actions.CreateArea(val, this.handleCallback));
  };

  handleCancel = () => {
    this.props.dispatch(push(`/area`));
  };

  handleCallback = () => {
    this.props.dispatch(push(`/area`));
  };

  render() {
    return (
      <div className="p5-page-container-small">        
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <Spin spinning={this.props.loading}>
              <AreaForm
                loading={this.props.loading}
                onSubmit={this.createArea}
                onCancel={this.handleCancel}
                title="Create Area"
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

AreaCreatePage.propTypes = {
  dispatch: () => {},
  area: PropTypes.shape({}),
  loading: PropTypes.bool,
};

AreaCreatePage.defaultProps = {
  dispatch: () => {},
  area: {},
  loading: false,
};


function mapStateToProps(state) {
  const { loading, area } = state.Area;
  return {
    area,
    loading
  };
}

const connectedAreaCreatePage = connect(mapStateToProps)(AreaCreatePage);


export default connectedAreaCreatePage;
