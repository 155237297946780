import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Layout, Menu, Row, Col } from "antd";
import {
  faUserCircle,
  faChevronDown,
  faSignOutAlt,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SOSLogo from "../../assets/img/TecSAFE_logo.png";
import Actions from "../../../features/account/actions";
import SideNav from "../side-nav";
// import "./MainLayout.scss";

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class MainLayout extends React.PureComponent {
  handleProfileClick = (eventKey) => {
    const { dispatch } = this.props;
    switch (eventKey.key) {
      case "profile":
        dispatch(push("/profile"));
        break;
      case "logout":
        dispatch(Actions.Logout());
        dispatch(push("/login"));
        break;
      default:
        dispatch(push("/login"));
    }
  }
  
  render() {
    const { Header, Content } = Layout;
    const { SubMenu } = Menu;

    const ProfileIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faUserCircle}
          id="ProfileInfo"
          className="top-nav-icon"
          alt="ProfileIcon"
        />
      </span>
    );

    const ChevronDownIcon = (
      <span>
        {this.props.collapsedSize > 0 && (
          <span style={{ marginRight: "10px", fontSize: "18px", color:"#F0F4F8" }}>{this.props.currentUser.UserName}</span> 
        )}              
        <span className="anticon" role="img">
          <FontAwesomeIcon
            icon={faChevronDown}
            id="ChevronDown"
            className="top-nav-secondary-icon"
            alt="ChevronDownIcon"
          />
        </span>
      </span>
    );

    const logOffIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faSignOutAlt}
          id="Logoff"
          alt="LogoffIcon"
          className=""
        />
      </span>
    );

    const userIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faUser}
          id="Users"
          alt="UserIcon"
          className="side-nav-icon"
        />
      </span>
    );

    return (
      <div className="MainContainer">
        <Layout>
          <Header>
            <div>
              <Row>
                <Col xs={0} sm={0} md={4} lg={4}>
                  <div className="logo-container">
                    <Link to="/">
                      <img src={SOSLogo} width="275px" alt="TechSOSLogo" className="logoimg" />
                    </Link>
                  </div>
                </Col>
                <Col xs={0} sm={0} md={14} lg={14}>
                  <div style={{textAlign: "center", width: "100%", marginTop: "15px", fontSize: "25px"}}>
                    { this.props.currentUser.ForceName } Portal {`${this.props.currentUser.SystemVersionId === 1 ? 'Lite' : ""}`}
                  </div> 
                </Col>
                <Col xs={3} sm={3} md={0} lg={0}>
                  <div className="logo-container">
                    <Link to="/">          
                      <img src={SOSLogo} width="150px" alt="TechSOSLogo" className="logoimg" />      
                    </Link>
                    <span style={{textAlign: "center", width: "100%", marginTop: "2px", fontSize: "15px", position: "relative", top: "-34px", left: "62px"}}>                        
                      { this.props.currentUser.ForceName } Portal {`${this.props.currentUser.SystemVersionId === 1 ? 'Lite' : ""}`}
                    </span> 
                  </div>
                </Col>
                <Col xs={18} sm={18} md={0} lg={0} />
                <Col xs={3} sm={3} md={6} lg={6}>
                  <div className="top-nav-items">                               
                    <Menu selectedKeys={[this.props.currentPage]} mode="horizontal">                  
                      <SubMenu
                        key="Profile"
                        icon={ProfileIcon}
                        title={ChevronDownIcon}
                      >
                        <Menu.ItemGroup>
                          {this.props.collapsedSize === 0 && (
                            <Menu.Item onClick={(e) => e.prevendDefault()}>
                              <span style={{fontSize: "18px"}}>{this.props.currentUser.UserName}</span>
                            </Menu.Item>                         
                          )}   
                          <Menu.Item key="profile" icon={userIcon} onClick={(e) => this.handleProfileClick(e)}>
                            <span>Profile</span>
                          </Menu.Item>
                          <Menu.Item key="logout" icon={logOffIcon} onClick={(e) => this.handleProfileClick(e)}>
                            <span>Logout</span>
                          </Menu.Item>
                        </Menu.ItemGroup>
                      </SubMenu>
                    </Menu>
                  </div>
                </Col>
              </Row>
              {/* <div className="logo-container">
                <Link to="/">
                  <img src={SOSLogo} alt="TechSOSLogo" className="logoimg" />
                </Link>
              </div>
              <div style={{textAlign: "center", width: "100%"}}>
                Who&apos;s News
              </div>              
              <div className="top-nav-items">                               
                <Menu selectedKeys={[this.props.currentPage]} mode="horizontal">                  
                  <SubMenu
                    key="Profile"
                    icon={ProfileIcon}
                    title={ChevronDownIcon}
                  >
                    <Menu.ItemGroup>
                      {this.props.collapsedSize === 0 && (
                        <Menu.Item onClick={(e) => e.prevendDefault()}>
                          <span style={{fontSize: "18px"}}>{this.props.currentUser.UserName}</span>
                        </Menu.Item>                         
                      )}   
                      <Menu.Item key="profile" icon={userIcon} onClick={(e) => this.handleProfileClick(e)}>
                        <span>Profile</span>
                      </Menu.Item>
                      <Menu.Item key="logout" icon={logOffIcon} onClick={(e) => this.handleProfileClick(e)}>
                        <span>Logout</span>
                      </Menu.Item>
                    </Menu.ItemGroup>
                  </SubMenu>
                </Menu>
              </div> */}
            </div>
          </Header>
          {this.props.PageHeader}
          <Layout>
            <SideNav />
            <Content>{this.props.children}</Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: () => {},
  currentUser: PropTypes.shape({
    UserName: PropTypes.string,
    SystemVersionId: PropTypes.number,
    ForceName: PropTypes.string,
  }),
  collapsedSize: PropTypes.number,
};

MainLayout.defaultProps = {
  dispatch: () => {},
  currentUser: {
    UserName: '',
    SystemVersionId: null,
    ForceName: null,
  },
  collapsedSize: 0,
};


function mapStateToProps(state) { 
  const { currentUser } = state.AccountState;
  const { currentPage, collapsedSize } = state.Navigation;
  return {
    currentUser,
    currentPage,
    collapsedSize
  };
}

const connectedMainLayout = connect(mapStateToProps)(MainLayout);

export { connectedMainLayout as default };

// export { MainLayout as default };
