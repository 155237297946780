import React from 'react';
import { connect } from 'formik';

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class ErrorFocus extends React.Component {
  componentDidUpdate(prevProps) {
    // console.log('prevstate', prevProps)
    const { isSubmitting, isValidating, errors } = prevProps.formik;    
    const keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const elementArray = [];

      for (let i = 0; i < keys.length; i += 1) { 
        const selector = `[id="${keys[i]}"]`
        const findElement = document.querySelector(selector);
        if (findElement && findElement.scrollHeight > 0) {
          const topLocaton = findElement.getBoundingClientRect();
          elementArray.push({ selector, topLocaton: topLocaton.top });
        }
      }

      // console.log('completed array', elementArray.sort(this.compare));

      if(elementArray.length > 0){
        const scrollElement = elementArray.sort(this.compare)[0];
        const errorElement = document.querySelector(scrollElement.selector);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }

  // Compare to sort all possible error fields to scroll to
  compare = (a, b) => {
    const elementA = Number(a.topLocaton);
    const elementB = Number(b.topLocaton);
  
    let comparison = 0;
    if (elementA > elementB) {
      comparison = 1;
    } else if (elementA < elementB) {
      comparison = -1;
    }
    return comparison;
  }

  render() {
    return null;
  }
}

export default connect(ErrorFocus);