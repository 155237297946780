import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { connect } from 'react-redux';
import imageUploadActions from './action';
import ImageDisplay from './ImageDisplay';
import ImageGallery from './ImageGallery';
import "./ImageUpload.scss";

class ImageUpload extends React.Component {
  
  handleUpload = files => {
    if (!(files && files.length)) {
      return;
    }
    this.props.dispatch(imageUploadActions.create(files, this.props.onUpload));
  };

  render() {
    return (
      <>
        <Dropzone accept="image/*" onDrop={this.handleUpload} multiple={this.props.multiple}>
          {({ getRootProps, getInputProps }) => (
            <section className="container dropzone-container">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Drag and drop image here, or click to select image</p>
              </div>
            </section>
        )}
        </Dropzone>
        {
                !this.props.multiple ? (
                    this.props.Id > 0 && (
                      <aside><ImageDisplay Id={this.props.Id} key={`upload-display-${this.props.Id}`} /></aside>
                    )
                  ) :
                    this.props.Images && this.props.Images.length > 0 && (
                      <>
                        <ImageGallery Items={this.props.Images} onRemove={this.props.onRemove} />
                      </>
                    )
              }
      </>
    );
  }
}

ImageUpload.propTypes = {
  dispatch: PropTypes.func,
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  Id: PropTypes.number,
  Images: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
};

ImageUpload.defaultProps = {
  dispatch: () => {},
  onUpload: () => {},
  onRemove: () => {},
  multiple: false,
  Id: 0,
  Images: [],
  loading: false,
};

function mapStateToProps(state) {
  const { loading, hasError } = state.ImageUpload;
  return {
    loading,
    hasError,
  };
}

const connectedImageUpload = connect(mapStateToProps)(ImageUpload);

export { connectedImageUpload as default };
