import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Empty } from "antd";
import { CustomPieChart } from "../../../common/components/dashboard";

class WNAppAllocationView extends React.Component {
  render() {
    return (
      <>
        <div className="p5-form-container">
          <Row className="text-center" justify="space-around" align="middle">
            <Col span={24}>
              <div>
                <h1 className="p5-dash-h1-header text-center">{this.props.title}</h1>  
              </div>
            </Col>
          </Row> 
          <Row justify="space-around" align="middle">
            {
              this.props.data.length === 0 ? (
                <>
                  <Empty />
                </>
                ) : (
                  <CustomPieChart
                    appendPadding={1}
                    data={this.props.data}
                    angleField="AllocatedCount"
                    colorField="AreaName"
                    radius={0.8}
                  />
                )
            }
          </Row> 
          <br />
          <Row className="text-center" justify="space-around" align="middle">
            <Col span={24}>
              <div>
                <span><strong>Data in this section does not change by area, team, or date filter</strong></span>
              </div>
            </Col>
          </Row>       
        </div>
      </>
    );
  }
}

WNAppAllocationView.propTypes = {
//   loading: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

WNAppAllocationView.defaultProps = {
//   loading: false,
  title: "",
  data: [],
};

export { WNAppAllocationView as default };
