import React from "react";
import { connect } from "react-redux";
import {
  Table,
  Input,
  Space,
  Button,
  Tooltip
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { history } from "../../../store";
import { UserService } from "../../../services";

class UserGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData() {
    UserService.getGridList().then((result) => {
      this.setState({
        data: result.data,
        loading: false,
      });
    });
  }

  editUser = (key) => {
    history.push(`user/${key.Id}`);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])} 
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    

    const columns = [
      {
        title: "Username",
        dataIndex: "UserName",
        key: "UserName",
        sorter: (a, b) => a.UserName.length - b.UserName.length,
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("UserName"),
      },
      {
        title: "Users",
        dataIndex: "FullName",    
        key: "FullName",             
        sorter: (a, b) => a.FullName.length - b.FullName.length,
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("FullName"),
      },  
      {
        title: "Email",
        dataIndex: "EmailAddress",
        key: "EmailAddress",
        sorter: (a, b) => a.EmailAddress.length - b.EmailAddress.length,
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("EmailAddress"),
      },
      {
        title: "Force",
        dataIndex: "ForceName",
        key: "ForceName",
        sorter: (a, b) => a.ForceName.length - b.ForceName.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: "Area",
        dataIndex: "AreaName",
        key: "AreaName",
        sorter: (a, b) => a.AreaName.length - b.AreaName.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: "Team",
        dataIndex: "TeamName",
        key: "TeamName",
        sorter: (a, b) => a.TeamName.length - b.TeamName.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: "Role",
        dataIndex: "RoleType",
        key: "RoleType",
        sorter: (a, b) => a.RoleType.length - b.RoleType.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: "",
        dataIndex: "Id",
        key: "Id",
        width: "5%",
        render: (text, record) =>
          this.state.data.length >= 1 ? (
            <>
              <span>
                <Tooltip title={`Edit ${record.FullName}`}> 
                  <FontAwesomeIcon
                    onClick={() => this.editUser(record)}
                    size="1x"
                    icon={faEdit}
                    pull="right"
                  />
                </Tooltip>
              </span>
            </>
          ) : null,   
      },
    ];

    const result = (
      <div className="user-grid">
        <Table columns={columns} dataSource={this.state.data} scroll={{ x: 700 }} size="small" rowKey="Id" loading={this.state.loading} />
      </div>
    );

    return result;
  }
}

function mapStateToProps() {
  return {};
}

const connectedUserGrid = connect(mapStateToProps)(UserGrid);

export { connectedUserGrid as default };
