import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const NameInput = (props) => {
  return(
    <Form.Item
      validateStatus={
        props.errors && props.errors.Name && props.touched.Name
          ? "error"
          : null
      }
      help={props.errors && props.touched.Name && props.errors.Name}
    >
      <h2 className="p5-form-label">
        Name
      </h2>
      <Input 
        type="text" 
        id="Name" 
        className="p5-form-input"
        value={props.values.Name} 
        onChange={props.onChange}
      />
    </Form.Item>
  );
}

NameInput.propTypes = {
    onChange: () => {},
    errors: PropTypes.shape({
        Name: PropTypes.string,
    }),
    values: PropTypes.shape({
        Name: PropTypes.string,
    }),
    touched: PropTypes.shape({
        Name: PropTypes.bool,
    })
}

NameInput.defaultProps = {
    onChange: () => {},
    errors: {},
    values: {},
    touched: {}
}

export default NameInput;
