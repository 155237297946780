/* eslint import/prefer-default-export: 0 */
import api from "../common";

function UserHasActiveTourGuides(userId) {
  return api.get(`/api/TourGuide/Active/User/${userId}`, { withCredentials: true, 'Access-Control-Allow-Origin' : '*'})
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getTourGuideById(id, userId) {
  return api.get(`/api/TourGuide/${id}/${userId}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
  }

function getTourGuideListById(userId) {
  return api.get(`/api/TourGuide/TourList/${userId}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function markTourGuideCompleted(data) {
  return api.put(`/api/TourGuide/Completed`, data, { withCredentials: true, 'Access-Control-Allow-Origin': '*'})
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export {
  UserHasActiveTourGuides,
  getTourGuideById,
  getTourGuideListById,
  markTourGuideCompleted
}