import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router';
import { AppContainer } from 'react-hot-loader';

import App from './app';
import { history, store } from './app/store';
import rootReducer from './app/root-reducer';

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Route path="/" component={App} />
        </ConnectedRouter>
      </Provider>
    </AppContainer>,
    document.getElementById('app'),
  );
};

render();

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./app', () => {
    render();
  });

  // Reload reducers
  module.hot.accept('./app/root-reducer', () => {
    store.replaceReducer(rootReducer(history));
  });
}
