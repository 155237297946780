import React from "react";
import { connect } from "react-redux";
// import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { ShepherdTour, TourMethods } from 'react-shepherd'
import moment from "moment";
import { LiteVictimDataTourSteps, VictimDataTourSteps } from '../tour-steps';
import navigationActions from "../../../common/components/navigation/action";
import { DataForm, LiteDataForm } from "../../registeration/form";
import TourRegAction from "../actions";
import TourGuideStartModal from "../components/tour-guide-start-modal";
import { IsLiteVersion, IsFullVersion } from "../../../common/utils/system-version-helper";
import "../shepardtour.css";
import "../../registeration/page/DataPage.scss";

const { tourOptions, LiteVictimTourSteps } = LiteVictimDataTourSteps;
const { tourFullOptions, VictimTourSteps } = VictimDataTourSteps; 

class VictimDataTourPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          TourStartModalshow: false,
          VictimData: {
            Id: 0,
            RegistrationId: 0,
            Name: "Jane Doe",
            GenderId: 1,
            FCR_EmailAddress: "Test@email.com",
            FCR_PhoneNumber: "5555555555",
            CrimeRefNumber: "0000",
            RiskLevelId: 1,
            RiskCategories:[1,2],
            PerpetratorName: "",
            ImageIds: [],
            VictimAddresses: [
              {
                Id: 0,
                VictimId: 0,
                AddressTypeId: 1,
                Address: "123 Street Road",
                PostalCode: "12345",
                County: "Test",
                City: "London",
              },
            ],
            VictimPerpetratorDetails: {
              Id: 0,
              VictimId: 0,
              Name: "",
              GenderId: 1,
              NameNotKnown: false,
              DescriptionNotKnown: false,
              MONotKnown: false,
              PNCNotKnown: false,
              DeployConsidNotKnown: false,
              DOB: moment(),
              DOBNotKnown: false,
              PNCID: "",
              WarningTypeIds: [],
              WarningTypeNone: false,
              RiskCategories: [],
              Description: "",
              MO: "",
              CourtOrderConditions: "",
              DeploymentConsiderations: "",
              ImageIds: [],
              VictimPerpetratorVehicleDetails: [
                {
                  Id: 0,
                  PerpetratorId: 0,
                  VRM: "",
                  Make: "",
                  Model: "",
                  Colour: "",
                },
              ],
            },
            VictimChildren: [
              {
                Id: 0,
                VictimId: 0,
                Name: "John Doe",
                Age: 10,
                Address: "123 Street Road",
                PostalCode: "12345",
                County: "Test",
                City: "London",
              },
            ],
            VictimVehicleDetail: [
              {
                Id: 0,
                VictimId: 0,
                VRM: "",
                Make: "",
                Model: "",
                Year: 0,
                Colour: "",
              },
            ],
            OIC_Name: "John Jackson",
            OIC_StaffID: "0000",
            AuthorisingOfficer: "Jack Johnson",
            disabilityOptions: [{}],
            edit: false,
          },
        };
      }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("register"));
    this.setState({ TourStartModalshow: true });
    this.props.dispatch(TourRegAction.GetCurrentTourGuide(
      8,
      this.props.userId
    ));
  }

  hideTourGuideLandingModal = () => {
    this.setState({ TourStartModalshow: false });
  };

  pivotCompleteData = () => ({
    TourId: this.props.CurrentTourGuide.TourGuideId,
    UserId: this.props.userId
  });

  CompleteTourGuide = () => {    
    this.props.dispatch(TourRegAction.MarkTourGuideAsCompleted(
      this.pivotCompleteData(),
      this.props.dispatch(TourRegAction.CheckUserActiveTourGuides(this.props.userId))
    ));
  }

  UpdateData = (val) => {
    console.log('Unable to update in tour', val);
  };

  handleCancel = () => {
    console.log('Unable to cancel in tour');
  };

  render() {
    return (
      <div className="p5-page-container">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            {IsFullVersion() === true && (
              <ShepherdTour
                steps={VictimTourSteps}
                tourOptions={tourFullOptions}
              >
                <TourMethods>
                  {(tourContext) => (
                    <TourGuideStartModal
                      startTour={tourContext}
                      hideModal={this.hideTourGuideLandingModal}
                      showModal={this.state.TourStartModalshow}
                      completeGuide={this.CompleteTourGuide}
                      tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                      userId={this.props.userId}
                      tourName="Registration Victim Data"
                      title="Welcome to the Registration Victim Data Tour Guide"
                      description="In this tour guide, you will learn about adding victim data for your registered users. We will explain each column victim data form and some important items to note while using the TecSAFE portal."
                    />
                )}
                </TourMethods>
                <DataForm
                  loading={this.props.loading}
                  onSubmit={this.UpdateData}
                  onCancel={this.handleCancel}
                  isTourMode
                  {...this.state.VictimData}
                />
              </ShepherdTour>
            )}
            {IsLiteVersion() === true && (
              <ShepherdTour
                steps={LiteVictimTourSteps}
                tourOptions={tourOptions}
              >
                <TourMethods>
                  {(tourContext) => (
                    <TourGuideStartModal
                      startTour={tourContext}
                      hideModal={this.hideTourGuideLandingModal}
                      showModal={this.state.TourStartModalshow}
                      completeGuide={this.CompleteTourGuide}
                      tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                      userId={this.props.userId}
                      tourName="Registration Victim Data"
                      title="Welcome to the Registration Victim Data Tour Guide"
                      description="In this tour guide, you will learn about adding victim data for your registered users. We will explain each column victim data form and some important items to note while using the TecSAFE portal."
                    />
                )}
                </TourMethods>
                <LiteDataForm
                  loading={this.props.loading}
                  onSubmit={this.UpdateData}
                  onCancel={this.handleCancel}
                  {...this.state.VictimData}
                />
              </ShepherdTour>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

VictimDataTourPage.propTypes = {
  dispatch: () => {},
  match: PropTypes.shape(),
  victimData: PropTypes.shape({}),
  loading: PropTypes.bool,
  userId: PropTypes.number,
  CurrentTourGuide: PropTypes.shape({
    TourGuideId: PropTypes.number
  }),
};

VictimDataTourPage.defaultProps = {
  dispatch: () => {},
  match: {},
  victimData: {},
  loading: false,
  userId: 0,
  CurrentTourGuide: {
    TourGuideId: 8
  },  
};

function mapStateToProps(state) {
  const { victimData, loading } = state.RegisterUserFormState;
  const { Id } = state.AccountState.currentUser;
  const { CurrentTourGuide } = state.TourGuide;

  return {
    victimData,
    loading,
    userId: Id,
    CurrentTourGuide,
  };
}

const connectedDataEditPage = connect(mapStateToProps)(VictimDataTourPage);

export default connectedDataEditPage;
