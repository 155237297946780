
const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
          enabled: false
        }
      },
      useModalOverlay: true
};

const HelpTourSteps = [    
  {
    title: "Operational Support",
    id: "tour-help-op-support",
    text: [
      `
      <p>
      This section provides a telephone number for urgent support and an email address for non-urgent support from TecSAFE.    
      </p>
      <p>
      Please <u>do not email with urgent</u> queries.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-help-op-support", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Billing",
    id: "tour-help-billing",
    text: [
      `
      <p>
      This section provides a telephone number for non-urgent queries relating to contracts or billing.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-help-billing", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Good News Stories",
    id: "tour-help-good-news",
    text: [
      `
      <p>
      This section  enables forces to submit details of 'Good News' stories about cases involving TecSAFE that they have dealt with.  
      This enables good practices to be disseminated to other TecSAFE forces.   
      </p>
      <p>
      All 'Good News' stories submitted should be anonymised - i.e.  use of the terms 'Victim' and 'Offender' instead of names. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-help-good-news", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Suggestions",
    id: "tour-help-suggestions",
    text: [
      `
      <p>
      This section provides an email address to submit any suggestions you may have about TecSAFE.
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-help-suggestion", on: "top" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Help/Good News Stories Complete",
    id: "tour-help-complete",
    text: [
      `
      <p>
      That concludes the Help/Good News Stories page. 
       Please select Complete to this tour to mark as complete.
      </p>
      `,
    ],
    attachTo: { on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Complete",
        type: "complete",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
];

  export default {
    tourOptions,
    HelpTourSteps
  }