import c from './constants';

const initialState = {
  loading: false,
  hasError: false,
  error: {},
  currentResource: {},
};

export default function resourceReducer(state = initialState, action) {
  switch (action.type) {
    case c.CREATE_RESOURCE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.CREATE_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.CREATE_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: false,
        error: action.error,
      };
    case c.GET_RESOURCE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        currentResource: {},
      };
    case c.GET_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        currentResource: action.data,
      };
    case c.GET_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: false,
        error: action.error,
      };
    case c.UPDATE_RESOURCE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.UPDATE_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.UPDATE_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: false,
        error: action.error,
      };
    case c.DELETE_RESOURCE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.DELETE_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.DELETE_RESOURCE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: false,
        error: action.error,
      };
    case c.GET_FILE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.GET_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.GET_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: false,
        error: action.error,
      };
    default:
      return state;
  }
}
