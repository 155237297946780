import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const Address2Input = (props) => {
  return(
    <Form.Item
      validateStatus={
        props.errors && props.errors.Address2 && props.touched.Address2
          ? "error"
          : null
      }
      help={props.errors && props.touched.Address2 && props.errors.Address2}
    >
      <h2 className="form-label">
        Address line 2
      </h2>
      <Input 
        type="text"
        id="Address2" 
        className="form-input"
        value={props.values.Address2} 
        onChange={props.onChange}
      />
    </Form.Item>
  );
}

Address2Input.propTypes = {
    onChange: () => {},
    errors: PropTypes.shape({
        Address2: PropTypes.string,
    }),
    values: PropTypes.shape({
        Address2: PropTypes.string,
    }),
    touched: PropTypes.shape({
        Address2: PropTypes.bool,
    })
}

Address2Input.defaultProps = {
    onChange: () => {},
    errors: {},
    values: {},
    touched: {}
}

export default Address2Input;
