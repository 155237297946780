import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Select } from "antd";

import genderAction from "./action";

const { Option } = Select;

class GenderSelect extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(genderAction.getGenderList());
  }

  render() {
    return (
      <Select
        className={this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        {this.props.genders.map((opt) => (
          <Option key={`gender-${opt.Id}`} value={opt.Id}>
            {opt.Name}
          </Option>
        ))}
      </Select>
    );
  }
}

GenderSelect.propTypes = {
  dispatch: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  genders: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

GenderSelect.defaultProps = {
  dispatch: () => {},
  value: 1,
  className: "",
  genders: [],
};

function mapStateToProps(state) {
  const { genders } = state.GenderList;
  return {
    genders,
  };
}

const connectedGenderSelect = connect(mapStateToProps)(GenderSelect);

export { connectedGenderSelect as default };
