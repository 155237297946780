import React from 'react';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Spin,
  Modal
} from 'antd';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import navigationActions from "../../../common/components/navigation/action";
import UserForm from '../form';
import actions from '../actions';
import './UserPage.css';

class EditPage extends React.Component {

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("user"));
    this.props.dispatch(actions.GetUserById(this.props.match.params.id));
  }

  updateUser = (val) => {
    this.props.dispatch(actions.UpdateUser(this.props.match.params.id, val, this.handleCallback));
  }

  deleteUser = () => {
    const { confirm } = Modal;
    const { currentUser, dispatch } = this.props;
    const callback = this.handleCallback;

    confirm({
      icon: <FontAwesomeIcon size="2x" pull="left" icon={faTrash} style={{ color: "#EF4E4E" }} />,
      // icon: <DeleteOutlined style={{ color: "#EF4E4E" }} />,
      /* eslint react/prop-types: 0 */
      content: <span style={{textAlign:"center"}}>Are you sure want to delete user: {currentUser.UserName}</span>,
      onOk() {
        /* eslint react/prop-types: 0 */
        dispatch(actions.DeleteUser(currentUser.Id, callback));
      },
    });
  };

  handleCancel = () => {
    this.props.dispatch(push("/user"));
  };

  handleCallback = () => {
    this.props.dispatch(push("/user"));
  };

  render() {
    return (
      <div className="p5-page-container">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <Spin spinning={this.props.loading}>            
              <UserForm
                loading={this.props.loading}
                onSubmit={this.updateUser}
                onCancel={this.handleCancel}
                onDelete={this.deleteUser}
                {...this.props.currentUser}
                // eslint-disable-next-line react/jsx-boolean-value
                isUpdateMode={true}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

EditPage.propTypes = {
  dispatch: () => { },
  match: PropTypes.shape(),
  currentUser: PropTypes.shape({
    Id: PropTypes.number,
  }),
  pivotData: PropTypes.shape({}),
  loading: PropTypes.bool,
};

EditPage.defaultProps = {
  dispatch: () => { },
  match: {},
  currentUser: {
    Id: 0,
  },
  pivotData: {},
  loading: false,
};

function mapStateToProps(state) {
  const { currentUser, loading } = state.Users;

  return {
    currentUser,
    loading
  };
}

const connectedEditPage = connect(mapStateToProps)(EditPage);

export default connectedEditPage;
