
const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
          enabled: false
        }
      },
      useModalOverlay: true
};

const SearchTourSteps = [    
  {
    title: "Search Input",
    id: "tour-quick-find-text",
    text: [
      `
      <p>
      Type the phone number or name of the victim you are searching for here.       
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-quick-find-text", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Search",
    id: "tour-quick-find-btn",
    text: [
      `
      <p>
      Click the magnifying glass to start your search and view results. 
      </p>
      <p>
      The results for your search will show in the table below.  Click the individual row of the victim information you want to view.
      </p>
      <p>
      This will show all of the information previously input for that victim; including addresses, children and perpetrator details.  
      This information is cannot be edited from this page. 
      </p>
      `,
    ],
    attachTo: { element: ".sh-tour-quick-find-btn", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
        },
      {
        classes: "shepherd-button-primary",
        text: "Next",   
        type: "next", 
      },
    ],
    when: {
        complete: () => {},
        hide: () => {},
        destroy: () => {},
        cancel: () => {},
        next: () => {},
        show: () => {},
    },
  }, 
  {
    title: "Search Complete",
    id: "tour-help-complete",
    text: [
      `
      <p>
      That concludes the Victim Search page. 
      Please select Complete to this tour to mark as complete.
      </p>
      `,
    ],
    attachTo: { on: "center" },
    buttons: [
      {
        classes: "shepherd-button-secondary",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary",
        text: "Complete",
        type: "complete",
      },
    ],
    when: {
      complete: () => {},
      hide: () => {},
      destroy: () => {},
      cancel: () => {},
      show: () => {},
    },
  },
];

  export default {
    tourOptions,
    SearchTourSteps
  }