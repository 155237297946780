import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Row, Col, Spin } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import HubForm from "../hub-form";
import action from "../action";
import { hasRights, rights } from "../../../common/utils/role-right-helper";
import "./HubPage.scss";

class HubCreatePage extends React.Component {
  componentDidMount() {
    if(hasRights([rights.CanManageHub]))
      this.props.dispatch(navigationActions.changePage("hub"));
    else
      this.props.dispatch(push("/hub"));
  }

  createAllocation = (val) => {
    this.props.dispatch(action.createAllocation(val, this.handleCallback));
  };

  handleCancel = () => {
    this.props.dispatch(push("/hub"));
  };

  handleCallback = () => {     
    this.props.dispatch(push(`/hub`));
  };

  render() {
    return (
      <div className="p5-page-container">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <Spin spinning={this.props.loading}>
              <HubForm
                loading={this.props.loading}
                onSubmit={this.createAllocation}
                onCancel={this.handleCancel}
                title="New Allocation"
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

HubCreatePage.propTypes = {
  dispatch: () => {},
  loading: PropTypes.bool,
};

HubCreatePage.defaultProps = {
  dispatch: () => {},
  loading: false,
};

function mapStateToProps(state) {
  const { loading } = state.Hub;
  return {
    loading,
  };
}

const connectedHubCreatePage = connect(mapStateToProps)(HubCreatePage);

export default connectedHubCreatePage;
