import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spin, Empty } from "antd";

class NewDeviceView extends React.Component {
  render() {
    return (
      <>
        <div className="p5-form-container">
          <Row className="text-center" justify="space-around" align="middle">
            <Col span={24}>
              <div>
                <h1 className="p5-dash-h1-header text-center">{this.props.title}</h1>
              </div>
            </Col>
          </Row>
          <br />
          <Spin size="medium" spinning={this.props.loading}>
            {
              this.props.data.length === 0 ? (
                <>
                  <Empty />
                </>
              ) : (
                <Row key="activated-dvices" justify="space-around" align="middle">
                  <Col span={12}>
                    <div>
                      <h1 className="p5-dash-h1-header text-center">
                        <span className="dash-percent-value">
                          {this.props.data[0]}
                        </span>
                      </h1>
                    </div>
                    <br />
                  </Col>
                </Row>
              )
            }
          </Spin>
          <Row className="text-center" justify="space-around" align="middle">
            <Col span={24}>
              <div>
                <span><strong>Data in this section is accurate from 1 January 2024</strong></span>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

NewDeviceView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  title: PropTypes.string,
};

NewDeviceView.defaultProps = {
  data: [],
  loading: false,
  title: '',
};

export { NewDeviceView as default };
