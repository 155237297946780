export default {
    DELETE_REQUEST: 'IMAGE_DELETE_REQUEST',
    DELETE_SUCCESS: 'IMAGE_DELETE_SUCCESS',
    DELETE_FAILURE: 'IMAGE_DELETE_FAILURE',
    CREATE_REQUEST: 'CREATE_REQUEST',
    CREATE_VICTIM_REQUEST: 'CREATE_VICTIM_REQUEST',
    CREATE_VICTIM_SUCCESS: 'CREATE_VICTIM_SUCCESS',
    CREATE_VICTIM_FAILURE: 'CREATE_VICTIM_FAILURE',
    UPDATE_VICTIM_REQUEST: 'UPDATE_VICTIM_REQUEST',
    UPDATE_VICTIM_SUCCESS: 'UPDATE_VICTIM_SUCCESS',
    UPDATE_VICTIM_FAILURE: 'UPDATE_VICTIM_FAILURE',
    GET_VICTIM_REQUEST: 'GET_VICTIM_REQUEST',
    GET_VICTIM_SUCCESS: 'GET_VICTIM_SUCCESS',
    GET_VICTIM_FAILURE: 'GET_VICTIM_FAILURE',
    DELETE_VICTIM_REQUEST: 'DELETE_VICTIM_REQUEST',
    DELETE_VICTIM_SUCCESS: 'DELETE_VICTIM_SUCCESS',
    DELETE_VICTIM_FAILURE: 'DELETE_VICTIM_FAILURE',
    EXTEND_REGISTRATION_REQUEST: 'EXTEND_REGISTRATION_REQUEST',
    EXTEND_REGISTRATION_SUCCESS: 'EXTEND_REGISTRATION_SUCCESS',
    EXTEND_REGISTRATION_FAILURE: 'EXTEND_REGISTRATION_FAILURE',
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',
    TRACKAPHONE_ACTIVATE_REQUEST: 'TRACKAPHONE_ACTIVATE_REQUEST',
    TRACKAPHONE_ACTIVATE_SUCCESS: 'TRACKAPHONE_ACTIVATE_SUCCESS',
    TRACKAPHONE_ACTIVATE_FAILURE: 'TRACKAPHONE_ACTIVATE_FAILURE',
    GET_REGISTER_REQUEST: 'GET_REGISTER_REQUEST',
    GET_REGISTER_SUCCESS: 'GET_REGISTER_SUCCESS',
    GET_REGISTER_FAILURE: 'GET_REGISTER_FAILURE',
    REGISTRATION_DETAIL_REQUEST: 'REGISTRATION_DETAIL_REQUEST',
    REGISTRATION_DETAIL_SUCCESS: 'REGISTRATION_DETAIL_SUCCESS',
    REGISTRATION_DETAIL_FAILURE: 'REGISTRATION_DETAIL_FAILURE',
    HIDE_REGISTRATION_EDIT_MODAL: 'HIDE_REGISTRATION_EDIT_MODAL',
    REGISTRATION_LIST_REQUEST: 'REGISTRATION_LIST_REQUEST',
    REGISTRATION_LIST_SUCCESS: 'REGISTRATION_LIST_SUCCESS',
    REGISTRATION_LIST_FAILURE: 'REGISTRATION_LIST_FAILURE',
    EXTEND_DETAIL_REQUEST: 'EXTEND_DETAIL_REQUEST',
    EXTEND_DETAIL_SUCCESS: 'EXTEND_DETAIL_SUCCESS',
    EXTEND_DETAIL_FAILURE: 'EXTEND_DETAIL_FAILURE',
    HIDE_EXTEND_MODAL: 'HIDE_EXTEND_MODAL',
    UPDATE_EXTEND_REQUEST: 'UPDATE_EXTEND_REQUEST',
    UPDATE_EXTEND_SUCCESS: 'UPDATE_EXTEND_SUCCESS',
    UPDATE_EXTEND_FAILURE: 'UPDATE_EXTEND_FAILURE',
    ADD_MIN_DATA_REQUEST: 'ADD_MIN_DATA_REQUEST',
    ADD_MIN_DATA_SUCCESS: 'ADD_MIN_DATA_SUCCESS',
    ADD_MIN_DATA_FAILURE: 'ADD_MIN_DATA_FAILURE',
    REGISTRATION_AREA_FILTER: "REGISTRATION_AREA_FILTER",
    REGISTRATION_TEAM_FILTER: "REGISTRATION_TEAM_FILTER",
    VICTIM_DETAIL_REQUEST: 'VICTIM_DETAIL_REQUEST',
    VICTIM_DETAIL_SUCCESS: 'VICTIM_DETAIL_SUCCESS',
    VICTIM_DETAIL_FAILURE: 'VICTIM_DETAIL_FAILURE',
    HIDE_VICTIM_MODAL: 'HIDE_VICTIM_MODAL',
    TOUR_REGISTRATION_DETAIL_REQUEST: 'TOUR_REGISTRATION_DETAIL_REQUEST',
    TOUR_REGISTRATION_DETAIL_SUCCESS: 'TOUR_REGISTRATION_DETAIL_SUCCESS',
    TOUR_REGISTRATION_DETAIL_FAILURE: 'TOUR_REGISTRATION_DETAIL_FAILURE',
    TOUR_EXTEND_DETAIL_REQUEST: 'TOUR_EXTEND_DETAIL_REQUEST',
    TOUR_EXTEND_DETAIL_SUCCESS: 'TOUR_EXTEND_DETAIL_SUCCESS',
  };
  