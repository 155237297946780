import React from "react";
import { connect } from "react-redux";
import { Table, Input, Button, Space, Tooltip } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { history } from "../../../store";
import { TeamService } from "../../../services";

class TeamGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      searchText: "",
      searchedColumn: "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    TeamService.getGridList().then((result) => {
      this.setState({
        data: result.data.value,
        loading: false,
      });
    });
  }

  goToEdit = (key) => {
    history.push(`team/${key.Id}`);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])} 
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Team",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ['descend', 'ascend'],
        width: "30%",
        ...this.getColumnSearchProps("Name"),
      },
      {
        title: "Force",
        dataIndex: "LawEnforcementAgency",
        key: "LawEnforcementAgency",
        sorter: (a, b) => a.LawEnforcementAgency.length - b.LawEnforcementAgency.length,
        sortDirections: ['descend', 'ascend'],
        width: "30%",
        ...this.getColumnSearchProps("LawEnforcementAgency"),
      },
      {
        title: "Area",
        dataIndex: "LawEnforcementAgencySubGroup",
        key: "LawEnforcementAgencySubGroup",
        sorter: (a, b) => a.LawEnforcementAgency.length - b.LawEnforcementAgency.length,
        sortDirections: ['descend', 'ascend'],
        width: "30%",
        ...this.getColumnSearchProps("LawEnforcementAgencySubGroup"),
      },
      {
        title: "",
        dataIndex: "Id",
        key: "Id",
        width: "10%",
        render: (text, record) =>
          this.state.data.length >= 1 ? (
            <>
              <span>
                <Tooltip title={`Edit ${record.Name}`}> 
                  <FontAwesomeIcon
                    onClick={() => this.goToEdit(record)}
                    size="1x"
                    icon={faEdit}
                    pull="right"
                  />
                </Tooltip>
              </span>
            </>
          ) : null,   
      },
    ];

    const result = (
      <div className="team-grid">
        <Table
          columns={columns}
          dataSource={this.state.data}
          scroll={{ x: 700 }}
          size="small"
          loading={this.state.loading}
          rowKey="Id"
        />
      </div>
    );

    return result;
  }
}

function mapStateToProps() {
  return {};
}

const connectedLEAGrid = connect(mapStateToProps)(TeamGrid);

export { connectedLEAGrid as default };
