import c from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  error: {},
  loggedIn: false,
  currentUser: {},
  forgotSent: false,
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case c.GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        currentUser: {},
        forgotSent: false,
      };
    case c.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        loggedIn: true,
        currentUser: action.profile.data,
      };
    case c.GET_CURRENT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: false,
        loggedIn: false,
        currentUser: {},
      };
    case c.LOGIN_REQUEST:
      return {
        ...state,
        loggedIn: false,
        loading: true,
        hasError: false,
      };
    case c.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        hasError: false,
      };
    case c.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.MFA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.LOGOUT_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        loading: false,
        hasError: false,
        isLoggedIn: false,
      };
    case c.LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case c.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotSent: false,
        loading: true,
        hasError: false,
      };
    case c.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        forgotSent: true,
      };
    case c.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        forgotSent: false,
        error: action.error,
      };
    case c.CONFIRM_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        emailConfirmedError: false,
      };
    case c.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        emailConfirmed: true,
        emailConfirmedError: false,
      };
    case c.CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        emailConfirmedError: true,
      };
    case c.RESEND_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        emailConfirmedError: false,
      };
    case c.RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        emailConfirmed: true,
        emailConfirmedError: false,
      };
    case c.RESEND_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        emailConfirmedError: true,
      };
    default:
      return state;
  }
}
