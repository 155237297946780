import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Tooltip } from "antd";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DocumentUpload } from "../../../common/components/file-upload";
import ResourceTypeSelect from "../../../common/components/resource-type-select";
import {
  CancelBtn,
  DescriptionInput,
  SaveBtn,
  TitleInput,
} from "../../../common/components/form";

class ResourceForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
    this.state = {
      Id: 0,
      Title: "",
      Description: "",
      TypeId: null,
      Documents: [],
    };
  }

  shouldComponentUpdate(nextProps) { 
    if(!nextProps.isUpdateMode)
      return false;

    return true;
  }

  pivotData = (data) => ({
    Id: data.Id || this.state.Id,
    Title: data.Title || this.state.Title,
    Description: data.Description || this.state.Description,
    Documents: data.Documents,
    TypeId: data.TypeId,
  });

  handleChange = (e) => {
    if(e.target === null)
      return 

    const { value, id } = e.target;
    this.setState({
      [id]: value,
    });
  };

  handleSelectChange = (id, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  changeDocument = (data) => {
    const { Documents } = this.formIK.current.values;
    this.formIK.current.setFieldValue("Documents", Documents.concat(data));
    this.setState((prevState) => ({
      ...prevState,
      Documents: Documents.concat(data),
    }));
  };

  removeDocument = (event, obj) => {
    const { Documents } = this.formIK.current.values;
    this.formIK.current.setFieldValue(
      "Documents",
      Documents.filter((x) => x.File.Id !== obj.File.Id)
    );
    this.setState((prevState) => ({
      ...prevState,
      Documents: Documents.filter((x) => x.File.Id !== obj.File.Id),
    }));
  };

  render() {
    const ValidationSchema = Yup.object().shape({
      Id: Yup.number(),
      Title: Yup.string()
        .typeError("Title is required")
        .required("Title is required"),
      Description: Yup.string()
        .typeError("Description is required")
        .required("Description is required"),
    });
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        initialValues={{
          Id: this.props.Id || this.state.Id,
          Title: this.props.Title || this.state.Title,
          Description: this.props.Description || this.state.Description,
          Documents: this.props.Documents || this.state.Documents,
          TypeId: this.props.TypeId || this.state.TypeId, 
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
        render={({ values, errors, touched, setFieldValue, handleChange, handleSubmit }) => (
          <Form
            autoComplete={0}
            name="ResourceForm"
            id="ResourceForm"
            onChange={this.handleChange}
            onSubmit={handleSubmit}
          >
            <div className="p5-form-container">
              <Row>
                {this.props.isUpdateMode ? (
                  <Col span={12}>
                    <h1 className="form-label">Resource</h1>
                  </Col>
                ) : (
                  <Col span={24}>
                    <h1 className="form-label">Resource</h1>
                  </Col>
                )}
                {this.props.isUpdateMode && (
                  <Col span={10}>
                    <Tooltip title="Delete Resource Library">
                      <FontAwesomeIcon
                        onClick={this.props.onDelete}
                        className="area-delete-btn"
                        size="2x"
                        icon={faTrash}
                        pull="right"
                      />
                    </Tooltip>
                  </Col>
                )}
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={16}>
                  <TitleInput
                    onChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
                <Col span={8}>
                  <ResourceTypeSelect
                    className="p5-form-dropdown"
                    name="TypeId"
                    value={values.TypeId}
                    onChange={(val) => {
                      this.handleSelectChange("TypeId", val);
                      setFieldValue("TypeId", val);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DescriptionInput
                    onChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <DocumentUpload
                      Documents={values.Documents}
                      onUpload={this.changeDocument}
                      onSelect={this.removeDocument}
                      multiple
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  style={{ margin: "5px" }}
                >
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  style={{ margin: "5px" }}
                >
                  <SaveBtn
                    hidden={this.props.disableSubmit}
                    onClick={handleSubmit}
                    loading={this.props.loading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      />
    );
  }
}

ResourceForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  disableSubmit: PropTypes.bool,
  Id: PropTypes.number,
  Title: PropTypes.string,
  Description: PropTypes.string,
  Documents: PropTypes.arrayOf(PropTypes.shape({})),
  TypeId: PropTypes.number,
  isUpdateMode: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
};

ResourceForm.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},
  onDelete: () => {},
  TypeId: null,
  disableSubmit: false,
  Id: 0,
  Title: "",
  Description: "",
  Documents: [],
  isUpdateMode: false,
  match: {},
  loading: false,
};

export default ResourceForm;
