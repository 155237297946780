import { store } from '../../store';

const versionTypes = [
    { Id: 1, Type: 'Lite'},
    { Id: 2, Type: 'Full'},
]

function IsLiteVersion() {
    const { currentUser } = store.getState().AccountState;
    const LiteVersion = versionTypes.find(v => v.Id === versionTypes[0].Id);
    if (currentUser && currentUser.SystemVersionId === LiteVersion.Id) { 
      return true;
    }
  
    return false;
}

function IsFullVersion() {
    const { currentUser } = store.getState().AccountState;
    const FullVersion = versionTypes.find(v => v.Id === versionTypes[1].Id);
    if (currentUser && currentUser.SystemVersionId === FullVersion.Id) { 
      return true;
    }
    
    return false;
}

function GetVersion() {
    const { currentUser } = store.getState().AccountState;
    if (currentUser) {
      const SystemVersion = versionTypes.find(v => v.Id === currentUser.SystemVersionId);
      return SystemVersion;
    }
  
    return null;
}

export {versionTypes, IsLiteVersion, IsFullVersion, GetVersion }
  