export default ({
    GET_AREA_LIST_REQUEST: "GET_AREA_LIST_REQUEST",
    GET_AREA_LIST_SUCCESS: "GET_AREA_LIST_SUCCESS",
    GET_AREA_LIST_FAILURE: "GET_AREA_LIST_FAILURE",
    CREATE_AREA_REQUEST: 'CREATE_AREA_REQUEST',
    CREATE_AREA_SUCCESS: 'CREATE_AREA_SUCCESS',
    CREATE_AREA_FAILURE: 'CREATE_AREA_FAILURE',
    GET_AREA_REQUEST: 'GET_AREA_REQUEST',
    GET_AREA_SUCCESS: 'GET_AREA_SUCCESS',
    GET_AREA_FAILURE: 'GET_AREA_FAILURE',
    UPDATE_AREA_REQUEST: 'UPDATE_AREA_REQUEST',
    UPDATE_AREA_SUCCESS: 'UPDATE_AREA_SUCCESS',
    UPDATE_AREA_FAILURE: 'UPDATE_AREA_FAILURE',
    DELETE_AREA_REQUEST: 'DELETE_AREA_REQUEST',
    DELETE_AREA_SUCCESS: 'DELETE_AREA_SUCCESS',
    DELETE_AREA_FAILURE: 'DELETE_AREA_FAILURE',
  });