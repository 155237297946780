export default {
    GET_VPS_REQUEST: 'GET_VPS_REQUEST',
    GET_VPS_SUCCESS: 'GET_VPS_SUCCESS',
    GET_VPS_FAILURE: 'GET_VPS_FAILURE',
    GET_LICENSED_REQUEST: 'GET_LICENSED_REQUEST',
    GET_LICENSED_SUCCESS: 'GET_LICENSED_SUCCESS',
    GET_LICENSED_FAILURE: 'GET_LICENSED_FAILURE',
    GET_VICTIM_DETAILS_REQUEST: 'GET_VICTIM_DETAILS_REQUEST',
    GET_VICTIM_DETAILS_SUCCESS: 'GET_VICTIM_DETAILS_SUCCESS',
    GET_VICTIM_DETAILS_FAILURE: 'GET_VICTIM_DETAILS_FAILURE',
    GET_REGISTERED_DEVICES_REQUEST: 'GET_REGISTERED_DEVICES_REQUEST',
    GET_REGISTERED_DEVICES_SUCCESS: 'GET_REGISTERED_DEVICES_SUCCESS',
    GET_REGISTERED_DEVICES_FAILURE: 'GET_REGISTERED_DEVICES_FAILURE',
    GET_ACTIVATED_DEVICES_REQUEST: 'GET_ACTIVATED_DEVICES_REQUEST',
    GET_ACTIVATED_DEVICES_SUCCESS: 'GET_ACTIVATED_DEVICES_SUCCESS',
    GET_ACTIVATED_DEVICES_FAILURE: 'GET_ACTIVATED_DEVICES_FAILURE',
    GET_OVERDUE_REGISTRATIONS_REQUEST: 'GET_OVERDUE_REGISTRATIONS_REQUEST',
    GET_OVERDUE_REGISTRATIONS_SUCCESS: 'GET_OVERDUE_REGISTRATIONS_SUCCESS',
    GET_OVERDUE_REGISTRATIONS_FAILURE: 'GET_OVERDUE_REGISTRATIONS_FAILURE',
    GET_GENDER_DEVICES_REQUEST: 'GET_GENDER_DEVICES_REQUEST',
    GET_GENDER_DEVICES_SUCCESS: 'GET_GENDER_DEVICES_SUCCESS',
    GET_GENDER_DEVICES_FAILURE: 'GET_GENDER_DEVICES_FAILURE',
    GET_RISK_CATEGORY_DEVICES_REQUEST: 'GET_RISK_CATEGORY_DEVICES_REQUEST',
    GET_RISK_CATEGORY_DEVICES_SUCCESS: 'GET_RISK_CATEGORY_DEVICES_SUCCESS',
    GET_RISK_CATEGORY_DEVICES_FAILURE: 'GET_RISK_CATEGORY_DEVICES_FAILURE',
    GET_NO_LOCATION_REPORTED_REQUEST: 'GET_NO_LOCATION_REPORTED_REQUEST',
    GET_NO_LOCATION_REPORTED_SUCCESS: 'GET_NO_LOCATION_REPORTED_SUCCESS',
    GET_NO_LOCATION_REPORTED_FAILURE: 'GET_NO_LOCATION_REPORTED_FAILURE',
    GET_ALLOCATION_DETAILS_REQUEST: 'GET_ALLOCATION_DETAILS_REQUEST',
    GET_ALLOCATION_DETAILS_SUCCESS: 'GET_ALLOCATION_DETAILS_SUCCESS',
    GET_ALLOCATION_DETAILS_FAILURE: 'GET_ALLOCATION_DETAILS_FAILURE',
    GET_ALLOCATION_USAGE_DETAILS_REQUEST: 'GET_ALLOCATION_USAGE_DETAILS_REQUEST',
    GET_ALLOCATION_USAGE_DETAILS_SUCCESS: 'GET_ALLOCATION_USAGE_DETAILS_SUCCESS',
    GET_ALLOCATION_USAGE_DETAILS_FAILURE: 'GET_ALLOCATION_USAGE_DETAILS_FAILURE',
    GET_ALERT_CALL_DETAILS_REQUEST: 'GET_ALERT_CALL_DETAILS_REQUEST',
    GET_ALERT_CALL_DETAILS_SUCCESS: 'GET_ALERT_CALL_DETAILS_SUCCESS',
    GET_ALERT_CALL_DETAILS_FAILURE: 'GET_ALERT_CALL_DETAILS_FAILURE'
}