import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Divider, Row } from 'antd';
import { ShepherdTour, TourMethods } from 'react-shepherd'
import { HelpSteps } from '../tour-steps';
import navigationActions from "../../../common/components/navigation/action";
import TourRegAction from "../actions";
import TourGuideStartModal from "../components/tour-guide-start-modal";
import "../shepardtour.css";
// import { Layout, PageHeader } from '../../../common/components';
import '../../help/page/HelpPage.css';

const { tourOptions, HelpTourSteps } = HelpSteps;

class HelpTourPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      TourStartModalshow: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("help"));
    this.setState({ TourStartModalshow: true });
    this.props.dispatch(TourRegAction.GetCurrentTourGuide(
      4,
      this.props.userId
    ));
  }

  hideTourGuideLandingModal = () => {
    this.setState({ TourStartModalshow: false });
  };

  pivotCompleteData = () => ({
    TourId: this.props.CurrentTourGuide.TourGuideId,
    UserId: this.props.userId
  });

  CompleteTourGuide = () => {    
    this.props.dispatch(TourRegAction.MarkTourGuideAsCompleted(
      this.pivotCompleteData(),
      this.props.dispatch(TourRegAction.CheckUserActiveTourGuides(this.props.userId))
    ));
  }

    render(){ 
        
        const newLineChar = '%0D%0A';

        return (
          <ShepherdTour steps={HelpTourSteps} tourOptions={tourOptions}>
            <TourMethods>
              {(tourContext) => (
                <TourGuideStartModal
                  startTour={tourContext}
                  hideModal={this.hideTourGuideLandingModal}
                  showModal={this.state.TourStartModalshow}
                  completeGuide={this.CompleteTourGuide}
                  tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                  userId={this.props.userId}
                  tourName="Help"
                  title="Welcome to the Help Tour Guide"
                  description="In this tour guide, you will learn about the Help sections. We will explain each column of the grid and some important items to note while using the TecSAFE portal."
                />
              )}
            </TourMethods>
            <div className="help-page-container">
              <Row justify="center">
                <Col
                  xs={20}
                  md={20}
                  lg={18}
                  className="sh-tour-help-op-support"
                >
                  <Divider
                    orientation="left"
                    style={{ fontSize: "18px", fontWeight: "500" }}
                    plain
                  >
                    Operational Support
                  </Divider>
                  <section className="help-text">
                    <span>
                      To speak with the duty TecSAFE manager about an urgent
                      operational issue 24/7, call 020 3998 7389.
                    </span>
                    <br />
                    <span>
                      For non urgent issues email
                      <a
                        href={`mailto:tecsafesupport@pds.police.uk?subject=TecSAFE&body=Report an issue${newLineChar}Force:${newLineChar}Area:${newLineChar}Name:${newLineChar}Contact Email:${newLineChar}Contact Phone Number:${newLineChar}App Activation Key or Handset Phone Number:${newLineChar}Description of issue:${newLineChar}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        &nbsp;tecsafesupport@pds.police.uk&nbsp;
                      </a>
                      and we will aim to respond within 24 hours
                    </span>
                  </section>
                </Col>
              </Row>
              <Row justify="center">
                <Col xs={20} md={20} lg={18} className="sh-tour-help-billing">
                  <Divider
                    orientation="left"
                    style={{ fontSize: "18px", fontWeight: "500" }}
                    plain
                  >
                    Contracts/Billing Enquiries
                  </Divider>
                  <section className="help-text">
                    <div>
                      <span>
                        Call 020 3998 7389 for non urgent issues to do with
                        contracts or billing enquiries during office hours.
                      </span>
                    </div>
                  </section>
                </Col>
              </Row>
              <Row justify="center">
                <Col xs={20} md={20} lg={18} className="sh-tour-help-good-news">
                  <Divider
                    orientation="left"
                    style={{ fontSize: "18px", fontWeight: "500" }}
                    plain
                  >
                    Good News Stories
                  </Divider>
                  <section className="help-text">
                    <div>
                      <span>
                        Submit your good news story about TecSAFE via the link
                        <a
                          href="mailto:tecsafesupport@pds.police.uk?subject=Good News Story TecSAFE&body=Good News Story Details -"
                          target="_blank"
                          rel="noreferrer"
                        >
                          &nbsp;tecsafesupport@pds.police.uk&nbsp;
                        </a>
                      </span>
                    </div>
                  </section>
                </Col>
              </Row>
              <Row justify="center">
                <Col
                  xs={20}
                  md={20}
                  lg={18}
                  className="sh-tour-help-suggestion"
                >
                  <Divider
                    orientation="left"
                    style={{ fontSize: "18px", fontWeight: "500" }}
                    plain
                  >
                    Suggestions
                  </Divider>
                  <section className="help-text">
                    <div>
                      <span>
                        Submit your suggestions in relation to TecSAFE via the
                        link
                        <a
                          href="mailto:tecsafesupport@pds.police.uk?subject=TecSAFE Suggestions&body=Suggestion Details -"
                          target="_blank"
                          rel="noreferrer"
                        >
                          &nbsp;tecsafesupport@pds.police.uk&nbsp;
                        </a>
                      </span>
                    </div>
                  </section>
                </Col>
              </Row>
            </div>
          </ShepherdTour>
        );
    }
}

HelpTourPage.propTypes = {
  dispatch: PropTypes.func,
  userId: PropTypes.number,
  CurrentTourGuide: PropTypes.shape({
    TourGuideId: PropTypes.number
  }),
};

HelpTourPage.defaultProps = {
  dispatch: () => {},
  userId: 0,
  CurrentTourGuide: {
    TourGuideId: 4
  },
};

function mapStateToProps(state) {
  const { Id } = state.AccountState.currentUser;
  const { CurrentTourGuide } = state.TourGuide;

  return {
    userId: Id,
    CurrentTourGuide,
  };
}

const connectedHelpPage = connect(mapStateToProps)(HelpTourPage);

export { connectedHelpPage as default };


