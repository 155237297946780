import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Col, Row, Spin } from "antd";
import { VictimDetails } from "../../search/component";
import registrationActions from "../actions";

class VictimDetailModal extends React.Component {
  CloseVictimDataModal = () => {
    const { dispatch } = this.props;
    dispatch(registrationActions.hideVictimDetailModal());
  };

  render() {
    return (
      <Modal
        visible={this.props.show}
        // title={this.props.data.Name}
        onCancel={this.CloseVictimDataModal}
        footer={null}
        style={{ top: 20 }}
        width={1000}
      >
        <div>
          <Spin spinning={this.props.loading}>
            <Row justify="center">
              <Col xs={24} md={24} lg={24}>
                <VictimDetails {...this.props.data} />
              </Col>
            </Row>
          </Spin>
        </div>
      </Modal>
    );
  }
}

VictimDetailModal.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  show: PropTypes.bool,
  data: PropTypes.shape({}),
};

VictimDetailModal.defaultProps = {
  dispatch: () => {},
  loading: false,
  show: false,
  data: {},
};

function mapStateToProps(state) {
  const { loading, show, data } = state.RegisterUserFormState.VictimModalData;
  return {
    loading,
    show,
    data,
  };
}

const connectedVictimDetailModal = connect(mapStateToProps)(VictimDetailModal);

export { connectedVictimDetailModal as default };
