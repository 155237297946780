import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Input, Form, Checkbox, Tooltip, Button, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { DynamicSelect } from "../../../common/components/dynamic-select";
import {
  UserService,
  agencyService,
  AreaService,
  TeamService,
} from "../../../services";
import AuthService from "../../../services/Auth";
import { SaveBtn, CancelBtn } from "../../../common/components/form";
import "./UserForm.css";
import { hasRights, rights, roles } from "../../../common/utils/role-right-helper";

class UserForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.formIK = React.createRef();

    this.state = {
      showResetModal: false,
      showLockoutModal: false,
    }
  }

  pivotData = (data) => ({
    EmailAddress: data.EmailAddress,
    FirstName: data.FirstName,
    Id: data.Id,
    LastName: data.LastName,
    // Password: data.Password,
    Role: { Id: data.Role },
    UserName: data.UserName,
    ReceivedTraining: data.ReceivedTraining,
    TrainedBy: data.TrainedBy,
    LawEnforcementAgencyId: data.Agency === 0 ? null : data.Agency,
    LawEnforcementAgencySubGroupId:
      data.AgencySubGroup === 0 ? null : data.AgencySubGroup,
    LawEnforcementAgencyTeamId:
      data.AgencyTeam === 0 ? null : data.AgencyTeam,
  });

  validateUsernameAndSubmit = (values, actions, isUpdate) => {
    if (isUpdate)
      this.props.onSubmit(values);
    else {
      actions.setStatus(undefined);
      UserService.validateUser(values.UserName).then(
        (valid) => {
          if (valid.data === false) {
            actions.setStatus({ UserName: 'Username is currently in use.' });
            toast.error(`Username is currently in use, please use a different username`);
          }
          else
            this.props.onSubmit(values);
        }
      );
    }
  }

  handleChange = (e) => {
    const { value, id } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  handleShowPasswordResetModal = () => {
    this.setState({
      showResetModal: true,
    });
  }

  handleHidePasswordResetModal = () => {
    this.setState({
      showResetModal: false,
    });
  }

  handleShowLockoutResetModal = () => {
    this.setState({
      showLockoutModal: true,
    });
  }

  handleHideLockoutResetModal = () => {
    this.setState({
      showLockoutModal: false,
    });
  }

  handlePasswordReset = () => {
    const { UserName } = this.props;

    AuthService.forgotPassword({UserName})
      .then(() => {
        toast(`Successfully reset password for ${UserName}`);
      })
      .catch(error => {
        toast.error(`An Error occurred: ${error.toString()}`);
      })
      .finally(() => {
        this.handleHidePasswordResetModal();
      })
  }

  handleLockoutReset = () => {
    const { UserName } = this.props;

    AuthService.resetLockout(UserName)
      .then(() => {
        toast(`Successfully reset user lockout for ${UserName}`);
      })
      .catch(error => {
        toast.error(`An Error occurred: ${error.toString()}`);
      })
      .finally(() => {
        this.handleHideLockoutResetModal();
      })
  }

  render() {
    return (
      <>
        <Modal
          title="Reset User Password"
          visible={this.state.showResetModal}
          onOk={() => this.handlePasswordReset()}
          onCancel={() => this.handleHidePasswordResetModal()}
          okText="Reset"
        >
          Would you like to reset this user&apos;s password?
        </Modal>
        <Modal
          title="Reset User Lockout"
          visible={this.state.showLockoutModal}
          onOk={() => this.handleLockoutReset()}
          onCancel={() => this.handleHideLockoutResetModal()}
          okText="Reset"
        >
          Would you like to reset this user&apos;s lockout period?
        </Modal>
        <Formik
          innerRef={this.formIK}
          enableReinitialize
          initialValues={{
            Id: this.props.Id,
            UserName: this.props.UserName,
            EmailAddress: this.props.EmailAddress,
            FirstName: this.props.FirstName,
            LastName: this.props.LastName,
            Role: this.props.Role.Id,
            Agency: this.props.LawEnforcementAgencyId ?? 0,
            AgencySubGroup: this.props.LawEnforcementAgencySubGroupId ?? 0,
            AgencyTeam: this.props.LawEnforcementAgencyTeamId ?? 0,
            ReceivedTraining: this.props.ReceivedTraining,
            TrainedBy: this.props.TrainedBy,
            isUpdateMode: this.props.isUpdateMode,
          }}
          validationSchema={Yup.object().shape({
            UserName: Yup.string()
              .typeError("Username is required")
              .required("Username is required")
              .test("UsernameValidationTest", "Username is already in use, please use another", value => {
                if (value && !this.props.isUpdateMode) {
                  return new Promise((resolve) => {
                    UserService.validateUser(value).then(
                      (response) => {
                        resolve(response.data)
                      }).catch(() => {
                        resolve(true);
                      });
                  })
                }
                return true;
              }),
            FirstName: Yup.string()
              .typeError("Name is required")
              .required("Name is required"),
            LastName: Yup.string()
              .typeError("Name is required")
              .required("Name is required"),
            EmailAddress: Yup.string()
              .typeError("Name is required")
              .required("Name is required"),
            Role: Yup.number()
              .typeError("Role is required")
              .required("Role is required"),
            Agency: Yup.number().when("Role", {
              is: (role) => role === roles.SystemAdmin,
              then: Yup.number(),
              otherwise: Yup.number()
                .typeError("Force is required")
                .required("Force is required")
                .test('ForceCheck', 'Force is required', value => { return value > 0 }),
            }),
            AgencySubGroup: Yup.number().when("Role", {
              is: (role) => role === roles.AreaAdmin,
              then: Yup.number()
                .typeError("Area is required")
                .required("Area is required")
                .test('AreaCheck', 'Area is required', value => { return value > 0 }),
              otherwise: Yup.number(),
            }),
            AgencyTeam: Yup.number().when("Role", {
              is: (role) => role === roles.TeamUser,
              then: Yup.number()
                .typeError("Team is required")
                .required("Team is required")
                .test('TeamCheck', 'Team is required', value => { return value > 0 }),
              otherwise: Yup.number(),
            }),
          })}
          onSubmit={(values, actions) => this.validateUsernameAndSubmit(this.pivotData(values), actions, values.isUpdateMode)}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <Form
              id={this.props.name}
              name={this.props.name}
              autoComplete="off"
              onChange={handleChange}
              onSubmit={handleSubmit}
            >
              <div className="p5-form-container">
                {this.props.isUpdateMode && (
                  <Row span={24} justify="end">
                    <Col span={10}>
                      <Tooltip title="Delete User">
                        <FontAwesomeIcon
                          onClick={this.props.onDelete}
                          className="p5-delete-icon sh-tour-user-delete-btn"
                          size="2x"
                          icon={faTrash}
                          pull="right"
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                )}
                <Row type="flex" gutter={[16, 8]}>
                  <Col xs={24} sm={24} md={12} lg={12} className="sh-tour-user-username">
                    <Form.Item
                      validateTrigger={['onChange', 'onBlur']}
                      validateStatus={
                        errors && errors.UserName && touched.UserName
                          ? "error"
                          : null
                      }
                      help={errors && touched.UserName && errors.UserName}
                    >
                      <div className="p5-form-label mt-3">
                        Username
                      </div>
                      <Input
                        disabled={this.props.isUpdateMode}
                        type="text"
                        id="UserFormUserName"
                        className="p5-form-input"
                        name="UserName"
                        placeholder="Username"
                        value={values.UserName}
                        onClick={() => setFieldTouched("UserName", true)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="UserName"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" justify="center" gutter={[16, 8]}>
                  <Col xs={24} sm={24} md={12} span={12} className="sh-tour-user-first-name">
                    <Form.Item
                      className="first-name"
                      validateStatus={
                        errors && errors.FirstName && touched.FirstName
                          ? "error"
                          : null
                      }
                      help={errors && touched.FirstName && errors.FirstName}
                    >
                      <div className="p5-form-label">
                        First Name
                      </div>
                      <Input
                        type="text"
                        id="FirstName"
                        className="p5-form-input"
                        name="FirstName"
                        placeholder="First Name"
                        value={values.FirstName}
                        onChange={handleChange}
                        autoComplete="FirstName"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} span={12} className="sh-tour-user-last-name">
                    <Form.Item
                      className="last-name"
                      validateStatus={
                        errors && errors.LastName && touched.LastName
                          ? "error"
                          : null
                      }
                      help={errors && touched.LastName && errors.LastName}
                    >
                      <div className="p5-form-label">
                        Last Name
                      </div>
                      <Input
                        type="text"
                        id="LastName"
                        className="p5-form-input"
                        name="LastName"
                        placeholder="Last Name"
                        value={values.LastName}
                        onChange={handleChange}
                        autoComplete="LastName"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" justify="center" gutter={[16, 8]}>
                  <Col xs={24} sm={24} md={12} span={12} className="sh-tour-user-email">
                    <Form.Item
                      className="user-email"
                      validateStatus={
                        errors && errors.EmailAddress && touched.EmailAddress
                          ? "error"
                          : null
                      }
                      help={errors && touched.EmailAddress && errors.EmailAddress}
                    >
                      <div className="p5-form-label">
                        Email Address
                      </div>
                      <Input
                        type="email"
                        id="EmailAddress"
                        className="p5-form-input"
                        name="EmailAddress"
                        value={values.EmailAddress}
                        placeholder="Email Address"
                        onChange={handleChange}
                        autoComplete="EmailAddress"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    span={12}
                    style={{ padding: "4px 26px 4px 8px" }}
                    className="sh-tour-user-role"
                  >
                    <Form.Item
                      className="user-role-picker"
                      validateStatus={
                        errors && errors.Role && touched.Role ? "error" : null
                      }
                      help={errors && touched.Role && errors.Role}
                    >
                      <div className="p5-form-label">
                        User Role
                      </div>
                      <DynamicSelect
                        Id="role-picker"
                        key="RolePicker"
                        className="p5-form-dropdown"
                        hasNone={false}
                        getData={UserService.getUserRoles}
                        displayFieldName="Name"
                        valueFieldName="Id"
                        value={values.Role}
                        onChange={(val) => {
                          setFieldValue("Role", val);
                          setFieldValue("Agency", 0);
                          setFieldValue("AgencySubGroup", 0);
                          setFieldValue("AgencyTeam", 0);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {values.Role !== null && values.Role !== roles.SystemAdmin && (
                  <Row type="flex" justify="center" gutter={[16, 8]}>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      span={12}
                      style={{ padding: "4px 26px 4px 8px" }}
                      className="sh-tour-user-force"
                    >
                      <Form.Item
                        className="user-force-picker"
                        validateStatus={
                          errors && errors.Agency && touched.Agency ? "error" : null
                        }
                        help={errors && touched.Agency && errors.Agency}
                      >
                        <div className="p5-form-label">
                          Force
                        </div>
                        <DynamicSelect
                          key="agencyPicker"
                          className="p5-form-dropdown"
                          hasNone={hasRights([rights.CanManageForceUsers])}
                          getData={agencyService.getForceList}
                          displayFieldName="Name"
                          valueFieldName="Id"
                          value={values.Agency}
                          onChange={(val) => {
                            setFieldValue("Agency", val);
                            setFieldValue("AgencySubGroup", 0);
                            setFieldValue("AgencyTeam", 0);
                          }}
                          isDisabled={
                            !hasRights([
                              rights.CanManageForceUsers,
                              rights.CanManageAreaUsers,
                              rights.CanManageTeamUsers,
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {values.Role !== roles.SystemAdmin && values.Role !== roles.SearchUser && values.Role !== null && (
                  <Row type="flex" justify="center" gutter={[16, 8]}>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      span={12}
                      style={{ padding: "4px 26px 4px 8px" }}
                      className="sh-tour-user-area"
                    >
                      <Form.Item
                        className="user-area-picker"
                        validateStatus={
                          errors && errors.AgencySubGroup && touched.AgencySubGroup
                            ? "error"
                            : null
                        }
                        help={
                          errors && touched.AgencySubGroup && errors.AgencySubGroup
                        }
                      >
                        <div className="p5-form-label">
                          Area
                        </div>
                        <DynamicSelect
                          key="subGroupPicker"
                          className="p5-form-dropdown"
                          getData={AreaService.getAreaList}
                          displayFieldName="Name"
                          valueFieldName="Id"
                          value={values.AgencySubGroup}
                          filters={{ force: values.Agency }}
                          onChange={(val) => {
                            setFieldValue("AgencySubGroup", val);
                            setFieldValue("AgencyTeam", 0);
                          }}
                          isDisabled={
                            !hasRights([
                              rights.CanManageAreaUsers,
                              rights.CanManageTeamUsers,
                            ])
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {values.Role !== roles.SystemAdmin && values.Role !== roles.SearchUser && values.Role !== null && (
                  <Row type="flex" justify="center" gutter={[16, 8]}>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      span={12}
                      style={{ padding: "4px 26px 4px 8px" }}
                      className="sh-tour-user-team"
                    >
                      <Form.Item
                        className="user-team-picker"
                        validateStatus={
                          errors && errors.AgencyTeam && touched.AgencyTeam
                            ? "error"
                            : null
                        }
                        help={errors && touched.AgencyTeam && errors.AgencyTeam}
                      >
                        <div className="p5-form-label">
                          Team
                        </div>
                        <DynamicSelect
                          key="TeamPicker"
                          className="p5-form-dropdown"
                          getData={TeamService.getTeamList}
                          displayFieldName="Name"
                          valueFieldName="Id"
                          value={values.AgencyTeam}
                          filters={{
                            force: values.Agency,
                            area:
                              values.AgencySubGroup === 0
                                ? null
                                : values.AgencySubGroup,
                          }}
                          onChange={(val) => {
                            setFieldValue("AgencyTeam", val);
                          }}
                          isDisabled={!hasRights([rights.CanManageTeamUsers])}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row justify="end">
                  <Col xs={24} sm={24} md={12} lg={12} className="sh-tour-user-trained">
                    <div className="p5-checkbox-col">
                      <Form.Item>
                        <div>
                          <span className="p5-checkbox">
                            <Checkbox
                              id="user-pds-trained"
                              disabled={this.props.isUpdateMode}
                              checked={values.ReceivedTraining}
                              value={values.ReceivedTraining}
                              onChange={(val) => {
                                setFieldValue(
                                  "ReceivedTraining",
                                  val.target.checked
                                );
                                setFieldValue(
                                  "TrainedBy",
                                  `${this.props.trainedFirstName} ${this.props.trainedLastName}`
                                )
                              }}
                            />
                          </span>
                          <span className="p5-form-label">
                            Trained by Police Digital Services
                          </span>
                        </div>
                        {values.ReceivedTraining && values.TrainedBy !== "" && values.TrainedBy !== null && (
                          <div style={{ marginLeft: "30px" }}>
                            <span>I, <strong>{`${values.TrainedBy}`}</strong> confirm that the Police TecSAFE User I am creating has been trained by Police Digital Services.</span>
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row className="sh-tour-user-footer-btn" style={{ marginBottom: -48 }}>
                  <Col
                    xs={24}
                    md={24}
                    xl={12}
                    style={{
                      marginBottom: 48
                    }}
                  >
                    {this.props.isUpdateMode ?
                      (
                        <Row>
                          <Col
                            xs={24}
                            md={12}
                            style={{ padding: "5px" }}
                          >
                            <Button
                              block
                              type="primary"
                              id="resetPasswordBtn"
                              onClick={() => this.handleShowPasswordResetModal()}
                            >
                              Reset Password
                            </Button>
                          </Col>
                          <Col
                            xs={24}
                            md={12}
                            style={{ padding: "5px" }}
                          >
                            <Button
                              block
                              type="primary"
                              id="resetLockoutBtn"
                              onClick={() => this.handleShowLockoutResetModal()}
                            >
                              Reset User Lockout
                            </Button>
                          </Col>
                        </Row>
                      ) : <></>}
                  </Col>
                  <Col
                    xs={24}
                    md={24}
                    xl={12}
                    style={{
                      marginBottom: 48
                    }}
                  >
                    <Row>
                      <Col
                        xs={24}
                        md={12}
                        style={{ padding: "5px" }}
                      >
                        <CancelBtn onClick={this.props.onCancel} />
                      </Col>
                      <Col
                        xs={24}
                        md={12}
                        style={{ padding: "5px" }}
                      >
                        <SaveBtn
                          onClick={handleSubmit}
                          loading={this.props.loading}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

UserForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  name: PropTypes.string,
  Id: PropTypes.number,
  UserName: PropTypes.string,
  // Password: PropTypes.string,
  EmailAddress: PropTypes.string,
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  Role: PropTypes.shape({
    Id: PropTypes.number,
    // Name: PropTypes.string,
  }),
  LawEnforcementAgencyId: PropTypes.number,
  LawEnforcementAgencySubGroupId: PropTypes.number,
  LawEnforcementAgencyTeamId: PropTypes.number,
  ReceivedTraining: PropTypes.bool,
  isUpdateMode: PropTypes.bool,
  loading: PropTypes.bool,
  TrainedBy: PropTypes.string,
  trainedFirstName: PropTypes.string,
  trainedLastName: PropTypes.string
};

UserForm.defaultProps = {
  onCancel: () => { },
  onDelete: () => { },
  name: "",
  history: {
    push: () => { },
  },
  Id: 0,
  UserName: "",
  // Password: "",
  EmailAddress: "",
  FirstName: "",
  LastName: "",
  Role: {
    Id: null,
    Name: null,
  },
  LawEnforcementAgencyId: 0,
  LawEnforcementAgencySubGroupId: 0,
  LawEnforcementAgencyTeamId: 0,
  ReceivedTraining: false,
  isUpdateMode: false,
  loading: false,
  TrainedBy: "",
  trainedFirstName: "",
  trainedLastName: "",
};

function mapStateToProps(state) {
  const { FirstName, LastName } = state.AccountState.currentUser;
  return {
    // trainedUserId: Id,
    trainedFirstName: FirstName,
    trainedLastName: LastName,
  };
}

const connectedUserForm = connect(mapStateToProps)(UserForm);

export { connectedUserForm as default };