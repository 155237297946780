import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button, Tooltip } from 'antd';
import Dropzone from "react-dropzone";
import { faFile, faFileWord, faFileExcel, faFilePdf, faFilePowerpoint } from "@fortawesome/pro-regular-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import fileUploadActions from "./action";

import "./DocumentUpload.scss";

class DocumentUpload extends React.Component {
  constructor(props) {
    super(props);

    this.downloadRef = React.createRef();
  }

  handleUpload = (files) => {
    if (!(files && files.length)) {
      return;
    }
    this.props.dispatch(
      fileUploadActions.createDocuments(files, this.props.onUpload)
    );
  };

  fileDownload = (response, document) => {
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const fileName = document.File.Name;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      this.downloadRef.current.href = URL.createObjectURL(blob);
      this.downloadRef.current.download = fileName;
      this.downloadRef.current.click();
    }
  };

  handleDownload = (document) => {
    this.props.dispatch(
      fileUploadActions.download(document, this.fileDownload)
    );
  };

  getFileIcon = (type) => {
    const fileIcon = (
      <span>
        <FontAwesomeIcon
          style={{ fontSize: "15px", marginRight: "5px", color: "#ef4e4e"}}
          icon={faFile}
        />
      </span>
    )
  
    const pdfIcon = (
      <span>
        <FontAwesomeIcon
          style={{ fontSize: "15px", marginRight: "5px", color: "#ff0000"}}
          icon={faFilePdf}
        />
      </span>
    )
  
    const wordIcon = (
      <span>
        <FontAwesomeIcon
          style={{ ffontSize: "15px", marginRight: "5px", color: "#0078d7"}}
          icon={faFileWord}
        />
      </span>
    )

    const excelIcon = (
      <span>
        <FontAwesomeIcon
          style={{ fontSize: "15px", marginRight: "5px", color: "#1D6F42"}}
          icon={faFileExcel}
        />
      </span>
    )
  
    const powerPointIcon = (
      <span>
        <FontAwesomeIcon
          style={{ fontSize: "15px", marginRight: "5px", color: "#D04423"}}
          icon={faFilePowerpoint}
        />
      </span>
    )
    switch (type) {
      case "application/pdf":
        return pdfIcon;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return wordIcon;
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return excelIcon;
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return powerPointIcon;            
      default:
          return fileIcon;
      }
    };

  render() {
    return (
      <div>
        <Dropzone
          accept=".pdf,.pptx,.docx,.xlsx,.txt"
          multiple={this.props.multiple}
          onDrop={this.handleUpload}
        >
          {({ getRootProps, getInputProps }) => (
            <section className="container dropzone-container">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>Drag and drop documents here, or click to select documents</p>
              </div>
              {this.props.Documents && this.props.Documents.length > 0 && (
                <aside>
                  <h4>Documents</h4>
                  <ul>
                    {this.props.Documents.map((document) => (
                      <li key={document.File.Id} style={{ listStyle: "none", display: "inline-block"}}>
                        <div style={{position: "relative", margin: "15px"}}>
                          <Tooltip title={`Download ${document.File.Name}`}>
                            <Button className="document-btns" onClick={() => this.handleDownload(document)} size="large" icon={this.getFileIcon(document.File.ContentType)}>
                              {document.File.Name.length > 20 ? `${document.File.Name.substring(0, 17)}...` : document.File.Name}              
                            </Button>
                          </Tooltip>
                          
                          <Tooltip title="Remove File">
                            <FontAwesomeIcon
                              onClick={(e) => this.props.onSelect(e, document)}
                              className="documentUpload-delete__icon"
                              icon={faTimesCircle}
                            />
                          </Tooltip>
                        </div>
                      </li>
                    ))}
                  </ul>
                </aside>
              )}
            </section>
          )}
        </Dropzone>
        <a
          style={{ display: "none" }}
          href={process.env.DOWNLOAD_URL}
          ref={this.downloadRef}
        >
          Download Link
        </a>
      </div>
    );
  }
}

DocumentUpload.propTypes = {
  dispatch: () => {},
  onUpload: PropTypes.func,
  onSelect: PropTypes.func,
  Id: PropTypes.number,
  Documents: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  hasError: PropTypes.bool,
};

DocumentUpload.defaultProps = {
  dispatch: () => {},
  onUpload: () => {},
  onSelect: () => {},
  Id: 0,
  Documents: [],
  hasError: false,
  loading: false,
  multiple: false,
};

function mapStateToProps(state) {
  const { loading, hasError } = state.DocumentUpload;
  return {
    loading,
    hasError,
  };
}

const connectedDocumentUpload = connect(mapStateToProps)(DocumentUpload);

export { connectedDocumentUpload as default };
