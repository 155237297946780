import React from "react";
import { connect } from "react-redux";
// import "antd/dist/antd.css";
import { Table, Tooltip } from "antd";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { history } from "../../../store";
import { agencyService } from "../../../services";

class LEAGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getLEAData();
  }

  getLEAData() {
    agencyService.getForceGrid().then((result) => {
      const pivotDate = result.data;
      const gridData = [];
      pivotDate.map((p) => {
        const areaGrid = [];
        const areaData = p.AreaList;
        areaData.map((a) => {
          const subTeamData =[{
            key: `area-team-heading-${a.id}`,
            Id:0,
            Name: `Teams (${Object.keys(a.TeamList).length})`,
            RecordType: "header",
            children: Object.keys(a.TeamList).length > 0 ? a.TeamList : null,
          }];
          const aData = {
            children: Object.keys(a.TeamList).length > 0 ? subTeamData : null,
            RecordType: 'area',
            key: `area-${a.Id}`,
            ...a
          }
          areaGrid.push(aData);
          return areaGrid;
        });
        const forceChidren = [
            {
              key: `force-area-${p.Id}`,
              Name: `Areas (${Object.keys(areaGrid).length})`,
              RecordType: 'header',
              children: Object.keys(areaGrid).length > 0 ? areaGrid : null,
            },
            {
              key: `force-team-${p.Id}`,
              Name:  `Teams (${Object.keys(p.TeamList).length})`,
              RecordType: 'header',
              children: Object.keys(p.TeamList).length > 0 ? p.TeamList : null,
            }          
        ];
        const temp = {
          key: p.Id,
          children: Object.keys(areaGrid).length > 0 || Object.keys(p.TeamList).length > 0 ? forceChidren : null ,
          ...p,
        };
        gridData.push(temp);
        return gridData;
      });
      this.setState({
        data: gridData,
        loading: false,
      });
    });
  }

  editForce = (key) => {
    // history.push(`force/${key.Id}`);
    history.push(`${key.url}`);
  };

  render() {
    const columns = [
      {
        title: "Force",
        dataIndex: "Name",    
        key: "Name",       
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ['descend', 'ascend'],
      },  
      {
        title: "",
        dataIndex: "Id",
        key: "Id",
        render: (text, record) =>
          this.state.data.length >= 1 && record.RecordType !== 'header' ? (
            <>
              <span>
                <Tooltip title={`Edit ${record.RecordType} - ${record.Name}`}> 
                  <FontAwesomeIcon
                    onClick={() => this.editForce(record)}
                    size="1x"
                    icon={faEdit}
                    pull="right"
                  />
                </Tooltip>
              </span>
            </>
          ) : null,   
      },
    ];

    const result = (
      <div className="force-grid">
        <Table columns={columns} dataSource={this.state.data} scroll={{ x: 700 }} size="small" loading={this.state.loading} />
      </div>
    );

    return result;
  }
}

function mapStateToProps() {
  return {};
}

const connectedLEAGrid = connect(mapStateToProps)(LEAGrid);

export { connectedLEAGrid as default };