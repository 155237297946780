/* eslint import/prefer-default-export: 0 */
import api from '../common';

function search(data) {
  return api.post('api/Victim/Search', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getVictimView(id) {
    return api.get(`api/Victim/Search/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
      .then((response) => response)
      .catch((error) => Promise.reject(error));
  }

export {
  search,
  getVictimView,
};
