import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row, Input, Form } from "antd";
// import Loading from '../../common/components/loading';

import accountActions from "../actions";
// import profileActions from '../profile/action';
import TechSosLogo from "../../../common/assets/img/TecSAFE_logo_light.png";
import "./pages.css";

class ConfirmEmailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      password: "",
      confirmPassword: "",
      showConfirm: false,
      username: "",
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryStringParameters = queryString.parse(location.search);
    this.setState({ token: queryStringParameters.token });
  }

  handleEmailConfirmation = (data) => {
    this.props.dispatch(accountActions.ConfirmEmail(data, this.handleSuccessCallback));
  };

  handleResendConfirmation = (data) => {
    this.props.dispatch(accountActions.ResendConfirmationEmail(data, this.handleSuccessCallback));
  };

  handleSuccessCallback = () => {
    this.props.dispatch(push("/login"));
  }

  pivotData = (data) => ({
    Token: data.token,
      Password: data.password,
  });

  pivotResendData = (data) => ({
    UserName: data.username,
  });

  validatePassword = value =>{
    // eslint-disable-next-line
    const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?!@#\$%\^&\*])(?=.{6,})/;

    return regex.test(value);
  }

  validateHasLowercase = (value) => {
    const regex = /(?=.*[a-z])/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }

    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateHasUppercase = (value) => {
    const regex = /(?=.*[A-Z])/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateHasNumber = (value) => {
    const regex = /(?=.*[0-9])/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateHasSpecialCharacter = (value) => {
    // eslint-disable-next-line
    const regex = /(?=.*[?!@#\$%\^&\*])/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateHasMinCount = (value) => {
    const regex = /(?=.{6,})/;

    if(regex.test(value)) {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  validateConfirmPassword = (value, confirm) => {
    if(value === confirm && value !== "") {
      return (
        <>
          <span>
            <FontAwesomeIcon
              style={{color: '#207603'}}
              icon={faCheckCircle}
            />
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>
          <FontAwesomeIcon
            style={{color: '#B90505'}}
            icon={faExclamationCircle}
          />
        </span>
      </>
    );
  }

  render() {
    // eslint-disable-next-line
    const passwordRegExp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[?!@#\$%\^&\*])(?=.{6,})/;
    const ValidationSchema = Yup.object().shape({
      password: Yup.string()
        .matches(passwordRegExp,"Password is weak.")
        .typeError("Confirm password is required")
        .required("Confirm password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .typeError("Confirm password is required")
        .required("Confirm password is required"),
    });

    return (
      <>
        {
          !this.state.showConfirm ? (
            <Formik
              enableReinitialize
              initialValues={{
          token: this.state.token,
          password: this.state.password,
          confirmPassword: this.state.confirmPassword,
        }}
              validationSchema={ValidationSchema}
              onSubmit={(values) => this.handleEmailConfirmation(this.pivotData(values))}
            >
              {({ values, errors, touched, setFieldValue, handleSubmit }) => (
                <Form
                  autoComplete={0}
                  name="ConfirmEmailForm"
                  id="ConfirmEmailForm"
                  onChange={this.handleChange}
                  onSubmit={handleSubmit}
                >
                  <Row justify="center">
                    <Col span={20}>
                      <div className="confirm-logo-container">
                        <img src={TechSosLogo} style={{"maxWidth": "450px"}} width="100%" id="Logo" alt="Logo" />
                      </div>
                    </Col>
                  </Row>
                  <div className="confirm-email-container">
                    <Row justify="center">
                      <h5 style={{ textAlign: "center" }}>
                        To complete email comfirmation, please set your password.
                      </h5>
                    </Row>
                    <br />
                    <Row justify="center">
                      <Col span={24}>
                        <Form.Item
                          className="email-password"
                          validateStatus={
                      errors && errors.password && touched.password
                        ? "error"
                        : null
                    }
                          help={errors && touched.password && errors.password}
                        >
                          <div className="p5-form-label">
                            <span>Password</span>
                          </div>
                          <Input.Password
                            placeholder="Password"
                            id="password"
                            className="p5-form-input"
                            value={values.password}
                            onChange={(val) => {
                        setFieldValue("password", val.target.value);
                      }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={24}>
                        <Form.Item
                          className="email-password"
                          validateStatus={
                      errors &&
                      errors.confirmPassword &&
                      touched.confirmPassword
                        ? "error"
                        : null
                    }
                          help={
                      errors &&
                      touched.confirmPassword &&
                      errors.confirmPassword
                    }
                        >
                          <div className="p5-form-label">
                            <span>Confirm Password</span>
                          </div>
                          <Input.Password
                            placeholder="Confirm Password"
                            id="confirm-password"
                            className="p5-form-input"
                            value={values.confirmPassword}
                            onChange={(val) => {
                        setFieldValue("confirmPassword", val.target.value);
                      }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: 0, paddingBottom: 20, marginTop: 10, marginBottom: -5 }}>
                      <div className="form-label" style={{ fontSize: 14 }}>Passwords must have at least one lowercase letter, one uppercase letter, one number, and one special character !?@#$%^&amp;*</div>
                    </Row>
                    <Row justify="Center">
                      <Col span={24}>
                        <div className="form-label" style={{ fontSize: 14 }}>{this.validateHasMinCount(values.password)} <span>Contains at least 6 characters</span></div>
                        <div className="form-label" style={{ fontSize: 14 }}>{this.validateHasLowercase(values.password)} <span>Contains lowercase letter</span></div>
                        <div className="form-label" style={{ fontSize: 14 }}>{this.validateHasUppercase(values.password)} <span>Contains uppercase letter</span></div>
                        <div className="form-label" style={{ fontSize: 14 }}>{this.validateHasNumber(values.password)} <span>Contains number</span></div>
                        <div className="form-label" style={{ fontSize: 14 }}>{this.validateHasSpecialCharacter(values.password)} <span>Contains special character !?@#$%^&amp;*</span></div>
                        <div className="form-label" style={{ fontSize: 14 }}>{this.validateConfirmPassword(values.password, values.confirmPassword)} <span>Passwords match</span></div>
                      </Col>
                    </Row>
                    <br />
                    <Row justify="center">
                      <Col span={24}>
                        <Button
                          className="confirmation-page-button"
                          id="confirmEmailButton"
                          onClick={handleSubmit}
                          block
                          type="submit"
                          loading={this.props.loading}
                          disabled={!this.validatePassword(values.password)}
                        >
                          Set Password
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          className="resend-link"
                          id="resendLinkButton"
                          onClick={() => this.setState({showConfirm: true})}
                          block
                          loading={this.props.loading}
                        >
                          Resend Confirmation Link
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
        )}
            </Formik>
          ) : (
            <Formik
              enableReinitialize
              initialValues={{
                username: this.state.username,
              }}
              onSubmit={(values) => this.handleResendConfirmation(this.pivotResendData(values))}
            >
              {({ values, setFieldValue, handleSubmit }) => (
                <Form
                  autoComplete={0}
                  name="ConfirmEmailForm"
                  id="ConfirmEmailForm"
                  onChange={this.handleChange}
                  onSubmit={handleSubmit}
                >
                  <Row justify="center">
                    <Col span={20}>
                      <div className="confirm-logo-container">
                        <img src={TechSosLogo} style={{"maxWidth": "450px"}} width="100%" id="Logo" alt="Logo" />
                      </div>
                    </Col>
                  </Row>
                  <div className="confirm-email-container">
                    <Row justify="center">
                      <h5 style={{ textAlign: "center" }}>
                        Enter username to resend confirmation link.
                      </h5>
                    </Row>
                    <br />
                    <Row justify="center">
                      <Col span={24}>
                        <Form.Item
                          className="email-password"
                        >
                          <div className="p5-form-label">
                            <span>Username</span>
                          </div>
                          <Input
                            placeholder="input username"
                            id="username"
                            className="p5-form-input"
                            value={values.username}
                            onChange={(val) => {
                              setFieldValue("username", val.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Row justify="center">
                        <Col span={24}>
                          <Button
                            className="confirmation-page-button"
                            id="confirmEmailButton"
                            onClick={handleSubmit}
                            block
                            type="submit"
                            loading={this.props.loading}
                          >
                            Resend Confirmation Link
                          </Button>
                        </Col>
                        <Col span={24}>
                          <Button
                            className="resend-link"
                            id="resendLinkButton"
                            onClick={() => this.setState({showConfirm: false})}
                            block
                          >
                            Back
                          </Button>
                        </Col>
                      </Row>
                    </Row>
                  </div>
                </Form>
        )}
            </Formik>
          )
        }
      </>
    );
  }
}

ConfirmEmailPage.propTypes = {
  currentUser: PropTypes.shape({
    UserName: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

ConfirmEmailPage.defaultProps = {
  currentUser: {},
  dispatch: () => {},
  location: {},
  loading: false,
};

function mapStateToProps(state) {
  const { loading } = state.AccountState;
  return {
    loading
  };
}

// eslint-disable-next-line max-len
const connectedConfirmEmailPage = connect(mapStateToProps)(ConfirmEmailPage);

export { connectedConfirmEmailPage as default };
