import React from "react";
import PropTypes from "prop-types";
import * as RoleRightHelpers from '../../utils/role-right-helper';

class RoleRightFilter extends React.Component {
  render() {
    const { roles, rights, children, ...childProps } = this.props;;

    const canShow =
      (!roles || RoleRightHelpers.inRoles(roles))
      && (!rights || RoleRightHelpers.hasRights(rights));
    
    const newChild = React.cloneElement(children, childProps);

    return canShow ? newChild : (<></>);
  }
}

RoleRightFilter.propTypes = {
  children: PropTypes.node.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  rights: PropTypes.arrayOf(PropTypes.string)
}

RoleRightFilter.defaultProps = {
  roles: null,
  rights: null
}

export default RoleRightFilter;