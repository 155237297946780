import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Row, Col } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import SearchGrid from "../search-grid";
import { hasRights, rights } from "../../../common/utils/role-right-helper";
import "./SearchPage.scss";

class SearchIndexPage extends React.Component {

  componentDidMount() {
    if(!hasRights([rights.CanSearchVictims]))
      this.props.dispatch(push("/"));

    this.props.dispatch(navigationActions.changePage("search"));
  }

  render() {
    return (
      <div className="p5-page-container mt-3">
        <Row justify="center">
          <Col xs={24} md={20} lg={18}>
            <SearchGrid 
              isLoading={this.props.loading}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

SearchIndexPage.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
};

SearchIndexPage.defaultProps = {
  dispatch: () => {},
  loading: false
};

function mapStateToProps(state) {
  const { loading } = state.Search;
  return {loading};
}

const connectedIndexPage = connect(mapStateToProps)(SearchIndexPage);

export { connectedIndexPage as default };
