import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { RiskCategoriesService } from '../../../services';

const { Option } = Select;

class RiskCategoriesSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getRiskCategories();
  }

  getRiskCategories() {
    RiskCategoriesService.getRiskCategories().then((result) => {
      this.setState({
        data: result.data.value,
      });
    });
  }

  render() {
    return (
      <Select
        id="RiskCategories"
        className={this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}
        mode="multiple"
      >
        {
          this.state.data.map((opt) => (
            <Option
              key={opt.Id}
              value={opt.Id}
            >
              {opt.Code.trim() === '' ? `${opt.Description}` : `${opt.Code} - ${opt.Description}`}
            </Option>
          ))
        }
      </Select>
    );
  }
}

RiskCategoriesSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
};

RiskCategoriesSelect.defaultProps = {
  value: null,
  className: "",
};

export { RiskCategoriesSelect as default };
