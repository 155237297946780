import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { DataForm, LiteDataForm } from "../form";
import { IsLiteVersion, IsFullVersion } from "../../../common/utils/system-version-helper";
import actions from "../actions";
import "./DataPage.scss";

class DataCreatePage extends React.Component {
  componentDidMount() {
    this.props.dispatch(
      actions.SetCreateDataForm(parseInt(this.props.match.params.regid, 10))
    );
  }

  createData = (val) => {
    this.props.dispatch(actions.CreateVictimData(val, this.handleCallback));
  };

  handleCancel = () => {
    this.props.dispatch(push(`/register`));
  };

  handleCallback = (Id) => {
    this.props.dispatch(push(`/register/${Id}/edit`));
  };

  render() {
    return (
      <div className="p5-page-container">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            {IsFullVersion() === true && (
              <DataForm
                loading={this.props.loading}
                onSubmit={this.createData}
                onCancel={this.handleCancel}
                {...this.props.victimData}
              />
            )}
            {IsLiteVersion() === true && (
              <LiteDataForm
                loading={this.props.loading}
                onSubmit={this.createData}
                onCancel={this.handleCancel}
                {...this.props.victimData}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

DataCreatePage.propTypes = {
  dispatch: () => {},
  match: PropTypes.shape(),
  victimData: PropTypes.shape({}),
  loading: PropTypes.bool,
};

DataCreatePage.defaultProps = {
  dispatch: () => {},
  match: {},
  victimData: {},
  loading: false,
};

function mapStateToProps(state) {
  const { victimData, loading } = state.RegisterUserFormState;
  return {
    victimData,
    loading
  };
}

const connectedDataCreatePage = connect(mapStateToProps)(DataCreatePage);

export default connectedDataCreatePage;
