import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, DatePicker, Checkbox } from "antd";
import moment from "moment";

class DateSelect extends React.Component {
  constructor(props) {
    super(props);
    const minStartDate = moment().subtract(1, 'months') < moment('1/1/2021') ? moment('1/1/2021') : moment().subtract(1, 'months');
    this.state = {
        startDate: minStartDate,
        endDate: moment(),
        viewAll: false,
    };
  }

  onChange = () => {
    this.props.onChange(this.state.startDate, this.state.endDate, this.state.viewAll);
  };

  render() {
    const dateFormat = ["DD/MM/YYYY", "DD/MM/YY"];
    const minDate = moment('1/1/2021');
    return (
      <>
        <Row gutter={[16, 8]}>
          <Col span={24} align="end">
            <span>
              <Checkbox
                checked={this.state.viewAll}
                value={this.state.viewAll}
                onChange={async (val) => {
                  if(val.target.checked) {
                    await this.setState({ viewAll: val.target.checked, startDate: minDate, endDate: moment() });                      
                  } else {
                    await this.setState({ 
                      viewAll: val.target.checked, 
                      startDate: moment().subtract(1, 'months') < minDate ? minDate : moment().subtract(1, 'months'), 
                      endDate: moment() 
                    });
                  }
                  
                  this.onChange();
                }}
              >
                View Entire History
              </Checkbox>
            </span>
          </Col>
        </Row>
        <Row gutter={[16, 8]}>
          <Col xs={24} md={24} lg={12}>
            <div className="p5-form-label">
              <span>Start Date:</span>              
            </div>
            <DatePicker
              id={`${this.props.Id}-startDate`}
              className="p5-form-datepicker-input"
              value={this.state.startDate}
              format={dateFormat}              
              disabled={this.state.viewAll}
              onChange={async (val) => {
                if(val < minDate)
                  await this.setState({ startDate: minDate });
                else
                  await this.setState({ startDate: val });
                this.onChange();
              }}
            />
          </Col>
          <Col xs={24} md={24} lg={12}>
            <div className="p5-form-label">
              <span>End Date:</span>
            </div>
            <DatePicker
              id={`${this.props.Id}-endDate`}
              className="p5-form-datepicker-input"
              value={this.state.endDate}
              format={dateFormat}
              disabled={this.state.viewAll}
              onChange={async (val) => {
                  await this.setState({ endDate: val });
                this.onChange();
            }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

DateSelect.propTypes = {
  Id: PropTypes.string,
  onChange: PropTypes.func
};

DateSelect.defaultProps = {
  Id: "",
  onChange: () => {}
};

export { DateSelect as default };
