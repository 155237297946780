import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout, PageHeader } from '../../common/components';
import { SearchIndexPage, SearchView } from './pages';

const SearchHeader = (
  <PageHeader title="Search" />
)

const Search = ({ match }) => (
  <Layout PageHeader={SearchHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={SearchIndexPage} />
      <Route exact path={`${match.url}/view/:id`} component={SearchView} /> 
    </Switch>
  </Layout>
);

Search.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Search.defaultProps = {
  match: {
    url: '',
  },
};

export default withRouter(Search);
