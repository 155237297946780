import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import actions from '../../account/actions';
import LoginForm from '../form/LoginForm';
import AccountLayout from '../../account/layout/layout';
import { history } from "../../../store";
import './LoginPage.css';

class LoginPage extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if(prevProps.loggedIn!==this.props.loggedIn) 
      history.push(this.getPath());
  }

  getPath() {
    return this.props.location.state
      ? this.props.location.state.from.pathname
      : "/";
  }

  handleLogin = (data) => {
    const { dispatch } = this.props;
    dispatch(actions.Login(data));
  }

  render() {
    return (
      <AccountLayout
        loading={this.props.loading}
        form={(
          <LoginForm
            name="Login"
            onSubmit={this.handleLogin}
            hasError={this.props.hasError}
            error={this.props.error}
          />
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  const { loading, hasError, loggedIn, error } = state.AccountState;
  return {
    loading,
    hasError,
    loggedIn, 
    error
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);

LoginPage.propTypes = {
  dispatch: () => {},
  hasError: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
  loggedIn: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.shape({
        pathname: PropTypes.string
      })
    })
  })
};

LoginPage.defaultProps = {
  dispatch: () => {},
  hasError: false,
  error: null,
  loading: false,
  loggedIn: false,
  location: {}
};

export { connectedLoginPage as default };
