import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

class FormikComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.initialValues,
        }
    }

    
    

    render(){
        return(
          <Formik
            enableReinitialize
            initialValues={{ ...this.state }}
            validationSchema={this.props.validationSchema}
            onSubmit={(val) => this.props.handleSubmit(val)}
            render={this.props.render}
          />
        );
    }
}

FormikComponent.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    validationSchema: PropTypes.shape({}).isRequired,
    initialValues: PropTypes.shape({}).isRequired,
    render: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
    ]).isRequired,
}

export default FormikComponent;
