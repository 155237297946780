import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Tooltip } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import './QuickFind.scss';

class QuickFind extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      VictimSearch: {
        SearchTerm: null,
      },
    }
  }

  handleChange = (e) => {
    this.setState({
      VictimSearch: {
        SearchTerm: e.target.value
      },
    })
  };

render() {
return (
  <>
    <div className="d-flex">
      <Input
        size="small"
        className='sh-tour-quick-find-text'
        placeholder="Quick Find"
        id={this.props.id}
        value={this.state.searchTerm}
        onChange={(e) => this.handleChange(e)}
        onPressEnter={() => this.props.handleSearch(this.state.VictimSearch)}
      />
      <Tooltip title="Search">
        <Button
          id="saveBtn"
          className="mt-1 ml-3 rounded sh-tour-quick-find-btn"
          onClick={() => this.props.handleSearch(this.state.VictimSearch)}          
        >
          <FontAwesomeIcon size="1x" icon={faSearch} />
        </Button>
      </Tooltip>
    </div>
  </>
  );
}
}

QuickFind.propTypes = {
  id: PropTypes.string,
  handleSearch: PropTypes.func,
};

QuickFind.defaultProps = {
  id: "",
  handleSearch: () => { },
};

export { QuickFind as default };
