import React from "react";
import PropTypes from "prop-types";
import { Modal, Spin, Image, Badge } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FileService } from '../../../services';
import "./ImageUpload.scss";

class ImageDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      src: null,
    }
  }

  componentDidMount() {
    const { Id } = this.props;
    if (Id !== null && Id > 0) {
      FileService.getImageResize(Id).then(res => {
        const base64 = btoa(
          new Uint8Array(res.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        const url = `data:${res.headers["content-type"]};base64,${base64}`;
        this.handleStateChange(url);
      });
    }
  }

  handleStateChange = (url) => {
    this.setState(prevState => ({
      ...prevState,
      loading: false,
      src: url,
    }));
  }

  handleRemove = () => {
    const { Id } = this.props;
    this.props.onRemove(Id);
  };

  confirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to remove image?',
      icon: <ExclamationCircleOutlined />,
      content: <Image alt="Image" className="mx-auto d-block" src={this.state.src} width={100} />,
      onOk: this.handleRemove,
      okText: 'Yes',
      cancelText: 'No',
    });
  };
  
  render() {
    return (
      this.state.loading ? (
        <Spin size="medium" />
      ) : (
        <div>
          <Badge count={<FontAwesomeIcon onClick={() => this.confirm()} color="#ff4d4f" size="1x" icon={faTrash} />}>
            <Image alt="Image" src={this.state.src} width={100} />
          </Badge>
        </div>
      )
    );
  }
}

ImageDisplay.propTypes = {
  Id: PropTypes.number,
  photo: PropTypes.shape({
    src: PropTypes.string,
    selected: PropTypes.bool,
  }),
  onRemove: PropTypes.func,
};

ImageDisplay.defaultProps = {
  Id: 0,
  photo: null,
  onRemove: () => {},
};

export { ImageDisplay as default };
