import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from "antd";
import { Progress } from '@ant-design/charts';

class CustomProgressBar extends React.Component {
  // constructor(props) {
  //   super(props);
  //   const {autoFit, height, width, percent, barWidthRatio, color} = this.props;
  //   this.state = {      
  //     progressConfig: {
  //       autoFit,
  //       height,
  //       width,
  //       percent,
  //       barWidthRatio,
  //       color,
  //       },
  //   }
  // }

  // componentDidMount() {
  //   this.setProgress();
  // }

  // componentDidUpdate(prevProps) {
  //   if (this.props.percent !== prevProps.percent) {
  //     this.setProgress()
  //   }
  // }

  // setProgress = () =>{
  //   // this.setState({
  //   //   progressConfig: {
  //   //     autoFit: true,
  //   //     height: 100,
  //   //     width: 300,
  //   //     percent: 0.25,
  //   //     barWidthRatio: 0.3,
  //   //     color: this.props.color,
  //   //   }
  //   // });

  //   const {autoFit, height, width, percent, barWidthRatio, color} = this.props;
  //   this.setState({
  //     progressConfig: {
  //       autoFit,
  //       height,
  //       width,
  //       percent,
  //       barWidthRatio,
  //       color,
  //     }
  //   });
  // }
    
render() {
  
    return (
      <>
        <Spin spinning={this.props.loading}>
          <Progress {...this.props} />
        </Spin>
      </>
    );
  }
}

CustomProgressBar.propTypes = {
  loading: PropTypes.bool,
  autoFit: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  percent: PropTypes.number,
  barWidthRatio: PropTypes.number,
  color: PropTypes.arrayOf(PropTypes.string),
};

CustomProgressBar.defaultProps = {
  loading: false,
  autoFit: true,
  height: 100,
  width: 300,
  percent: 0,
  barWidthRatio: 0.3,
  color: ['#EF4E4E', '#E8EDF3'],
};

export { CustomProgressBar as default };