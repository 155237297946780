import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { 
  Table,
  Button,
  Space,
  Input, 
} from "antd";
import { faEdit, faEye } from "@fortawesome/pro-regular-svg-icons";
import { SearchOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Highlighter from 'react-highlight-words';
import { history } from "../../../store";
// import "antd/dist/antd.css";
import { hasRights, rights } from '../../../common/utils/role-right-helper';

class ResourceGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      searchedColumn: "",
    };
  }
  
  editResource = (key) => { history.push(`ResourceLibrary/${key}`) }

  viewResource = (key) => { history.push(`ResourceLibrary/view/${key}`) }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (( filtered ) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />),
    onFilter: ((value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''),
    onFilterDropdownVisibleChange: ((visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100)
      }
    }),
    render: ((text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ))
  })

  render() {
    const columns = [
      {
        title: "Resource",
        dataIndex: "Title",    
        key: "Title", 
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.Title - b.Title,
        ...this.getColumnSearchProps('Title'),
        render: ((text, record) =>
        this.props.data.length >= 1 && (
          <>
            <span>
              {record.Title}
              { hasRights([rights.CanManageLibrary]) && (
                <FontAwesomeIcon
                  onClick={() => this.props.disabled ? console.log('unable to view in tour mode') : this.editResource(record.Id)}
                  size="1x"
                  icon={faEdit}
                  pull="right"
                  id="ResourceLib"
                  className="resource-icon"
                />
              )}
              { hasRights([rights.CanViewLibrary]) && (
                <FontAwesomeIcon
                  onClick={() => this.props.disabled ? console.log('unable to view in tour mode') : this.viewResource(record.Id)}
                  size="1x"
                  icon={faEye}
                  pull="right"
                  id="ResourceLib"
                  className="resource-icon"
                />
              )}
            </span>
          </>
        ))
      },
      {
        title: "Description",
        dataIndex: "Description",
        key: "Description",
        ellipsis: true,
        sorter: (a, b) => a.Description - b.Description,
        ...this.getColumnSearchProps('Description')
      }
    ];

    const result = (
      <div className="resource-grid">
        <Table columns={columns} dataSource={this.props.data} scroll={{ x: 700 }} size="small" rowKey="Id" loading={this.props.loading} />
      </div>
    );

    return result;
  };
}

function mapStateToProps() {
  return {};
}

ResourceGrid.propTypes = {
  data: PropTypes.arrayOf((PropTypes.shape({}))),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

ResourceGrid.defaultProps = {
  data: [],
  loading: true,
  disabled: false,
};

const connectedResourceGrid = connect(mapStateToProps)(ResourceGrid);

export { connectedResourceGrid as default };
