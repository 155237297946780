import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { WarningTypeService } from '../../../services';

const { Option } = Select;

class WarningTypeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getWarningTypes();
  }

  getWarningTypes() {
    WarningTypeService.getWarningTypes().then((result) => {
      this.setState({
        data: result.data,
      });
    });
  }

  render() {
    return (
      <Select
        className={this.props.className}
        value={!this.props.disabled ? this.props.value : 'None'}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
        mode="multiple"
      >
        {
          this.state.data.map((opt) => (
            <Option
              key={opt.Id}
              value={opt.Id}
            >
              {opt.Description}
            </Option>
          ))
        }
      </Select>
    );
  }
}

WarningTypeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
};

WarningTypeSelect.defaultProps = {
  value: null,
  disabled: false,
  className: "",
};

export { WarningTypeSelect as default };
