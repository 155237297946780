import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from 'connected-react-router';
import { Table, Row, Col } from "antd";
import SearchAction from "../actions";
import {
  phoneCodeAndNumberFormatter,
} from "../../../common/utils/phone-number-helper";
import QuickFind from "../../../common/components/quick-find";
import { IsFullVersion, IsLiteVersion } from "../../../common/utils/system-version-helper";

class SearchGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      VictimSearch: {
        SearchTerm: null,
      },
    };
  }

  componentDidMount() {
    if (this.props.list.length > 0) {
      this.setState({
        data: this.props.list
      })
    }
  }

  handleCallback = (result) => {
    this.setState({
      data: result.data.Data
    });
  };

  handleSearch = async (e) => {
    await this.setState({
      VictimSearch: {
        SearchTerm: e.SearchTerm
      },
    });
    this.props.dispatch(
      SearchAction.Search(this.state.VictimSearch, this.handleCallback)
    );
  };

  goToView = (id) => {
    this.props.dispatch(push(`/search/view/${id}`));
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ['descend', 'ascend'],
        width: "30%",
        render: (text, record) =>
        this.state.data.length >= 1 ? (
          <span key={record.Id}>
            {record.Name}
          </span>
        ) : null,
      },
      {
        title: "Phone Numbers",
        dataIndex: "CLIPhoneNumber",
        key: "CLIPhoneNumber",
        sorter: (a, b) => (a.CLIPhoneNumber ? a.CLIPhoneNumber.length : 0) - (b.CLIPhoneNumber ? b.CLIPhoneNumber.length : 0),
        sortDirections: ['descend', 'ascend'],
        width: "30%",
        render: (text, record) =>
        this.state.data.length >= 1 && (
        <span key={record.Id}>{phoneCodeAndNumberFormatter(record.CLIPhoneNumber)}{(record.PhoneNumber !== "" && record.PhoneNumber !== record.CLIPhoneNumber) && `, ${record.PhoneNumber}`}</span>
        )
      }
    ];

    const liteColumns = [
      {
        title: "Phone Numbers",
        dataIndex: "CLIPhoneNumber",
        key: "CLIPhoneNumber",
        sorter: (a, b) => (a.CLIPhoneNumber ? a.CLIPhoneNumber.length : 0) - (b.CLIPhoneNumber ? b.CLIPhoneNumber.length : 0),
        sortDirections: ['descend', 'ascend'],
        width: "30%",
        render: (text, record) =>
        this.state.data.length >= 1 && (
        <span key={record.Id}>{phoneCodeAndNumberFormatter(record.CLIPhoneNumber)}</span>
        )
      }
    ];

    const result = (
      <>
        <Row justify="left">
          <Col xs={24} md={20} lg={18}>
            <span>
              <div className="search-header mt-4">
                <span>
                  <b>Search for individual by Name or Phone number</b>
                </span>
                <>
                  <div className="mt-2">
                    <QuickFind id="search-grid-bar" handleSearch={(e) => this.handleSearch(e)} />
                  </div>
                </>
              </div>
            </span>
          </Col>
        </Row>
        <div className="mt-3">
          {IsFullVersion() && (
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: 700 }}
              size="small"
              loading={this.props.loading}
              rowKey="Id"
              onRow={(record) => {
                return {
                  onClick: () => {this.goToView(record.Id)},
                };
              }}
            />
          )}

          {IsLiteVersion() && (
            <Table
              columns={liteColumns}
              dataSource={this.state.data}
              scroll={{ x: 700 }}
              size="small"
              loading={this.props.loading}
              rowKey="Id"
              onRow={(record) => {
                return {
                  onClick: () => {this.goToView(record.Id)},
                };
              }}
            />
          )}
          
        </div>
      </>
    );
    return result;
  }
}

function mapStateToProps(state) {
  const { loading, list } = state.Search;
  return {
    list,
    loading,
  };
}

SearchGrid.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({})),
};

SearchGrid.defaultProps = {
  dispatch: () => {},
  list: [],
  loading: false,
};

const connectedSearchGrid = connect(mapStateToProps)(SearchGrid);

export { connectedSearchGrid as default };
