import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  Row,
  Col,
  Modal,
  Spin,
} from 'antd';
import UserForm from '../form';
import actions from '../actions';
import './UserPage.css';

class CreatePage extends React.Component {

  createUser = (val) => {
    if(val.ReceivedTraining !== true){
      this.showTrainingWarning();
    }
    else 
      this.props.dispatch(actions.CreateUser(val, this.handleCallback));
  }

  handleCancel = () => {
    this.props.dispatch(push("/user"));
  };

  handleCallback = () => {
    this.props.dispatch(push("/user"));
  };

  // checkUsername = (value) => {
  //   console.log('check', value.target.value);
  //   this.props.dispatch(actions.ValidateUsername(value));
  // }

  showTrainingWarning = () => {
    Modal.warning({
      title: 'Training Required',
      content: 'Please check the "Training Recieved" box to confirm that the user has been trained directly by TecSAFE in the use of the TecSAFE products.',
    })
  }

    render(){
        return(
          <div className="p5-page-container">
            <Row justify="center">
              <Col xs={20} md={20} lg={18}>
                <Spin spinning={this.props.loading}>
                  <UserForm
                    loading={this.props.loading}
                    onSubmit={this.createUser}
                    onCancel={this.handleCancel}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        );
    }
}

function mapStateToProps(state) {
  const { currentUser, loading } = state.Users;
  return {
    currentUser,
    loading,
  };
}

const connectedCreatePage = connect(mapStateToProps)(CreatePage);

CreatePage.propTypes = {
  dispatch: () => {},
  loading: PropTypes.bool,
};

CreatePage.defaultProps = {
  dispatch: () => {},
  loading: false,
};

export default connectedCreatePage;
