import React from 'react';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import ChangePasswordForm from '../form';
import actions from '../actions';
import './ChangePasswordPage.css';
import navigationActions from "../../../common/components/navigation/action";

class ChangePasswordPage extends React.Component {

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("profile"));
  }

  validateForm = (val) => {
    if (val.CurrentPassword !== val.NewPassword && val.NewPassword === val.ConfirmPassword) {
      this.props.dispatch(actions.ChangePassword({
        'UserName': val.UserName,
        'OldPassword': val.CurrentPassword,
        'NewPassword': val.NewPassword
      }, this.handleCallback));
    }
  }

  handleCancel = () => {
    this.props.dispatch(push(`/`));
  };

  handleCallback = () => {
    this.props.dispatch(push(`/`));
  }

  render() {

    return (
      <div className="ChangePasswordContainer">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <ChangePasswordForm
              loading={this.props.loading}
              onSubmit={this.validateForm}
              onCancel={this.handleCancel}
              {...this.props.currentUser}
              UserName={this.props.currentUser.UserName}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { currentUser, loading } = state.AccountState;
  return {
    currentUser,
    loading,
  };
}

const connectedChangePasswordPage = connect(mapStateToProps)(ChangePasswordPage);

ChangePasswordPage.propTypes = {
  dispatch: () => { },
  match: PropTypes.shape(),
  currentUser: PropTypes.shape({
    UserName: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

ChangePasswordPage.defaultProps = {
  dispatch: () => { },
  match: {},
  currentUser: {
    UserName: '',
  },
  loading: false,
};

export default connectedChangePasswordPage;
