import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  Row,
  Col,
  Tooltip,
  Tabs
} from "antd";
import navigationActions from "../../../common/components/navigation/action";
import { history } from "../../../store";
import ResourceGrid from "../grid";
import { ResourceLibService } from "../../../services";
import {TourGuideList} from '../../guide-tour/components';
import "./ResourceLib.css";
import { hasRights, rights } from "../../../common/utils/role-right-helper";

const { TabPane } = Tabs;

class ResourceIndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeTab: "library",
      header: 'Resource Library',
      loading: true
    };
  }

  componentDidMount() {
    if(!hasRights([rights.CanViewLibrary]))
      this.props.dispatch(push("/"));

    this.props.dispatch(navigationActions.changePage("resourceLib"));
    this.UpdateTabPage(this.state.activeTab);
  }

  getResourceData(queryString){
    ResourceLibService.getResourceList(queryString)
      .then((result) => {
        this.setState({
          data: result.data.value,
          loading: false
        });
    });
  }

  handleAddClick = () => { history.push('/ResourceLibrary/create'); };

  UpdateTabPage = (tab) => {
    this.setState({ activeTab: tab });
    if (tab === "library") {
      this.setState({
        header: 'Resource Library',
        loading: true
      });
      this.getResourceData("$filter=IsGoodNews eq false");
    } else {
      this.setState({
        header: 'Communications',
        loading: true
      });
      this.getResourceData("$filter=IsGoodNews eq true");
    }
  };

  render() {
    return (
      <div className="p5-page-container">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <span>
              <h1 className="p5-page-h1-header">
                {this.state.header}
                { hasRights([rights.CanManageLibrary]) && (
                  <Tooltip title="New Resource">
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      id="resourceLibraryAdd"
                      className="resource-add-icon"
                      alt="resourceLibraryAddIcon"
                      onClick={() => this.handleAddClick()}
                      pull="right"
                      size="1x" 
                    />
                  </Tooltip>
                )}
              </h1>
            </span>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <Tabs
              className="p5-tabs"
              size="large"
              activeKey={this.state.activeTab}
              onTabClick={(e) => this.UpdateTabPage(e)}
            >
              <TabPane
                className="p5-tabs-pane"
                tab="Resource Library"
                key="library"
              >
                <ResourceGrid
                  data={this.state.data}
                  loading={this.state.loading}
                />
              </TabPane>
              <TabPane
                className="p5-tabs-pane"
                tab="Communications"
                key="news"
              >
                <ResourceGrid
                  data={this.state.data}
                  loading={this.state.loading}
                />
              </TabPane>
              <TabPane
                className="p5-tabs-pane"
                tab="Tour Guides"
                key="guides"
              >
                <TourGuideList />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}

ResourceIndexPage.propTypes = {
  dispatch: PropTypes.func,
};

ResourceIndexPage.defaultProps = {
  dispatch: () => {},
};

function mapStateToProps() {
  return {};
}

const connectedResourceIndexPage = connect(mapStateToProps)(ResourceIndexPage);

export { connectedResourceIndexPage as default };
