import React from "react";
import PropTypes from "prop-types";
import { isEqual } from 'lodash';
import { Select } from "antd";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;

class DynamicSelect extends React.Component {
  // eslint-disable-next-line no-underscore-dangle
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = true;
    this.loadData();
    // console.log(`val:${  this.props.value}`);
  }

   // eslint-disable-next-line consistent-return
   async shouldComponentUpdate(nextProps) {  
    if (isEqual(nextProps.filters,this.props.filters) === false) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps.filters, this.props.filters) === false) {
      this.props.getData(this.props.filters).then((result) => {
        const list1 = [{ Id: 0, Name: this.props.allOption ? "All" :"None" }, ...result.data.value];
        const list2 = result.data.value;

        this.setState((prevState) => ({
          ...prevState,
          data: this.props.hasNone || this.props.allOption ? list1 : list2,
        }));
      });
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = false;
  }

  loadData() {
    this.props.getData(this.props.filters).then((result) => {
      const list1 = [{ Id: 0, Name: this.props.allOption ? "All" : "None" }, ...result.data.value];
      const list2 = result.data.value;

      this.setState((prevState) => ({
        ...prevState,
        data: this.props.hasNone || this.props.allOption ? list1 : list2,
      }));
    });
  }

  render() {
    return (
      <Select
        id={`${this.props.id}`}
        className={`${this.props.className}`}
        key={uuidv4()}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        size="middle"
        style={{ borderRadius: 5 }}
        value={this.props.value}
        disabled={this.props.isDisabled}
      >
        {Array.from(this.state.data).map((opt) => (
          <Option style={{ borderRadius: 5 }} key={uuidv4()} value={opt.Id} disabled={opt.Disabled}>
            {opt.Name}
          </Option>
        ))}
      </Select>
    );
  }
}

DynamicSelect.propTypes = {
  id: PropTypes.string,
  getData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  // displayFieldName: PropTypes.string.isRequired,
  // valueFieldName: PropTypes.string.isRequired,
  // defaultValue: PropTypes.string,
  value: PropTypes.number,
  // eslint-disable-next-line
  data: PropTypes.array,
  hasNone: PropTypes.bool,
  className: PropTypes.string,
  filters: PropTypes.shape({}),
  isDisabled: PropTypes.bool,
  allOption: PropTypes.bool,
  placeholder: PropTypes.string,
};

DynamicSelect.defaultProps = {
  id: "",
  // value: 1,
  value: null,
  hasNone: true,
  allOption: false,
  className: "",
  filters: {},
  isDisabled: false,
  placeholder: 'Please select a value',
};

export { DynamicSelect as default };
