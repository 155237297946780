import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Spin,
} from 'antd';
import { history } from "../../../store";
import navigationActions from "../../../common/components/navigation/action";
import actions from '../actions';
import { ResourceView } from '../form';
import './ResourceLib.css';

class ResourceReadPage extends React.Component {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("ResourceLibrary"));
    this.props.dispatch(actions.GetResource(this.props.match.params.id));
  }

  handleCancel = () => { history.push('/ResourceLibrary') };

    render(){
        return(
          <div className="ResourceContainer">
            <Row justify="center">
              <Col xs={20} md={20} lg={18}>
                <Spin spinning={this.props.loading}>
                  <ResourceView                                    
                    onCancel={this.handleCancel}
                    {...this.props.currentResource}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        );
    }
}

function mapStateToProps(state) {
  const { currentResource, loading } = state.ResourceLibrary;
  return {
    currentResource,
    loading,
  };
}

const connectedResourceReadPage = connect(mapStateToProps)(ResourceReadPage);

ResourceReadPage.propTypes = {
  dispatch: () => {},
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    })
  }),
  currentResource: PropTypes.shape({}),
  loading: PropTypes.bool,
};

ResourceReadPage.defaultProps = {
  dispatch: () => {},
  match: {},
  currentResource: {},
  loading: false,
};

export default connectedResourceReadPage;
