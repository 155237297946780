import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Input, Tooltip } from "antd";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DynamicSelect } from "../../../common/components/dynamic-select";
import { agencyService } from "../../../services";
import { SaveBtn, CancelBtn } from "../../../common/components/form";

import "./AreaForm.scss";

class AreaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Id: 0,
      Name: "",
      Force: { Id: 1, Name: "" },
    };
  }

  pivotData = (data) => ({
    Id: this.props.Id,
    Name: data.Name,
    LawEnforcementAgencyId: data.Force.Id,
  });

  render() {
    const ValidationSchema = Yup.object().shape({
      Id: Yup.number(),
      Name: Yup.string()
        .typeError("Name is required")
        .required("Name is required"),
    });

    return (
      <Formik
        enableReinitialize
        initialValues={{
          Id: this.props.Id || this.state.Id,
          Name: this.props.Name || this.state.Name,
          Force: this.props.Force || this.state.Force,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({ values, errors, touched, setFieldValue, handleSubmit }) => (
          <Form
            autoComplete={0}
            name="AreaForm"
            id="AreaForm"
            onChange={this.handleChange}
            onSubmit={handleSubmit}
          >
            <div className="p5-form-container">
              <Row justify="center">
                {this.props.isUpdateMode ? (
                  <Col span={12}>
                    <h1 className="p5-form-h1-header">{this.props.title}</h1>
                  </Col>
                ) : (
                  <Col span={22}>
                    <h1 className="p5-form-h1-header">{this.props.title}</h1>
                  </Col>
                )}
                {this.props.isUpdateMode && (
                  <Col span={10}>
                    <Tooltip title="Delete Area">
                      <FontAwesomeIcon
                        onClick={this.props.onDelete}
                        className="area-delete-btn"
                        size="2x"
                        icon={faTrash}
                        pull="right"
                      />
                    </Tooltip>
                  </Col>
                )}
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
                <Col className="gutter-row" xs={24} sm={24} md={22}>
                  <Form.Item
                    className="forcePickerBox"
                    validateStatus={
                      errors && errors.Force && touched.Force ? "error" : null
                    }
                    help={errors && touched.Force && errors.Force}
                  >
                    <div className="p5-form-label">
                      <span>Force</span>
                    </div>
                    <DynamicSelect
                      hasNone={false}
                      className="p5-form-dropdown"
                      key="ForcePicker"
                      getData={agencyService.getForceList}
                      displayFieldName="Name"
                      valueFieldName="Id"
                      value={values.Force.Id}
                      onChange={(val) => {
                        setFieldValue("Force.Id", val);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
                <Col className="gutter-row" xs={24} sm={24} md={22}>
                  <Form.Item
                    validateStatus={
                      errors && errors.Name && touched.Name ? "error" : null
                    }
                    help={errors && touched.Name && errors.Name}
                  >
                    <div className="p5-form-label">
                      <span>Area Name</span>
                    </div>
                    <Input
                      type="text"
                      id="Name"
                      className="p5-form-input"
                      value={values.Name}
                      onChange={(val) => {
                        setFieldValue("Name", val.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <br />
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="end">
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  className="gutter-row"
                >
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={6}
                  className="gutter-row"
                >
                  <SaveBtn
                    onClick={handleSubmit}
                    loading={this.props.loading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

AreaForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  Id: PropTypes.number,
  Name: PropTypes.string,
  Force: PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string,
  }),
  isUpdateMode: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
};

AreaForm.defaultProps = {
  // onSubmit: () => {},
  onCancel: () => {},
  onDelete: () => {},
  Id: 0,
  Name: "",
  Force: {
    Id: 1,
    Name: "",
  },
  isUpdateMode: false,
  title: "",
  loading: false,
};

export default AreaForm;
