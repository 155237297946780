import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Divider, Row } from 'antd';
import navigationActions from "../../../common/components/navigation/action";
import { Layout, PageHeader } from '../../../common/components';
import './HelpPage.css';

class HelpPage extends React.PureComponent {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("help"));
  }

    render(){ 
        const HelpHeader =  (
          <PageHeader title="Help/Submit Good News or Suggestions" />
        );

        const newLineChar = '%0D%0A';

        return(
          <Layout PageHeader={HelpHeader}>
            <div className="help-page-container">              
              <Row justify="center">
                <Col xs={20} md={20} lg={18}>
                  <Divider orientation="left" style={{fontSize: "18px", fontWeight: "500"}} plain>Operational Support</Divider>
                  <section className="help-text">
                    <span>To speak with the duty TecSAFE manager about an urgent operational issue 24/7, call 020 3998 7389.</span>
                    <br />
                    <span>
                      For non urgent issues email 
                      <a 
                        href={`mailto:tecsafesupport@pds.police.uk?subject=TecSAFE&body=Report an issue${newLineChar}Force:${newLineChar}Area:${newLineChar}Name:${newLineChar}Contact Email:${newLineChar}Contact Phone Number:${newLineChar}App Activation Key or Handset Phone Number:${newLineChar}Description of issue:${newLineChar}`}
                        target="_blank" 
                        rel="noreferrer"
                      >
                      &nbsp;tecsafesupport@pds.police.uk&nbsp;
                      </a>
                      and we will aim to respond within 24 hours
                    </span>                    
                  </section>
                </Col>                
              </Row>              
              <Row justify="center">
                <Col xs={20} md={20} lg={18}>
                  <Divider orientation="left" style={{fontSize: "18px", fontWeight: "500"}} plain>Contracts/Billing Enquiries</Divider>
                  <section className="help-text">
                    <div>
                      <span>
                        Call 020 3998 7389 for non urgent issues to do with contracts or billing enquiries during office hours.
                      </span>
                    </div>
                  </section>
                </Col>
              </Row>              
              <Row justify="center">
                <Col xs={20} md={20} lg={18}>
                  <Divider orientation="left" style={{fontSize: "18px", fontWeight: "500"}} plain>Good News Stories</Divider>
                  <section className="help-text">                    
                    <div>
                      <span>
                        Submit your good news story about TecSAFE via the link 
                        <a 
                          href="mailto:tecsafesupport@pds.police.uk?subject=Good News Story TecSAFE&body=Good News Story Details -"
                          target="_blank" 
                          rel="noreferrer"
                        >
                        &nbsp;tecsafesupport@pds.police.uk&nbsp;
                        </a>
                      </span>
                    </div>
                  </section>
                </Col>                
              </Row>
              <Row justify="center">
                <Col xs={20} md={20} lg={18}>
                  <Divider orientation="left" style={{fontSize: "18px", fontWeight: "500"}} plain>Suggestions</Divider>
                  <section className="help-text">                    
                    <div>
                      <span>
                        Submit your suggestions in relation to TecSAFE via the link 
                        <a 
                          href="mailto:tecsafesupport@pds.police.uk?subject=TecSAFE Suggestions&body=Suggestion Details -"
                          target="_blank" 
                          rel="noreferrer"
                        >
                        &nbsp;tecsafesupport@pds.police.uk&nbsp;
                        </a>
                      </span>
                    </div>
                  </section>
                </Col>                
              </Row>
            </div>
          </Layout>
        );
    }
}

HelpPage.propTypes = {
  dispatch: PropTypes.func,
};

HelpPage.defaultProps = {
  dispatch: () => {},
};

function mapStateToProps() {
  return {};
}

const connectedHelpPage = connect(mapStateToProps)(HelpPage);

export { connectedHelpPage as default };


// export default HelpPage;
