import { toast } from "react-toastify";
import c from "./constants";
import { UserService } from "../../services";

function GetGridList() {
  function request() {
    return { type: c.GET_USER_LIST_REQUEST };
  }
  function success(list) {
    return { type: c.GET_USER_LIST_SUCCESS, list };
  }
  function failure(error) {
    return { type: c.GET_USER_LIST_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    UserService.getGridList().then(
      (List) => {
        dispatch(success(List.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}
function CreateUser(data, callback) {
  function request() {
    return { type: c.CREATE_USER_REQUEST };
  }
  function success(user) {
    return { type: c.CREATE_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: c.CREATE_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.addUser(data).then(
      (user) => {
        dispatch(success(user));
        toast('Successfully created User');
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(`An Error occurred: ${error.response.data.Error}`);
      }
    );
  };
}

function GetUser(data) {
  function request() {
    return { type: c.GET_FORCE_REQUEST };
  }
  function success(user) {
    return { type: c.GET_FORCE_SUCCESS, user };
  }
  function failure(error) {
    return { type: c.GET_FORCE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.getUser(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function GetUserById(data) {
  function request() {
    return { type: c.GET_USER_ID_REQUEST };
  }
  function success(user) {
    return { type: c.GET_USER_ID_SUCCESS, user };
  }
  function failure(error) {
    return { type: c.GET_USER_ID_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    
    UserService.getUserById(data).then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function UpdateUser(id, data, callback) {
  function request() {
    return { type: c.UPDATE_USER_REQUEST };
  }
  function success() {
    return { type: c.UPDATE_USER_SUCCESS };
  }
  function failure(error) {
    return { type: c.UPDATE_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.updateUser(id, data).then(
      () => {
        dispatch(success());
        toast('Successfully updated User');
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(`An Error occurred: ${error.toString()}`);
      }
    );
  };
}

function DeleteUser(Id, callback) {
  function request() {
    return { type: c.DELETE_USER_REQUEST };
  }
  function success() {
    return { type: c.DELETE_USER_SUCCESS };
  }
  function failure(error) {
    return { type: c.DELETE_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.deleteUser(Id).then(
      () => {
        dispatch(success());
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function ValidateUsername(username) {
  function request() {
    return { type: c.VALIDATE_USER_REQUEST };
  }
  function success(valid) {
    return { type: c.VALIDATE_USER_SUCCESS, valid };
  }
  function failure(error) {
    return { type: c.VALIDATE_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.validateUser(username).then(
      (valid) => {
        dispatch(success(valid.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

export default {
  GetGridList,
  CreateUser,
  GetUser,
  GetUserById,
  UpdateUser,
  DeleteUser,
  ValidateUsername,
};
