import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { HelperService } from '../../../services';

const { Option } = Select;

class DisabilitySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getDisabilities();
  }

  getDisabilities() {
    HelperService.getDisabilityList().then((result) => {
      this.setState({
        data: result.data,
      });
    });
  }

  render() {
    return (
      <Select
        id={this.props.id}
        name={this.props.id}
        className={this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
      >
        {
          this.state.data.map((opt) => (
            <Option
              key={opt.Id}
              value={opt.Id}
              title={opt.Name}
              disabled={Object.keys(this.props.selectedValues.filter((d) => d.DisabilityId === opt.Id && opt.IncludeFreeText === false)).length > 0}
            >
              {opt.Name.trim()}
            </Option>
          ))
        }
      </Select>
    );
  }
}

DisabilitySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  selectedValues: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string,
};

DisabilitySelect.defaultProps = {

  value: null,
  className: "",
  disabled: false,
  selectedValues: [],
  id: "",
};

export { DisabilitySelect as default };
