import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { SystemVersionService } from '../../../services';

const { Option } = Select;

class SystemVersionSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getSystemVersions();
  }

  getSystemVersions() {
    SystemVersionService.getSystemVersions().then((result) => {
      this.setState({
        data: result.data.value,
      });
    });
  }

  render() {
    return (
      <Select
        className={this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        {
          this.state.data.map((opt) => (
            <Option
              key={opt.Id}
              value={opt.Id}
            >
              {opt.Name}
            </Option>
          ))
        }
      </Select>
    );
  }
}

SystemVersionSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
};

SystemVersionSelect.defaultProps = {
  value: null,
  className: "",
};

export { SystemVersionSelect as default };
