/* eslint import/prefer-default-export: 0 */
import api from '../common';

function getRiskLevels() {
  return api.get('api/RiskLevel/GetRiskLevels', { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export {
  getRiskLevels,
};
