import api from '../common';

function getGridList() {
  return api.get('/odata/LawEnforcementAgencyOData?$select=Id,Name&',{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function createForce(data) {
  return api.post('/api/LawEnforcementAgency/Create', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getForce(data) {
  return api.get('/api/LawEnforcementAgency', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getForceById(id) {
  return api.get(`/api/LawEnforcementAgency/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateForce(data) {
  return api.put('/api/LawEnforcementAgency/Update', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
}

function deleteForce(id) {
  return api.delete(`/api/LawEnforcementAgency/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getForceList(query = '') {
  return api.get(`/odata/LawEnforcementAgencyOData?$select=Id,Name&$orderby=Name&${query}`,{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getForceGrid() {
  return api.get('/api/LawEnforcementAgency/forcegrid',{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
  .then((response) => response)
  .catch((error) => Promise.reject(error));
}

export default ({
  getGridList,
  createForce,
  getForce,
  getForceById,
  updateForce,
  deleteForce,
  getForceList,
  getForceGrid,
});
