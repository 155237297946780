/* eslint import/prefer-default-export: 0 */
import api from '../common';

function getSystemInfo(){
  return api.get(`api/SystemInfo`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response)
    .catch(error => Promise.reject(error));
}

function updateSystemInfo(data){
  return api.put(`api/SystemInfo`, data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(response => response)
    .catch(error => Promise.reject(error));
}

export {
  getSystemInfo,
  updateSystemInfo
}