import axios from 'axios';
import https from 'https';
import { history } from '../../store';

const api = axios.create({
  baseURL: process.env.API_URL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

api.interceptors.request.use((config) => {
  /* eslint-disable no-param-reassign */
  config.headers = {
    Accept: 'application/json',
    withCredentials: true,
    'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
  };

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {    
    //  Temporary resolution for now    
    if(history.location.pathname === '/login' || history.location.pathname === '/account/forgot-password' || history.location.pathname === '/account/reset-password' || history.location.pathname === '/account/confirmemail' || history.location.pathname === '/install' || history.location.pathname === '/account/resendMFA') {
      return Promise.reject(error);
    }
    
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      history.push('/login');
    }
    return Promise.reject(error);
  },
);

export default api;
