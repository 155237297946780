import imageUploadConstants from './constants';

const initialState = ({ loading: false, uploading: false, hasError: false, url: '' });

export default function imageUpload(state = initialState, action) {
  switch (action.type) {
    case imageUploadConstants.GET_REQUEST:
      return { ...state, loading: true,
        hasError: false,};
    case imageUploadConstants.GET_SUCCESS:
      return { ...state, loading: false,
        hasError: false,
        url: action.url,};
    case imageUploadConstants.GET_FAILURE:
      return { ...state, loading: false,
        hasError: true,};
    case imageUploadConstants.CREATE_REQUEST:
      return { ...state, uploading: true,
        hasError: false,};
    case imageUploadConstants.CREATE_SUCCESS:
      return { ...state, uploading: false,
        hasError: false,};
    case imageUploadConstants.CREATE_FAILURE:
      return { ...state, uploading: false,
        hasError: true,};
    default:
      return state;
  }
}