import React from "react";
import PropTypes from "prop-types";
import ReactPhotoGallery from 'react-photo-gallery';
import ImageDisplay from './ImageDisplay';

class ImageGallery extends React.Component {

// eslint-disable-next-line
  renderItem(props) {
    return (<ImageDisplay onRemove={this.props.onRemove} Id={parseInt(props.photo.src, 10)} key={`upload-item-${props.photo.src}`} {...props} />);
  }

  render() {
    return (
      <ReactPhotoGallery margin={10} direction="row" renderImage={(props) => this.renderItem(props)} photos={this.props.Items} onClick={this.props.onSelect} />
    );
  }
}

ImageGallery.propTypes = {
  Items: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
};

ImageGallery.defaultProps = {
  Items: [],
  onSelect: () => {},
  onRemove: () => {},
};

export { ImageGallery as default };
