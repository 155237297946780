import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { Row, Col} from "antd";
import { ShepherdTour, TourMethods } from 'react-shepherd'
import { HubAllocSteps } from '../tour-steps';
import navigationActions from "../../../common/components/navigation/action";
import TourRegAction from "../actions";
import TourGuideStartModal from "../components/tour-guide-start-modal";
import "../shepardtour.css";
import HubGrid from "../../hub/hub-grid";
import "../../hub/page/HubPage.scss";

const { tourOptions, HubAllocationSteps } = HubAllocSteps;

class HubPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          TourStartModalshow: false,
        };
      }

      
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("hub"));
    this.setState({ TourStartModalshow: true });
    this.props.dispatch(TourRegAction.GetCurrentTourGuide(
      3,
      this.props.userId
    ));
  }

  hideTourGuideLandingModal = () => {
    this.setState({ TourStartModalshow: false });
  };

  pivotCompleteData = () => ({
    TourId: this.props.CurrentTourGuide.TourGuideId,
    UserId: this.props.userId
  });

  CompleteTourGuide = () => {
    this.props.dispatch(TourRegAction.MarkTourGuideAsCompleted(
      this.pivotCompleteData(),
      this.props.dispatch(TourRegAction.CheckUserActiveTourGuides(this.props.userId))
    ));
  }

  handleAddClick = () => {
    this.props.dispatch(push("/hub/create"))
  };

  render() {
    return (
      <div className="p5-page-container">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <div>
              <h1 className="p5-page-h1-header">Allocation</h1>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <ShepherdTour steps={HubAllocationSteps} tourOptions={tourOptions}>
              <TourMethods>
                {(tourContext) => (
                  <TourGuideStartModal
                    startTour={tourContext}
                    hideModal={this.hideTourGuideLandingModal}
                    showModal={this.state.TourStartModalshow}
                    completeGuide={this.CompleteTourGuide}
                    tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                    userId={this.props.userId}
                    tourName="Hub"
                    title="Welcome to the Hub Tour Guide"
                    description="In this tour guide, you will learn about the Hub section where your organisations app and handset allocations can be viewed. We will explain each column of the grid and some important items to note while using the TecSAFE portal."
                  />
                )}
              </TourMethods>
              <HubGrid />
            </ShepherdTour>
          </Col>
        </Row>
      </div>
    );
  }
}

HubPage.propTypes = {
  dispatch: PropTypes.func,
  userId: PropTypes.number,
  CurrentTourGuide: PropTypes.shape({
    TourGuideId: PropTypes.number
  }),
};

HubPage.defaultProps = {
  dispatch: () => {},
  userId: 0,
  CurrentTourGuide: {
    TourGuideId: 3
  },
};

function mapStateToProps(state) {
    const { Id } = state.AccountState.currentUser;
    const { CurrentTourGuide } = state.TourGuide;
  return {
    userId: Id,
    CurrentTourGuide,
  };
}

const connectedHubPage = connect(mapStateToProps)(HubPage);

export { connectedHubPage as default };
