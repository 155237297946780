export default ({
    GET_RESOURCE_LIST_REQUEST: "GET_RESOURCE_LIST_REQUEST",
    GET_RESOURCE_LIST_SUCCESS: "GET_RESOURCE_LIST_SUCCESS",
    GET_RESOURCE_LIST_FAILURE: "GET_RESOURCE_LIST_FAILURE",
    GET_RESOURCE_REQUEST: "GET_RESOURCE_REQUEST",
    GET_RESOURCE_SUCCESS: "GET_RESOURCE_SUCCESS",
    GET_RESOURCE_FAILURE: "GET_RESOURCE_FAILURE",
    CREATE_RESOURCE_REQUEST: "CREATE_RESOURCE_REQUEST",
    CREATE_RESOURCE_SUCCESS: "CREATE_RESOURCE_SUCCESS",
    CREATE_RESOURCE_FAILURE: "CREATE_RESOURCE_FAILURE",
    DELETE_RESOURCE_REQUEST: "DELETE_RESOURCE_REQUEST",
    DELETE_RESOURCE_SUCCESS: "DELETE_RESOURCE_SUCCESS",
    DELETE_RESOURCE_FAILURE: "DELETE_RESOURCE_FAILURE",
    UPDATE_RESOURCE_REQUEST: "UPDATE_RESOURCE_REQUEST",
    UPDATE_RESOURCE_SUCCESS: "UPDATE_RESOURCE_SUCCESS",
    UPDATE_RESOURCE_FAILURE: "UPDATE_RESOURCE_FAILURE",
    GET_FILE_REQUEST: "GET_FILE_REQUEST",
    GET_FILE_SUCCESS: "GET_FILE_SUCCESS",
    GET_FILE_FAILURE: "GET_FILE_FAILURE",
})
