import api from '../common';

function getGridList() {
  return api.get('/odata/LawEnforcementAgencyTeamOData?$select=Id,Name,LawEnforcementAgency,LawEnforcementAgencySubGroup&',{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function createTeam(data) {
  return api.post('/api/LawEnforcementAgencyTeam', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getTeamById(id) {
  return api.get(`/api/LawEnforcementAgencyTeam/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateTeam(data) {
  return api.put('/api/LawEnforcementAgencyTeam', data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteTeam(id) {
  return api.delete(`/api/LawEnforcementAgencyTeam/${id}`, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getTeamList(filter) {
  return api.get(`/odata/LawEnforcementAgencyTeamOData?$filter=LawEnforcementAgencyId eq ${filter.force} and LawEnforcementAgencyAreaId eq ${filter.area}&$select=Id,Name&`,{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

export default ({
    getGridList,
    createTeam,
    getTeamById,
    updateTeam,
    deleteTeam,
    getTeamList,
  });
  