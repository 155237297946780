/* eslint import/prefer-default-export: 0 */
import api from '../common';

function getSystemVersions() {
  return api.get('/odata/SystemVersionOData?$select=Id,Name&',{ withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

export {
    getSystemVersions,
};
