import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { push } from "connected-react-router";

import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Button, Spin } from "antd";
import { ShepherdTour, TourMethods } from 'react-shepherd'
import { UserManagementTourSteps } from '../tour-steps';
import navigationActions from "../../../common/components/navigation/action";
import TourRegAction from "../actions";
import TourGuideStartModal from "../components/tour-guide-start-modal";
import UserGrid from "../../user/grid";
import UserForm from '../../user/form';
import "../../user/page/UserPage.css";

const { tourOptions, UserManageTourSteps } = UserManagementTourSteps;

class UserManagementPageTour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TourStartModalshow: false,
      ShowGridSection: true,
      ShowVictimFormSection: false,
      ShowDeleteBtn: false,
      UserData: {
        Id: 0,
        UserName: "John.Doe",
        // Password: PropTypes.string,
        EmailAddress: "Test@email.com",
        FirstName: "John",
        LastName: "Doe",
        Role: {
          Id: 4,
          // Name: PropTypes.string,
        },
        LawEnforcementAgencyId: this.props.ForceId,
        LawEnforcementAgencySubGroupId: 0,
        LawEnforcementAgencyTeamId: 0,
        ReceivedTraining: true
      }
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("user"));
    this.setState({ TourStartModalshow: true });
    this.props.dispatch(TourRegAction.GetCurrentTourGuide(
      7,
      this.props.userId
    ));
  }

  hideTourGuideLandingModal = () => {
    this.setState({ TourStartModalshow: false });
  };

  pivotCompleteData = () => ({
    TourId: this.props.CurrentTourGuide.TourGuideId,
    UserId: this.props.userId
  });

  CompleteTourGuide = () => {
    this.props.dispatch(TourRegAction.MarkTourGuideAsCompleted(
      this.pivotCompleteData(),
      this.props.dispatch(TourRegAction.CheckUserActiveTourGuides(this.props.userId))
    ));
  }

  updateUser = (val) => {
    console.log('Tour Guide User, cannot create user: ', val);
  }

  deleteUser = () => {
    console.log('Tour Guide User, cannot delete user');
  };

  handleCancel = () => {
    console.log('Tour Guide User, cannot cancel: ');
  };

  render() {
    return (
      <Spin spinning={this.props.loading}>
        <ShepherdTour steps={UserManageTourSteps} tourOptions={tourOptions}>
          <TourMethods>
            {(tourContext) => (
              <TourGuideStartModal
                startTour={tourContext}
                hideModal={this.hideTourGuideLandingModal}
                showModal={this.state.TourStartModalshow}
                completeGuide={this.CompleteTourGuide}
                tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                userId={this.props.userId}
                tourName="User Management"
                title="Welcome to the User Management Tour Guide"
                description="In this tour guide, you will learn how to add more users to your organisations in the TecSAFE portal. We will explain each column of the user form and some important items to note while using the TecSAFE portal."
              />
            )}
          </TourMethods>
          <Button
            id="sh-show-user-grid-btn"
            style={{ display: "None" }}
            onClick={() =>
              this.setState({
                ShowGridSection: true,
                ShowVictimFormSection: false,
                ShowDeleteBtn: false,
              })}
          />
          <Button
            id="sh-show-user-form-btn"
            style={{ display: "None" }}
            onClick={() =>
              this.setState({
                ShowGridSection: false,
                ShowVictimFormSection: true,
                ShowDeleteBtn: false,
              })}
          />
          <Button
            id="sh-show-user-delete-btn"
            style={{ display: "None" }}
            onClick={() => this.setState({ ShowDeleteBtn: true })}
          />
          <Button
            id="sh-hide-user-delete-btn"
            style={{ display: "None" }}
            onClick={() => this.setState({ ShowDeleteBtn: false })}
          />
          {this.state.ShowGridSection && (
            <div className="p5-grid-page-container">
              <Row justify="center">
                <Col xs={20} md={20} lg={20}>
                  <span>
                    <h1 className="p5-page-h1-header">
                      Users
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        id="userAdd"
                        className="user-add-icon sh-tour-user-add-icon"
                        alt="userAddIcon"
                        // onClick={() => this.handleAddClick()}
                        pull="right"
                        size="1x"
                      />
                    </h1>
                  </span>
                </Col>
              </Row>
              <Row justify="center">
                <Col xs={20} md={20} lg={20}>
                  <UserGrid />
                </Col>
              </Row>
            </div>
          )}
          {this.state.ShowVictimFormSection && (
            <div className="p5-page-container">
              <Row justify="center">
                <Col xs={20} md={20} lg={18}>
                  <UserForm
                    // loading={this.props.loading}
                    onSubmit={this.updateUser}
                    onCancel={this.handleCancel}
                    onDelete={this.deleteUser}
                    {...this.state.UserData}
                    // eslint-disable-next-line react/jsx-boolean-value
                    isUpdateMode={this.state.ShowDeleteBtn}
                  />
                </Col>
              </Row>
            </div>
          )}
        </ShepherdTour>
      </Spin>
    );
  }
}

UserManagementPageTour.propTypes = {
  loading: PropTypes.bool,
  dispatch: PropTypes.func,
  userId: PropTypes.number,
  ForceId: PropTypes.number,
  CurrentTourGuide: PropTypes.shape({
    TourGuideId: PropTypes.number
  }),
};

UserManagementPageTour.defaultProps = {
  loading: false,
  dispatch: () => {},
  userId: 0,
  ForceId: 0,
  CurrentTourGuide: {
    TourGuideId: 7
  },
};

function mapStateToProps(state) {
    const { Id, ForceId } = state.AccountState.currentUser;
    const { CurrentTourGuide, loading } = state.TourGuide;
  
    return {
      userId: Id,
      ForceId,
      CurrentTourGuide,
      loading,
    };
  }

const connectedListView = connect(mapStateToProps)(UserManagementPageTour);

export { connectedListView as default };
