import React from 'react';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  Spin,
} from 'antd';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import navigationActions from "../../../common/components/navigation/action";
import LEAForm from '../form';
import actions from '../actions';
import './LEAPage.css';

class EditPage extends React.Component {

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("force"));
    this.props.dispatch(actions.GetForceById(this.props.match.params.id));
  }

  updateForce = (val) => {
    this.props.dispatch(actions.UpdateForce(val, this.handleCallback))
  }

  handleCallback = () => {
    this.props.dispatch(push(`/force`));
  };

  deleteForce = () => {
    const { confirm } = Modal;
    const { currentForce, dispatch } = this.props;
    confirm({
      icon: <FontAwesomeIcon size="2x" pull="left" icon={faTrash} style={{ color: "#EF4E4E" }} />,
      // icon: <DeleteOutlined style={{ color: "#EF4E4E" }} />,
      /* eslint react/prop-types: 0 */
      content: <span style={{textAlign:"center"}}>All Areas, Teams and Users will be deleted and all registrations will be deregistered that are associated with this Force. Are you sure want to delete Force {currentForce.Name}?</span>,
      onOk() {
        /* eslint react/prop-types: 0 */
        dispatch(actions.DeleteForce(currentForce.Id));
      },
    });
  };

  handleCallback = () => {
    this.props.dispatch(push("/force"));
  };

  handleCancel = () => {
    this.props.dispatch(push("/force"));
  };

    render(){
        return(
          <div className="p5-page-container-small">
            <Row justify="center">
              <Col xs={20} md={20} lg={18}>
                <Spin spinning={this.props.loading}>
                  <LEAForm
                    loading={this.props.loading}
                    onSubmit={this.updateForce}
                    onCancel={this.handleCancel}
                    onDelete={this.deleteForce}
                    title="Edit Force"
                    isUpdateMode
                    {...this.props.currentForce}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        );
    }
}

function mapStateToProps(state) {
  const { currentForce, loading } = state.LEAState;
  return {
    currentForce,
    loading,
  };
}

EditPage.propTypes = {
  dispatch: () => {},
  match: PropTypes.shape(),
  currentForce: PropTypes.shape({}),
  loading: PropTypes.bool,
};

EditPage.defaultProps = {
  dispatch: () => {},
  match: {},
  currentForce: {},
  loading: false,
};

const connectedEditPage = connect(mapStateToProps)(EditPage);

export default connectedEditPage;
