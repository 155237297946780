import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

// import "antd/dist/antd.css";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Tooltip } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import HubGrid from "../hub-grid";
import { hasRights, rights } from "../../../common/utils/role-right-helper";
import "./HubPage.scss";

class HubPage extends React.Component {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("hub"));
  }

  handleAddClick = () => {
    this.props.dispatch(push("/hub/create"))
  };

  render() {
    return (
      <div className="p5-page-container">
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <div>
              <h1 className="p5-page-h1-header">
                Allocation
                { hasRights([rights.CanManageHub]) && (
                  <Tooltip title="New Allocation">
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      id="HubAdd"
                      className="hub-add-icon"
                      alt="hubAddIcon"
                      onClick={() => this.handleAddClick()}
                      pull="right"
                      size="1x"
                    />
                  </Tooltip>
                )}
              </h1>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} md={20} lg={18}>
            <HubGrid />
          </Col>
        </Row>
      </div>
    );
  }
}

HubPage.propTypes = {
  dispatch: PropTypes.func,
};

HubPage.defaultProps = {
  dispatch: () => {},
};

function mapStateToProps() {
  return {};
}

const connectedHubPage = connect(mapStateToProps)(HubPage);

export { connectedHubPage as default };
