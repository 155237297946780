import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const PostalCodeInput = (props) => {
  return(
    <Form.Item
      validateStatus={
      props.errors && props.errors.PostalCode && props.touched.PostalCode
        ? "error"
        : null
    }
      help={props.errors && props.touched.PostalCode && props.errors.PostalCode}
    >
      <h2 className="form-label">
        Postal Code
      </h2>
      <Input 
        type="text" 
        id="PostalCode" 
        className="form-input"
        value={props.values.PostalCode} 
        onChange={props.onChange}
      />
    </Form.Item>
  );
}

PostalCodeInput.propTypes = {
    onChange: () => {},
    errors: PropTypes.shape({
        PostalCode: PropTypes.string,
    }),
    values: PropTypes.shape({
        PostalCode: PropTypes.string,
    }),
    touched: PropTypes.shape({
        PostalCode: PropTypes.bool,
    })
}

PostalCodeInput.defaultProps = {
    onChange: () => {},
    errors: {},
    values: {},
    touched: {}
}

export default PostalCodeInput;
