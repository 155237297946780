import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { ShepherdTour, TourMethods } from 'react-shepherd'
import {RegistrationGridSteps, AdminRegistrationGridSteps, LiteRegistrationGridSteps, AdminLiteRegistrationGridSteps} from '../tour-steps';
import { RegistrationModal, LiteRegistrationModal } from "../../../common/components/registration-modal";
import navigationActions from "../../../common/components/navigation/action";
import { RegisteredGrid, AdminRegisteredGrid, LiteAdminRegisteredGrid, LiteRegisteredGrid } from "../../registeration/grid";
import { RegistrationEditModal } from '../../registeration/registration-edit-modal';
import RegisterAction from "../../registeration/actions";
import TourRegAction from "../actions";
import TourGuideStartModal from "../components/tour-guide-start-modal";
import { agencyService, AreaService } from '../../../services';
import "../shepardtour.css";
import "../../registeration/page/RegisterPage.css";
import { hasRights, rights } from "../../../common/utils/role-right-helper";
import { IsLiteVersion, IsFullVersion } from "../../../common/utils/system-version-helper";

const { tourOptions, RegistrationSteps } = RegistrationGridSteps;
const { AdminTourOptions, AdminRegistrationSteps } = AdminRegistrationGridSteps;
const { LiteRegistrationtourOptions, LiteRegistrationSteps } = LiteRegistrationGridSteps;
const { AdminLiteTourOptions, AdminLiteRegistrationSteps } = AdminLiteRegistrationGridSteps;

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class RegistrationTourPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ForceName: null,
      isRegisterVisible: false,
      registrationData: [],
      TourStartModalshow: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("register", true));
    this.setState({ TourStartModalshow: true });
    this.props.dispatch(TourRegAction.GetCurrentTourGuide(
      1,
      this.props.userId
    ));
    agencyService
      .getForceList(`$filter=Id eq ${this.props.ForceId}`)
      .then((response) => {
        const FName = response.data.value[0].Name;
        if (this.props.AreaId !== null) {
          AreaService.getGridList(`$filter=Id eq ${this.props.AreaId}`).then(
            (aResponse) => {
              const AName = aResponse.data.value[0].Name;
              this.setState({
                ForceName: `${FName} - ${AName}`,
              });
            }
          );
        } else {
          this.setState({
            ForceName: FName,
          });
        }
      });
    this.getRegistrationData();
    this.interval = setInterval(() => this.getRegistrationData(), 60000);
  }

  // componentDidUpdate = (oldProps) => {
  //   if (oldProps.userId === 0 && this.props.userId > 0) {
  //     // console.log("try again toure", this.props);
  //     this.props.dispatch(TourRegAction.GetCurrentTourGuide(
  //       1,
  //       this.props.userId
  //     ));
  //   }
  // };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  hideTourGuideLandingModal = () => {
    this.setState({ TourStartModalshow: false });
  };

  pivotCompleteData = () => ({
    TourId: this.props.CurrentTourGuide.TourGuideId,
    UserId: this.props.userId
  });

  CompleteTourGuide = () => {
    this.props.dispatch(TourRegAction.MarkTourGuideAsCompleted(
      this.pivotCompleteData(),
      this.props.dispatch(TourRegAction.CheckUserActiveTourGuides(this.props.userId))
    ));
  }

  getRegistrationData = () => {
    let search = "$orderby=RegistrationId asc&$filter=IsRegistered eq true";

    if (
      hasRights([rights.CanManageAreaUsers]) &&
      this.props.areaFilter !== null &&
      this.props.areaFilter !== 0
    )
      search += ` and AreaId eq ${this.props.areaFilter}`;

    if (
      hasRights([rights.CanManageTeamUsers]) &&
      this.props.teamFilter !== null &&
      this.props.teamFilter !== 0
    )
      search += ` and TeamId eq ${this.props.teamFilter}`;

    this.props.dispatch(
      TourRegAction.GetRegistrationList(search, this.setRegistrationData)
    );
  };

  filterByArea = (id) => {
    this.props.dispatch(RegisterAction.RegistrationAreaFilter(id));
    let search = "$orderby=RegistrationId asc&$filter=IsRegistered eq true";

    if (hasRights([rights.CanManageAreaUsers]) && id !== null && id !== 0)
      search += ` and AreaId eq ${id}`;

    this.props.dispatch(
      RegisterAction.GetRegistrationList(search, this.setRegistrationData)
    );
  };

  filterByTeam = (id) => {
    this.props.dispatch(RegisterAction.RegistrationTeamFilter(id));
    let search = "$orderby=RegistrationId asc&$filter=IsRegistered eq true";

    if (
      hasRights([rights.CanManageAreaUsers]) &&
      this.props.areaFilter !== null &&
      this.props.areaFilter !== 0
    )
      search += ` and AreaId eq ${this.props.areaFilter}`;

    if (hasRights([rights.CanManageTeamUsers]) && id !== null && id !== 0)
      search += ` and TeamId eq ${id}`;

    this.props.dispatch(
      RegisterAction.GetRegistrationList(search, this.setRegistrationData)
    );
  };

  setRegistrationData = (data) => {
    this.setState({
      registrationData: data,
    });
  };

  openEditModal = (Id, ConsumerId) => {
    this.props.dispatch(
      RegisterAction.openRegistrationEditModal(Id, ConsumerId)
    );
  };

  RefreshGrids = () => {
    this.getRegistrationData();
  };

  hideRegisterModal = () => {
    this.setState({ isRegisterVisible: false });
    this.RefreshGrids();
  };

  handleRegisterOk = () => {
    this.setState({ isRegisterVisible: false });
    this.RefreshGrids();
  };

  openVictimDataModal = (Id) => {
    this.props.dispatch(RegisterAction.openVictimDetailModal(Id));
  };

  render() {
    return (
      <>
        <div className="p5-grid-page-container registration-tour-start">
          {hasRights([rights.CanManageRegistration]) &&
          this.props.TeamId !== null &&
          this.props.TeamId !== 0 ? (
            <Row justify="center">
              <Col xs={24} md={24} lg={23}>
                <div>
                  <h1 className="register-grid-header">
                    {this.state.ForceName}
                    <Tooltip title="New Registration">
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        id="registerAdd"
                        className="register-add-icon"
                        alt="registerAddIcon"
                        onClick={() => this.setState({ isRegisterVisible: true })}
                        pull="right"
                        size="1x"
                      />
                    </Tooltip>
                  </h1>
                </div>
              </Col>
            </Row>
          ) : (
            <Row justify="center">
              <Col xs={24} md={24} lg={23}>
                <div>
                  <h1 className="register-grid-header">
                    {this.state.ForceName}
                  </h1>
                </div>
              </Col>
            </Row>
          )}
          <Row justify="center">
            <Col xs={24} md={24} lg={23}>
              {(hasRights([rights.CanManageAreaUsers]) ||
                hasRights([rights.CanManageTeamUsers])) &&
                IsFullVersion() && (
                  <ShepherdTour
                    steps={AdminRegistrationSteps}
                    tourOptions={AdminTourOptions}
                  >
                    <TourMethods>
                      {(tourContext) => (
                        <TourGuideStartModal
                          startTour={tourContext}
                          hideModal={this.hideTourGuideLandingModal}
                          showModal={this.state.TourStartModalshow}
                          completeGuide={this.CompleteTourGuide}
                          tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                          userId={this.props.userId}
                          tourName="registration"
                          title="Welcome to the Registration Tour Guide"
                          description="In this tour guide, you will learn about the registration grid where your organisations registered user can be viewed. We will explain each column of the grid and some important items to note while using the TecSAFE portal."
                        />
                      )}
                    </TourMethods>
                    <AdminRegisteredGrid
                      openModal={this.openEditModal}
                      registrationList={this.state.registrationData}
                      filterArea={this.filterByArea}
                      filterTeam={this.filterByTeam}
                      onGridRefresh={this.RefreshGrids}
                    />
                  </ShepherdTour>
                )}
              {hasRights([rights.CanManageAreaUsers]) === false &&
                hasRights([rights.CanManageTeamUsers]) === false &&
                IsFullVersion() && (
                  <ShepherdTour
                    steps={RegistrationSteps}
                    tourOptions={tourOptions}
                  >
                    <TourMethods>
                      {(tourContext) => (
                        <TourGuideStartModal
                          startTour={tourContext}
                          hideModal={this.hideTourGuideLandingModal}
                          showModal={this.state.TourStartModalshow}
                          completeGuide={this.CompleteTourGuide}
                          tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                          userId={this.props.userId}
                          tourName="registration"
                          title="Welcome to the Registration Tour Guide"
                          description="In this tour guide, you will learn about the registration grid where your organisations registered user can be viewed. We will explain each column of the grid and some important items to note while using the TecSAFE portal."
                        />
                      )}
                    </TourMethods>
                    <RegisteredGrid
                      viewDetails={this.openVictimDataModal}
                      openModal={this.openEditModal}
                      registrationList={this.state.registrationData}
                      onGridRefresh={this.RefreshGrids}
                    />
                  </ShepherdTour>
                )}
              {(hasRights([rights.CanManageAreaUsers]) ||
                hasRights([rights.CanManageTeamUsers])) &&
                IsLiteVersion() && (
                  <ShepherdTour
                    steps={AdminLiteRegistrationSteps}
                    tourOptions={AdminLiteTourOptions}
                  >
                    <TourMethods>
                      {(tourContext) => (
                        <TourGuideStartModal
                          startTour={tourContext}
                          hideModal={this.hideTourGuideLandingModal}
                          showModal={this.state.TourStartModalshow}
                          completeGuide={this.CompleteTourGuide}
                          tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                          userId={this.props.userId}
                          tourName="registration"
                          title="Welcome to the Registration Tour Guide"
                          description="In this tour guide, you will learn about the registration grid where your organisations registered user can be viewed. We will explain each column of the grid and some important items to note while using the TecSAFE portal."
                        />
                      )}
                    </TourMethods>
                    <LiteAdminRegisteredGrid
                      openModal={this.openEditModal}
                      registrationList={this.state.registrationData}
                      filterArea={this.filterByArea}
                      filterTeam={this.filterByTeam}
                      onGridRefresh={this.RefreshGrids}
                    />
                  </ShepherdTour>
                )}
              {hasRights([rights.CanManageAreaUsers]) === false &&
                hasRights([rights.CanManageTeamUsers]) === false &&
                IsLiteVersion() && (
                  <ShepherdTour
                    steps={LiteRegistrationSteps}
                    tourOptions={LiteRegistrationtourOptions}
                  >
                    <TourMethods>
                      {(tourContext) => (
                        <TourGuideStartModal
                          startTour={tourContext}
                          hideModal={this.hideTourGuideLandingModal}
                          showModal={this.state.TourStartModalshow}
                          completeGuide={this.CompleteTourGuide}
                          tourGuideId={this.props.CurrentTourGuide.TourGuideId}
                          userId={this.props.userId}
                          tourName="registration"
                          title="Welcome to the Registration Tour Guide"
                          description="In this tour guide, you will learn about the registration grid where your organisations registered user can be viewed. We will explain each column of the grid and some important items to note while using the TecSAFE portal."
                        />
                      )}
                    </TourMethods>
                    <LiteRegisteredGrid
                      viewDetails={this.openVictimDataModal}
                      openModal={this.openEditModal}
                      registrationList={this.state.registrationData}
                      onGridRefresh={this.RefreshGrids}
                    />
                  </ShepherdTour>
                )}
            </Col>
          </Row>
        </div>
        <RegistrationEditModal onRefresh={this.RefreshGrids} />
        {IsLiteVersion() && (
          <LiteRegistrationModal
            onSubmit={this.addNewRegistration}
            handleOk={this.handleRegisterOk}
            handleCancel={this.hideRegisterModal}
            isVisible={this.state.isRegisterVisible}
          />
        )}
        {IsFullVersion() && (
          <RegistrationModal
            onSubmit={this.addNewRegistration}
            handleOk={this.handleRegisterOk}
            handleCancel={this.hideRegisterModal}
            isVisible={this.state.isRegisterVisible}
          />
        )}
      </>
    );
  }
}

RegistrationTourPage.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  ForceId: PropTypes.number,
  TeamId: PropTypes.number,
  AreaId: PropTypes.number,
  teamFilter: PropTypes.number,
  areaFilter: PropTypes.number,
  userId: PropTypes.number,
  CurrentTourGuide: PropTypes.shape({
    TourGuideId: PropTypes.number
  }),
};

RegistrationTourPage.defaultProps = {
  dispatch: () => {},
  history: {
    push: () => {},
  },
  ForceId: null,
  AreaId: null,
  TeamId: null,
  teamFilter: null,
  areaFilter: null,
  userId: 0,
  CurrentTourGuide: {
    TourGuideId: 1
  },
};

function mapStateToProps(state) {
  const { teamFilter, areaFilter } = state.RegisterUserFormState;
  const { TeamId, ForceId, AreaId, Id } = state.AccountState.currentUser;
  const { CurrentTourGuide } = state.TourGuide;
  // console.log('loading Current Tour Guide info', state.TourGuide);
  return {
    teamFilter,
    areaFilter,
    TeamId,
    ForceId,
    AreaId,
    userId: Id,
    CurrentTourGuide,
  };  
}

const connectedRegisterPage = connect(mapStateToProps)(RegistrationTourPage);

export { connectedRegisterPage as default };
