import registrationConstants from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  victimData: {},
  regDetail: {},
  registrationList: [],
  areaFilter: 0,
  teamFilter: 0,
  RegistrationEditModalData: {
    show: false,
    loading: false,
    hasError: false,
    data: {},
  },
  ExtendModalData: {
    show: false,
    loading: false,
    hasError: false,
    data: {},
  },
  VictimModalData: {
    show: false,
    loading: false,
    hasError: false,
    data: {},
  },
  TapActivationLoading: false,
};

export default function imageDelete(state = initialState, action) {
  switch (action.type) {
    case registrationConstants.DELETE_REQUEST:
      return { ...state, loading: true, hasError: false };
    case registrationConstants.DELETE_SUCCESS:
      return { ...state, loading: false, hasError: false, url: action.url };
    case registrationConstants.DELETE_FAILURE:
      return { ...state, loading: false, hasError: true };
    case registrationConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: false,
        hasError: false,
        victimData: action.RegId,
      };
    case registrationConstants.CREATE_VICTIM_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case registrationConstants.CREATE_VICTIM_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case registrationConstants.CREATE_VICTIM_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case registrationConstants.GET_VICTIM_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case registrationConstants.GET_VICTIM_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        victimData: action.victim.data,
      };
    case registrationConstants.GET_VICTIM_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case registrationConstants.DELETE_VICTIM_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        RegistrationEditModalData: {
          ...state.RegistrationEditModalData,
          loading: true,
        }
      };
    case registrationConstants.DELETE_VICTIM_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        victimData: {},
        RegistrationEditModalData: {
          ...state.RegistrationEditModalData,
          loading: false,
        }
      };
    case registrationConstants.DELETE_VICTIM_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
        RegistrationEditModalData: {
          ...state.RegistrationEditModalData,
          loading: false,
        }
      };
    case registrationConstants.EXTEND_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case registrationConstants.EXTEND_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case registrationConstants.EXTEND_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case registrationConstants.REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        RegistrationEditModalData: {
          ...state.RegistrationEditModalData,
          loading: true,
          hasError: false,
        },
      };
    case registrationConstants.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        RegistrationEditModalData: {
          ...state.RegistrationEditModalData,
          loading: false,
          hasError: false,
        },
      };
    case registrationConstants.REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        RegistrationEditModalData: {
          ...state.RegistrationEditModalData,
          loading: false,
          hasError: true,
          error: action.error,
        },
      };
    case registrationConstants.UPDATE_VICTIM_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case registrationConstants.UPDATE_VICTIM_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case registrationConstants.UPDATE_VICTIM_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    case registrationConstants.GET_REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case registrationConstants.GET_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        regDetail: action.detail.data,
      };
    case registrationConstants.GET_REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case registrationConstants.HIDE_REGISTRATION_EDIT_MODAL:
      return {
        ...state,
        RegistrationEditModalData: {
          show: false,
          loading: false,
          hasError: false,
          data: {},
        },
      };
    case registrationConstants.TRACKAPHONE_ACTIVATE_REQUEST:
      return {
        ...state,
        TapActivationLoading: true
      };
    case registrationConstants.TRACKAPHONE_ACTIVATE_SUCCESS:
      return {
        ...state,
        TapActivationLoading: false
      };
    case registrationConstants.TRACKAPHONE_ACTIVATE_FAILURE:
      return {
        ...state,
        TapActivationLoading: false
      };
    case registrationConstants.REGISTRATION_DETAIL_REQUEST:
      return {
        ...state,
        RegistrationEditModalData: {
          show: true,
          loading: true,
          hasError: false,
        },
      };
    case registrationConstants.REGISTRATION_DETAIL_SUCCESS:
      return {
        ...state,
        RegistrationEditModalData: {
          show: true,
          loading: false,
          hasError: false,
          data: action.registrationData.data,
        },
      };
    case registrationConstants.REGISTRATION_DETAIL_FAILURE:
      return {
        ...state,
        RegistrationEditModalData: {
          show: true,
          loading: false,
          hasError: true,
          error: action.error,
          data: {},
        },
      };
    case registrationConstants.TOUR_REGISTRATION_DETAIL_REQUEST:
      return {
        ...state,
        RegistrationEditModalData: {
          show: true,
          loading: true,
          hasError: false,
        },
      };
    case registrationConstants.TOUR_REGISTRATION_DETAIL_SUCCESS:
      return {
        ...state,
        RegistrationEditModalData: {
          show: true,
          loading: false,
          hasError: false,
          data: action.registrationData[0],
        },
      };
    case registrationConstants.REGISTRATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        registrationList: [],
      };
    case registrationConstants.REGISTRATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        // registrationList: action.registrationList,
      };
    case registrationConstants.REGISTRATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        registrationList: [],
      };
    case registrationConstants.HIDE_EXTEND_MODAL:
      return {
        ...state,
        ExtendModalData: {
          show: false,
          loading: false,
          hasError: false,
          data: {},
        },
      };
    case registrationConstants.EXTEND_DETAIL_REQUEST:
      return {
        ...state,
        ExtendModalData: {
          show: true,
          loading: true,
          hasError: false,
        },
      };
    case registrationConstants.EXTEND_DETAIL_SUCCESS:
      return {
        ...state,
        ExtendModalData: {
          show: true,
          loading: false,
          hasError: false,
          data: action.ExtendData,
        },
      };
    case registrationConstants.EXTEND_DETAIL_FAILURE:
      return {
        ...state,
        ExtendModalData: {
          show: true,
          loading: false,
          hasError: true,
          error: action.error,
          data: {},
        },
      };
    case registrationConstants.TOUR_EXTEND_DETAIL_REQUEST:
      return {
        ...state,
        ExtendModalData: {
          show: true,
          loading: true,
          hasError: false,
        },
      };
    case registrationConstants.TOUR_EXTEND_DETAIL_SUCCESS:
      return {
        ...state,
        ExtendModalData: {
          show: true,
          loading: false,
          hasError: false,
          data: action.ExtendData,
        },
      };

    case registrationConstants.UPDATE_EXTEND_REQUEST:
      return {
        ...state,
        ExtendModalData: {
          ...state.ExtendModalData,
          loading: true,
          hasError: false,
        },
      };
    case registrationConstants.UPDATE_EXTEND_SUCCESS:
      return {
        ...state,
        ExtendModalData: {
          ...state.ExtendModalData,
          loading: false,
          hasError: false,
        },
      };
    case registrationConstants.UPDATE_EXTEND_FAILURE:
      return {
        ...state,
        ExtendModalData: {
          ...state.ExtendModalData,
          loading: false,
          hasError: true,
          error: action.error,
        },
      };
    case registrationConstants.ADD_MIN_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        RegistrationEditModalData: {
          ...state.RegistrationEditModalData,
          loading: true,
          hasError: false,
        },
      };
    case registrationConstants.ADD_MIN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        RegistrationEditModalData: {
          ...state.RegistrationEditModalData,
          loading: false,
          hasError: false,
        },
      };
    case registrationConstants.ADD_MIN_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        RegistrationEditModalData: {
          ...state.RegistrationEditModalData,
          loading: false,
          hasError: true,
          error: action.error,
        },
      };
    case registrationConstants.REGISTRATION_AREA_FILTER:
      return {
        ...state,
        areaFilter: action.area,
        teamFilter: 0,
      };
    case registrationConstants.REGISTRATION_TEAM_FILTER:
      return {
        ...state,
        teamFilter: action.team,
      };
      case registrationConstants.HIDE_VICTIM_MODAL:
        return {
          ...state,
          VictimModalData: {
            show: false,
            loading: false,
            hasError: false,
            data: {},
          },
        };
      case registrationConstants.VICTIM_DETAIL_REQUEST:
        return {
          ...state,
          VictimModalData: {
            show: true,
            loading: true,
            hasError: false,
          },
        };
      case registrationConstants.VICTIM_DETAIL_SUCCESS:
        return {
          ...state,
          VictimModalData: {
            show: true,
            loading: false,
            hasError: false,
            data: action.VictimData,
          },
        };
      case registrationConstants.VICTIM_DETAIL_FAILURE:
        return {
          ...state,
          VictimModalData: {
            show: true,
            loading: false,
            hasError: true,
            error: action.error,
            data: {},
          },
        };
    default:
      return state;
  }
}
