import registrationConstants from "./constants";

const initialState = { loading: false, hasError: false };

export default function UnregisterModalReducer(state = initialState, action) {
  switch (action.type) {
    case registrationConstants.DEREGISTER_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case registrationConstants.DEREGISTER_REGISTRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case registrationConstants.DEREGISTER_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };    
    default:
      return state;
  }
}
