import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";

import { RegistrationModal } from "../../../common/components/registration-modal";
import navigationActions from "../../../common/components/navigation/action";
import UnregisteredGrid from "../../registeration/grid/UnregisteredGrid";
import RegisterAction from "../../registeration/actions";
import "../../registeration/page/RegisterPage.css";
import { hasRights, rights } from "../../../common/utils/role-right-helper";

class UnregisteredPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isRegisterVisible: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("unregister"));
    this.props.dispatch(
      RegisterAction.GetRegistrationList(
        "$orderby=RegistrationId asc&$filter=IsRegistered eq false"
      )
    );
  }

  openEditModal = (Id, ConsumerId) => {
    this.props.dispatch(RegisterAction.openRegistrationEditModal(Id, ConsumerId));
  };

  RefreshGrids = () => {
    this.props.dispatch(
    RegisterAction.GetRegistrationList(
        "$orderby=RegistrationId asc&$filter=IsRegistered eq false"
    )
    );
  };

  hideRegisterModal = () => {
    this.setState({ isRegisterVisible: false });
  };

  handleRegisterOk = () => {
    this.setState({ isRegisterVisible: false });
    this.RefreshGrids();
  };

  render() {
    return (
      <>
        <div className="p5-grid-page-container">
          {hasRights([rights.CanManageRegistration]) && (
            <Row justify="center">
              <Col xs={24} md={24} lg={20}>
                <div>
                  <h1 className="register-grid-header">
                    Unregistered
                    <Tooltip title="New Registration">
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        id="registerAdd"
                        className="register-add-icon"
                        alt="registerAddIcon"
                        onClick={() => this.setState({ isRegisterVisible: true })}
                        pull="right"
                        size="1x"
                      />
                    </Tooltip>
                  </h1>
                </div>
              </Col>
            </Row>
          )}
          <Row justify="center">
            <Col xs={24} md={24} lg={20}>
              <UnregisteredGrid openModal={this.openEditModal} />
            </Col>
          </Row>
        </div>
        <RegistrationModal
          onSubmit={this.addNewRegistration}
          handleOk={this.handleRegisterOk}
          handleCancel={this.hideRegisterModal}
          isVisible={this.state.isRegisterVisible}
        />
      </>
    );
  }
}

UnregisteredPage.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

UnregisteredPage.defaultProps = {
  dispatch: () => {},
  history: {
    push: () => {},
  },
};

function mapStateToProps() {
  return {};
}

const connectedUnregisteredPage = connect(mapStateToProps)(UnregisteredPage);

export { connectedUnregisteredPage as default };
